<template>
  <v-container fluid>
    <v-card class="px-5 py-3  br-15 w-100" elevation="0">
      <v-row>
        <v-col class="flex-grow-1">
          <v-toolbar-title class="page-title"
            >Avisos aplicativo
          </v-toolbar-title>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
          class="d-flex justify-end"
        >
          <v-btn
            v-if="selected.length >= 1"
            class="ml-4 btn-excluir new-button"
            color="error"
            @click="excluir()"
          >
            <v-icon left>mdi-delete</v-icon> Excluir
            <strong>{{ selected.length }} </strong>
            {{ selected.length === 1 ? "aviso" : "avisos" }}
          </v-btn>
          <Adicionar @filtrar="filtrar()" />
          <ImportarAvisos @filtrar="filtrar()" />
          <v-btn class="new-button ml-4" color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
        </v-col>
      </v-row>
      <Filtro ref="filtro" @filtrar="filtrar()" class="my-3" />
      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            show-select
            selectable-key="id_aviso"
            item-key="id_aviso"
            :headers="headers"
            :items="dados"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-options': [5, 10, 15, 25, 50, 100],
              itemsPerPageText: 'Linhas por páginas',
              ofText: 'de',
              pageText: '{0}-{1} de {2}',
            }"
            :server-items-length="total"
            :options.sync="pagination"
            multi-sort
            class="elevation-0 tabela"
          >
            <template v-slot:[`item.data_criacao`]="{ item }">
              <span>{{ $moment(item.data_criacao).format("DD/MM/YYYY") }}</span>
            </template>
            <template v-slot:[`item.arquivo`]="{ item }">
              <a
                v-if="item.arquivo"
                :href="$assinarUrl(item.arquivo)"
                target="_blank"
              >
                <v-icon>mdi-file-document-outline</v-icon>
              </a>
            </template>
            <template v-slot:[`item.data_visualizacao`]="{ item }">
              <span>{{
                item.data_visualizacao &&
                  $moment(item.data_visualizacao).format("DD/MM/YYYY")
              }}</span>
            </template>
            <template v-slot:[`item.mensagem`]="{ item }">
              <span>{{ resumirMensagem(item.mensagem) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span>{{ traduzirStatus(item.status) }}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Filtro from "./Filtro.vue";
import Adicionar from "./Adicionar.vue";
import ImportarAvisos from "./Importar.vue";

export default {
  components: {
    Filtro,
    Adicionar,
    ImportarAvisos,
  },
  provide() {
    return {
      listaCampos: this.listaCampos(),
    };
  },
  data: () => ({
    dados: [],
    pagination: {},
    total: null,
    singleSelect: false,
    selected: [],
    headers: [
      { text: "Filial", value: "filial", class: "th-separador" },
      { text: "Mensagem", value: "mensagem", class: "th-separador" },
      {
        text: "Status",
        value: "status",
        align: "center",
        class: "th-separador",
      },
      {
        text: "Enviado em:",
        value: "data_criacao",
        align: "center",
        class: "th-separador",
      },
      {
        text: "Arquivo",
        value: "arquivo",
        align: "center",
        class: "th-separador",
      },
      { text: "Visualizado em:", value: "data_visualizacao", align: "center" },
    ],
  }),
  methods: {
    async listaCampos() {
      var response = await this.$http().post("/app/avisos/filtros");

      return response.data;
    },
    async filtrar() {
      const filtro = { ...this.$refs?.filtro?.filtro };
      const data = { ...filtro };
      data.pagination = this.pagination;

      data.mesInicial = this.$moment(data.mesInicial, "YYYY-MM")
        .startOf("month")
        .format("YYYY-MM-DD");
      data.mesFinal = this.$moment(data.mesFinal, "YYYY-MM")
        .endOf("month")
        .format("YYYY-MM-DD");

      const response = await this.$http().post("/app/avisos/lista", {
        data,
      });

      this.total = response.data.length ? response.data[0].total : 0;

      this.dados = response.data;
    },

    async exportar() {
      const filtro = { ...this.$refs?.filtro?.filtro };
      const data = { ...filtro };
      data.pagination = this.pagination;

      data.mesInicial = this.$moment(data.mesInicial, "YYYY-MM")
        .startOf("month")
        .format("YYYY-MM-DD");
      data.mesFinal = this.$moment(data.mesFinal, "YYYY-MM")
        .endOf("month")
        .format("YYYY-MM-DD");

      const response = await this.$http().post("/app/avisos/exportar", {
        data,
      });

      window.location = this.$http("baseURL") + response.data.url;
    },

    async excluir() {
      const response = await this.$http().post("/app/avisos/excluir", {
        data: this.selected.map((aviso) => aviso.id_aviso),
      });

      if (response.status == 200) {
        this.filtrar();
        this.selected = [];
        this.$dialog.notify.success("Avisos excluído com sucesso", {
          position: "top-right",
          timeout: 5000,
        });
      } else {
        this.$dialog.notify.error("Erro ao excluir avisos", {
          position: "top-right",
          timeout: 5000,
        });
      }
    },

    resumirMensagem(mensagem) {
      const limite = 80;
      return mensagem.length > limite
        ? mensagem.substring(0, limite) + "..."
        : mensagem;
    },
    traduzirStatus(status) {
      switch (status.toString()) {
        case "0":
          return "Não lido";
        case "1":
          return "Lido";
        case "2":
          return "App atualizado";
        default:
          return status;
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.btn-excluir strong {
  margin: 0 4px;
}
</style>
