var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-toolbar-title',{staticClass:"ml-2 mt-2"},[_vm._v("Acessos e Logs")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"8","xl":"8","align":"end"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.exportar()}}},[_c('v-list-item-title',[_vm._v(" Exportar Consolidado por Mês ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportar(true)}}},[_c('v-list-item-title',[_vm._v(" Exportar Consolidado por Dia ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportarDetalhado()}}},[_c('v-list-item-title',[_vm._v(" Exportar Detalhado ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportarConsolidadoFornecedor()}}},[_c('v-list-item-title',[_vm._v(" Exportar Consolidado Fornecedor ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportarDetalhadoFornecedor()}}},[_c('v-list-item-title',[_vm._v(" Exportar Detalhado Fornecedor ")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-2 pt-4 px-2",attrs:{"elevation":"1"}},[_c('filtro',{ref:"filtro",on:{"filtrar":_vm.filtrar}})],1),_c('v-card',{staticClass:"my-4 pt-2 px-2",attrs:{"elevation":"1"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dados,"items-per-page":5,"footer-props":{
                'items-per-page-options': [5, 10, 15, 25, 50, 100],
            },"options":_vm.pagination,"multi-sort":""},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.detalhado}})],1),_c('v-overlay',{attrs:{"value":_vm.carregando,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"width":"95%"},model:{value:(_vm.dialogDetalhado),callback:function ($$v) {_vm.dialogDetalhado=$$v},expression:"dialogDetalhado"}},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1 primary white--text"},[_vm._v(" Detalhado ")]),_c('v-data-table',{staticClass:"elevation-0 my-4",attrs:{"headers":_vm.headersDetalhado,"items":_vm.dadosDetalhado,"items-per-page":5,"footer-props":{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                },"options":_vm.paginationDetalhado,"multi-sort":""},on:{"update:options":function($event){_vm.paginationDetalhado=$event}}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialogDetalhado = false}}},[_vm._v(" ok ")])],1)],1)],1),_c('v-snackbar',{staticStyle:{"z-index":"400"},attrs:{"color":_vm.alertaColor,"timeout":_vm.alertaTimeout,"top":""},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_c('v-alert',{attrs:{"type":_vm.alertaColor}},[_vm._v(" "+_vm._s(_vm.alertaTexto)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }