<template>
    <div>
        <div class="resumo">
            <div class="resumo-title">Resumo do planejamento</div>

            <div class="resumo-conteudo">
                <div class="resumo-item">
                    <span class="titulo">Vigência do planejamento</span>

                    <p>
                        Semana: <strong>{{ semana }}</strong>
                    </p>
                    <p>
                        Data inicio:
                        <strong>{{ dataInicio }}</strong>
                    </p>
                    <p>
                        Data fim:
                        <strong>{{ dataFim }}</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <span class="titulo">Detalhes do espaço:</span>
                    <p>
                        Bandeira:
                        <strong>{{ bandeira }} </strong>
                    </p>
                    <p>
                        Ativação: <strong>{{ ativacao }}</strong>
                    </p>
                    <p>
                        Categoria: <strong>{{ categoria }}</strong>
                    </p>
                    <p>
                        Device: <strong>{{ devices }}</strong>
                    </p>
                </div>
                <v-divider />
                <div class="resumo-item">
                    <p class="titulo">Informações do fornecedor:</p>
                    <p>
                        Fornecedor: <strong>{{ fornecedor }}</strong>
                    </p>
                    <p>
                        Ação: <strong>{{ value.acao }}</strong>
                    </p>
                    <p>
                        Marca: <strong>{{ marcas }}</strong>
                    </p>
                    <p>
                        Plano: <strong>{{ value.plano }}</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import useFiltros from '@/hooks/useFiltros'
export default {
    setup() {
        const { isLoading, isError, isFetching, data, error, refetch } =
            useFiltros('/planejamentoEcommerce/listaCampos')
        return {
            isLoading,
            isError,
            isFetching,
            lista: data,
            error,
            refetch,
        }
    },
    props: ['value'],
    async created() {},
    data() {
        return {}
    },
    computed: {
        dataInicio() {
            return this.$moment(this.value.data_inicio).format('DD/MM/YYYY')
        },
        dataFim() {
            return this.$moment(this.value.data_fim).format('DD/MM/YYYY')
        },
        semana() {
            if (this.value.semana?.length == 1){
                const sem = this.value.semana[0];
                return `Semana ${sem?.semana}`
            }
            return ` ${this.value.semana?.length || 0} semanas selecionadas`
        },
        devices() {
            if (this.value.device && this.value.device.length > 0) {
                return this.value.device.join(', ')
            }
            return ''
        },
        marcas() {
            if (this.value.marca && this.value.marca.length > 0) {
                return this.value.marca.join(', ')
            }
            return ''
        },
        bandeira() {
            if (this.value.id_bandeira) {
                if (this.value.id_bandeira == '1') return 'Raia'
                if (this.value.id_bandeira == '2') return 'Drogasil'
            }
            return ''
        },
        ativacao() {
            if (!this.lista.ativacoes || !this.value.id_ativacao) return ''
            const ativacao = this.lista.ativacoes.find(
                (item) => item.value === this.value.id_ativacao
            )
            if (ativacao) return ativacao.text
            return ''
        },
        categoria() {
            if (!this.lista.categorias || !this.value.id_categoria) return ''
            const categoria = this.lista.categorias.find(
                (item) => item.value === this.value.id_categoria
            )
            if (categoria) return categoria.text
            return ''
        },
        fornecedor() {
            if (!this.lista.fornecedor || !this.value.id_fornecedor) return ''
            const fornecedor = this.lista.fornecedor.find(
                (item) => item.value === this.value.id_fornecedor
            )
            if (fornecedor) return fornecedor.text
            return ''
        },
    },
    methods: {
        formataData(data) {
            return this.$moment(data).format('DD/MM/YYYY')
        },
        formatarMes(mes) {
            return this.$moment(mes, 'YYYY-MM')
                .format('MMMM [de] YYYY')
                .capitalize()
        },
    },
}
</script>
<style lang="scss">
.resumo {
    .resumo-title {
        font-size: 1.1rem;
        background: var(--v-primary-base);
        text-align: center;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        border-radius: 8px 8px 0 0;
    }
    .resumo-conteudo {
        display: grid;
        grid-template-columns: 1fr 20px 1fr 20px 1fr;
        grid-gap: 15px;
        align-items: center;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        font-weight: 500;
        font-size: 0.8rem !important;
        color: #808080;
        border: 1px solid #ddd;
        p {
            margin: 0;
        }
        .resumo-item {
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            border-radius: 8px;
            background: #eaeaea;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .titulo {
                font-weight: bold;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>