<template>
  <div>
    <v-btn class="new-button ml-4" color="primary" @click="abrirDialogo">
      <v-icon left>mdi-upload</v-icon>
      Importar avisos
    </v-btn>
    <v-dialog content-class="br-15" v-model="dialog" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title>
            Importar Avisos!
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialog = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-alert type="info">
            <p>
              O arquivo CSV deve conter as seguintes colunas: id_java e mensagem
            </p>
          </v-alert>
          <v-alert
            :type="alerta.type"
            transition="scale-transition"
            dismissible
            v-model="alerta.mostrar"
          >
            <p v-html="alerta.mensagem"></p>
          </v-alert>
          <v-row>
            <v-col cols="12" class="">
              <v-form v-model="isCsv">
                <v-file-input
                  label="Arquivo CSV"
                  outlined
                  dense
                  v-model="csvModelo"
                  :rules="regra.csv"
                  accept=".csv"
                ></v-file-input>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mx-3 my-3 new-button"
            :disabled="!isCsv"
            @click="importarAvisos()"
          >
            <v-icon left>mdi-upload</v-icon>
            Importar avisos
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      csvModelo: null,
      isCsv: false,
      carregando: false,
      alerta: {
        mostrar: false,
        type: "success",
        mensagem: "",
      },
      regra: {
        csv: [
          (v) => !!v || "Selecione um arquivo.",
          (v) =>
            (v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
            "O arquivo deve ser do tipo CSV.",
        ],
      },
    };
  },

  methods: {
    abrirDialogo() {
      this.csvModelo = null;
      this.dialog = true;
    },
    async importarAvisos() {
      this.carregando = true;

      const parseCsv = async (csv) => {
        return new Promise((resolve) => {
          this.$papa.parse(csv, {
            delimiter: ";",
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function(results) {
              resolve(results);
            },
          });
        });
      };
      var csv = await parseCsv(this.csvModelo);

      if (csv.errors.length) {
        this.alerta = {
          mostrar: true,
          type: "error",
          mensagem:
            "Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.",
        };
        this.carregando = false;
        return;
      }
      try {
        const formData = new FormData();

        formData.append("files", this.csvModelo);
        // console.log(csv.data)

        const response = await this.$http().post(
          "/app/avisos/importar",
          formData
        );

        if (response.data.sucesso) {
          this.$dialog.notify.success("Importação realizada com sucesso.", {
            position: "top-right",
            timeout: 5000,
          });
          this.dialog = false;
        } else if (response.data.erros) {
          const mensagens = response.data.erros
            .map((erro) => erro.msg)
            .join("<br>");
          this.alerta = {
            mostrar: true,
            type: "error",
            mensagem: "Erro ao processar arquivo CSV. <br>" + mensagens,
          };
        }
      } catch (error) {
        console.log(error);

        this.alerta = {
          mostrar: true,
          type: "error",
          mensagem: "Erro ao importar avisos.",
        };
      }
      this.$emit("filtrar");

      this.carregando = false;
    },
  },
};
</script>

<style></style>
