<template>
    <v-container fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Preços Campanha</v-toolbar-title
                    >
                </v-col>
                <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                    <v-btn
                        class="ml-2 my-1 new-button"
                        color="primary"
                        @click="exportar()"
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    </v-btn>
                    <v-btn
                        class="ml-2 my-1 new-button"
                        color="primary"
                        :disabled="mes < mesProximo"
                        @click="abrirImportar()"
                    >
                        <v-icon left>mdi-upload</v-icon> Importar
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="busca"
                        label="Pesquisar"
                        class="input-button input-cinza"
                        dense
                        outlined
                        hide-details
                    >
                        <template>
                            <v-icon slot="append" color="white"
                                >mdi-magnify</v-icon
                            >
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                    <v-form ref="formFiltro">
                        <MesInput
                            label="Mês"
                            v-model="mes"
                            :rules="regra.mes"
                            required
                        ></MesInput>
                    </v-form>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
                    <v-btn
                        class="ml-2 my-1 new-button"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table
                :headers="headers"
                :items="dados"
                :search="busca"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }"
                multi-sort
                class="tabela elevation-0"
            >
                <template v-slot:[`item.botao`]="{ item }">
                    <v-icon
                        v-if="mes >= mesProximo"
                        small
                        class="mr-2"
                        color="primary"
                        @click="abrirEditar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>

                <template v-slot:[`body.append`]>
                    <tr>
                        <td class="text-center" colspan="2"></td>

                        <td class="text-center">
                            <b>TOTAL:</b>
                        </td>
                        <td class="text-center">
                            {{ dados.total_qtdlojas }}
                        </td>
                        <td class="text-center">
                            {{ dados.total_adesao }}
                        </td>
                        <td class="text-center">
                            {{ dados.total_diamante }}
                        </td>
                        <td class="text-center">
                            {{ dados.total_ouro }}
                        </td>
                        <td class="text-center">
                            {{ dados.total_prata }}
                        </td>
                        <td class="text-center">
                            {{ dados.total_bronze }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <v-card class="px-5 py-3 br-15 w-100 mt-6" elevation="0">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Campanhas</v-toolbar-title
                    >
                </v-col>
                    <v-btn
                        class="ml-2 mr-4 my-1 new-button"
                        color="primary"
                        @click="abrirCampanha()"
                    >
                        <v-icon left>mdi-plus</v-icon> Nova Campanha
                    </v-btn>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headersCampanhas"
                        :items="dadosCampanhas"
                        :search="busca"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                        multi-sort
                        class="tabela elevation-0"
                    >
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="dialogoCampanha" content-class="br-15" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Criar nova campanha </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoCampanha = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-form ref="formCampanha">
                        <v-row class="mt-4">
                            <v-col cols="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense
                                    class="input-cinza"
                                    label="Nome da campanha"
                                    required
                                    :rules="regra.campanha"
                                    v-model="campanha.nome"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <MesInput
                                    label="Mês Inicial"
                                    :min="mesProximo"
                                    v-model="campanha.mesInicial"
                                
                                    required
                                ></MesInput>
                            </v-col>
                            <v-col cols="6">
                                <MesInput
                                    name="mesFinal"
                                    :min="mesProximo"
                                    label="Mês Final"
                                    v-model="campanha.mesFinal"

                                    required
                                ></MesInput>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="mr-3 mb-3 new-button px-5"
                        @click="criarCampanha()"
                    >
                        <v-icon left>mdi-check-circle</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogo" width="600">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Editar
                </v-card-title>
                <v-card-text class="mt-4">
                    <v-form v-model="validaForm">
                        <v-row no-gutters class="mt-4">
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    label="ID Preço Campanha"
                                    v-model="precoCampanha.id_precocampanha"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <v-autocomplete
                                    item-text="text"
                                    item-value="value"
                                    outlined
                                    dense
                                    label="Cluster Tabela/Rede"
                                    :items="lista.clusterstabela"
                                    v-model="precoCampanha.ids_cluster_tabela"
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                                class="px-2"
                            >
                                <v-autocomplete
                                    item-text="text"
                                    item-value="value"
                                    outlined
                                    dense
                                    label="Campanhas"
                                    :items="lista.campanhas"
                                    v-model="precoCampanha.id_campanha"
                                ></v-autocomplete>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <v-text-field
                                    outlined
                                    dense
                                    disabled
                                    label="Mês"
                                    v-model="precoCampanha.mes_nome"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <v-text-field
                                    outlined
                                    dense
                                    type="number"
                                    label="Qtd Lojas"
                                    v-model="precoCampanha.quantidade_lojas"
                                ></v-text-field>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <vuetify-money
                                    outlined
                                    dense
                                    label="Adesão"
                                    v-model="precoCampanha.adesao"
                                    :options="options"
                                    clearable="clearable"
                                ></vuetify-money>
                            </v-col>

                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <vuetify-money
                                    outlined
                                    dense
                                    label="Diamante"
                                    v-model="precoCampanha.diamante"
                                    :options="options"
                                    clearable="clearable"
                                ></vuetify-money>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <vuetify-money
                                    outlined
                                    dense
                                    label="Ouro"
                                    v-model="precoCampanha.ouro"
                                    :options="options"
                                    clearable="clearable"
                                ></vuetify-money>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <vuetify-money
                                    outlined
                                    dense
                                    label="Prata"
                                    v-model="precoCampanha.prata"
                                    :options="options"
                                    clearable="clearable"
                                ></vuetify-money>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                class="px-2"
                            >
                                <vuetify-money
                                    outlined
                                    dense
                                    label="Bronze"
                                    v-model="precoCampanha.bronze"
                                    :options="options"
                                    clearable="clearable"
                                ></vuetify-money>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        :disabled="!validaForm"
                        @click="editar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoImportar" content-class="br-15" width="90%">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        Importar CSV para
                    {{ $moment(mes).format("MMMM [de] YYYY") }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoImportar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px"
                    >
                        <ul>
                            <li>
                                A base utilizada para importação deve ser
                                parecida com base exportada.
                            </li>
                            <li>
                                As colunas
                                <strong>cluster_tabela</strong>
                                ,<strong>id_campanha</strong>,
                                <strong>quantidade_lojas</strong> ,
                                <strong>adesao</strong> ,
                                <strong>diamante</strong> ,
                                <strong>ouro</strong>, <strong>prata</strong>,
                                <strong>bronze</strong>, são obrigatórias
                                (<strong
                                    >e os nomes devem ser identicos aos da base
                                    exportada</strong
                                >), as demais colunas podem ser excluidas.
                            </li>
                            <li>
                                As colunas <b>em valores</b> devem estar no
                                formato moeda "<b>R$ 0.000,00</b>" com virgula
                                "," como separador decimal e ponto "." como
                                separador de milhar.
                            </li>
                            <li>
                                A coluna <strong>mes</strong> não é considerada
                                na importação.
                            </li>

                            <li>
                                O <strong>mês</strong> considerado na importação
                                é o mês selecionano no
                                <strong
                                    >filtro da lista de Preços Campanha</strong
                                >.
                            </li>
                        </ul>
                    </v-alert>
                    <v-row no-gutters>
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input
                                    label="Arquivo CSV"
                                    outlined
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col>
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon>
                                Importar Arquivo
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6" class="mb-3">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name="erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left
                                                >mdi-microsoft-excel</v-icon
                                            >
                                            Exportar Erros
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row>
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros"
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            headers: [
                {
                    text: "Campanha",
                    value: "campanha",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cluster/Rede",
                    value: "cluster_tabela",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Mês/Ano",
                    value: "mes_nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Qtd Lojas",
                    value: "quantidade_lojas",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Adesão",
                    value: "adesao",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Diamante",
                    value: "diamante",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Ouro",
                    value: "ouro",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Prata",
                    value: "prata",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Bronze",
                    value: "bronze",
                    align: "center",
                    class: "th-separador",
                },
                { text: "", value: "botao", align: "center", sortable: false },
            ],
            headersCampanhas: [
                {
                    text: "Campanha",
                    value: "campanha",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Mês inicial",
                    value: "mes_inicial",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Mês final",
                    value: "mes_final",
                    align: "center",
                    class: "th-separador",
                },
            ],
            dados: [],
            dadosCampanhas: [],
            mes: null,
            precoCampanha: {
                id_precocampanha: null,
                mes: null,
                id_cluster: null,
                id_campanha: null,
                quantidade_lojas: null,
                adesao: null,
                diamante: null,
                ouro: null,
                prata: null,
                bronze: null,
                cluster: null,
                campanha: null,
            },
            pagination: {},
            paginationErros: {},
            total: null,
            carregando: false,
            busca: null,
            dialogo: false,
            dialogoImportar: false,
            dialogoCampanha: false,
            validaForm: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            campanha: {
                campanha: null,
                mesInicial: null,
                mesFinal: null,
            },
            arquivoCSV: null,
            lista: {
                macrofornecedor: [],
            },
            regra: {
                csv: [
                    (v) => !!v || "Selecione um arquivo.",
                    (v) =>
                        (!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo CSV.",
                ],
                mes: [(v) => !!v || "O campo mês é obrigatório."],
                campanha: [
                    (v) => !!v || "O campo campanha é obrigatório.",
                ],
        
            },
            isCsv: false,
            headerErros: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            erros: [],
            errosLabels: { msg: "Descrição do Erro" },
            erro: false,
            options: {
                locale: "pt-BR",
                prefix: "R$",
                suffix: "",
                length: 11,
                precision: 2,
            },
        };
    },
    watch: {
        pagination: {
            handler() {
                this.filtrar();
            },
            deep: true,
        },
    },
    async created() {
        this.mes = this.mesProximo;

        var response = await this.$http().post("/campanha/listaAuxiliares", {});
        var lista = response.data;
        this.lista.cluster = lista.clusters;
        this.lista.campanhas = lista.campanhas;
        this.lista.clusterstabela = lista.clusterstabela

        this.filtrar();
    },
    computed: {
        mesProximo() {
            return this.$moment().add(1, "months").format("YYYY-MM");
        },
    },
    methods: {
        async filtrar() {
            // if(!this.$refs.formFiltro.validate()) return

            this.carregando = true;

            var data = { mes: this.mes + "-01" };

            data.pagination = this.pagination;
            data.busca = this.busca;

            var response = await this.$http().post("/campanha/lista", {
                data: data,
            });

            this.dados = response.data.lista;
            this.dadosCampanhas = response.data.listaCampanhas;

            const adesao = response.data.lista
                .map((x) => this.moedaParaNumero(x.adesao))
                .reduce((a, b) => a + b, 0);
            const diamante = response.data.lista
                .map((x) => this.moedaParaNumero(x.diamante))
                .reduce((a, b) => a + b, 0);
            const ouro = response.data.lista
                .map((x) => this.moedaParaNumero(x.ouro))
                .reduce((a, b) => a + b, 0);
            const prata = response.data.lista
                .map((x) => this.moedaParaNumero(x.prata))
                .reduce((a, b) => a + b, 0);
            const bronze = response.data.lista
                .map((x) => this.moedaParaNumero(x.bronze))
                .reduce((a, b) => a + b, 0);
            const qtdlojas = response.data.lista
                .map((x) => parseInt(x.quantidade_lojas || 0))
                .reduce((a, b) => a + b, 0);

            this.dados.total_adesao = adesao.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            this.dados.total_diamante = diamante.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            this.dados.total_ouro = ouro.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            this.dados.total_prata = prata.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            this.dados.total_bronze = bronze.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            this.dados.total_qtdlojas = qtdlojas;

            this.carregando = false;
        },
        moedaParaNumero(valor) {
            if (!valor) return null;
            return parseInt(valor.replace(/[^0-9,]*/g, "").replace(",", "."));
        },
        async exportar() {
            this.carregando = true;

            var data = { mes: this.mes + "-01" };

            var response = await this.$http().post("/campanha/exportar", {
                data: data,
            });

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        abrirEditar(item) {
            this.precoCampanha = {
                ...item,
                adesao: this.moedaParaNumero(item.adesao),
                diamante: this.moedaParaNumero(item.diamante),
                ouro: this.moedaParaNumero(item.ouro),
                prata: this.moedaParaNumero(item.prata),
                bronze: this.moedaParaNumero(item.bronze),
            };

            this.dialogo = true;
        },
        abrirCampanha() {
            try{
                this.$refs.formCampanha.reset();
            }
            catch(e){
                console.log(e);
            }
            this.campanha = {
                mesFinal: this.mes,
                mesInicial: this.mes,
            };
            this.dialogoCampanha = true;
        },
        async editar() {
            this.carregando = true;

            var data = this.precoCampanha;

            var response = await this.$http().post("/campanha/editar", {
                data: data,
            });

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();

                this.dialogo = false;
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        async criarCampanha() {
            console.log('aaa');
            if (!this.$refs.formCampanha.validate()) return;
            console.log('bbb');
            this.carregando = true;

            const data = this.campanha;

            const response = await this.$http().post("/campanha/criar", {
                data: data,
            });

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                this.campanha = {
                    campanha: null,
                    mesInicial: null,
                    mesFinal: null,
                };
                this.dialogoCampanha = false;
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        abrirImportar() {
            if (!this.$refs.formFiltro.validate()) return;

            this.arquivoCSV = null;
            this.erro = false;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            this.dialogoImportar = true;
        },
        async importar() {
            this.carregando = true;

            var data = { mes: this.mes + "-01" };

            var formData = new FormData();

            formData.append("files", this.arquivoCSV);
            formData.append("data", JSON.stringify(data));

            this.arquivoCSV = null;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            var response = await this.$http().post(
                "/campanha/importar",
                formData
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                this.dialogoImportar = false;
            } else {
                this.filtrar();
                this.erros = response.data.erros;
                this.pagination.page = 1;
                this.erro = true;
            }
        },
    },
};
</script>