<template>
    <v-form ref="formFiltro">
        <v-row no-gutters>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-text-field
                    label="Período Inicial"                
                    v-model="filtro.mesInicial"  
                    :rules="regra.mesInicial"           
                    required
                    outlined
                    dense 
                    type="month"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-text-field
                    label="Período Final"                
                    v-model="filtro.mesFinal"  
                    :rules="regra.mesFinal"           
                    required
                    outlined
                    dense 
                    type="month"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-autocomplete
                    label="Filial"
                    v-model="filtro.id_filial"
                    :items="lista.filial"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <div 
                            v-if="filtro.id_filial.length === 1 && index === 0"
                            style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                        >{{ item.text }}</div>
                        <span
                            v-if="filtro.id_filial.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.id_filial.length }} selecionados</span>
                    </template>
                </v-autocomplete>
            </v-col>      
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-select
                    label="Bandeira"
                    v-model="filtro.bandeira"
                    :items="lista.bandeira"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <span 
                            v-if="filtro.bandeira.length === 1 && index === 0"
                        >{{ item.text }}</span>
                        <span
                            v-if="filtro.bandeira.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.bandeira.length }} selecionados</span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-autocomplete
                    label="Fornecedor"
                    v-model="filtro.id_fornecedor"
                    :items="lista.fornecedor"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <div 
                            v-if="filtro.id_fornecedor.length === 1 && index === 0"
                            style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                        >{{ item.text }}</div>
                        <span
                            v-if="filtro.id_fornecedor.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.id_fornecedor.length }} selecionados</span>
                    </template>
                </v-autocomplete>
            </v-col>      
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-autocomplete
                    label="Espaço"
                    v-model="filtro.id_espaco"
                    :items="lista.espaco"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <div 
                            v-if="filtro.id_espaco.length === 1 && index === 0"
                            style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                        >{{ item.text }}</div>
                        <span
                            v-if="filtro.id_espaco.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.id_espaco.length }} selecionados</span>
                    </template>
                </v-autocomplete>
            </v-col>        
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-autocomplete
                    label="Espaço Macro"
                    v-model="filtro.espaco_macro"
                    :items="lista.espaco_macro"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <div 
                            v-if="filtro.espaco_macro.length === 1 && index === 0"
                            style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                        >{{ item.text }}</div>
                        <span
                            v-if="filtro.espaco_macro.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.espaco_macro.length }} selecionados</span>
                    </template>
                </v-autocomplete>
            </v-col>      
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-select
                    label="Cluster"
                    :items="lista.cluster"
                    v-model="filtro.id_cluster"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <span 
                            v-if="filtro.id_cluster.length === 1 && index === 0"
                        >{{ item.text }}</span>
                        <span
                            v-if="filtro.id_cluster.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.id_cluster.length }} selecionados</span>
                    </template>
                </v-select>
            </v-col>    
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-autocomplete
                    label="Região"
                    v-model="filtro.regiao"
                    :items="lista.regiao"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <div 
                            v-if="filtro.regiao.length === 1 && index === 0"
                            style="width: 70% !important; white-space: nowrap !important; text-overflow: ellipsis !important; overflow: hidden;"
                        >{{ item.text }}</div>
                        <span
                            v-if="filtro.regiao.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.regiao.length }} selecionados</span>
                    </template>
                </v-autocomplete>
            </v-col>    
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-select
                    label="Perfil"
                    :items="lista.perfil"
                    v-model="filtro.perfil"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <span 
                            v-if="filtro.perfil.length === 1 && index === 0"
                        >{{ item.text }}</span>
                        <span
                            v-if="filtro.perfil.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.perfil.length }} selecionados</span>
                    </template>
                </v-select>
            </v-col>    
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                <v-select
                    label="Tamanho"
                    :items="lista.tamanho"
                    v-model="filtro.tamanho"
                    multiple
                    outlined
                    dense 
                >
                    <template v-slot:selection="{ item, index }">
                        <span 
                            v-if="filtro.tamanho.length === 1 && index === 0"
                        >{{ item.text }}</span>
                        <span
                            v-if="filtro.tamanho.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                        >{{ filtro.tamanho.length }} selecionados</span>
                    </template>
                </v-select>
            </v-col> 
            <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2 ml-auto pb-2" align="end">
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn> 
                <v-btn
                    class="ml-2 mb-1"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <v-overlay :value="overlay" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-form>
</template>

<script>
import  useFiltros  from "../../../hooks/useFiltros";

export default {
    setup() {
		const {  isError, isFetching, data: lista, error, refetch } = useFiltros("/gerencial/filtros");

        return {  isError, isFetching, lista, error, refetch };
    },
    data() {
        return {
            // lista: {},
            filtro: {},
            regra: {
                mesInicial: [
                    v => !!v || 'Selecione um período inicial para o filtro',
                ],            
                mesFinal: [
                    v => !!v || 'Selecione um período final para o filtro',
                    v => (!!v && v >= this.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
                ],  
            },   
        }
    },    
    async mounted() {
        console.log('mounted')
        this.iniciaFiltro()

        setTimeout(() => {

            this.filtrar()
        }, 100) 
    },
    computed: {
        overlay(){
            return  Object.keys(this.lista).length === 0 && this.isFetching
        }
    },
    methods: {    
        iniciaFiltro() {
            this.filtro = {
                mesInicial: '',
                mesFinal: '',
                bandeira: [],
                id_espaco: [],
                espaco_macro: [],
                id_fornecedor: [],
                id_cluster: [],
                id_filial: [],
                regiao: [],
                perfil: [],
                tamanho: [],
            }
        },     
        limpar() {

            this.iniciaFiltro()

            this.filtrar()
        },      
        filtrar() {

            if(!this.validate()) return

            this.$emit('filtrar')
        },          
        validate() {
            return this.$refs.formFiltro.validate()
        },
        resetValidation() {
            try {
                this.$refs.formFiltro.resetValidation()
            } catch (error) {
                //console.log(error)
            }   
        },      
    }
}
</script>