<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="6" class="py-0">
                <MesInput
                    label="Período Inicial"
                    v-model="value.mesInicial"
                    :rules="regra.mesInicial"
                    :min="mesAtual"
                    :disabled="value.fnEditar"
                    :change="periodoInicialChange"
                    required
                    outlined
                />
            </v-col>
            <v-col cols="6" class="py-0">
                <MesInput
                    label="Período Final"
                    v-model="value.mesFinal"
                    :rules="regra.mesFinal"
                    :min="mesAtual"
                    :disabled="value.fnEditar"
                    :change="periodoFinalChange"
                    required
                />
            </v-col>

            <v-col cols="6" class="py-0">
                <v-autocomplete
                    label="Produto"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="id_produtoBusca"
                    :items="lista.produto"
                    :search-input.sync="buscaProdutoFornecedor"
                    :loading="isLoadingProduto"
                    @change="limpaDesconto()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    clearable
                >
                    <template v-slot:no-data>
                        <p
                            v-if="
                                !buscaProdutoFornecedor ||
                                (buscaProdutoFornecedor &&
                                    buscaProdutoFornecedor.length <= 4)
                            "
                            class="ml-4 mt-4"
                        >
                            Insira pelo menos 4 caracteres para listar os
                            produtos.
                        </p>
                        <p v-else-if="isLoadingProduto" class="ml-4 mt-4">Buscando produtos...</p>
                        <p v-else class="ml-4 mt-4">Nada encontrado</p>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
                <v-text-field
                    label="Fornecedor"
                    v-model="value.fornecedor"
                    :rules="regra.fornecedor"
                    :disabled="value.fnEditar"
                    outlined
                    class="input-cinza"
                    dense
                    readonly
                    required
                ></v-text-field>
            </v-col>

            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Espaço"
                    v-model="value.id_espaco"
                    :items="lista.espaco"
                    :menu-props="menuProps"
                    :rules="regra.espaco"
                    :disabled="value.fnEditar"
                    @change="limpaDesconto()"
                    clearable
                    class="input-cinza"
                    outlined
                    dense
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Bandeira"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="value.bandeira"
                    :items="lista.bandeira"
                    :rules="regra.bandeira"
                    @change="limpaDesconto()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    multiple
                    required
                ></v-select>
            </v-col>
            <!-- <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Categoria"
                    v-model="value.id_categoria"
                    :items="computedCategoria"
                    :menu-props="menuProps"
                    :rules="regra.categoria"
                    :disabled="value.fnEditar"
                    @change="limpaDesconto()"
                    class="input-cinza"
                    outlined
                    clearable
                    dense
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Device"
                    v-model="value.id_device"
                    :items="computedDevice"
                    :menu-props="menuProps"
                    :rules="regra.device"
                    :disabled="value.fnEditar"
                    @change="limpaDesconto()"
                    class="input-cinza"
                    outlined
                    clearable
                    dense
                >
                </v-autocomplete>
            </v-col> -->

            <v-col cols="4" class="py-0">
                <v-select
                    label="Plano"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="value.plano"
                    :items="lista.plano"
                    :rules="regra.plano"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    class="input-cinza"
                    v-model="value.obs_departamento"
                    dense
                    rows="3"
                    label="Observação Departamento"
                ></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    class="input-cinza"
                    v-model="value.obs_contrato"
                    dense
                    rows="3"
                    label="Observação do Contrato"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-alert 
            type="error" 
            class="mt-5"
            transition="scale-transition"
            dismissible
            v-model="alerta"
        >
            {{alertaMesagem}}
        </v-alert>
        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
import MesInput from "../../../../components/MesInput.vue";

export default {
    inject: ["listaCampos"],
    components: {
        MesInput,
    },
    props: ["value"],
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            lista: {
                bandeira: [],
                espaco: [],
                custos: [],
                categorias: [],
                device: [],
                plano: ["ANUAL", "AVULSO"],
            },
            id_produtoBusca: null,
            buscaProdutoFornecedor: null,
            isLoadingProduto: false,
            regra: {
                mesInicial: [
                    (v) => !!v || "O campo período inicial é obrigatório",
                    (v) =>
                        (v && v >= this.mesAtual) ||
                        this.value.fnLeitura ||
                        "O período inicial deve ser igual ou posterior ao mês atual",
                ],
                mesFinal: [
                    (v) => !!v || "O campo período final é obrigatório",
                    (v) =>
                        (v && v >= this.value.mesInicial) ||
                        "O período final deve ser igual ou posterior ao período inicial",
                ],
                espaco: [(v) => !!v || "O campo espaço é obrigatório"],
                plano: [(v) => !!v || "O campo plano é obrigatório"],
                fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
                categoria: [(v) => !!v || "O campo categoria é obrigatório"],
                device: [(v) => !!v || "O campo device é obrigatório"],
                bandeira: [
                    (v) => v.length > 0 || "O campo bandeira é obrigatório",
                ],
            },
            semAcesso: false,
            alerta: false,
            alertaMesagem: ''
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.lista.bandeira = lista.bandeira;
        this.lista.espaco = lista.espaco;
        this.lista.categorias = lista.categorias;
        this.lista.device = lista.device;
        this.lista.custos = lista.custos;
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        computedCustos() {

            return this.lista.custos.filter(
                (custo) =>
                    (!this.value.id_espaco ||
                        custo.id_espaco == this.value.id_espaco) &&
                    (!this.value.id_categoria ||
                        custo.id_categoria == this.value.id_categoria) &&
                    (!this.value.id_device ||
                        custo.id_device == this.value.id_device)
                    //      &&
                    // (!this.value.bandeira ||
                    //     custo.bandeira == bandeiras[this.value.bandeira])
            );
        },
        computedCategoria() {
            const categorias = [
                ...new Set(
                    this.computedCustos.map((custo) => custo.id_categoria)
                ),
            ];

            return this.lista.categorias.filter((categoria) =>
                categorias.includes(categoria.value)
            );
        },
        computedDevice() {
            const devices = [
                ...new Set(this.computedCustos.map((custo) => custo.id_device)),
            ];

            return this.lista.device.filter((device) =>
                devices.includes(device.value)
            );
        },
        computedEspacos(){
            const espacos = [
                ...new Set(this.computedCustos.map((custo) => custo.id_espaco)),
            ];
            return this.lista.espaco.filter((espaco) =>
                espacos.includes(espaco.value)
            );
        },
        computedBandeiras() {
            const bandeiras = [
                ...new Set(
                    this.computedCustos.map((custo) => custo.bandeira)
                ),
            ];

            return this.lista.bandeira.filter((bandeira) =>
                bandeiras.includes(bandeira.text)
            );
        },
    },
    watch: {
        async buscaProdutoFornecedor(val) {
            if (!val) {
                this.id_produtoBusca = null;
                this.lista.produto = [];
                return;
            }

            if (val.length < 4) {
                this.lista.produto = [];
                return;
            }

            if (this.isLoadingProduto) return;

            this.isLoadingProduto = true;

            var data = { produto: val };

            var response = await this.$http().post("/acordo/buscaProdutos", {
                data: data,
            });

            this.lista.produto = response.data;

            this.isLoadingProduto = false;
        },

        id_produtoBusca(val) {
            var produto = this.lista.produto.find((e) => e.value == val);

            this.value.id_fornecedor = produto ? produto.id_fornecedor : null;
            this.value.fornecedor = produto ? produto.fornecedor : null;
            this.value.produto = produto ? produto.text : null;
            this.$emit("input", { ...this.value });
        },
    },
    methods: {
        clearVarAcordo(key) {
            this.acordo[key] = null;
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },

        limpaCadastro() {
            this.id_produtoBusca = null;
            this.buscaProdutoFornecedor = null;
            this.lista.produto = [];
            this.acordo = {
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                fornecedor: null,
                bandeira: [ "RAIA", "DROGASIL"],
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                comentario: null,
                fnEditar: false,
            };
        },
        limpaDesconto() {
            this.value.desconto = [];
        },
        limpaProdutos() {
            this.$emit("limpaProdutos");
        },
        validate() {
            return this.$refs.form.validate();
        },
        reset() {
            return this.$refs.form.reset();
        },
        resetValidation() {
            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },
        periodoInicialChange(valor) {
            this.limpaDesconto();
            if (this.value.mesFinal < valor) {
                this.value.mesFinal = valor;
            }
        },
        periodoFinalChange(valor) {
            this.limpaDesconto();

            if (this.value.mesInicial > valor) {
                this.value.mesInicial = valor;
            }
        },
    },
};
</script>
