<template>
	<!-- <v-expansion-panels tile>
		<v-expansion-panel>
			<v-expansion-panel-header>
				Legendas
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels> -->

	<v-menu top close-on-content-click>
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" dark v-bind="attrs" v-on="on" outlined>
				Visualizar legendas
			</v-btn>
		</template>

		<v-list>
			<v-list-item>
				<v-list-item-icon>
					<v-icon v-text="'mdi-minus-box-outline'" disabled></v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title
						v-text="'Não possui inventário'"
					></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item>
				<v-list-item-icon>
					<v-icon
						v-text="'mdi-checkbox-blank-off-outline'"
						disabled
					></v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title
						v-text="'Não possui oportunidade'"
					></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-if="organicos">
				<v-list-item-icon>
					<v-icon v-text="'mdi-checkbox-marked'" disabled></v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title
						v-text="
							'Orgânicos: Não possui oportunidade. A filial existente no acordo teve seu cluster alterado, porém o mesmo já esta alocado a outro acordo'
						"
					></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item v-if="organicos">
				<v-list-item-icon>
					<v-icon
						v-text="'mdi-checkbox-marked'"
						color="primary"
					></v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title
						v-text="
							'Orgânicos: Filial não esta salva no acordo, porém será incluída automaticamente se clicar em [SALVAR FILIAIS SELECIONADAS]'
						"
					></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	props: {
		organicos: Boolean,
	},
	data() {
		return {};
	},
};
</script>
