var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formFiltro"},[_c('v-card',{staticClass:"px-6 py-3 br-15 w-100",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Extrato ")]),_c('div',{staticClass:"ma-1"},[_c('InfoBox',{attrs:{"expands":""}},[_c('p',[_vm._v(" No extrato é possivel visualizar o resumo dos investimentos por fornecedor. Também é possivel visualizar os acordos de forma detalhada, trazendo lojas e produtos e essas informações estão nas sheets \"verba e vb\". No extrato atual temos as informações do PDV e Ecommece, futuramente será visualizado também as campanhas. ")])])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('MesInput',{attrs:{"label":"Mês","rules":_vm.usuarioTipo == 'fornecedor' && _vm.regra.mesInicial,"max":_vm.filtroMesMaximo},model:{value:(_vm.filtro.mesInicial),callback:function ($$v) {_vm.$set(_vm.filtro, "mesInicial", $$v)},expression:"filtro.mesInicial"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Fornecedor","items":_vm.lista.fornecedor,"rules":_vm.regra.fornecedor,"outlined":"","dense":"","menu-props":_vm.menuProps,"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_fornecedor.length === 1 && index === 0)?_c('div',{staticClass:"autocomplete-selecao"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_fornecedor.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_fornecedor.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_fornecedor),callback:function ($$v) {_vm.$set(_vm.filtro, "id_fornecedor", $$v)},expression:"filtro.id_fornecedor"}})],1)],1),_c('v-divider',{staticClass:"mt-n1 mb-2"}),_c('v-expansion-panels',{attrs:{"flat":"","accordion":""},model:{value:(_vm.mostrarFiltros),callback:function ($$v) {_vm.mostrarFiltros=$$v},expression:"mostrarFiltros"}},[_c('v-expansion-panel',[_c('v-expansion-panel-content',{staticClass:"p-0 m-0"},[_c('v-row',{staticClass:"my-2"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Filial","items":_vm.lista.filial,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_filial.length === 1 && index === 0)?_c('div',{staticClass:"autocomplete-selecao"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_filial.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_filial.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_filial),callback:function ($$v) {_vm.$set(_vm.filtro, "id_filial", $$v)},expression:"filtro.id_filial"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Bandeira","items":_vm.lista.bandeira,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.bandeira.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.bandeira.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.bandeira.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.bandeira),callback:function ($$v) {_vm.$set(_vm.filtro, "bandeira", $$v)},expression:"filtro.bandeira"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Espaço","items":_vm.lista.espaco,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_espaco.length === 1 && index === 0)?_c('div',{staticClass:"autocomplete-selecao"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_espaco.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_espaco.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_espaco),callback:function ($$v) {_vm.$set(_vm.filtro, "id_espaco", $$v)},expression:"filtro.id_espaco"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Espaço Macro","items":_vm.lista.espaco_macro,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.espaco_macro.length === 1 && index === 0)?_c('div',{staticClass:"autocomplete-selecao"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.espaco_macro.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.espaco_macro.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.espaco_macro),callback:function ($$v) {_vm.$set(_vm.filtro, "espaco_macro", $$v)},expression:"filtro.espaco_macro"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Cluster","items":_vm.lista.cluster,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_cluster.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.id_cluster.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_cluster.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_cluster),callback:function ($$v) {_vm.$set(_vm.filtro, "id_cluster", $$v)},expression:"filtro.id_cluster"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Região","items":_vm.lista.regiao,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.regiao.length === 1 && index === 0)?_c('div',{staticClass:"autocomplete-selecao"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.regiao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.regiao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.regiao),callback:function ($$v) {_vm.$set(_vm.filtro, "regiao", $$v)},expression:"filtro.regiao"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Perfil","items":_vm.lista.perfil,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.perfil.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.perfil.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.perfil.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.perfil),callback:function ($$v) {_vm.$set(_vm.filtro, "perfil", $$v)},expression:"filtro.perfil"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Tamanho","items":_vm.lista.tamanho,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.tamanho.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.tamanho.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.tamanho.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.tamanho),callback:function ($$v) {_vm.$set(_vm.filtro, "tamanho", $$v)},expression:"filtro.tamanho"}})],1)],1),_c('v-divider',{staticClass:"mt-n2 mb-3"})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('div',{staticClass:"new-button d-flex align-center pt-5",attrs:{"text":""}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.expandirFiltros}},[_vm._v("Personalizar Filtros")]),_c('v-btn',{staticClass:"ml-3",staticStyle:{"background":"#e6e6e6","width":"20px","height":"20px"},attrs:{"fab":"","icon":"","x-small":""},on:{"click":_vm.expandirFiltros}},[_c('v-icon',[_vm._v(_vm._s(_vm.mostrarFiltros == 0 ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)]),_c('v-col',{staticClass:"px-3 ml-auto pb-3",attrs:{"cols":"6","align":"end"}},[_c('v-btn',{staticClass:"ml-4 px-8 new-button",attrs:{"color":"yellow","dark":""},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-5 px-8 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }