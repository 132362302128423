<template>
  <v-card class="br-15 pa-3 px-5 overflow-hidden" elevation="0">
    <InfoBox>
      <span class="titulo">Resumo trimestral: </span> Nesta tabela é possivel
      avaliar os valores de investimentos por timestre, nas visões: PDV,
      Campanha e Ecommerce.
    </InfoBox>
    <h2 class="text-center" style="font-weight: normal">Resumo Trimestral</h2>
    <div style="height: 503px">
      <template>
        <v-simple-table
          dense
          class="mt-3 tabela-resumo-trimestral"
          height="493px"
          v-if="!error && !empty && !overlay"
        >
          <template v-slot:default>
            <thead>
              <tr class="v-data-table-header">
                <th class="text-center th-separador">Ferramentas</th>
                <th
                  v-for="i in [1, 2, 3, 4]"
                  :key="'th' + i"
                  class="text-center th-separador"
                >
                  {{ i }}º Trimestre
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  colspan="5"
                  class="text-center v-data-table-header teal darken-2"
                  style="padding: 5px !important"
                >
                  {{ ano }}
                </th>
              </tr>
              <tr
                v-for="(item, index) in filterTable(ano)"
                :key="'tra' + index"
                :class="{
                  'text-center': true,
                  'table-footer': item.ferramenta === 'Total',
                }"
              >
                <td>{{ item.ferramenta }}</td>
                <td
                  v-for="i in [1, 2, 3, 4]"
                  :key="'tda' + i"
                  class="text-center"
                >
                  <formataReal :valor="item['tri' + i]" />
                </td>
              </tr>
              <tr>
                <th
                  colspan="5"
                  class="text-center v-data-table-header teal darken-2"
                  style="padding: 5px !important"
                >
                  {{ ano - 1 }}
                </th>
              </tr>
              <tr
                v-for="(item, index) in filterTable(ano - 1)"
                :key="'trb' + index"
                :class="{
                  'text-center': true,
                  'table-footer': item.ferramenta === 'Total',
                }"
              >
                <!-- <td v-if="index == 0" rowspan="4" class="grey white--text">
                  <div class="rotate90">{{ item.ano }}</div>
                </td> -->
                <td>
                  {{ item.ferramenta }}
                </td>
                <td
                  v-for="i in [1, 2, 3, 4]"
                  :key="'tdb' + i"
                  class="text-center"
                >
                  <formataReal :valor="item['tri' + i]" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>
    </div>
  </v-card>
</template>

<script>
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox,
  },
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      tableData: [],
    };
  },

  computed: {},

  methods: {
    filterTable(ano) {
      const filtrado = this.tableData.filter((e) => e.ano == ano);

      filtrado.push(
        filtrado.reduce(
          (a, b) => ({
            ...a,
            ano: b.ano,
            tri1: a.tri1 + +b.tri1,
            tri2: a.tri2 + +b.tri2,
            tri3: a.tri3 + +b.tri3,
            tri4: a.tri4 + +b.tri4,
          }),
          {
            ferramenta: "Total",
            tri1: 0,
            tri2: 0,
            tri3: 0,
            tri4: 0,
          }
        )
      );
      return filtrado;
    },
    async getDados() {
      this.overlay = true;
      this.error = false;
      this.empty = false;

      const response = await this.$http().post(
        "/simulacao/resumo/resumoTrimestral",
        {
          data: {
            id_fornecedor: this.fornecedor.value,
            ano_atual: this.ano,
            ano_anterior: this.ano - 1,
          },
        }
      );

      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        this.error = true;

        return;
      }

      this.tableData = response.data;
      this.overlay = false;
    },
  },
  watch: {
    $props: {
      handler() {
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
  created() {},
};
</script>

<style scoped>
.tabela-resumo-trimestral {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  color: #808080 !important;
}
.tabela-resumo-trimestral td,
.tabela-resumo-trimestral th {
  padding: 0.8rem !important;
}

tr.border-double td {
  border-bottom: double #4db6ac !important;
}
</style>
