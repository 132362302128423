<template>
  <v-card class="my-0 br-15 pa-5 pt-3 overflow-hidden" elevation="0">
    <div style="height: 538px">
      <InfoBox expands>
        <span class="titulo">Evolução mensal dos investimentos: </span>Gráfico
        com a evolução dos valores de investimento comparando o ano atual com o
        anterior.
      </InfoBox>
      <h2 class="text-center" v-if="empty || error" style="font-weight: normal">
        Evolução Mensal dos Investimentos
      </h2>
      <highcharts
        :options="grafico"
        ref="chartEvolMensal"
        v-if="mostrarInfo"
      />
      <v-row v-if="mostrarInfo">
        <v-col cols="12" sm="6" md="6">
          <div class="legenda">
            <div class="legenda-titulo ano-anterior">{{ ano - 1 }}</div>
            <div class="legenda-valor">
              <div class="line yellow">
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="3" md="6">
          <div class="legenda">
            <div class="legenda-titulo ano-vigente">{{ ano }}</div>
            <div class="legenda-valor">
              <div class="line">
                <div class="dots"></div>
                <div class="dots"></div>
                <div class="dots"></div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>
    </div>
  </v-card>
</template>

<script>
import colors from "vuetify/es5/util/colors";
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox,
  },
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      grafico: {
        chart: {
          type: "spline",
          height: 470,
        },
        title: {
          text: `Evolução Mensal dos Investimentos`,
        },
        
        xAxis: {
          categories: this.$moment.monthsShort(),
          className: "text-capitalize",
        },
        yAxis: {
          title: {
            text: "",
          },  
          labels: {
            formatter: function () {
              return "R$ " + this.value;
            },
          },
        },
        colors: ["#fbc17a", "#2f988b"],

        exporting: {
          chartOptions: {
            legend: {
              enabled: true,
            },
          },
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "print",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadCSV",
                "downloadXLS",
              ],
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          crosshairs: true,
          shared: true,
           borderRadius: 10,
          padding: 12,
          useHTML: true,
          className: "text-capitalize",
          

              pointFormat: '<div style="padding-top: 4px"> <span style="color:{series.color}"><b>● {series.name}: </b> </span> R$ {point.y:.2f}<br/></div>',
          // headerFormat: '<div style="padding: 0 10px; margin-bottom:4px; font-size:14px; color: {point.color}; text-align: center">{point.point.name} ({point.y:.0f}%)</div>',
          // pointFormat:
          //   '<div style="text-align: center; padding: 0 10px;  font-size:14px; "> R$ {point.valor:.2f} </div>',
 
        },
        plotOptions: {
          spline: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: true,
            showInLegend: true,
          },
        },
        // legend: {
        //   verticalAlign: "middle",
        //   align: "right",
        //   floating: false,
        //   layout: "vertical",
        // },
        series: [
          {
            name: "",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    anoAtual() {
      return this.$moment().format("YYYY");
    },
    anoAnterior() {
      return this.$moment().subtract(1, "year").format("YYYY");
    },
    mostrarInfo() {
      return !this.error && !this.empty && !this.overlay;
    },
  },
  methods: {
    async getDados() {
      this.overlay = true;
      this.error = false;
      this.empty = false;

      const response = await this.$http().post(
        "/simulacao/resumo/evolucaoMensal",
        {
          data: {
            id_fornecedor: this.fornecedor.value,
            ano_atual: this.ano,
            ano_anterior: this.ano - 1,
          },
        }
      );

      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        this.error = true;

        return;
      }

      const isEmpty =
        response.data[0].data.filter((e) => e > 0).length == 0 &&
        response.data[1].data.filter((e) => e > 0).length == 0;

      if (isEmpty) {
        this.empty = true;
        return (this.overlay = false);
      }

      this.grafico.series = response.data;
      this.overlay = false;
    },
  },

  watch: {
    $props: {
      handler() {
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
  created() {},
};
</script>
<style scoped>
.ano-vigente {
  background-color: #2f988b;
}
.ano-anterior {
  background-color: #fbc17a;
}
.legenda {
  width: 100%;
  min-width: 100%;
  display: block;
  font-size: 0.8rem;
}
.legenda .legenda-titulo {
  text-align: center;
  color: #fff;
  font-size: 1rem;
  padding: 6px;
  border-radius: 8px 8px 0 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.legenda .legenda-valor {
  background-color: #f2f2f2;
  text-align: center;
  padding: 9px;
  border-radius: 0 0 8px 8px;
  color: #888;
  font-weight: 500;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
}
.line {
  height: 0px;
  width: 150px;

  display: flex;
  justify-content: space-between;
  position: relative;
}
.line::after {
  content: "";
  width: calc(100% - 20px);
  height: 2px;
  background-color: #2f988b;
  position: absolute;
  top: 4px;
  margin: 0 10px;
}
.dots {
  margin-top: -20px;
  width: 10px;
  height: 10px;
  background-color: #2f988b;
  border-radius: 50%;
  margin: 0 2px;
}
.line.yellow::after {
  background-color: #fbc17a;
}
.line.yellow .dots {
  background-color: #fbc17a;
}
</style>
