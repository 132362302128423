<template>
  <v-container fluid>
    <v-card class="px-5 py-3 br-15 w-100" elevation="0">
      <v-row>
        <v-col class="flex-grow-1">
          <v-toolbar-title class="page-title">API</v-toolbar-title>
        </v-col>
        <v-col>
          <v-datetime-picker
            :time-picker-props="timeProps"
            label="Data Inicial"
            :textFieldProps="textFieldProps"
            v-model="dt_inicial"
          >
          </v-datetime-picker>
        </v-col>
        <v-col>
          <v-datetime-picker
            :time-picker-props="timeProps"
            label="Data Final"
            :textFieldProps="textFieldProps"
            v-model="dt_final"
          >
          </v-datetime-picker>
        </v-col>
        <div class="d-flex py-3 px-4">
          <v-btn class="new-button" color="primary" @click="filtrar()">
            <v-icon left>mdi-filter</v-icon> Filtrar
          </v-btn>
          <v-btn
            class="ml-5 new-button"
            color="primary"
            @click="mostrarDialogoUpdate()"
          >
            <v-icon left>mdi-update</v-icon> Update
          </v-btn>
        </div>
      </v-row>
    </v-card>

    <v-card class="px-5 py-5 br-15 w-100 mt-5" elevation="0">
      <v-data-table
        class="elevation-0 tabela"
        :headers="headers"
        :items="dados"
        :options.sync="pagination"
        :server-items-length="total"
        :items-per-page="20"
        :footer-props="tableConfig"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        multi-sort
      >
        <template v-slot:[`item.detalhes`]="{ item }">
          <v-btn class="mr-2" @click="mostrarDialogo(item)" icon
            ><v-icon color="grey" small>mdi-xml</v-icon></v-btn
          >
          <v-btn @click="mostrarDialogoUpdate(item)" icon>
            <v-icon color="grey" small>mdi-update</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog content-class="br-15" scrollable v-model="dialogo" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title> Detalhes bruto </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            
            light
            @click="dialogo = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
         
          <pre>{{ dialogoDetalhes }}</pre>
        
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="br-15" v-model="dialogUpdate" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title> Atualizar acordos </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogUpdate = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-form ref="form">
            <v-alert class="mb-4" v-if="alerta" dark color="error">
              {{ alertaMensagem }}
            </v-alert>
            <v-row class="pt-4">
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  label="Acordo"
                  v-model="updateAcordo.id_acordo"
                  class="input-cinza"
                  :rules="regra.id_acordo"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-select
                  label="Tipo de acordo"
                  v-model="updateAcordo.tipo_acordo"
                  :items="['pdv', 'ecommerce']"
                  class="input-cinza"
                  :menu-props="menuProps"
                  outlined
                  dense
                >
                </v-select
              ></v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  label="Acordo RD"
                  v-model="updateAcordo.acordo_rd"
                  class="input-cinza"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="6" class="my-0 py-0">
                <v-text-field
                  label="Contrato RD"
                  v-model="updateAcordo.contrato"
                  class="input-cinza"
                  outlined
                  dense
                ></v-text-field
              ></v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-select
                  label="Status"
                  :items="listaCampos.status"
                  v-model="updateAcordo.id_status"
                  class="input-cinza"
                  :menu-props="menuProps"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="ml-auto new-button"
                  @click="atualizarAcordo()"
                >
                  <v-icon left>mdi-check-circle</v-icon>
                  Salvar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
export default {
  name: "Api",

  data() {
    return {
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      dt_inicial: this.$moment().startOf("day").toDate(),
      dt_final: this.$moment().endOf("day").toDate(),
      token: this.$store.getters.getToken,
      listaCampos: {
        status: []
      },
      carregando: false,
      dialogo: false,
      dialogUpdate: false,
      dialogoDetalhes: {},
      alerta: false,
      alertaMensagem: "",
      updateAcordo: {
        id_acordo: null,
        acordo_rd: null,
        contrato: null,
        id_status: null,
        tipo_acordo: "ecommerce",
      },
      tableConfig: {
        itemsPerPageOptions: [10, 15, 25, 50, 100],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}",
      },
      textFieldProps: {
        outlined: true,
        dense: true,
        class: "input-cinza",
        'hide-details': true,
      },
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
        format: "24hr",
      },
      dados: [],
      total: 0,
      pagination: {},
      regra: {
        id_acordo: [(v) => !!v || "Campo Acordo é obrigatório."],
      },
      headers: [
        {
          text: "Detalhes",
          value: "detalhes",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Acordo",
          value: "id_acordo",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Contrato",
          value: "id_contrato",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Acordo RD",
          value: "acordo_rd",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Contrato RD",
          value: "contrato",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Tipo acordo",
          value: "tipo_acordo",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Bandeira",
          value: "bandeira",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Espaço",
          value: "codigo_espaco",
          class: "th-separador min-width-100",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          class: "th-separador min-width-100",
          align: "center",
        },

        {
          text: "Data atualização",
          value: "data_atualizacao",
          class: "th-separador min-width-100",
          align: "center",
        },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        this.filtrar();
      },
      deep: true,
    },
  },
  created() {

    // this.filtrar();
    this.fnListaCampos();
  },
  methods: {
    async fnListaCampos() {
      const response = await this.$http().post(
        "/acordoEcommerce/listaCampos",
        {}
      );
      this.listaCampos = response.data;
    },
    mostrarDialogo(item) {
      this.dialogo = true;
      this.dialogoDetalhes = item;
    },
    mostrarDialogoUpdate(item) {
      this.resetarCamposUpdate();
      this.dialogUpdate = true;
      if (item) {
        this.updateAcordo.id_acordo = item.id_acordo;
        this.updateAcordo.tipo_acordo = item.tipo_acordo;
      }
    },
    acordoStatus(status) {
      const finsStatus = this.listaCampos.status.find(
        (item) => item.value == status
      );
      if (finsStatus) {
        return finsStatus.text;
      }
      return "";
    },
    acordoBandeira(bandeira) {
      switch (bandeira) {
        case 1:
          return "RAIA";
        case 2:
          return "DROGASIL";
      }
      return "a";
    },
    resetarCamposUpdate() {
      this.alerta = false;
      this.alertaMensagem = "";
      this.updateAcordo = {
        id_acordo: null,
        acordo_rd: null,
        contrato: null,
        id_status: null,
        tipo_acordo: "ecommerce",
      };
    },
    async atualizarAcordo() {
      if (!this.$refs.form.validate()) return;
      this.carregando = true;
      this.alerta = false;
      this.alertaMensagem = "";
      const data = {
        token: this.token,
        acordos: [this.updateAcordo],
      };
      const response = await this.$http().put("/api/updateAcordos", data);
      this.carregando = false;

      if (response.data[0].sucesso) {
        this.dialogUpdate = false;
        this.filtrar();
        this.resetarCamposUpdate();
        this.$dialog.notify.success(
          response.data[0].acordo.msg || " Acordo atualizado com sucesso!",
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      } else {
        this.alerta = true;
        this.alertaMensagem = response.data[0].erro || "Erro desconhecido";
      }
    },
    async filtrar() {
      console.log(this.$moment(this.dt_inicial).format("YYYY-MM-DD HH:mm:ss"));
      this.carregando = true;
      const data = {
        dt_inicial: this.$moment(this.dt_inicial).format("YYYY-MM-DD HH:mm:ss"),
        dt_final: this.$moment(this.dt_final).format("YYYY-MM-DD HH:mm:ss"),
        token: this.token,
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
      };
      const response = await this.$http().post("/api/getAcordos", data);

      if (response.data.acordos && response.data.acordos.length > 0) {
        this.dados = response.data.acordos.map((acordo) => {
          return {
            ...acordo,
            status: this.acordoStatus(acordo.id_status),
            data_atualizacao: this.$moment(acordo.data_atualizacao).format(
              "DD/MM/YYYY HH:mm:ss"
            ),
            bandeira: this.acordoBandeira(acordo.id_bandeira),
          };
        });
        this.total = +response.data.acordos[0]["linhas"] || 0;
      }else {
        this.dados = [];
        this.total = 0;
      }
      this.carregando = false;
    },
  },
};
</script>

