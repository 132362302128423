<template>
    <div>
        <v-dialog content-class="br-15" scrollable v-model="dialog" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialog = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    {{ dialogTexto }}
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogImagem" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogImagem = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4" v-html="htmlPlanograma">
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogRenovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogRenovar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    Selecione as datas para a
                    <strong style="color: var(--v-primary-base)"
                        >renovação</strong
                    >
                    do acordo.
                    <br />
                    <br />
                    <v-form v-model="isRenovar">
                        <MesInputMultiple
                            v-model="mesesRenovacao"
                            :min="termino"
                            label="Selecionar Período"
                            :rules="regra.renovar"
                            required
                        />
                        <br />
                        <v-select
                            label="Modelo Check-out"
                            outlined
                            dense
                            v-if="lista.modelos_ck.length > 0"
                            v-model="modelo_ck"
                            :items="lista.modelos_ck"
                            :rules="regra.modelo"
                            :disabled="lista.modelos_ck.length == 1"
                            required
                        />
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button px-6 mb-3 mr-2"
                        @click="renovarAcordo()"
                        :disabled="!isRenovar"
                    >
                        Renovar Acordo
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-dialog
                content-class="br-15"
                v-model="dialogoErro.open"
                persistent
                width="90%"
            >
                <v-card>
                    <v-card-title class="subtitle-1 error white--text">
                        Erro(s) ao Salvar Acordo(s)
                    </v-card-title>
                    <v-card-text class="mt-4">
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro"
                            class="elevation-1 data-table-2"
                        ></v-data-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <download-csv
                            :data="listaErro"
                            :labels="listaErroLabels"
                            name="erros.csv"
                            delimiter=";"
                        >
                            <v-btn color="primary">
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </download-csv>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="fecharErros(dialogoErro.area)"
                        >
                            {{ dialogoErro.label }}
                            <v-icon left v-if="dialogoErro.area != 'renovar'"
                                >mdi-close</v-icon
                            >
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogEditar" width="90%" scrollable>
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogEditar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-row no-gutters>
                        <v-col cols="4" class="mb-2">
                            <b>Bandeira:</b> {{ item.bandeira }}
                        </v-col>
                        <v-col cols="8" class="mb-2">
                            <b>Fornecedor:</b> {{ item.fornecedor }}
                        </v-col>
                        <v-col cols="4" class="mb-2">
                            <b>Espaço:</b> {{ item.espaco }}
                        </v-col>
                        <v-col cols="8" class="mb-2">
                            <b>Modelo de Check-Out:</b> {{ item.check_out }}
                        </v-col>
                        <v-col cols="12" class="mb-2">
                            <b>Selecione uma imagem ou PDF do Planograma.</b>
                        </v-col>
                        <v-col cols="12">
                            <v-form v-model="isPlanograma">
                                <v-file-input
                                    v-model="filePlanograma"
                                    label="Planograma"
                                    class="input-cinza mt-3"

                                    outlined
                                    dense
                                ></v-file-input>
                            </v-form>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="item.comentario"
                                outlined
                                dense
                                class="input-cinza"
                                rows="3"
                                label="Comentários Gerais"
                            ></v-textarea>
                        </v-col>
                        <v-col
                            cols="12"
                            class="mb-2"
                            v-show="item.obs_departamento"
                        >
                            <b>Observação Departamento:</b>
                            {{ item.obs_departamento }}
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headersProdutos"
                        :items="dadosProdutos"
                        :items-per-page="5"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                        class="elevation-0 tabela"
                    ></v-data-table>
                </v-card-text>
                <v-card-actions class="card-footer">
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="error" class="new-button mr-4" @click="dialogEditar = false">
                        Fechar
                    </v-btn> -->
                    <v-btn
                        color="primary" class="new-button mb-2 mr-2 mt-2"
                        :disabled="!isPlanograma"
                        @click="editarPlanograma()"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogAprovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary degrade white--text fix-flex"
                >
                    <v-toolbar-title>{{ dialogTitulo }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogAprovar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{ dialogTexto }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        color="error"
                        @click="dialogAprovar = false"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Não, não aprovar
                    </v-btn>
                    <v-btn
                        class="new-button mb-2 px-3"
                        color="primary"
                        @click="aprovarAcordo('aprovar')"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, aprovar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogReprovar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogReprovar = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{ dialogTexto }}
                    <br />
                    <br />
                    <v-form v-model="isJustificativa">
                        <v-select
                            label="Justificativa"
                            v-model="justificativa"
                            :items="justificativasReprovacao"
                            :rules="regra.justificativa"
                            :menu-props="menuProps"
                            class="input-cinza"
                            outlined
                            dense
                        ></v-select>
                        <v-textarea
                            outlined
                            label="Observação"
                            class="input-cinza"
                            v-model="observacao"
                        ></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        :disabled="!isJustificativa"
                        @click="aprovarAcordo('reprovar')"
                    >
                        Reprovar Acordo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog scrollable content-class="br-15" v-model="dialogHistorico" width="80%">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogHistorico = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div class="pa-5 pb-o">
                    <v-data-table
                        :headers="headersHistorico"
                        :items="dessertsHistorico"
                        item-key="id"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                        :items-per-page="5"
                        class="elevation-0 tabela"
                    ></v-data-table>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogCancelar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogCancelar = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    Deseja realmente <span style="color: red">cancelar</span> o
                    acordo? <br />
                    <br />
                    <v-form v-model="isJustificativa">
                        <v-select
                            label="Justificativa"
                            v-model="justificativa"
                            :items="justificativasCancelamento"
                            :rules="regra.justificativa"
                            outlined
                            dense
                        ></v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="new-button mr-3 px-4 mb-2"
                        @click="dialogCancelar = false"
                    >
                        <v-icon class="mr-3">mdi-close-circle</v-icon>

                        Não, não quero cancelar
                    </v-btn>
                    <v-btn
                        class="new-button px-4 mb-2"
                        color="primary"
                        @click="cancelarAcordo()"
                        :disabled="!isJustificativa"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, quero cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog content-class="br-15" v-model="dialogLiberar" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogLiberar = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    {{ dialogTexto }}
                    <br />
                    <br />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        color="error"
                        @click="dialogLiberar = false"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Não, não liberar
                    </v-btn>
                    <v-btn
                        class="new-button mb-2 px-3"
                        color="primary"
                        @click="liberarAcordo()"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, liberar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    inject: ["listaCampos"],
    data() {
        return {
            item: {},
            id_acordo_campanha: null,
            dialog: false,
            dialogImagem: false,
            dialogRenovar: false,
            dialogHistorico: false,
            dialogEditar: false,
            dialogAprovar: false,
            dialogReprovar: false,
            dialogCancelar: false,
            dialogLiberar: false,
            dialogTitulo: null,
            dialogTexto: null,
            htmlPlanograma: null,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            carregando: false,
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            regra: {
                planograma: [
                    (v) => !!v || "Selecione um arquivo.",
                    (v) =>
                        (v &&
                            [
                                ".pdf",
                                ".PDF",
                                ".jpg",
                                ".JPG",
                                ".png",
                                ".PNG",
                                "jpeg",
                                "JPEG",
                            ].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo PDF, JPG ou PNG.",
                ],
                justificativa: [(v) => !!v || "Selecione uma justificativa."],
                renovar: [
                    (v) =>
                        !!v || "Selecione a data final de renovação do acordo."
                   
                ],
                modelo: [
                    (v) => !!v || "O campo modelo check-out é obrigatório",
                ],
            },
            filePlanograma: null,
            isPlanograma: false,
            justificativa: null,
            observacao: null,
            isJustificativa: false,
            justificativasReprovacao: [],
            justificativasCancelamento: [],
            headersHistorico: [
                {
                    text: "Processo",
                    value: "processo",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Detalhes",
                    value: "detalhe",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Data",
                    value: "data",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Usuário",
                    value: "usuario",
                    sortable: false,
                    align: "center",
                },
            ],
            dessertsHistorico: [],
            termino: null,
            mesesRenovacao: [],
            modelo_ck: null,
            clusters_acordo: [],
            lista: {
                modelos_ck: [],
            },
            isRenovar: false,
            dialogoErro: {
                open: false,
                label: "FECHAR",
                area: "",
                data: [],
            },
            listaHeaderErro: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            listaErro: [],
            listaErroLabels: { msg: "Descrição do Erro" },
            paginationErro: {
                page: 1,
            },
            dadosProdutos: [],
            headersProdutos: [
                {
                    text: "Código",
                    value: "codigo",
                    sortable: false,
                    align: "center",
                    class: "th-separador"
                },
                {
                    text: "Marca",
                    value: "marca",
                    sortable: false,
                    align: "center",
                    class: "th-separador"
                },
                {
                    text: "Produto",
                    value: "nome",
                    sortable: false,
                    align: "center",
                    class: "th-separador"
                },
                { text: "VB", value: "vb", sortable: false, align: "center" },
            ],
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.justificativasReprovacao = [
            { text: "", value: "" },
            ...lista.justificativasReprovacao,
        ];
        this.justificativasCancelamento = [
            { text: "", value: "" },
            ...lista.justificativasCancelamento,
        ];
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
    },
    methods: {
        dialogDepartamento(item) {
            this.dialog = true;
            this.dialogTitulo =
                "Obs. Departamento (Acordo: " + item.id_acordo_campanha + ")";
            this.dialogTexto = item.obs_departamento;
        },
        dialogContrato(item) {
            this.dialog = true;
            this.dialogTitulo =
                "Obs. Contrato (Acordo: " + item.id_acordo_campanha + ")";
            this.dialogTexto = item.obs_contrato;
        },
        planograma(item) {
            this.dialogImagem = true;
            this.dialogTitulo = "Planograma (Acordo: " + item.id_acordo_campanha + ")";
            if (item.planograma) {
                if (item.planograma.substr(-3) == "pdf") {
                    this.htmlPlanograma = `<embed src="${
                        this.$arquivoProtegido(item.planograma)
                    }" type="application/pdf" width="100%" height="450px">`;
                } else {
                    this.htmlPlanograma = `<img src="${
                        this.$arquivoProtegido(item.planograma)
                    }" width="100%"></img>`;
                }
            } else {
                this.dialogImagem = false;
            }
        },
        async planogramaEditar(item) {
            this.carregando = true;

            this.item = item;
            this.id_acordo_campanha = item.id_acordo_campanha;

            var response = await this.$http().post("/acordoCampanhas/acordoCampanhasProdutos", {
                data: { id_acordo_campanha: item.id_acordo_campanha },
            });

            this.dadosProdutos = response.data;

            const urlToObject = async () => {
                const response = await fetch(this.item.planograma);
                // here image is url/location of image
                const blob = await response.blob();
                const file = new File(
                    [blob],
                    this.item.planograma == null ? "" : this.item.planograma,
                    { type: blob.type }
                );

                this.filePlanograma = file;
            };
            urlToObject();
            this.dialogTitulo =
                "Editar Planograma (Acordo: " + item.id_acordo_campanha + ")";

            this.dialogEditar = true;
            this.carregando = false;
        },
        renovacao(item) {
            this.modelo_ck = null;
            this.lista.modelos_ck = [];
            this.clusters_acordo = [];

            var termino = new Date(item.termino + "-15");
            termino.setMonth(termino.getMonth() + 1);
            this.termino =
                termino.getFullYear() +
                "-" +
                ("0" + (termino.getMonth() + 1)).slice(-2);

            if (
                item.organico == 1 &&
                (item.espaco_macro || "").toLowerCase() == "check-out" &&
                (item.check_out_modelo || "") == ""
            ) {
                let _ckout = (item.check_out || "").replace(/ /g, "");
                this.lista.modelos_ck = _ckout != "" ? _ckout.split(",") : [];
            }

            if (this.lista.modelos_ck.length == 1)
                this.modelo_ck = this.lista.modelos_ck[0];

            if ((item.check_out_modelo || "") != "") {
                this.modelo_ck = item.check_out_modelo;
                this.lista.modelos_ck = [item.check_out_modelo];
            }

            if (item.organico == 1) this.clusters_acordo = item.clusters_acordo;

            this.id_acordo_campanha = item.id_acordo_campanha;
            this.mesesRenovacao = [];
            this.dialogRenovar = true;
            this.dialogTitulo =
                "Renovar Acordo (Acordo: " + item.id_acordo_campanha + ")";
        },
        async historico(item) {
            this.carregando = true;
            this.$gtag.pageview({ page_title: `Histórico acordo (${item.id_acordo_campanha})` })
            this.dessertsHistorico = [];

            this.dialogTitulo = "Histórico (Acordo: " + item.id_acordo_campanha + ")";

            var response = await this.$http().post("/acordoCampanhas/historico", {
                data: { id_acordo_campanha: item.id_acordo_campanha },
            });

            this.dessertsHistorico = response.data;

            this.dialogHistorico = true;
            this.carregando = false;
        },
        async aprovar(item) {
            this.justificativa = null;
            this.observacao = null;
            this.dialogAprovar = true;

            if (item) {
                this.id_acordo_campanha = item.id_acordo_campanha;
                this.dialogTitulo =
                    "Aprovar Acordo Fora do Padão (Acordo: " +
                    item.id_acordo_campanha +
                    ")";
                this.dialogTexto = "Deseja realmente aprovar o acordo.";
            } else {
                this.id_acordo_campanha = null;
                this.dialogTitulo = "Aprovar Acordos";
                this.dialogTexto =
                    "Deseja realmente aprovar o(s) acordo(s) selecionados.";
            }
        },
        reprovar(item) {
            this.justificativa = null;
            this.observacao = null;
            this.dialogReprovar = true;

            if (item) {
                this.id_acordo_campanha = item.id_acordo_campanha;
                this.dialogTitulo =
                    "Reprovar Acordo (Acordo: " + item.id_acordo_campanha + ")";
                this.dialogTexto =
                    "Selecione uma justificativa para a reprovação do acordo.";
            } else {
                this.id_acordo_campanha = null;
                this.dialogTitulo = "Reprovar Acordos";
                this.dialogTexto =
                    "Selecione uma justificativa para a reprovação do(s) acordo(s).";
            }
        },
        async aprovarAcordo(tipo) {
            var data = {
                tipo: tipo,
                id_acordo_campanha: this.id_acordo_campanha,
            };

            if (tipo == "reprovar") {
                const justificativa = this.justificativasReprovacao.find(
                    (item) => item.value == this.justificativa
                );

                data.id_justificativa = this.justificativa;
                data.justificativa = justificativa.text;
                data.observacao = this.observacao;
            }

            this.$emit("aprovarAcordo", data);

            this.dialogAprovar = false;
            this.dialogReprovar = false;
        },
        async editarPlanograma() {
            this.carregando = true;

            var formData = new FormData();

            this.filePlanograma
                ? formData.append("files", this.filePlanograma)
                : null;
            formData.append("id_acordo_campanha", this.item.id_acordo_campanha);
            formData.append("id_contrato", this.item.id_contrato);
            formData.append("mes_acordo", this.item.mes_acordo);
            formData.append("comentario", this.item.comentario);

            var response = await this.$http().post(
                "/acordoCampanhas/editarPlanograma",
                formData
            );

            if (response.data.planograma) {
                this.item.planograma = response.data.planograma;
            }

            this.$emit("filtrar", false);

            if (response.data.sucesso) {
                this.alerta = true;
                this.alertaColor = "success";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;

                setTimeout(() => {
                    this.alerta = false;
                    this.dialogEditar = false;
                    this.carregando = false;
                    this.planograma(this.item);
                }, 1000);
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
                this.carregando = false;
            }
        },
        async renovarAcordo() {
            this.carregando = true;

            var data = {
                id_acordo_campanha: this.id_acordo_campanha,
                mesesRenovacao: this.mesesRenovacao,
                termino: this.termino,
                modelo_ck: this.modelo_ck,
                clusters_acordo: this.clusters_acordo,
            };

            var response = await this.$http().post("/acordoCampanhas/renovarAcordo", {
                data: data,
            });

            this.$emit("filtrar");

            //console.log(response.data);

            if (response.data.sucesso) {
                this.$emit("renovar", response.data);

                this.carregando = false;
                this.dialogRenovar = false;

                this.alerta = true;
                this.alertaColor = "success";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;

                setTimeout(() => {
                    this.alerta = false;
                    this.carregando = false;
                    this.dialogRenovar = false;
                }, 1000);
            } else {
                this.dialogoErro.open = true;
                this.dialogoErro.label = "CONTINUAR";
                this.dialogoErro.area = "renovar";
                this.dialogoErro.data = response.data;
                this.listaErro = response.data.erros;
                this.carregando = false;
                this.paginationErro.page = 1;
            }
        },
        fecharErros(area) {
            this.dialogoErro.open = false;
            this.carregando = false;
            this.dialogRenovar = false;

            switch (area) {
                case "renovar":
                    this.$emit("renovar", this.dialogoErro.data);
                    break;
            }
        },
        cancelar(item) {
            this.dialogCancelar = true;
            this.justificativa = null;

            this.id_acordo_campanha = item.id_acordo_campanha;
            this.dialogTitulo =
                "Cancelar Acordo (Acordo: " + item.id_acordo_campanha + ")";
            this.dialogTexto = "Deseja realmente cancelar o acordo?";
        },
        async cancelarAcordo() {
            this.carregando = true;
            const justificativa = this.justificativasCancelamento.find(
                (item) => item.value == this.justificativa
            );

            var data = {
                id_acordo_campanha: this.id_acordo_campanha,
                id_justificativa: this.justificativa,
                justificativa: justificativa.text,
            };

            var response = await this.$http().post("/acordoCampanhas/cancelarAcordo", {
                data: data,
            });

            this.$emit("filtrar", false);

            if (response.data.sucesso) {
                this.alerta = true;
                this.alertaColor = "success";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;

                setTimeout(() => {
                    this.alerta = false;
                    this.carregando = false;
                    this.dialogCancelar = false;
                }, 1000);
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
                this.carregando = false;
            }
        },
        liberar(item) {
            this.dialogLiberar = true;

            if (item) {
                this.id_acordo_campanha = item.id_acordo_campanha;
                this.dialogTitulo =
                    "Liberar Acordo (Acordo: " + item.id_acordo_campanha + ")";
                this.dialogTexto =
                    "Deseja liberar o acordo para Aguardando Gestão de Contratos?";
            } else {
                this.id_acordo_campanha = null;
                this.dialogTitulo = "Liberar Acordos";
                this.dialogTexto =
                    "Deseja realmente liberar o(s) acordo(s) selecionados.";
            }
        },
        async liberarAcordo() {
            var data = {
                id_acordo_campanha: this.id_acordo_campanha,
            };

            this.$emit("liberarAcordo", data);

            this.dialogLiberar = false;
        },
    },
};
</script>
<style >
.card-footer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
    z-index: 10;
}
</style>