var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"top":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Visualizar legendas ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":""},domProps:{"textContent":_vm._s('mdi-minus-box-outline')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Não possui inventário')}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":""},domProps:{"textContent":_vm._s('mdi-checkbox-blank-off-outline')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Não possui oportunidade')}})],1)],1),(_vm.organicos)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":""},domProps:{"textContent":_vm._s('mdi-checkbox-marked')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
						'Orgânicos: Não possui oportunidade. A filial existente no acordo teve seu cluster alterado, porém o mesmo já esta alocado a outro acordo'
					)}})],1)],1):_vm._e(),(_vm.organicos)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-checkbox-marked')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
						'Orgânicos: Filial não esta salva no acordo, porém será incluída automaticamente se clicar em [SALVAR FILIAIS SELECIONADAS]'
					)}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }