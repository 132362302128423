var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-toolbar-title',{staticClass:"ml-2 mt-2"})],1)],1),_c('v-card',{staticClass:"mt-2 pt-4 px-2",attrs:{"elevation":"1"}},[_c('filtro',{ref:"filtro",on:{"filtrar":_vm.filtrar}})],1),_c('v-card',{staticClass:"my-4 px-2",attrs:{"elevation":"1"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-4 px-4",attrs:{"cols":"6"}},[_c('v-toolbar-title',{staticClass:"grey--text"})],1),_c('v-col',{staticClass:"py-2",attrs:{"cols":"6","align":"end"}},[_c('v-btn',{staticClass:"ml-2 my-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar CSV ")],1)],1)],1),_c('div',{staticClass:"involucroGerencialSimulador"},[_c('v-data-table',{staticClass:"elevation-0 tabela-gerencial flex-table",attrs:{"headers":_vm.headers,"items":_vm.dados,"items-per-page":5,"footer-props":{
          'items-per-page-options': [5, 10, 15, 25, 50, 100],
        },"multi-sort":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"header0"},[_c('tr',_vm._l((_vm.headersGroup),function(h,i){return _c('th',{key:i,class:h.class,attrs:{"colspan":h.colspan,"align":"center"}},[_vm._v(" "+_vm._s(h.text)+" ")])}),0)])]}},_vm._l((_vm.periodo),function(key){return {key:("item.simulado-" + key),fn:function(ref){
        var item = ref.item;
return [_c('div',{key:key},[_vm._v(_vm._s(_vm.formataMoeda(item[("simulado-" + key)])))])]}}}),_vm._l((_vm.periodo),function(key){return {key:("item.realizado-" + key),fn:function(ref){
        var item = ref.item;
return [_c('div',{key:key},[_vm._v(_vm._s(_vm.formataMoeda(item[("realizado-" + key)])))])]}}}),_vm._l((_vm.periodo),function(key){return {key:("item.realxplan-" + key),fn:function(ref){
        var item = ref.item;
return [_c('div',{key:key},[_vm._v(_vm._s(item[("realxplan-" + key)] || 0)+"%")])]}}}),_vm._l((_vm.periodo),function(key){return {key:("item.diff-" + key),fn:function(ref){
        var item = ref.item;
return [_c('div',{key:key,class:item[("diff-" + key)] < 0 ? 'valor-negativo' : 'text-success'},[_vm._v(" "+_vm._s(_vm.formataMoeda(item[("diff-" + key)]))+" ")])]}}}),{key:"body.append",fn:function(){return [_c('tr',{staticClass:"table-footer"},[_c('td',{staticClass:"text-center pl-4 bg-1",attrs:{"colspan":_vm.colspan}},[_vm._v("Total:")]),_vm._l((_vm.periodo),function(key){return [_c('td',{key:'simulado-' + key,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formataMoeda(_vm.somaColuna('simulado-' + key)))+" ")]),_c('td',{key:'realizado-' + key,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formataMoeda(_vm.somaColuna('realizado-' + key)))+" ")]),_c('td',{key:'realxplan-' + key,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.mediaColuna('simulado-' + key,'realizado-' + key) + '%')+" ")]),_c('td',{key:'diff-' + key,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formataMoeda(_vm.somaColuna('diff-' + key)))+" ")])]})],2)]},proxy:true}],null,true)})],1)],1),_c('v-overlay',{attrs:{"value":_vm.carregando,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{staticStyle:{"z-index":"400"},attrs:{"color":_vm.alertaColor,"timeout":_vm.alertaTimeout,"top":""},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_c('v-alert',{attrs:{"type":_vm.alertaColor}},[_vm._v(" "+_vm._s(_vm.alertaTexto)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }