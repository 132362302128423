var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-5 py-3  br-15 w-100",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Avisos aplicativo ")])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"12","md":"8","lg":"8","xl":"8"}},[(_vm.selected.length >= 1)?_c('v-btn',{staticClass:"ml-4 btn-excluir new-button",attrs:{"color":"error"},on:{"click":function($event){return _vm.excluir()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Excluir "),_c('strong',[_vm._v(_vm._s(_vm.selected.length)+" ")]),_vm._v(" "+_vm._s(_vm.selected.length === 1 ? "aviso" : "avisos")+" ")],1):_vm._e(),_c('Adicionar',{on:{"filtrar":function($event){return _vm.filtrar()}}}),_c('ImportarAvisos',{on:{"filtrar":function($event){return _vm.filtrar()}}}),_c('v-btn',{staticClass:"new-button ml-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1)],1)],1),_c('Filtro',{ref:"filtro",staticClass:"my-3",on:{"filtrar":function($event){return _vm.filtrar()}}}),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 tabela",attrs:{"show-select":"","selectable-key":"id_aviso","item-key":"id_aviso","headers":_vm.headers,"items":_vm.dados,"items-per-page":10,"footer-props":{
            'items-per-page-options': [5, 10, 15, 25, 50, 100],
            itemsPerPageText: 'Linhas por páginas',
            ofText: 'de',
            pageText: '{0}-{1} de {2}',
          },"server-items-length":_vm.total,"options":_vm.pagination,"multi-sort":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.data_criacao",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.data_criacao).format("DD/MM/YYYY")))])]}},{key:"item.arquivo",fn:function(ref){
          var item = ref.item;
return [(item.arquivo)?_c('a',{attrs:{"href":_vm.$assinarUrl(item.arquivo),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1):_vm._e()]}},{key:"item.data_visualizacao",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.data_visualizacao && _vm.$moment(item.data_visualizacao).format("DD/MM/YYYY")))])]}},{key:"item.mensagem",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.resumirMensagem(item.mensagem)))])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.traduzirStatus(item.status)))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }