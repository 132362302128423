<template>
  <span>{{
    formatarValor(
      (this.$slots.defaul && this.$slots.default[0].text) || valor || 0
    )
  }}</span>
</template>

<script>
export default {
  props: ["valor"],

  methods: {
    formatarValor(valor) {
      return Number(valor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
  },

};
</script>

<style>
</style>