import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

import { auth } from './auth'
import planejamento_ecommerce from './planejamento_ecommerce'
import planejamento_ecommerce_gerencial from './planejamento_ecommerce_gerencial'
import planejamento_ecommerce_gerencialhp from './planejamento_ecommerce_gerencialhp'

export default new Vuex.Store({
  modules: {
    auth,
    planejamento_ecommerce,
    planejamento_ecommerce_gerencial,
    planejamento_ecommerce_gerencialhp
  },
  plugins: [vuexLocal.plugin]
})
