<template>
    <v-container fluid>
        <v-card class="px-6 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col class="d-flex" cols="6">
                    <v-toolbar-title class="page-title"
                        >Plantas Filiais
                    </v-toolbar-title>
                    <div class="ma-1">
                        <!-- <InfoBox expands>
                            <p>
                            </p>
                        </InfoBox> -->
                    </div>
                </v-col>
                <v-col cols="3" class="pb-0">
                    <v-autocomplete
                        label="Filial"
                        :items="lista.filiais"
                        v-model="filtro.id_java"
                        multiple
                        outlined
                        dense
                        class="input-cinza"
                        :menu-props="menuProps"
                        clearable
                    >
                        <template v-slot:selection="{ item, index }">
                            <div
                                v-if="
                                    filtro.id_java.length === 1 && index === 0
                                "
                                class="autocomplete-selecao"
                            >
                                {{ item.text }}
                            </div>
                            <span
                                v-if="
                                    filtro.id_java.length > 1 && index === 0
                                "
                                class="grey--text caption mr-1"
                                >{{
                                    filtro.id_java.length
                                }}
                                selecionados</span
                            >
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="3" align="end">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="novo()"
                    >
                        <v-icon left>mdi-plus-thick</v-icon> Adicionar Plantas
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headers"
                :items="dadosFiltrados"
                :items-per-page="5"
                :search="busca"
                :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0 tabela"
            >
                <template v-slot:[`item.botao`]="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"
                        @click="baixar(item)"
                        title="Baixar arquivo"
                    >
                        mdi-download
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            content-class="br-15"
            scrollable
            width="900"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Adicionar Plantas </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogo = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    <v-form ref="form">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <div class="instrucoes mt-2 mb-6">
                                    <h4 color="primary">
                                        Para importar mais de uma planta por
                                        vez, siga as instruções abaixo
                                    </h4>
                                    <ul>
                                        <li>
                                            Você tem a opção de carregar vários
                                            arquivos de uma só vez, o que torna
                                            o processo mais rápido e eficiente.
                                            Para facilitar a associação da
                                            planta corretamente, recomendamos
                                            que você inclua o código ID_JAVA no
                                            nome do arquivo, seguindo o formato
                                            sugerido "lj_0001.pdf". Se por algum
                                            motivo o sistema não conseguir
                                            identificar automaticamente a filial
                                            correspondente, será necessário
                                            informá-la manualmente.
                                        </li>
                                    </ul>
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                                class="px-2"
                            >
                                <v-data-table
                                    v-if="arquivos.length > 0"
                                    class="elevation-0 tabela mb-5"
                                    :hide-default-footer="true"
                                    key="id_java"
                                    :headers="[
                                        {
                                            text: 'Arquivos',
                                            align: 'center',
                                            value: 'name',
                                            class: 'th-separador',
                                        },
                                        {
                                            text: 'Filial',
                                            align: 'center',
                                            value: 'id_java',
                                        },
                                    ]"
                                    :items="arquivos"
                                >
                                    <template
                                        v-slot:[`item.id_java`]="{ item }"
                                    >
                                        <div class="d-flex">
                                            <v-autocomplete
                                                :items="lista.filiais"
                                                v-model="item.id_java"
                                                placeholder="Selecione a filial"
                                                outlined
                                                dense
                                                class="input-cinza"
                                                :menu-props="menuProps"
                                                :rules="[
                                                    (v) =>
                                                        !!v ||
                                                        'Selecione a filial',
                                                ]"
                                                hide-details
                                                required
                                            >
                                            </v-autocomplete>
                                            <!-- Add a button to remove this item -->
                                            <v-btn
                                                icon
                                                class="ml-2"
                                                @click="
                                                    arquivos.splice(
                                                        arquivos.indexOf(item),
                                                        1
                                                    )
                                                "
                                            >
                                                <v-icon color="red"
                                                    >mdi-close</v-icon
                                                >
                                            </v-btn>
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <v-card-actions class="mb-3 mr-3">
                    <v-spacer></v-spacer>

                    <v-btn
                        class="px-4 new-button mr-4"
                        color="yellow"
                        v-if="arquivos.length > 0"
                        dark
                        @click="limpar()"
                    >
                        <v-icon left>mdi-filter-off</v-icon> Limpar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="px-4 new-button mr-4"
                        dark
                        @click="handleFileImport"
                    >
                        <v-icon left>mdi-plus-thick</v-icon>
                        Adicionar Arquivos
                    </v-btn>
                    <v-btn
                        class="px-4 new-button"
                        color="primary"
                        :disabled="!arquivos.length > 0"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-check-circle</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- <v-progress-linear indeterminate color="green"></v-progress-linear> -->
        <v-file-input
            style="display: none"
            ref="uploader"
            label="Plantas"
            class="input-cinza"
            @change="arquivoAdicionado"
            accept="image/*,.pdf"
            multiple
            outlined
            dense
        ></v-file-input>

</v-container>
</template>

<script>
export default {
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            uploadPercentage: 0,
            arquivosDetalhes: [],
            headers: [
                {
                    text: "ID Java",
                    value: "id_java",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Filial",
                    value: "filial",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Data de Criação",
                    value: "data_atualizacao",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Usuário",
                    value: "usuario",
                    align: "center",
                    class: "th-separador",
                },
                { text: "", value: "botao", align: "center", sortable: false },
            ],
            dados: [],
            lista: {
                filiais: [],
            },
            filtro: {
                id_java: [],
            },
            arquivos: [],
            pagination: {},
            carregando: false,
            busca: null,
            dialogo: false,
            validaForm: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            regra: {
                manual: [
                    (v) => !!v || "Selecione um arquivo PDF.",
                    (v) =>
                        (v && [".pdf", ".PDF"].includes(v.name.substr(-4))) ||
                        "O arquivo deve ser do tipo PDF.",
                ],
            },
        };
    },
    async created() {
        this.listaCampos();
        this.filtrar();
    },

    computed: {
dadosFiltrados(){
    if(this.filtro.id_java.length > 0){
        return this.dados.filter((item) => {
            return this.filtro.id_java.includes(item.id_java);
        });
    }
        return this.dados;
}
},

    methods: {
        validaAssociacaoIdJava() {
            console.log(this.arquivos.length);
            // if (this.arquivos.length == 0) {
            //     return true;
            // }
            return !this.arquivos.every((item) => item.id_java != null);
        },
        arquivoAdicionado(arquivos) {
            arquivos.forEach((item) => {
                const idJava = Number(item.name.replace(/\D/g, ""));
                item.id_java = idJava > 0 ? idJava : null;
            });

            this.arquivos.push(...arquivos);
            this.$refs.uploader.value = [];
        },
        async listaCampos() {
            this.carregando = true;
            const response = await this.$http().post(
                "/app/plantas/listarCampos"
            );
            if (response.status != 200) {
                /// tratar erros de carregamento
                this.$dialog.notify.error("Erro ao carregar lista de filiais", {
                    position: "top-right",
                    timeout: 5000,
                });
                this.carregando = false;
                return;
            }
            this.lista = response.data;
            this.carregando = false;
            return;
        },
        limpar() {
            try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}
            this.arquivos = [];
        },
        async filtrar() {
            this.carregando = true;

            this.pagination.page = 1;

            var data = {

            };

            var response = await this.$http().post("/app/plantas/lista", {
                data: data,
            });

            this.dados = response.data;

            this.carregando = false;
        },

        novo() {
            this.manual = null;

            this.limpar();

            this.dialogo = true;
        },
        baixar(item) {
            window.open(this.$arquivoProtegido(item.arquivo), "_blank");
        },
        validar(){
            this.$refs.form.validate();
            if(this.arquivos.length == 0){
                this.$dialog.notify.error("Adicione pelo menos 1 arquivo para salvar.", {
                    position: "top-right",
                    timeout: 5000,
                });
                return true;
            }
            if(this.arquivos.length > 0){
                if(!this.arquivos.every((item) => item.id_java != null)){
                    this.$dialog.notify.error("Por favor associe as filiais aos arquivos", {
                    position: "top-right",
                    timeout: 5000,
                });
                    return true;
                }
            }
            return false;
        },
        async salvar() {
            if (this.validar()) return;

            this.carregando = true;

            var formData = new FormData();

            this.arquivos.forEach((item) => {
                formData.append("files", item);
            });
            
            formData.append("arquivosDetalhes", JSON.stringify(this.arquivos));

            var response = await this.$http().post(
                "/app/plantas/salvar",
                formData,
                {
                    onUploadProgress: function (progressEvent) {
                        this.uploadPercentage = parseInt(
                            Math.round(
                                (progressEvent.loaded / progressEvent.total) *
                                    100
                            )
                        );
                    }.bind(this),
                }
            );

            if (response.data.sucesso) {
                this.filtrar();
                this.carregando = false;
                this.dialogo = false;
                this.limpar();
                this.$dialog.notify.success('Arquivos salvos com sucesso!', {
                    position: "top-right",
                    timeout: 5000,
                });
            } else {
                this.carregando = false;

                this.alerta = true;
                this.$dialog.notify.error('Erro ao salvar arquivos', {
                    position: "top-right",
                    timeout: 5000,
                });
            }
        },
        handleFileImport() {
            this.$refs.uploader.$refs.input.click();
        },
    },
};
</script>
<style>
.instrucoes {
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 6px;
    color: #808080;
}
.instrucoes h4 {
    color: var(--v-primary-base);
    margin-bottom: 6px;
}
</style>