<template>
	<v-layout align-center justify-center row fill-height>
		<div >
			<v-overlay :absolute="true" :value="overlay">
				<v-progress-circular indeterminate size="40" />
			</v-overlay>
			<v-alert
				:value="error && !empty"
				color="error"
				type="warning"
				outlined
			>
				Falha na requisição, tente novamente!
			</v-alert>
			<v-alert :value="empty && !error" color="grey" type="info" outlined>
				Nenhum registro localizado!
			</v-alert>
		</div>
	</v-layout>
</template>

<script>
export default {
	props: {
		overlay: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		empty: {
			type: Boolean,
			default: false,
		},
	},

	created() {},
};
</script>
<style scoped></style>
