<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2"
                    >Investimento</v-toolbar-title
                >
            </v-col>
        </v-row>
        <v-card class="mt-2 pt-4 px-2" elevation="1">
            <filtro ref="filtro" @filtrar="filtrar"></filtro>
        </v-card>
        <v-row no-gutters>
            <v-col
                cols="12"
                sm="12"
                :md="layoutCompacto ? 8 : 12"
                :lg="layoutCompacto ? 8 : 12"
                :xl="layoutCompacto ? 8 : 12"
            >
                <v-card class="mt-4 px-2 pt-2" elevation="1">
                    <v-icon
                        style="
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            z-index: 2;
                        "
                        left
                        :color="layoutCompacto ? 'primary' : ''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                        >mdi-view-compact</v-icon
                    >
                    <highcharts
                        :options="graficoInvestimentoMes"
                        ref="grafico1"
                    ></highcharts>
                    <v-overlay :value="carregandoInvestimentoMes" absolute>
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                :md="layoutCompacto ? 4 : 12"
                :lg="layoutCompacto ? 4 : 12"
                :xl="layoutCompacto ? 4 : 12"
            >
                <v-card
                    class="mt-4 px-2 pt-2"
                    :class="layoutCompacto ? 'ml-4' : ''"
                    elevation="1"
                >
                    <v-icon
                        style="
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            z-index: 2;
                        "
                        left
                        :color="layoutCompacto ? 'primary' : ''"
                        @click="mudaLayout()"
                        title="Layout compacto"
                        >mdi-view-compact</v-icon
                    >
                    <highcharts
                        :options="graficoInvestimentoAno"
                        ref="grafico2"
                    ></highcharts>
                    <v-overlay :value="carregandoInvestimentoAno" absolute>
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="mt-4 px-2 pt-2" elevation="1">
                    <highcharts
                        :options="graficoInvestimento"
                        ref="grafico3"
                    ></highcharts>
                    <v-overlay :value="carregandoInvestimentoFornecedor" absolute>
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-card class="mt-4 px-2 pt-2 ml-4" elevation="1">
                    <highcharts
                        :options="graficoDesconto"
                        ref="grafico4"
                    ></highcharts>
                    <v-overlay :value="carregandoDesconto" absolute>
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import Filtro from './Filtro.vue'

export default {
    components: {
        Filtro,
    },
    data() {
        return {
            carregando: false,
            carregandoInvestimentoMes: false,
            carregandoInvestimentoAno: false,
            carregandoInvestimentoFornecedor: false,
            carregandoDesconto: false,
            layoutCompacto: true,
            graficoInvestimentoMes: {
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                title: {
                    text: 'Investimento Mensal Comparativo',
                },
                chart: {
                    type: 'column',
                },
                yAxis: {
                    visible: false,
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })
                            },
                            style: {
                                fontWeight: 'bold',
                                color: '#000',
                                textOutline: 'none',
                            },
                        },
                        states: {
                            inactive: {
                                opacity: 1,
                            },
                        },
                    },
                },
                xAxis: {
                    categories: [],
                },
                series: [
                    {
                        name: 'Investimento',
                        data: [],
                        color: this.$vuetify.theme.themes.light.primary.base,
                    },
                    {
                        name: 'Investimento Ano Anterior',
                        data: [],
                        color: this.$vuetify.theme.themes.light.info,
                    },
                    {
                        name: 'Desconto',
                        data: [],
                        color: this.$vuetify.theme.themes.light.warning,
                    },
                    {
                        name: 'Desconto Ano Anterior',
                        data: [],
                        color: '#ffc24b',
                    },
                ],
            },
            graficoInvestimentoAno: {
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                title: {
                    text: 'Investimento Anual Comparativo',
                },
                chart: {
                    type: 'column',
                },
                yAxis: {
                    visible: false,
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })
                            },
                            style: {
                                fontWeight: 'bold',
                                color: '#000',
                                textOutline: 'none',
                            },
                        },
                        states: {
                            inactive: {
                                opacity: 1,
                            },
                        },
                    },
                },
                xAxis: {
                    categories: [],
                },
                series: [
                    {
                        name: 'Investimento',
                        data: [],
                        color: this.$vuetify.theme.themes.light.primary.base,
                    },
                    {
                        name: 'Desconto',
                        data: [],
                        color: this.$vuetify.theme.themes.light.warning,
                    },
                ],
            },
            graficoInvestimento: {
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                title: {
                    text: 'Top 10 Investimentos',
                },
                chart: {
                    type: 'bar',
                },
                yAxis: {
                    visible: false,
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })
                            },
                            style: {
                                fontWeight: 'bold',
                                color: '#000',
                                textOutline: 'none',
                            },
                        },
                    },
                },
                xAxis: {
                    categories: [],
                },
                series: [
                    {
                        data: [],
                        color: this.$vuetify.theme.themes.light.primary.base,
                    },
                ],
            },
            graficoDesconto: {
                credits: {
                    enabled: false,
                },
                tooltip: {
                    enabled: false,
                },
                title: {
                    text: 'Top 10 Descontos',
                },
                chart: {
                    type: 'bar',
                },
                yAxis: {
                    visible: false,
                },
                legend: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.point.y.toLocaleString('pt-br', {
                                    style: 'currency',
                                    currency: 'BRL',
                                })
                            },
                            style: {
                                fontWeight: 'bold',
                                color: '#000',
                                textOutline: 'none',
                            },
                        },
                    },
                },
                xAxis: {
                    categories: [],
                },
                series: [
                    {
                        data: [],
                        color: this.$vuetify.theme.themes.light.primary.base,
                    },
                ],
            },
        }
    },
    // provide() {
    //     return {
    //         listaCampos: this.listaCampos()
    //     }
    // },
    watch: {},
    computed: {},
    methods: {
        mudaLayout() {
            this.layoutCompacto = !this.layoutCompacto

            setTimeout(() => {
                this.$refs.grafico1.chart.reflow()
                this.$refs.grafico2.chart.reflow()
            }, 100)
        },
        // async listaCampos() {
        //     this.carregando = true

        //     var response = await this.$http().post('/gerencial/filtros', {})
        //     this.carregando = false
        //     return response.data
        // },
        async buscarInvestimentoMes(data) {
            this.carregandoInvestimentoMes = true

            var response = await this.$http().post(
                '/gerencial/investimentoMes',
                {
                    data: data,
                }
            )

            this.graficoInvestimentoMes.xAxis.categories = response.data.map(
                (e) => e.mes
            )
            this.graficoInvestimentoMes.series[0].data = response.data.map(
                (e) => e.investimento * 1
            )
            this.graficoInvestimentoMes.series[1].data = response.data.map(
                (e) => e.investimento_anterior * 1
            )
            this.graficoInvestimentoMes.series[2].data = response.data.map(
                (e) => e.desconto * 1
            )
            this.graficoInvestimentoMes.series[3].data = response.data.map(
                (e) => e.desconto_anterior * 1
            )

            this.carregandoInvestimentoMes = false
        },
        async buscarInvestimentoAno(data) {
            this.carregandoInvestimentoAno = true

            var response = await this.$http().post(
                '/gerencial/investimentoAno',
                {
                    data: data,
                }
            )

            this.graficoInvestimentoAno.xAxis.categories = response.data.map(
                (e) => e.ano
            )
            this.graficoInvestimentoAno.series[0].data = response.data.map(
                (e) => e.investimento * 1
            )
            this.graficoInvestimentoAno.series[1].data = response.data.map(
                (e) => e.desconto * 1
            )

            this.carregandoInvestimentoAno = false
        },

        async buscarInvestimentoFornecedor(data) {
            this.carregandoInvestimentoFornecedor = true

            var response = await this.$http().post(
                '/gerencial/investimentoFornecedor',
                {
                    data: data,
                }
            )

            this.graficoInvestimento.xAxis.categories = response.data.map(
                (e) => e.fornecedor
            )
            this.graficoInvestimento.series[0].data = response.data.map(
                (e) => e.investimento * 1
            )

            this.carregandoInvestimentoFornecedor = false
        },

        async buscarDesconto(data) {
            this.carregandoDesconto = true

            var response = await this.$http().post('/gerencial/investimentoDesconto', {
                data: data,
            })

            this.graficoDesconto.xAxis.categories = response.data.map(
                (e) => e.fornecedor
            )
            this.graficoDesconto.series[0].data = response.data.map(
                (e) => e.desconto * 1
            )

            this.carregandoDesconto = false
        },
        async filtrar() {
            // this.carregando = true

            if (this.$refs.filtro.filtro.mesInicial == undefined) {
                this.$refs.filtro.iniciaFiltro()
            }

            var filtro = { ...this.$refs.filtro.filtro }

            var data = {}

            data.mesInicial = filtro.mesInicial
            data.mesFinal = filtro.mesFinal
            data.id_filial = filtro.id_filial
            data.bandeira = filtro.bandeira
            data.id_fornecedor = filtro.id_fornecedor
            data.id_espaco = filtro.id_espaco
            data.espaco_macro = filtro.espaco_macro
            data.id_cluster = filtro.id_cluster
            data.regiao = filtro.regiao
            data.perfil = filtro.perfil
            data.tamanho = filtro.tamanho

            this.buscarInvestimentoMes(data)
            this.buscarInvestimentoAno(data)
            this.buscarInvestimentoFornecedor(data)
            this.buscarDesconto(data)

            // var response = await this.$http().post('/gerencial/investimento', {data: data})

            // this.graficoInvestimentoMes.xAxis.categories = response.data.investimentoMes.map(e => e.mes)
            // this.graficoInvestimentoMes.series[0].data = response.data.investimentoMes.map(e => e.investimento*1)
            // this.graficoInvestimentoMes.series[1].data = response.data.investimentoMes.map(e => e.investimento_anterior*1)
            // this.graficoInvestimentoMes.series[2].data = response.data.investimentoMes.map(e => e.desconto*1)
            // this.graficoInvestimentoMes.series[3].data = response.data.investimentoMes.map(e => e.desconto_anterior*1)

            // this.graficoInvestimentoAno.xAxis.categories = response.data.investimentoAno.map(e => e.ano)
            // this.graficoInvestimentoAno.series[0].data = response.data.investimentoAno.map(e => e.investimento*1)
            // this.graficoInvestimentoAno.series[1].data = response.data.investimentoAno.map(e => e.desconto*1)

            // this.graficoInvestimento.xAxis.categories = response.data.fornecedorInvestimento.map(e => e.fornecedor)
            // this.graficoInvestimento.series[0].data = response.data.fornecedorInvestimento.map(e => e.investimento*1)

            // this.graficoDesconto.xAxis.categories = response.data.fornecedorDesconto.map(e => e.fornecedor)
            // this.graficoDesconto.series[0].data = response.data.fornecedorDesconto.map(e => e.desconto*1)

            // this.carregando = false
        },
    },
}
</script>


<style>
</style>