<template>
  <div
    :class="
      absolute ? ' absolute info-box text-center' : 'info-box text-center'
    "
  >
    <v-menu offset-y :content-class="menuClass">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          color="transparent"
          elevation="0"
          v-bind="attrs"
          x-small
          allow-overflow
          auto
          v-on="on"
        >
          <v-icon color="#808080"> mdi-information-outline</v-icon>
        </v-btn>
      </template>
      <div class="triangle-with-shadow"></div>
      <v-card class="pa-5 br-15 card"  style="width: 450px">
        <div class="info-box-text"><slot></slot></div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    valor: Number,
    expands: Boolean,
    absolute: Boolean,
  },
  computed: {
    menuClass() {
      return "my-menu " + (this.expands ? "expansive" : "");
    },
  },
  methods: {
    formatarValor(valor) {
      return Number(valor).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
};
</script>

<style scoped>
.my-menu {
  margin-left: -210px;
  margin-top: -5px;
  contain: initial;
  overflow: visible;
  box-shadow: none !important;
  border-radius: 15px !important;
  color: #808080;
}
.absolute {
  position: absolute;
}
.info-box {
  z-index: 10;
}
.triangle-with-shadow {
  width: 100px;
  height: 25px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  z-index: 10;
}
.triangle-with-shadow:after {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  background: #fff;
  transform: scaleX(0.7) rotate(45deg) translateY(10px);
  top: 10px;
  left: 15px;
  box-shadow: -1px -1px 10px  rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.titulo {
  font-weight: 500;
  color: var(--v-primary-base);
}
.info-box-text {
  color: #808080;
  font-size: 0.85rem;
}
.expanded .my-menu.expansive {
  margin-left: -70px;
}
.expanded .expansive .triangle-with-shadow {
  margin: 0 35px;
}
.card{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;

}
</style>