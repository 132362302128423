<template>
	<span>
		<v-badge
			bordered
			:color="alertClusterType"
			:icon="
				alertClusterType == 'warning' ? 'mdi-exclamation' : 'mdi-check'
			"
			overlap
			left
			title="Teste"
			class="ml-3 mr-3"
		>
			<v-btn class="white--text" color="primary" @click="showUpdate()">
				<v-icon left>mdi-refresh</v-icon> Atualizar<br />
				Cluster
			</v-btn>
		</v-badge>

		<Modal
			v-model="showModal"
			title="Atualizar Cluster"
			:fullscreen="false"
			:maxWidth="'70%'"
			:progress="progress"
			:progressLabel="progressLabel"
		>
			<template slot="body" v-if="showModal">
				<v-container fluid>
					<v-row>
						<v-col cols="7">
							Selecione a partir de qual mês deseja atualizar o
							cluster dos acordos:

							<v-row>
								<v-col cols="12" md="7" lg="7">
									<v-form ref="formCluster">
										<v-text-field
											label="Mês"
											outlined
											dense
											type="month"
											:rules="regras.mes"
											v-model="mes"
											:min="mesProximo"
										/>
									</v-form>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="5">
							<v-alert dense :type="alertClusterType">
								{{
									dadosUpdateDay.length > 0 &&
									!dadosUpdateDay[0][
										"alteracao_cluster_filiais"
									]
										? "Última atualização do Cluster dia " +
										  dadosUpdateDay[0]["dt_registro"]
										: "Atualização de Cluster pendente."
								}}
							</v-alert>
						</v-col>
					</v-row>

					<v-divider></v-divider>
					<v-row>
						<v-col cols="12" align="end">
							<v-btn color="primary" @click="update()">
								Atualizar
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</Modal>
	</span>
</template>

<script>
import Modal from "../../../../components/Modal.vue";

export default {
	components: { Modal },
	props: {
		alertClusterType: {
			type: String,
			default: () => "warning",
		},
		dadosUpdateDay: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			showModal: false,
			mes: null,
			load: false,
			progress: null,
			progressLabel: null,
			regras: {
				mes: [(v) => !!v || "O campo mês é obrigatório."],
			},
		};
	},
	computed: {
		mesAtual() {
			return this.$moment().format("YYYY-MM");
		},
		mesProximo() {
			return this.$moment()
				.add(2, "months")
				.format("YYYY-MM");
		},
	},
	methods: {
		async update() {
			if (!this.$refs.formCluster.validate()) return;
			var _this = this;

			this.progress = 0;

			const _response = await this.$http().post(
				"/inventario/updateClusterEspaco",
				{
					mes_ini: this.mes + "-01",
				},
				{
					onDownloadProgress: (progressEvent) => {
						let retorno = progressEvent.currentTarget.response.split(
							";"
						);

						let obj = JSON.parse(retorno[retorno.length - 2]);

						if (obj.step) {
							this.progress = obj.step;
							this.progressLabel = obj.label;
						}

						if (obj.success) {
							this.progress = 100;
							this.progressLabel =
								"Atualização dos Cluster das filiais realizadas";
							this.$emit("refresh-alert");
						}

						console.log("progress=>", obj);
					},
				}
			);

			let _resultEnd = _response.data.split(";");
			let _objEnd = JSON.parse(_resultEnd[_resultEnd.length - 2]);

			console.log(_resultEnd, _objEnd);

			if (!(_objEnd.success || false)) {
				this.$dialog.notify.error(
					"Ops! Falha ao atualizar o cluster, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.progress = null;
				this.progressLabel = null;
				//this.showModal = false;
				return false;
			}

			this.progressLabel = "Processo finalizado com sucesso!";

			setTimeout(() => {
				_this.progress = null;
				_this.progressLabel = null;
				_this.showModal = false;
			}, 3000);
		},
		async showUpdate() {
			this.mes = this.mesProximo;
			this.showModal = true;
		},
	},
};
</script>

<style></style>
