<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Preços Tablóide</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="exportar()"   
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                </v-btn>               
                <v-btn
                    class="ml-2 my-1"
                    color="primary"        
                             
                    @click="abrirImportar()"   
                >
                    <v-icon left>mdi-upload</v-icon> Importar                    
                </v-btn>              
            </v-col>            
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3"> 
                </v-col>   
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                   <v-form ref="formFiltro">
                        <v-text-field
                            label="Ano"                
                            v-model="ano"  
                            :rules="regra.ano"        
                            required
                            outlined
                            dense 
                            type="number" 
                            placeholder="YYYY"                            
                            max="2900"
                            v-mask="'####'"
                         
                        ></v-text-field>  

                       
                    </v-form>
                </v-col> 
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">                            
                    <v-btn
                        class="ml-2 my-1"
                        color="primary"                     
                        @click="filtrar()"   
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar                    
                    </v-btn>      
                </v-col>                   
            </v-row>    
             
            <v-data-table
                :headers="headers"
                :items="dados" 
                :search="busca"  
                :options.sync="pagination"             
                multi-sort
                class="elevation-0"
            >   
                <template v-slot:[`item.botao`]="{ item }"> 
        
                    <v-icon
                      
                        small
                        class="mr-2"
                        color="primary"   
                        @click="abrirEditar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
         
         
          
         

        
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="600"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Editar
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form v-model="validaForm"> 
                        <v-row no-gutters class="mt-4">                                         
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="ID Preço Campanha"
                                    v-model="precoTabloide.id_precotabloide"
                                ></v-text-field> 
                            </v-col>                                  
                         



                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                   <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Ano"
                                    v-model="precoTabloide.ano"
                                ></v-text-field> 
                            </v-col>   

                               <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                   <v-text-field
                                    outlined
                                    dense 
                                                                    
                                    label="Tipo de Anúncio"
                                    v-model="precoTabloide.tipo_anuncio"
                                ></v-text-field> 
                            </v-col>                                            
                                                    
                                                                
                                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Dimensões"
                                    v-model="precoTabloide.dimensoes"                                  
                                ></v-text-field>
                            </v-col>                                                
                                                          
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <vuetify-money 
                                    outlined
                                    dense 
                                    label="Valor"
                                    v-model="precoTabloide.valor"
                                    :options="options"
                                    clearable="clearable"
                                ></vuetify-money >
                            </v-col>                                                
                                                   
                            
                                
                        </v-row>  
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        :disabled="!validaForm"
                        @click="editar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoImportar"
            width="90%"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV para ano de {{ano}}
                </v-card-title>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                A base utilizada para importação deve ser parecida com base exportada. 
                            </li>
                            <li>
                                As colunas 
                                <strong>ano</strong> 
                                ,<strong>tipo_anuncio</strong>	,
                                <strong>dimensoes</strong>,	 
                                <strong>valor</strong>	,
                                são obrigatórias (<strong>e os nomes devem ser identicos aos da base exportada</strong>), as demais colunas podem ser excluidas.
                            </li>                            
                            <li>
                                As colunas <b>em valores</b> devem estar no formato moeda "<b>R$ 0.000,00</b>" com virgula "," como separador decimal e ponto "." como separador de milhar.
                            </li>
                            <li>
                                A coluna <strong>ano</strong> não é considerada na importação.
                            </li>
                            
                            <li>
                                O <strong>mês</strong> considerado na importação é o ano selecionano no <strong>filtro da lista de Preços Tablóide</strong>.
                            </li>
                          
                        </ul>  
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-expand-transition>
                        <div v-show="erro">                            
                            <v-row no-gutters class="mb-0">  
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Erros
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table                            
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>                    
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [   
                   
                { text: 'Ano', value: 'ano', align: 'center'},
                { text: 'Tipo de Anúncio', value: 'tipo_anuncio', align: 'center'},
                { text: 'Dimensões', value: 'dimensoes', align: 'center'},
                { text: 'Valor', value: 'valor', align: 'center'},              
                 { text: '', value: 'botao', align: 'center', sortable: false },                 
            
            ],
            dados: [

            ], 
            ano: null, 
            precoTabloide: {
                id_precotabloide: null,          
                ano: null,                       
                tipo_anuncio:null,
                dimensoes:null, 
                valor:null            
           
            },
            pagination: {}, 
            paginationErros: {}, 
            total: null,
            carregando: false, 
            busca: null, 
            dialogo: false,
            dialogoImportar: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            arquivoCSV: null,
            lista: {
                macrofornecedor: [],
            },
            regra: { 
                csv: [
                    v => !!v || 'Selecione um arquivo.',
                    v => (!!v && [
                        '.csv', 
                        '.CSV'
                    ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                ],       
                ano: [
                    v => !!v || 'O campo mês é obrigatório.',
                ],   
            },  
            isCsv: false,            
            headerErros: [
                { text: 'Descrição do Erro', value: 'msg', align: 'left'},
            ],
            erros: [],
            errosLabels: {msg: 'Descrição do Erro'}, 
            erro: false,            
            options: {
                locale: "pt-BR",
                prefix: "R$",
                suffix: "",
                length: 11,
                precision: 2
            },
        }
    },
    watch: {
        pagination: {
            handler () {
                this.filtrar()
            },
            deep: true,
        },
        busca(v) {

            setTimeout(() => {

                if(v == this.busca){
                    
                    this.filtrar()
                }

            }, 1500)            
        },
    },
    async created() {    
        
        this.ano = this.anoProximo
        

  

        this.filtrar() 
       
    },    
    computed: {
        anoProximo() {
            return this.$moment().format("YYYY")
        },  
    },
    methods: {
        async filtrar() {

            // if(!this.$refs.formFiltro.validate()) return
            
             this.carregando = true

            var data = {ano: this.ano }

        
            data.pagination = this.pagination
            data.busca = this.busca

            var response = await this.$http().post('/tabloide/lista',{data: data})  
           
            
            this.dados = response.data      
                   

           
        // const adesao = response.data.map(x => parseInt(x.adesao.replace(/[^0-9,]*/g, '').replace(',', '.'))).reduce((a, b) => a + b, 0)
            

             
         //  this.dados.total_adesao=adesao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
           
               
          
                    

            this.carregando = false
        },
        async exportar() {
             
           
            this.carregando = true
            
            var data = {ano: this.ano }           

            var response = await this.$http().post('/tabloide/exportar', {data: data})   
          
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        abrirEditar(item) {
           
            this.precoTabloide = {...item,
            valor:parseInt(item.valor.replace(/[^0-9,]*/g, '').replace(',', '.'))}       
      
         
            
            this.dialogo = true          
        }, 
        async editar() {
            
            this.carregando = true
            
            var data = this.precoTabloide

            var response = await this.$http().post('/tabloide/editar', {data: data})   
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()

                this.dialogo = false  
            }
            else{

                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
        abrirImportar() {

            if(!this.$refs.formFiltro.validate()) return

            this.arquivoCSV = null
            this.erro = false

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            this.dialogoImportar = true 
        },
        async importar() {
            
            this.carregando = true  
            
            var data = {ano: this.ano }
            
            var formData = new FormData()

            formData.append("files", this.arquivoCSV)
            formData.append("data", JSON.stringify(data))

            this.arquivoCSV = null

            try {
                this.$refs.form.resetValidation()
            } catch (error) {
                //console.log(error)
            }  

            var response = await this.$http().post('/tabloide/importar', formData)  
            
            this.carregando = false

            if(response.data.sucesso){

                this.filtrar()
                this.dialogoImportar = false
            }
            else{

                this.filtrar()
                this.erros = response.data.erros
                this.pagination.page = 1
                this.erro = true
            }  
        }, 
    },
}
</script>

<style >

</style>