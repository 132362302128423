<template>
	<div class="indicador">
		<div class="indicador-icon">
			<v-icon size="26" color="primary">{{ icone }}</v-icon>
		</div>
		<div class="indicado-info">
			<div class="indicador-text">
			<span>{{ titulo }}: </span>
		</div>
		<div class="indicador-value">
			<span>{{ formatarValor(valor || 0) }}</span>
		</div>
		</div>
		<v-overlay :absolute="true" :value="load">
			<v-progress-circular indeterminate size="40" />
		</v-overlay>
	</div>
</template>

<script>
export default {
	props: ["titulo", "valor", "icone", "load"],

	methods: {
		formatarValor(valor) {
			return Number(valor).toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
		},
	},
};
</script>

<style scoped>
.indicador {
	display: flex;
	align-items: center;
	padding: 1rem;
	padding-left: 0;
	border-bottom: 1px solid #e0e0e0;
	background: #eaeaea;
	border-radius: 10px;
	position: relative;
	overflow: hidden;
	transition: all 0.4s;

}
.indicado-info{
	display: flex;
	flex-direction: column;
}

.indicador-icon {
	background-color: #fff;
	border: 2px solid var(--v-primary-base);
	border-radius: 50%;
	padding: 8px;
	display: flex;
	position: relative;
	margin: 0 1rem;
}
.indicador-text {
	font-weight: 500;
	color: #000;
	margin-bottom: 0;
	font-size: 0.8rem;
	transition: all 0.4s;

}

.indicador-value {
	font-size: 1rem;
	font-weight: 500;
	color: var(--v-primary-base);
	transition: all 0.4s;

}
.indicador:hover {
	color: #fff !important;
	background: var(--v-primary-base);

}
.indicador:hover .indicador-value, .indicador:hover .indicador-text{
	color: #fff;
}
</style>
