<template>
  <v-card class="br-15 pa-3 px-5 pb-5 overflow-hidden" elevation="0">
    <InfoBox expands>
      <span class="titulo">Valores e ativações mensal: </span> Nesta tabela é
      possivel avaliar os valores e ativações dos investimentos por mês, nas
      visões: PDV, Tablóide, Campanha e Ecommerce.
    </InfoBox>
    <h2 class="text-center" style="font-weight: normal">
      Valores e Ativações mensal ({{ ano }})
    </h2>

    <div class="involucroValorAtivacaoMensal">
      <template v-if="!error && !empty && !overlay">
        <v-simple-table dense class="mt-3 tabela-valores-ativacao flex-table">
          <template v-slot:default>
            <thead>
              <tr class="v-data-table-header">
                <th class="text-center min-width-150">Master Espaço</th>
                <th
                  v-for="(item, index) in months"
                  :key="index"
                  :class="`text-center th-separador text-capitalize min-width-150 ${estacaoDoAno(
                    index
                  )}-dark`"
                >
                  {{ item }}
                  <v-icon
                    v-if="estacaoDoAno(index) === 'verao'"
                    color="#c28f5b"
                    class="mx-1"
                    size="18"
                  >
                    mdi-white-balance-sunny
                  </v-icon>
                  <v-icon
                    v-if="estacaoDoAno(index) === 'inverno'"
                    color="#1e7199"
                    class="mx-1"
                    size="18"
                  >
                    mdi-snowflake
                  </v-icon>
                </th>
                <th class="text-center v-data-table-header min-width-100">
                  Total
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <th
                  colspan="14"
                  class="text-center v-data-table-header teal darken-2"
                  style="padding: 5px !important"
                >
                  Valores
                </th>
              </tr>
              <!-- VALOR -->
              <tr v-for="(f, index) in filterData('Valor')" :key="'a' + index">
                <td class="text-center">
                  {{ f.ferramenta }}
                </td>

                <td
                  v-for="(v, index) in f.valores"
                  :key="'b' + index"
                  :class="{
                    'text-center': true,
                  }"
                >
                  <span>
                    <formataReal :valor="v" />
                  </span>
                </td>
                <td
                  :class="{
                    'text-center': true,
                  }"
                >
                  <formataReal :valor="sumRow(f.valores)" />
                </td>
              </tr>
              <!-- TOTAL -->
              <tr class="table-footer">
                <td class="text-center">Total:</td>
                <td v-for="(m, i) in months" :key="'c' + i" class="text-center">
                  <formataReal :valor="sumCols('Valor', i)" />
                </td>
                <td class="text-center">
                  <formataReal :valor="sumCols('Valor', 'all')" />
                </td>
              </tr>
              <!-- ATIVAÇÕES -->
              <tr>
                <th
                  colspan="14"
                  class="text-center v-data-table-header teal darken-2"
                  style="padding: 5px !important"
                >
                  Ativações
                </th>
              </tr>

              <tr
                v-for="(f, index) in filterData('#Ativações')"
                :key="'d' + index"
              >
                <td class="text-center">
                  {{ f.ferramenta }}
                </td>
                <td
                  v-for="(v, index) in f.valores"
                  :key="'e' + index"
                  :class="{
                    'text-center': true,
                  }"
                >
                  <span>
                    {{ v }}
                  </span>
                </td>
                <td class="text-center">
                  {{ sumRow(f.valores) }}
                </td>
              </tr>

              <tr class="table-footer">
                <td class="text-center">Total:</td>
                <td v-for="(m, i) in months" :key="'c' + i" class="text-center">
                  {{ sumCols("#Ativações", i).toLocaleString() }}
                </td>
                <td class="text-center">
                  {{ sumCols("#Ativações", "all").toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>
    </div>
  </v-card>
</template>

<script>
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox,
  },
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      tableData: [],
    };
  },

  computed: {
    months() {
      return this.$moment.months();
    },
  },

  methods: {
    estacaoDoAno(mes) {
      return 4 <= mes && mes <= 8 ? "inverno" : "verao";
    },
    async getDados() {
      this.overlay = true;
      this.error = false;
      this.empty = false;

      const response = await this.$http().post(
        "/simulacao/resumo/valoresAtivMensal",
        {
          data: {
            id_fornecedor: this.fornecedor.value,
            ano_atual: this.ano,
          },
        }
      );
      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        this.error = true;
        return;
      }

      this.tableData = response.data;
      this.overlay = false;
    },

    distinctFerramentas(grupo) {
      return [
        ...new Map(
          this.tableData
            .filter((e) => e.grupo == grupo)
            .map((item) => [item["ferramenta"], item])
        ).values(),
      ];

      //return [...new Set(this.tableData.map((e) => e.ferramenta))];
    },
    filterData(grupo) {
      return this.tableData.filter((e) => e.grupo == grupo);
    },
    sumRow(valores = []) {
      return valores.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
    sumCols(grupo, index) {
      if (index == "all") {
        return this.tableData
          .filter((e) => e.grupo == grupo)
          .map((e) => e.valores)
          .reduce((tt, n) => [...tt, ...n], [])
          .reduce((tt, n) => tt + n, 0);
      }

      return this.tableData
        .filter((e) => e.grupo == grupo)
        .map((e) => e.valores[index])
        .reduce((total, num) => total + num, 0);
    },
  },
  watch: {
    $props: {
      handler() {
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
  created() {},
};
</script>

<style >
/* .verao {
  background-color: #f2b36c;
}
.inverno {
  background-color: #0d8ab0;
} */
.tabela-valores-ativacao {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #eaeaea;
  color: #808080 !important;
}

.tabela-valores-ativacao td,
.tabela-valores-ativacao th {
  padding: 0.8rem !important;
}

.involucroValorAtivacaoMensal {
  height: 650px;
  display: flex;
  margin-top: 20px;
}

.involucroValorAtivacaoMensal .flex-table {
  display: flex;
  flex-direction: column;
  height: 620px;
  width: 100%;
}

.involucroValorAtivacaoMensal .flex-table > div {
  width: 100%;
}

.involucroValorAtivacaoMensal thead {
  position: -webkit-sticky;
	position: sticky;
	overflow: hidden;
	z-index: 4;
	top: 0px;
	/* background-color: #ffff; */
    z-index: 4;
}

.tabela-valores-ativacao .v-data-table-header {
	top: 90px;
    z-index: 4;
} 

.tabela-valores-ativacao .v-data-table__wrapper {
	overflow-y: auto !important;
} 

</style>
