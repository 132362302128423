<template>
	<v-form ref="formFiltro">
		<v-row no-gutters>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-text-field
					label="Mês"
					v-model="filtro.mes"
					:rules="regra.mes"
					required
					outlined
					dense
					type="month"
				></v-text-field>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Filial"
					v-model="filtro.id_filial"
					:items="lista.filial"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="filtro.id_filial.length === 1 && index === 0"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.id_filial.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.id_filial.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-select
					label="Bandeira"
					v-model="filtro.bandeira"
					:items="lista.bandeira"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<span
							v-if="filtro.bandeira.length === 1 && index === 0"
							>{{ item.text }}</span
						>
						<span
							v-if="filtro.bandeira.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.bandeira.length }} selecionados</span
						>
					</template>
				</v-select>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Fornecedor"
					v-model="filtro.id_fornecedor"
					:items="lista.fornecedor"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="
								filtro.id_fornecedor.length === 1 && index === 0
							"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="
								filtro.id_fornecedor.length > 1 && index === 0
							"
							class="grey--text caption mr-1"
							>{{
								filtro.id_fornecedor.length
							}}
							selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Espaço"
					v-model="filtro.id_espaco"
					:items="lista.espaco"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="filtro.id_espaco.length === 1 && index === 0"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.id_espaco.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.id_espaco.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Espaço Macro"
					v-model="filtro.espaco_macro"
					:items="lista.espaco_macro"
					multiple
					outlined
					dense
					
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="
								filtro.espaco_macro.length === 1 && index === 0
							"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.espaco_macro.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.espaco_macro.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-select
					label="Cluster"
					:items="lista.cluster"
					v-model="filtro.id_cluster"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<span
							v-if="filtro.id_cluster.length === 1 && index === 0"
							>{{ item.text }}</span
						>
						<span
							v-if="filtro.id_cluster.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.id_cluster.length }} selecionados</span
						>
					</template>
				</v-select>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Região"
					v-model="filtro.regiao"
					:items="lista.regiao"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="filtro.regiao.length === 1 && index === 0"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.regiao.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.regiao.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-select
					label="Perfil"
					:items="lista.perfil"
					v-model="filtro.perfil"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<span
							v-if="filtro.perfil.length === 1 && index === 0"
							>{{ item.text }}</span
						>
						<span
							v-if="filtro.perfil.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.perfil.length }} selecionados</span
						>
					</template>
				</v-select>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-select
					label="Tamanho"
					:items="lista.tamanho"
					v-model="filtro.tamanho"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<span
							v-if="filtro.tamanho.length === 1 && index === 0"
							>{{ item.text }}</span
						>
						<span
							v-if="filtro.tamanho.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.tamanho.length }} selecionados</span
						>
					</template>
				</v-select>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Grupo"
					v-model="filtro.grupo"
					:items="lista.grupo"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="filtro.grupo.length === 1 && index === 0"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.grupo.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.grupo.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Subgrupo"
					v-model="filtro.subgrupo"
					:items="lista.subgrupo"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="filtro.subgrupo.length === 1 && index === 0"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.subgrupo.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.subgrupo.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Categoria"
					v-model="filtro.categoria"
					:items="lista.categoria"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="filtro.categoria.length === 1 && index === 0"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.categoria.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.categoria.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
				<v-autocomplete
					label="Subcategoria"
					v-model="filtro.subcategoria"
					:items="lista.subcategoria"
					multiple
					outlined
					dense
				>
					<template v-slot:selection="{ item, index }">
						<div
							v-if="
								filtro.subcategoria.length === 1 && index === 0
							"
							style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
						>
							{{ item.text }}
						</div>
						<span
							v-if="filtro.subcategoria.length > 1 && index === 0"
							class="grey--text caption mr-1"
							>{{ filtro.subcategoria.length }} selecionados</span
						>
					</template>
				</v-autocomplete>
			</v-col>

			<v-col
				cols="12"
				sm="6"
				md="4"
				lg="3"
				xl="3"
				class="px-2 ml-auto pb-2"
				align="end"
			>
				<v-btn class="ml-2 mb-1" color="primary" @click="limpar()">
					<v-icon left>mdi-filter-off</v-icon> Limpar
				</v-btn>
				<v-btn class="ml-2 mb-1" color="primary" @click="filtrar()">
					<v-icon left>mdi-filter</v-icon> Filtrar
				</v-btn>
			</v-col>

			<v-col cols="12" class="px-2 d-flex justify-start">
				<v-checkbox
					class="mt-0"
					v-model="filtro.vbZerado"
					label="VB zerado "
					@change="ajusteVbZerado"
				></v-checkbox>
				<v-checkbox
					class="ml-3 mt-0"
					v-model="filtro.cuboMovelDermo"
					label="Cubo+Movel Dermo "
					@change="ajusteCuboMovelDermo"
				></v-checkbox>
				<v-checkbox
					class="ml-3 mt-0"
					v-model="filtro.apenasCampanhas"
					label="Apenas Campanhas"
					@change="ajusteApenasCampanhas"
				></v-checkbox>
			</v-col>
		</v-row>
	</v-form>
</template>

<script>
import useFiltros from "../../../hooks/useFiltros";

export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros("/gerencial/filtros");

        return { isLoading, isError, isFetching, lista, error, refetch };
    },
	data() {
		return {
			// lista: {},
			// cuboMovelDermo: false,
			filtro: {},
			regra: {
				mes: [(v) => !!v || "O campo mes é obrigatório"],
			},
		};
	},
    computed: {
        overlay() {
            return Object.keys(this.lista).length === 0 && this.isFetching;
        },
    },	
	methods: {
		iniciaFiltro() {
			this.filtro = {
				mes: "",
				vbZerado: false,
				apenasCampanhas: false,
				bandeira: [],
				id_espaco: [],
				espaco_macro: [],
				id_fornecedor: [],
				id_cluster: [],
				id_filial: [],
				regiao: [],
				perfil: [],
				tamanho: [],
				grupo: [],
				subgrupo: [],
				categoria: [],
				subcategoria: [],
				cuboMovelDermo: false,
			};

			this.resetValidation();
		},
		ajusteApenasCampanhas() {
			// this.filtro.cuboMovelDermo = false
			this.filtro.vbZerado = false
			this.$forceUpdate();
		},
		ajusteVbZerado () {
			this.filtro.cuboMovelDermo = false
			this.filtro.apenasCampanhas = false
			this.$forceUpdate();
		},	
		ajusteCuboMovelDermo (v) {
			this.filtro.vbZerado = false
			// this.filtro.apenasCampanhas = false
			if (v) {
				this.filtro.espaco_macro = ['Cubo','Móvel Dermo']
			} 
			this.$forceUpdate();
		},
		limpar() {
			this.iniciaFiltro();
		},
		filtrar() {
			console.log(this.filtro.cuboMovelDermo)
			if (this.filtro.cuboMovelDermo) this.ajusteCuboMovelDermo (true)
			if (!this.validate()) return;
			this.$emit("filtrar");
		},
		validate() {
			return this.$refs.formFiltro.validate();
		},
		resetValidation() {
			try {
				this.$refs.formFiltro.resetValidation();
			} catch (error) {
				//console.log(error)
			}
		},
	},
};
</script>
