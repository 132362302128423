var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","bottom":"","width":"0","content-class":"select-outline","nudge-top":"25px","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:{
          select: true,
          'input-cinza': true,
          active: _vm.menu1,
        },attrs:{"readonly":"","placeholder":_vm.placeholder,"label":_vm.label,"rules":_vm.rules,"required":_vm.required,"disabled":_vm.disabled,"outlined":"","dense":""},on:{"click:clear":function($event){_vm.date = null}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-divider',{staticClass:"mt-0"}),_c('v-date-picker',{staticClass:"input-mes",attrs:{"type":"month","min":_vm.min,"max":_vm.max,"locale":"pt-BR","color":"primary","multiple":"","width":"auto","readonly":_vm.readonly,"no-title":""},on:{"change":_vm.handleChange},model:{value:(_vm.cmpValue),callback:function ($$v) {_vm.cmpValue=$$v},expression:"cmpValue"}}),_c('v-divider',{staticClass:"mt-0 mb-3"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[(!_vm.readonly)?_c('v-btn',{staticClass:"w-100",attrs:{"color":"yellow","dark":""},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }