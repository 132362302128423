// computed.js
import moment from "moment";
moment.locale("pt-br");

export default {
  calculaSemanas(mes) {
    const primeiroDiaMes = moment(mes).startOf("month");
    const ultimoDiaMes = moment(mes).endOf("month");

    const primeiroDiaIso = moment(primeiroDiaMes).isoWeekday();
    const ultimoDiaIso = moment(ultimoDiaMes).isoWeekday();

    let inicio;
    if (primeiroDiaIso >= 5) {
      const diasAdicionar = 8 - primeiroDiaIso;
      inicio = moment(primeiroDiaMes).add(diasAdicionar, "days");
    } else {
      inicio = moment(primeiroDiaMes).startOf("isoWeek");
    }
    let fim;
    if (ultimoDiaIso >= 4) {
      fim = moment(ultimoDiaMes).endOf("isoWeek");
    } else {
      fim = moment(ultimoDiaMes).subtract(ultimoDiaIso, "days");
    }

    const semanas = [];
    let semana = 1;
    while (inicio.isSameOrBefore(fim)) {
      console.log();
      semanas.push({
        mes : moment(mes).format("YYYY-MM-DD"),
        semana: semana,
        inicio: inicio,
        fim: moment(inicio).add(6, "days")
      });
      inicio = moment(inicio).add(7, "days");
      semana++;
    }

    return semanas;
  },
  calculaSemanaPeriodo(primeiroDia, ultimoDia) {
    
    // const primeiroMes = moment(primeiroDia).startOf("month");
    // const ultimoMes = moment(ultimoDia).endOf("month");

    // const semanas = [];
    // while (primeiroMes.isSameOrBefore(ultimoMes)) {
    //   const semana = this.calculaSemanas(primeiroMes);
    //   console.log(primeiroMes.format("DD/MM/YYYY"))
    //   semanas.push(...semana);
    //   primeiroMes.add(1, "month");
    // }
    // return semanas.filter(semana => {
    //     return (
    //     semana.inicio.isSameOrBefore(ultimoDia) &&
    //     semana.fim.isSameOrAfter(primeiroDia)
    //   );
    // });
    const diaInicial = moment(primeiroDia)
    const diaFinal = moment(ultimoDia)
    const primeiroDiaMesIncio = moment(primeiroDia).startOf("month").subtract(1,'month')
    const prmeiroDiaMesFinal = moment(ultimoDia).startOf("month").add(1,'month')
    
    let semanas = []
    while (primeiroDiaMesIncio.isBefore(prmeiroDiaMesFinal)) {
        semanas.push(...this.calculaSemanas(primeiroDiaMesIncio))
        primeiroDiaMesIncio.add(1,"month")
    }

    let eleita = false
    const semanasEleitas = semanas.filter(semana => {
        if ((diaInicial.isSameOrAfter(semana.inicio)) && (diaInicial.isSameOrBefore(semana.fim))) {
            eleita = true
        }
        if (semana.inicio.isAfter(diaFinal)) {
            eleita = false
        }
        return eleita
    })

    return semanasEleitas
  }
};
