<template>
  <v-container fluid>
    <v-toolbar-title>Justificativas aplicativo</v-toolbar-title>
    <v-alert
      :type="alerta.type"
      transition="scale-transition"
      dismissible
      v-model="alerta.mostrar"
    >
      {{ alerta.mensagem }}
    </v-alert>
    <v-row>
      <v-col cols="6" class="">
        <v-form v-model="isCsv">
          <v-file-input
            label="Arquivo CSV"
            outlined
            dense
            v-model="csvModelo"
            :rules="regra.csv"
            accept=".csv"
          ></v-file-input>
        </v-form>
      </v-col>
      <v-col cols="3" class="">
        <v-btn
          color="primary"
          :disabled="!isCsv"
          @click="importarJustificativas()"
        >
          <v-icon left>mdi-upload</v-icon>
          Importar justificativas
        </v-btn>
      </v-col>
      <v-col cols="3" class="">
        <v-btn color="primary" @click="exportarJustificativas()">
          <v-icon left>mdi-microsoft-excel</v-icon>
          Exportar justificativas
        </v-btn>
      </v-col>
      <!-- <v-btn
					class="ml-2 mb-1"
					color="primary"
					@click="abrirImportacao()"
				>
					<v-icon left>mdi-upload</v-icon> Importar justificativas
				</v-btn> -->
    </v-row>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      csvModelo: null,
      isCsv: false,
	  carregando: false,
      alerta: {
        mostrar: false,
        type: "success",
        mensagem: "",
      },
      regra: {
        csv: [
          (v) => !!v || "Selecione um arquivo.",
          (v) =>
            (v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
            "O arquivo deve ser do tipo CSV.",
        ],
      },
    };
  },

  methods: {
    async exportarJustificativas() {
      this.carregando = true;

      var response = await this.$http().post("/app/justificativas/exportar");

      window.location = this.$http("baseURL") + response.data.url;

      this.carregando = false;
    },
    async importarJustificativas() {
           this.carregando = true;


      const parseCsv = async (csv) => {
        return new Promise((resolve) => {
          this.$papa.parse(csv, {
            delimiter: ";",
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: function (results) {
              resolve(results);
            },
          });
        });
      };
      var csv = await parseCsv(this.csvModelo);

      if (csv.errors.length) {
        this.alerta = {
          mostrar: true,
          type: "error",
          mensagem:
            "Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.",
        };

        return;
      }

      var formData = new FormData();

      formData.append("files", this.csvModelo);
      // console.log(csv.data)

      var response = await this.$http().post(
        "/app/justificativas/importar",
        formData
      );

      this.carregando = false;

      if (response.data.sucesso) {
        this.alerta = {
          mostrar: true,
          type: "success",
          mensagem: "Importação realizada com sucesso. ",
        };
      } else {
        this.alerta = {
          mostrar: true,
          type: "error",
          mensagem:
            "Erro ao processar arquivo CSV. Verifique se o arquivo está no formato correto.",
        };
      }
    },
  },
};
</script>

<style>
</style>