var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-6 py-3 br-15 w-100",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Plantas Filiais ")]),_c('div',{staticClass:"ma-1"})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Filial","items":_vm.lista.filiais,"multiple":"","outlined":"","dense":"","menu-props":_vm.menuProps,"clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(
                                    _vm.filtro.id_java.length === 1 && index === 0
                                )?_c('div',{staticClass:"autocomplete-selecao"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
                                    _vm.filtro.id_java.length > 1 && index === 0
                                )?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_java.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_java),callback:function ($$v) {_vm.$set(_vm.filtro, "id_java", $$v)},expression:"filtro.id_java"}})],1),_c('v-col',{attrs:{"cols":"3","align":"end"}},[_c('v-btn',{staticClass:"w-100 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.novo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar Plantas ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 tabela",attrs:{"headers":_vm.headers,"items":_vm.dadosFiltrados,"items-per-page":5,"search":_vm.busca,"footer-props":{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        },"options":_vm.pagination,"multi-sort":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.botao",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary","title":"Baixar arquivo"},on:{"click":function($event){return _vm.baixar(item)}}},[_vm._v(" mdi-download ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"content-class":"br-15","scrollable":"","width":"900"},model:{value:(_vm.dialogo),callback:function ($$v) {_vm.dialogo=$$v},expression:"dialogo"}},[_c('v-card',[_c('v-toolbar',{staticClass:"primary-degrade white--text fix-flex",attrs:{"elevation":"0","dark":""}},[_c('v-toolbar-title',[_vm._v(" Adicionar Plantas ")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","width":"24px","height":"24px","elevation":"0","light":""},on:{"click":function($event){_vm.dialogo = false}}},[_c('v-icon',{attrs:{"size":"18px","color":"primary"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('div',{staticClass:"instrucoes mt-2 mb-6"},[_c('h4',{attrs:{"color":"primary"}},[_vm._v(" Para importar mais de uma planta por vez, siga as instruções abaixo ")]),_c('ul',[_c('li',[_vm._v(" Você tem a opção de carregar vários arquivos de uma só vez, o que torna o processo mais rápido e eficiente. Para facilitar a associação da planta corretamente, recomendamos que você inclua o código ID_JAVA no nome do arquivo, seguindo o formato sugerido \"lj_0001.pdf\". Se por algum motivo o sistema não conseguir identificar automaticamente a filial correspondente, será necessário informá-la manualmente. ")])])])]),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[(_vm.arquivos.length > 0)?_c('v-data-table',{key:"id_java",staticClass:"elevation-0 tabela mb-5",attrs:{"hide-default-footer":true,"headers":[
                                        {
                                            text: 'Arquivos',
                                            align: 'center',
                                            value: 'name',
                                            class: 'th-separador',
                                        },
                                        {
                                            text: 'Filial',
                                            align: 'center',
                                            value: 'id_java',
                                        } ],"items":_vm.arquivos},scopedSlots:_vm._u([{key:"item.id_java",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"items":_vm.lista.filiais,"placeholder":"Selecione a filial","outlined":"","dense":"","menu-props":_vm.menuProps,"rules":[
                                                    function (v) { return !!v ||
                                                        'Selecione a filial'; } ],"hide-details":"","required":""},model:{value:(item.id_java),callback:function ($$v) {_vm.$set(item, "id_java", $$v)},expression:"item.id_java"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.arquivos.splice(
                                                        _vm.arquivos.indexOf(item),
                                                        1
                                                    )}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)],1)]}}],null,true)}):_vm._e()],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mb-3 mr-3"},[_c('v-spacer'),(_vm.arquivos.length > 0)?_c('v-btn',{staticClass:"px-4 new-button mr-4",attrs:{"color":"yellow","dark":""},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1):_vm._e(),_c('v-btn',{staticClass:"px-4 new-button mr-4",attrs:{"color":"primary","dark":""},on:{"click":_vm.handleFileImport}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar Arquivos ")],1),_c('v-btn',{staticClass:"px-4 new-button",attrs:{"color":"primary","disabled":!_vm.arquivos.length > 0},on:{"click":function($event){return _vm.salvar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Salvar ")],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.carregando,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-file-input',{ref:"uploader",staticClass:"input-cinza",staticStyle:{"display":"none"},attrs:{"label":"Plantas","accept":"image/*,.pdf","multiple":"","outlined":"","dense":""},on:{"change":_vm.arquivoAdicionado}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }