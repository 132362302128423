<template>
  <v-app style="background: #e8e8e8">
      <v-main class="bg align-center">          
        <v-container fluid>
            <v-row justify="end">                
                <v-col sm="12" md="8" lg="5" xl="5">                  
                    <v-card class="mx-10 pa-8">

                        <v-img src="@/assets/images/login/logo.png" height="150" contain></v-img>
                        
                        <v-form
                            ref="form"
                            lazy-validation
                            @submit.prevent="entrar"
                        > 

                            <v-text-field
                                class="mt-15"
                                solo
                                label=""
                                prepend-inner-icon="mdi-account"
                                v-model="login"
                                :rules="loginRegra"
                                required
                            ></v-text-field>
                            
                            <v-text-field
                                class="mt-3"
                                solo
                                label=""
                                prepend-inner-icon="mdi-lock"
                                v-model="senha"
                                :rules="senhaRegra"
                                required
                                type="password"
                            ></v-text-field>

                            <v-btn
                                class="mt-3"
                                color="primary"
                                type="submit"
                            >
                            Entrar
                            </v-btn>

                        </v-form>

                        <v-alert 
                            type="error" 
                            class="mt-5"
                            transition="scale-transition"
                            dismissible
                            v-model="alerta"
                        >
                            {{alertaMesagem}}
                        </v-alert>

                    </v-card>
                    <div class="d-flex justify-center mt-3 px-15 text-center">
                        <a href="https://comunicacao.ipdvonline.com.br/gestao-de-investimentos-comerciais-merchandising-ifinance-abradilan" target="_blank">Conheça mais sobre a solução de gestão de verbas e acordos comerciais da IPDV.</a>

                    </div>
                </v-col>
            </v-row>
        </v-container>  
      </v-main>    
  </v-app>
</template>

<script>

export default {
    data() {
        return {
            login: null,
            senha: null,
            loginRegra: [
                v => !!v || 'O campo usuário é obrigatório'
            ],
            senhaRegra: [
                v => !!v || 'O campo senha é obrigatório'
            ],
            alerta: false,
        }
    },
    computed: {
        alertaMesagem() {
            this.atualizaAlerta()
            return this.$store.getters.getAlertaMesagem
        },
    },
    methods: {
        atualizaAlerta() {
            if(this.$store.getters.getAlertaMesagem) {
                this.alerta = true
            }
            else {
                this.alerta = false
            }
        },
        entrar() {
            if(this.$refs.form.validate()){

                this.$store.dispatch('login', [this.login, this.senha])
            }
        }
    }
}
</script>

<style scoped>
    .bg {
        background-image: url('../assets/images/login/bg.jpg');
        background-size: auto 100%;
    }
</style>