<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Permissões</v-toolbar-title>
            </v-col>           
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1"> 
            <v-row no-gutters class="mb-4">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Tipo de Permissão"
                        :items="lista.menu"
                        v-model="id_menuBusca"
                        outlined
                        dense 
                        hide-details
                    >
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">                     
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>     
                </v-col>            
            </v-row>          
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :search="busca"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
                show-select                
                v-model="permissoes"
                item-key="id_usuario"
            >   
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"   
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="ml-4 mr-2"
                        color="primary"   
                        @click="dialogoRedefinir=true; usuario={...item};"
                        title="Redefinir Senha"
                    >
                        mdi-lock-reset
                    </v-icon>
                </template>
            </v-data-table>
            <v-row no-gutters class="mt-4">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-select
                        label="Tipo de Permissão"
                        :items="lista.menu"
                        v-model="id_menu"
                        multiple
                        outlined
                        dense 
                        hide-details
                    >
                        <template v-slot:selection="{ item, index }">
                            <span 
                                v-if="id_menu.length === 1 && index === 0"
                            >{{ item.text }}</span>
                            <span
                                v-if="id_menu.length > 1 && index === 0"
                                class="grey--text caption mr-1"
                            >{{ id_menu.length }} selecionados</span>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end"> 
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar Permissões
                    </v-btn>    
                </v-col>            
            </v-row>          
        </v-card>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'Cargo', value: 'cargo', align: 'center'},
                { text: 'Nome', value: 'nome', align: 'center'},
                { text: 'Login', value: 'login', align: 'center'},
                { text: 'ID Usuário', value: 'id_usuario', align: 'center'},
            ],
            dados: [], 
            permissoes: [], 
            lista: {
                menu: [],
            },
            id_menuBusca: null,
            id_menu: [],
            pagination: {}, 
            carregando: false, 
            busca: null, 
            dialogo: false,
            dialogoRedefinir: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                nome: [v => !!v || 'O campo nome é obrigatório.'],
                login: [v => !!v || 'O campo login é obrigatório.'],
            },  
        }
    },
    watch: {
        id_menuBusca(v) {

            this.id_menu = [v]
            
            this.filtrar()           
        },
    },
    async created() { 
        
        var response = await this.$http().post('/usuario/menu', {}) 

        var menu = []

        for (const m of response.data) {
            
            if(m.pai == 0) {

                menu.push({header: m.nome})
            }
            
            if(m.caminho || !m.visivel) {

                menu.push({text: m.nome, value: m.id_menu})
            }   
        }

        this.lista.menu = menu 
    },
    methods: {
        async filtrar() {

            this.carregando = true

            var data = {}

            data.id_menu = this.id_menuBusca

            var response = await this.$http().post('/usuario/permissaoMenu', {data: data})   
            
            this.dados = response.data
            
            this.permissoes = []
            
            for (const d of response.data) {
                
                if(d.permissao) {

                    this.permissoes.push(d)
                } 
            }

            this.carregando = false
        },
        async salvar() {

            this.carregando = true
            
            var data = {}

            data.menus = this.id_menu

            data.usuarios = []
            
            for (const d of this.dados) {
                
                data.usuarios.push({
                    id_usuario: d.id_usuario,
                    permissao: this.permissoes.includes(d)
                })
            }

            var response = await this.$http().post('/usuario/salvarUsuarioMenu', {data: data})   
            
            this.carregando = false
            
            if(!response.data.sucesso){
                
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
    },
}
</script>