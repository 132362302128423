const helpers = {}

helpers.downloadCsv = function (content, filename, contentType) {
    
    if(!contentType) contentType = 'application/octet-stream';
        var a = document.createElement('a');
        var blob = new Blob([content], {'type':contentType});
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        a.click();
}

helpers.jsonCsv = function (data, label, colunas, arquivo) {

    var csv = ''

    for (const l of label) {
        csv += l + ';'
    }

    csv += '\n'

    for (const d of data) {
        for (const c of colunas) {
            csv += (d[c] ? d[c] : '') + ';'
        }
        csv += '\n'
    }

    helpers.downloadCsv(csv, arquivo)
}

helpers.download = function (url) {

    var link = document.createElement('a')
    link.target = '_blank'
    link.download = ''
    link.href = url
    document.body.appendChild(link)
    link.click()
    link.remove()
}

helpers.roundDecimal = function (num, decimal) {

    const factor = Math.pow(10,decimal);
    return Math.round(num * factor) / factor;
}

helpers.cloneObj = function (obj) {

    return JSON.parse(JSON.stringify(obj))
}

export default helpers