<template>
    <v-dialog
        v-model="dialogForm"
        scrollable
        persistent
        content-class="dialog-ecommerce br-15"
    >
        <v-card>
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title>
                    {{ message }} - Etapa ({{ etapa }} de 4)
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    color="#fff"
                    @click="fecharDialogo()"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-stepper v-model="etapa" non-linear class="elevation-0 card-body">
                <v-stepper-header class="px-2">
                    <v-stepper-step
                        editable
                        :rules="regra.etapa1"
                        error-icon="mdi-alert-circle"
                        step="1"
                    >
                        Cadastro de acordo
                    </v-stepper-step>
                    <v-divider class="mx-2" />
                    <v-stepper-step
                        editable
                        :rules="regra.etapa2"
                        error-icon="mdi-alert-circle"
                        step="2"
                    >
                        Desconto
                    </v-stepper-step>
                    <v-divider class="mx-2" />
                    <v-stepper-step
                        editable
                        :rules="regra.etapa3"
                        error-icon="mdi-alert-circle"
                        step="3"
                    >
                        Seleção de Produtos</v-stepper-step
                    >
                    <v-divider class="mx-2" />
                    <v-stepper-step editable step="4">Resumo</v-stepper-step>
                </v-stepper-header>

                <div class="etapas-conteudo">
                    <v-stepper-items class="min-h-100">
                        <v-stepper-content step="1">
                            <Cadastro
                                ref="cadastro"
                                v-model="acordo"
                            ></Cadastro>
                        </v-stepper-content>
                        <v-stepper-content step="2" class="min-h-100">
                            <Desconto
                                ref="desconto"
                                v-model="acordo"
                            ></Desconto>
                        </v-stepper-content>
                        <v-stepper-content step="3" class="min-h-100">
                            <Produto ref="produto" v-model="acordo"></Produto>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                            <Efetivacao v-model="acordo" />
                        </v-stepper-content>
                    </v-stepper-items>
                </div>
                <div class="d-flex card-footer pa-4">
                    <v-btn
                        color="yellow"
                        width="130px"
                        class="new-button"
                        dark
                        @click="voltar"
                    >
                        <v-icon left>mdi-chevron-left-circle</v-icon>
                        Voltar
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        width="130px"
                        class="new-button"
                        @click="proximo"
                        v-if="!acordo.fnLeitura || el < 4"
                    >
                        {{ botao }}
                        <v-icon right>{{ botaoIco }}</v-icon>
                    </v-btn>
                </div>
            </v-stepper>
        </v-card>
        <v-overlay z-index="500" :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
import Cadastro from "./Cadastro.vue";
import Efetivacao from "./Efetivacao.vue";
import Desconto from "./Desconto.vue";
import Produto from "./Produto.vue";

export default {
    inject: ["listaCampos"],

    components: {
        Cadastro,
        Efetivacao,
        Desconto,
        Produto,
    },
    data() {
        return {
            etapa: 1,
            message: "Criação de acordo",
            dialogForm: false,
            carregando: false,
            acordo: {
                desconto: [],
                mesInicial: this.$moment().format("YYYY-MM"), // remover depois
                mesFinal: this.$moment().format("YYYY-MM"), // remover depois
            },
            erroNaoHaFiliais: 'aquisdjskd',
            botao: "Avançar",
            botaoIco: "mdi-chevron-right-circle",
            regra: {
                etapa1: [
                    () => {
                        if (this.etapa > 1) {
                            return this.$refs.cadastro.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa2: [
                    () => {
                        if (this.etapa > 2) {
                            return this.$refs.desconto.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
                etapa3: [
                    () => {
                        if (this.etapa > 3) {
                            return this.$refs.produto.validate(); // validar etapa 1
                        }
                        return true; //  valido
                    },
                ],
            },
        };
    },
    async provide() {
        return {
            listaCampos: await this.listaCampos,
        };
    },
    watch: {
        etapa(valor) {
            if (valor == 1) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-chevron-right-circle";
            } else if (valor == 2) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-check-circle";

                this.$refs.desconto.criaDesconto();
            } else if (valor == 3) {
                this.botao = "Avançar";
                this.botaoIco = "mdi-check-circle";
                this.$refs.produto.buscaProdutos();
            } else if (valor == 4) {
                this.botao = "Salvar";
                this.botaoIco = "mdi-check-circle";
            }
        },
    },

    methods: {
        async salvarAcordo() {
            if (
                !this.$refs.cadastro.validate() ||
                !this.$refs.desconto.validate() ||
                !this.$refs.produto.validate() || 
                this.$refs.desconto.erroFaltaFiliais
            ) {
                return this.$dialog.notify.error("Formulário com erros", {
                    position: "top-right",
                    timeout: 5000,
                });
            }
            this.carregando = true;
            let response = {};
            const data = this.acordo;

            if (this.acordo.fnEditar) {
                response = await this.$http().post("/acordoEcommerce/editar", {
                    data,
                });
            } else {
                response = await this.$http().post("/acordoEcommerce/salvar", {
                    data: this.acordo,
                });
            }

            if (response.data.success) {
                this.$dialog.notify.success(response.data.message, {
                    position: "top-right",
                    timeout: 5000,
                });

                this.carregando = false;
                this.fecharDialogo();
                this.$emit("filtrar");
            } else {
                this.carregando = false;
                this.$dialog.notify.error(
                    response.data.message || "Falha ao salvar acordo",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
            }
        },
        proximo() {
            if (this.etapa == 4) {
                return this.salvarAcordo();
            } else {
                this.etapa++;
            }
        },
        voltar() {
            if (this.etapa > 1) {
                this.etapa--;
            }
        },
        fecharDialogo() {
            this.etapa = 1;
            this.dialogForm = false;
        },
        novo() {
            this.dialogForm = true;
            this.etapa = 1;
            try {
                this.$refs.cadastro.limpaCadastro();
                this.$refs.cadastro.reset();
                this.$refs.produto.reset();
            } catch (error) {
                error;
            }
            (this.message = "Criação de acordo"), (this.carregando = false);
            this.acordo = {
                mesInicial: this.$moment().format("YYYY-MM"),
                mesFinal: this.$moment().format("YYYY-MM"),
                bandeira: ["RAIA", "DROGASIL"],
                id_device: 1,
                id_categoria: 1,
                desconto: [],
                produtos: [],
            };
        },
        async editar(item, fnLeitura = false) {
            this.dialogForm = true;
            this.message = `Editar Acordo (${item.id_acordo}) `;
            this.carregando = true;

            var data = {
                id_acordo: item.id_acordo,
            };

            const response = await this.$http().post(
                "/acordoEcommerce/acordo",
                {
                    data: data,
                }
            );

            const contrato = response.data.contrato;
            const acordo = response.data.acordo;
            const fornecedor = response.data.fornecedor;
            const idAcordos = response.data.idAcordos;
            this.status = response.data.status;

            this.etapa = 1;
            this.acordo = {
                fnEditar: true,
                fnLeitura: fnLeitura,
                idAcordos: idAcordos || [],
                desconto: [],
                produtos: [],
                mesInicial: item.mes_acordo,
                mesFinal: item.termino,
                bandeira: [contrato.bandeira],
                obs_departamento: acordo.obs_departamento,
                obs_contrato: acordo.obs_contrato,
                idStatus: item.id_status,
                id_fornecedor: contrato.id_fornecedor,
                fornecedor: fornecedor.nome,
                id_espaco: contrato.id_espaco,
                id_categoria: acordo.id_categoria,
                id_device: acordo.id_device,
                plano: acordo.plano,
                investimento: acordo.investimento,
                id_categoria_produto: acordo.id_categoria_produto,
            };
            await new Promise((resolve) => setTimeout(resolve, 100));
            await this.$refs.desconto.criaDesconto();
            await this.$refs.produto.limpaProdutos(true);

            this.carregando = false;
        },
    },
};
</script>
<style>
.card-econ {
    height: 100%;
    background: red;
}
.etapas-conteudo {
    height: 100%;
    overflow: auto;
}
.card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.card-footer {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
}
.fix-flex {
    flex-grow: 0;
    flex-shrink: 1;
}
</style>
<style lang="scss">
.dialog-ecommerce {
    height: 100%;
    max-height: calc(100% - 50px) !important;
    .v-stepper__header {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15) !important;
    }
    .v-stepper__content {
        box-shadow: unset !important;
    }
    .v-stepper__step {
        padding: 10px;
        margin: 15px;
        background-color: #f5f5f5;
        border-radius: 8px;
        min-width: 200px;
        justify-content: center;
        .v-stepper__label {
            color: #000 !important;
            font-weight: 500;
            font-size: 0.9rem;
        }
        .v-stepper__step__step {
            background-color: var(--v-primary-base) !important;
            height: 20px;
            width: 20px;
            min-width: 20px;
        }
        &:hover .v-stepper__label {
            text-shadow: unset !important;
        }
        &.v-stepper__step--error {
            .v-stepper__label {
                color: red !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                .v-icon {
                    color: red;
                    font-size: 24px;
                }
            }
        }
        &.v-stepper__step--active {
            background-color: var(--v-primary-base);
            color: white;
            &:hover {
                background-color: var(--v-primary-base);
            }
            .v-stepper__label {
                color: #fff !important;
            }
            .v-stepper__step__step {
                background-color: #fff !important;
                color: var(--v-primary-base) !important;
            }
        }
    }
}
</style>