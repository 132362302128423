<template>
  <v-form ref="formFiltro">
    <v-card class="px-6 py-3 br-15 w-100" elevation="0">
      <v-row>
        <v-col class="d-flex" cols="4">
          <v-toolbar-title class="page-title">Extrato </v-toolbar-title>
          <div class="ma-1">
            <InfoBox expands>
              <p>
                No extrato é possivel visualizar o resumo dos investimentos por
                fornecedor. Também é possivel visualizar os acordos de forma
                detalhada, trazendo lojas e produtos e essas informações estão
                nas sheets "verba e vb". No extrato atual temos as informações
                do PDV e Ecommece, futuramente será visualizado também as
                campanhas.
              </p>
            </InfoBox>
          </div>
        </v-col>
        <v-col cols="4" class="pb-0">
          <MesInput
            label="Mês"
            v-model="filtro.mesInicial"
            :rules="usuarioTipo == 'fornecedor' && regra.mesInicial"
            :max="filtroMesMaximo"
          />
        </v-col>

        <!-- <v-col cols="3" class="pb-0">
                    <MesInput
                        label="Período Final"
                        v-model="filtro.mesFinal"
                        :rules="regra.mesFinal"
                    />
                </v-col> -->
        <v-col cols="4" class="pb-0">
          <v-autocomplete
            label="Fornecedor"
            v-model="filtro.id_fornecedor"
            :items="lista.fornecedor"
            :rules="regra.fornecedor"
            outlined
            dense
            class="input-cinza"
            :menu-props="menuProps"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <div
                v-if="filtro.id_fornecedor.length === 1 && index === 0"
                class="autocomplete-selecao"
              >
                {{ item.text }}
              </div>
              <span
                v-if="filtro.id_fornecedor.length > 1 && index === 0"
                class="grey--text caption mr-1"
                >{{ filtro.id_fornecedor.length }} selecionados</span
              >
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-divider class="mt-n1 mb-2"></v-divider>

      <v-expansion-panels flat v-model="mostrarFiltros" accordion>
        <v-expansion-panel>
          <v-expansion-panel-content class="p-0 m-0">
            <v-row class="my-2">
              <v-col cols="3" class="pb-0">
                <v-autocomplete
                  label="Filial"
                  v-model="filtro.id_filial"
                  :items="lista.filial"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <div
                      v-if="filtro.id_filial.length === 1 && index === 0"
                      class="autocomplete-selecao"
                    >
                      {{ item.text }}
                    </div>
                    <span
                      v-if="filtro.id_filial.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.id_filial.length }} selecionados</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-select
                  label="Bandeira"
                  v-model="filtro.bandeira"
                  :items="lista.bandeira"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="filtro.bandeira.length === 1 && index === 0">{{
                      item.text
                    }}</span>
                    <span
                      v-if="filtro.bandeira.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.bandeira.length }} selecionados</span
                    >
                  </template>
                </v-select>
              </v-col>

              <v-col cols="3" class="pb-0">
                <v-autocomplete
                  label="Espaço"
                  v-model="filtro.id_espaco"
                  :items="lista.espaco"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <div
                      v-if="filtro.id_espaco.length === 1 && index === 0"
                      class="autocomplete-selecao"
                    >
                      {{ item.text }}
                    </div>
                    <span
                      v-if="filtro.id_espaco.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.id_espaco.length }} selecionados</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-autocomplete
                  label="Espaço Macro"
                  v-model="filtro.espaco_macro"
                  :items="lista.espaco_macro"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <div
                      v-if="filtro.espaco_macro.length === 1 && index === 0"
                      class="autocomplete-selecao"
                    >
                      {{ item.text }}
                    </div>
                    <span
                      v-if="filtro.espaco_macro.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.espaco_macro.length }} selecionados</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-select
                  label="Cluster"
                  :items="lista.cluster"
                  v-model="filtro.id_cluster"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="filtro.id_cluster.length === 1 && index === 0"
                      >{{ item.text }}</span
                    >
                    <span
                      v-if="filtro.id_cluster.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.id_cluster.length }} selecionados</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-autocomplete
                  label="Região"
                  v-model="filtro.regiao"
                  :items="lista.regiao"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <div
                      v-if="filtro.regiao.length === 1 && index === 0"
                      class="autocomplete-selecao"
                    >
                      {{ item.text }}
                    </div>
                    <span
                      v-if="filtro.regiao.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.regiao.length }} selecionados</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-select
                  label="Perfil"
                  :items="lista.perfil"
                  v-model="filtro.perfil"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="filtro.perfil.length === 1 && index === 0">{{
                      item.text
                    }}</span>
                    <span
                      v-if="filtro.perfil.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.perfil.length }} selecionados</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3" class="pb-0">
                <v-select
                  label="Tamanho"
                  :items="lista.tamanho"
                  v-model="filtro.tamanho"
                  multiple
                  outlined
                  dense
                  class="input-cinza"
                  :menu-props="menuProps"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="filtro.tamanho.length === 1 && index === 0">{{
                      item.text
                    }}</span>
                    <span
                      v-if="filtro.tamanho.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.tamanho.length }} selecionados</span
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-divider class="mt-n2 mb-3"></v-divider>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col cols="3" class="py-0">
          <div class="new-button d-flex align-center pt-5" text>
            <span @click="expandirFiltros" style="cursor: pointer"
              >Personalizar Filtros</span
            >
            <v-btn
              style="
                                background: #e6e6e6;
                                width: 20px;
                                height: 20px;
                            "
              fab
              icon
              class="ml-3"
              @click="expandirFiltros"
              x-small
            >
              <v-icon>{{
                mostrarFiltros == 0 ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6" class="px-3 ml-auto pb-3" align="end">
          <v-btn
            class="ml-4 px-8 new-button"
            color="yellow"
            dark
            @click="limpar()"
          >
            <v-icon left>mdi-filter-off</v-icon> Limpar
          </v-btn>
          <v-btn
            class="ml-5 px-8 new-button"
            color="primary"
            @click="filtrar()"
          >
            <v-icon left>mdi-filter</v-icon> Filtrar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-overlay :value="overlay" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
import InfoBox from "../../../components/InfoBox.vue";
import MesInput from "../../../components/MesInput.vue";
import useFiltros from "../../../hooks/useFiltros";

export default {
  setup() {
    const {
      isLoading,
      isError,
      isFetching,
      lista,
      error,
      refetch,
    } = useFiltros("/gerencial/filtros");

    return { isLoading, isError, isFetching, lista, error, refetch };
  },
  components: {
    MesInput,
    InfoBox,
  },
  data() {
    return {
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      mostrarFiltros: null,
      // lista: {},
      filtro: {},
      regra: {
        mesInicial: [
          (v) => !!v || "Selecione um período inicial para o filtro",
          // if usuarioTipo is equal "fornecedor" then mesInicial should be before or equal to last month
          (v) =>
            (!!v &&
              this.$moment(this.filtro.mesInicial, "YYYY-MM") <
                this.$moment()) ||
            "O  mes selecionado deve ser igual ou anterior ao mês anterior.",
        ],
        mesFinal: [
          (v) => !!v || "Selecione um período final para o filtro",
          (v) =>
            (!!v && v >= this.filtro.mesInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
        fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
      },
    };
  },
  beforeMount() {
    this.iniciaFiltro();
  },
  computed: {
    overlay() {
      return Object.keys(this.lista).length === 0 && this.isFetching;
    },
    usuarioTipo() {
      if (this.$store.getters.getUsuario.id_fornecedor) {
        return "fornecedor";
      }
      return "rd";
    },
    filtroMesMaximo() {
      if (this.usuarioTipo === "fornecedor") {
        return this.$moment()
        //   .subtract(1, "months")
          .format("YYYY-MM");
      }
      return null;
    },
  },

  methods: {
    iniciaFiltro() {
      this.filtro = {
        mesInicial: this.$moment().format("YYYY-MM"),
        mesFinal: this.$moment().format("YYYY-MM"),
        bandeira: [],
        id_espaco: [],
        espaco_macro: [],
        id_fornecedor: [],
        id_cluster: [],
        id_filial: [],
        regiao: [],
        perfil: [],
        tamanho: [],
      };

      if (this.usuarioTipo === "fornecedor") {
        this.filtro.mesInicial = this.$moment()
        //   .subtract(1, "months")
          .format("YYYY-MM");

        if (this.lista.fornecedor && this.lista.fornecedor.length === 1) {
          this.filtro.id_fornecedor = [this.lista.fornecedor[0].value];
        }
      }

      this.resetValidation();
    },
    limpar() {
      this.iniciaFiltro();
    },
    filtrar() {
      if (!this.validate()) return;

      this.$emit("filtrar");
    },
    validate() {
      return this.$refs.formFiltro.validate();
    },
    expandirFiltros() {
      this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
      setTimeout(() => {
        // this.validate();
      }, 100);
    },
    resetValidation() {
      try {
        this.$refs.formFiltro.resetValidation();
      } catch (error) {
        //console.log(error)
      }
    },
  },
  watch: {
    "lista.fornecedor": function() {
      if (this.usuarioTipo === "fornecedor" && this.filtro.id_fornecedor.length === 0) {
        this.iniciaFiltro();
      }
    },
  },
};
</script>
