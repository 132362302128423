import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import { http } from '@/config/http'

export default {
    namespaced: true,
    state: {
        filtro: {
            mesInicial: moment().startOf("month").format("YYYY-MM-DD"),
            mesFinal: moment().endOf("month").format("YYYY-MM-DD"),
            tipoPeriodo: -1,
            id_bandeira: [],
            id_planejamento: null,
            semana: [],
            id_ativacao: [],
            device: [],
            id_area: [],
            id_fornecedor: [],
            id_categoria: [],
            agrupar: [],
            plano: [],
            id_status_planejamento: [],
            id_termo: [],
            id_macrofornecedor: []
        }    
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        setFiltro(state,payload) {
            state.filtro.mesInicial = payload.mesInicial
            state.filtro.mesFinal = payload.mesFinal
            state.filtro.tipoPeriodo = payload.tipoPeriodo
            state.filtro.id_bandeira = payload.id_bandeira
            state.filtro.id_planejamento = payload.id_planejamento
            state.filtro.semana = payload.semana
            state.filtro.id_ativacao = payload.id_ativacao
            state.filtro.device = payload.device
            state.filtro.id_area = payload.id_area
            state.filtro.id_fornecedor = payload.id_fornecedor
            state.filtro.id_categoria = payload.id_categoria
            state.filtro.agrupar = payload.agrupar
            state.filtro.plano = payload.plano
            state.filtro.id_status_planejamento = payload.id_status_planejamento
            state.filtro.id_termo = payload.id_termo
            state.filtro.id_macrofornecedor = payload.id_macrofornecedor
        },
        setClear (state) {
            state.filtro.mesInicial = moment().startOf("month").format("YYYY-MM-DD")
            state.filtro.mesFinal = moment().endOf("month").format("YYYY-MM-DD")
            state.filtro.tipoPeriodo = -1
            state.filtro.id_bandeira = []
            state.filtro.id_planejamento = null
            state.filtro.semana = []
            state.filtro.id_ativacao = []
            state.filtro.device = []
            state.filtro.id_area = []
            state.filtro.id_fornecedor = []
            state.filtro.id_categoria = []
            state.filtro.agrupar = []
            state.filtro.plano = []
            state.filtro.id_status_planejamento = []
            state.filtro.id_termo = []
            state.filtro.id_macrofornecedor = []
        }
    },
    actions: {
        async getInitialData(context) {
            const response = await http().post('/planejamentoEcommerce/gerencialhotpage/getcache')
            if (response.status == 200 && response.data.payload) {
                const payload = response.data.payload
                context.commit('setFiltro',payload)    
            }
        },
        async saveCache(context) {
            console.log(context)
            const response = await http().post('/planejamentoEcommerce/gerencialhotpage/setcache',{
                payload:{...context.state.filtro}
            })
            if (response.status == 200 && response.data.payload) {
                const { payload } = response.data.payload
                context.commit('setFiltro',payload)    
            }
        },
        limparFiltro(context) {
            context.commit('setClear')
        },
    }    
}    