<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="2" lg="2" xl="2">
				<v-toolbar-title class="ml-2 mt-2">Inventário</v-toolbar-title>
			</v-col>
			<v-col cols="12" sm="12" md="10" lg="10" xl="10" align="end">
				<ClusterEspaco
					:alertClusterType="alertClusterType"
					:dadosUpdateDay="dadosUpdateDay"
					@refresh-alert="validaUpdateCluster"
				/>
				<ExportacoesBtn @export-action="exportAction($event)" />
				<AprovacaoBtn
					@action-aprovar="aprovarMes"
					@export-action="exportAction($event)"
					:ultimoHistorico="ultimoHistorico"
					:lista="lista"
				/>
				<!-- <v-badge
					bordered
					color="info"
					:content="1"
					:value="ultimoHistorico.processo == 'Primeira Aprovação'"
					overlap
					:title="
						ultimoHistorico.nome +
							' - ' +
							ultimoHistorico.atualizacao
					"
				>
					<v-btn
						class="ml-3 my-1"
						color="primary"
						:disabled="
							lista.pendente.length == 0 &&
							(ultimoHistorico.processo == 'Importação' ||
								(ultimoHistorico.processo ==
									'Primeira Aprovação' &&
									ultimoHistorico.id_usuario !=
										this.$store.getters.getUsuario
											.id_usuario))
								? false
								: true
						"
						@click="confirmAprovar()"
					>
						<v-icon left>mdi-check</v-icon> Aprovar
					</v-btn>
				</v-badge> -->
				<v-btn
					class="ml-3 my-1"
					color="error"
					:disabled="
						ultimoHistorico.processo == 'Importação' ||
						ultimoHistorico.processo == 'Primeira Aprovação'
							? false
							: true
					"
					@click="confirmReprovar()"
				>
					<v-icon left>mdi-close</v-icon> Reprovar
				</v-btn>
				<!-- <v-btn class="ml-3 my-1" color="primary" @click="historico()">
					<v-icon left>mdi-microsoft-excel</v-icon> Histórico
				</v-btn>
				<v-btn
					class="ml-3 my-1"
					color="primary"
					@click="exportarImportacoes()"
				>
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
					Importações
				</v-btn>
				<v-btn class="ml-3 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn> -->
				<v-btn
					class="ml-2 my-1"
					color="primary"
					:disabled="
						!ultimoHistorico.processo ||
						ultimoHistorico.processo == 'Segunda Aprovação' ||
						ultimoHistorico.processo == 'Reprovação'
							? false
							: true
					"
					@click="abrirImportar()"
				>
					<v-icon left>mdi-upload</v-icon> Importar
				</v-btn>
				<v-btn
					class="ml-3 my-1"
					color="primary"
					:disabled="lista.pendente.length ? false : true"
					@click="auditar()"
				>
					<v-icon left>mdi-order-bool-ascending-variant</v-icon>
					Pendências ({{ lista.pendente.length }})
				</v-btn>
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					<v-text-field
						v-model="busca"
						append-icon="mdi-magnify"
						label="Pesquisar"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						<v-text-field
							label="Mês"
							v-model="mes"
							:rules="regra.mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
			</v-data-table>
		</v-card>

		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV
				</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<v-row no-gutters class="mt-4">
							<v-col cols="12" sm="12" md="3" lg="3" xl="3">
								<v-text-field
									label="Mês"
									v-model="mesImportacao"
									:rules="regra.mesImportacao"
									:min="mesProximo"
									required
									outlined
									dense
									type="month"
								></v-text-field>
							</v-col>
							<v-spacer></v-spacer>
							<v-col cols="12" sm="12" md="8" lg="8" xl="8">
								<v-alert dense :type="alertClusterType">
									{{
										dadosUpdateDay.length > 0
											? "Última atualização do Cluster dia " +
											  dadosUpdateDay[0]["dt_registro"]
											: "Atualização de Cluster pendente."
									}}
								</v-alert>
							</v-col>
						</v-row>

						<v-alert
							text
							type="info"
							border="left"
							style="font-size: 12px;"
						>
							<ul>
								<li>
									<b>Procedimento:</b> 1º atualizar modelo de
									check-out / 2º atualizar cluster / 3º
									importar inventário.
								</li>
								<li>
									A base utilizada para importação deve seguir
									o mesmo modelo da base de exportação,
									excluindo a coluna C (espaço_rd).
								</li>
								<li>
									As colunas <strong>id_java</strong> (ID
									JAVA) , <strong>codigo_rd</strong> (Código
									RD do Espaço) e
									<strong>quantidade</strong> (Quantidade) são
									obrigatórias (<strong
										>e a ordem das colunas deve ser identica
										ao da base exportada</strong
									>).
								</li>
								<li>
									Colunas em branco (colunas sem valores) ou
									colunas a mais ocasionarão erro na leitura
									do arquivo.
								</li>
								<li>
									Na coluna
									<strong>quantidade</strong> (Quantidade)
									qualquer valor que não seja um
									<strong>número inteiro maior que 0</strong>
									(zero) será considerado como
									<strong>0</strong> (zero).
								</li>
								<li>
									Se houver mais de uma linha com o mesmo
									<strong>id_java</strong> (ID JAVA) e
									<strong>codigo_rd</strong> (Código RD do
									Espaço), a ultima linha será considerada e
									as anteriores serão desconsideradas.
								</li>
							</ul>
						</v-alert>
						<v-row no-gutters>
							<v-col cols="8" class="px-2">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-col>
							<v-col cols="4" class="px-2">
								<v-btn color="primary" @click="importar()">
									<v-icon left>mdi-upload</v-icon>
									Importar Arquivo
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
					<v-row no-gutters class="mx-2" v-show="progresso">
						<v-col cols="12" class="px-2">
							{{ labelProgresso }}
						</v-col>
						<v-col cols="12" class="px-2">
							<v-progress-linear
								v-model="indexProgresso"
								height="25"
								:color="errorProgresso ? 'red' : 'primary'"
							>
								<span class="font-weight-bold white--text"
									>{{ Math.ceil(indexProgresso) }}%
								</span>
							</v-progress-linear>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Logs
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogo" fullscreen persistent>
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Pendências
				</v-card-title>
				<v-card-text>
					<v-row no-gutters>
						<v-col cols="6">
							<v-btn
								color="primary"
								class="my-2"
								@click="exportarPendente()"
							>
								<v-icon left>mdi-microsoft-excel</v-icon>
								Exportar
							</v-btn>
							<v-btn
								color="primary"
								class="my-2 ml-3"
								@click="abrirImportarPendente()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar
							</v-btn>
						</v-col>
						<v-col cols="6" align="end">
							<v-text-field
								v-model="buscaAuditar"
								append-icon="mdi-magnify"
								label="Pesquisar"
								dense
								outlined
								hide-details
								class="my-2"
							></v-text-field>
						</v-col>
					</v-row>
					<v-data-table
						:headers="headerAuditar"
						:items="lista.pendente"
						:search="buscaAuditar"
						:items-per-page="5"
						:options.sync="paginationAuditar"
						:footer-props="{
							'items-per-page-options': [5, 10, 50, 100, 500],
						}"
						class="elevation-0 data-table-2"
						item-key="index"
						show-select
						:single-select="false"
						multi-sort
						v-model="auditadas"
					>
						<template v-slot:[`item.acordos_all_array`]="{ item }">
							<v-select
								:items="itemsAcordo(item)"
								item-disabled="disabled"
								:menu-props="{
									maxHeight: '150',
									closeOnContentClick: true,
									offsetY: true,
								}"
								placeholder="Exibir"
								outlined
								dense
								hide-details="true"
								background-color="primary"
								dark
								class="myselect"
							/>
						</template>
						<!-- <template v-slot:[`item.botao`]="{ item }">
							<v-icon
								small
								class="mr-6"
								color="error"
								@click="confirmRemover(item)"
								title="Remover Filial do Acordo"
							>
								mdi-trash-can
							</v-icon>
						</template> -->
					</v-data-table>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="error"
						class="pr-4"
						:disabled="auditadas.length == 0"
						@click="confirmRemover()"
					>
						<v-icon left>mdi-trash-can</v-icon> Remover Filiais dos
						Acordos
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogoImportarPendente" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV de Pendências
				</v-card-title>
				<v-card-text>
					<v-form ref="formPendente">
						<v-alert
							class="mt-3"
							text
							type="info"
							border="left"
							style="font-size: 12px;"
						>
							<ul>
								<li>
									A base utilizada para importação deve ser
									semelhante a base exportada.
								</li>
								<li>
									As colunas <strong>ID JAVA</strong>,
									<strong>Acordo</strong>,
									<strong>Acordos Contrato</strong>,
									<strong>Mês</strong>, <strong>Ano</strong> e
									<strong>Excluir Acordo</strong> são
									obrigatórias.
								</li>
								<li>
									As colunas <strong>ID JAVA</strong>,
									<strong>Acordo</strong>,
									<strong>Acordos Contrato</strong>,
									<strong>Mês</strong> e
									<strong>Ano</strong> não podem ter seus
									dados alterados.
								</li>
								<li>
									A única coluna que pode ter seus dados
									alterados é a coluna
									<strong>Excluir Acordo</strong>.
								</li>
								<li>
									Na coluna
									<strong>Excluir Acordo</strong> coloque
									<strong>s</strong> ou
									<strong>S</strong> para excluir a filial do
									acordo.
								</li>
								<li>
									Na coluna
									<strong>Excluir Acordo</strong> qualquer
									valor diferente de <strong>s</strong> ou
									<strong>S</strong> não excluirá a filial do
									acordo.
								</li>
							</ul>
						</v-alert>
						<v-row no-gutters>
							<v-col cols="8" class="px-2">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-col>
							<v-col cols="4" class="px-2">
								<v-btn
									color="primary"
									@click="importarPendente()"
								>
									<v-icon left>mdi-upload</v-icon>
									Importar Arquivo
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
					<v-row no-gutters class="mx-2" v-show="progresso">
						<v-col cols="12" class="px-2">
							{{ labelProgresso }}
						</v-col>
						<v-col cols="12" class="px-2">
							<v-progress-linear
								v-model="indexProgresso"
								height="25"
							>
								<span class="font-weight-bold white--text"
									>{{ Math.ceil(indexProgresso) }}%</span
								>
							</v-progress-linear>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Logs
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportarPendente = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogConfirm" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogConfirmTitulo }}
				</v-card-title>
				<v-card-text class="mt-4">
					{{ dialogConfirmTexto }}
					<br />
					<br />
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="dialogConfirm = false">
						Fechar
					</v-btn>
					<v-btn
						:color="dialogConfirmBotaoColor"
						@click="dialogConfirmFunction"
					>
						{{ dialogConfirmBotao }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogProgresso" width="70%">
			<v-card class="py-4">
				<v-row no-gutters>
					<v-col cols="12" class="px-2">
						{{ labelProgresso }}
					</v-col>
					<v-col cols="12" class="px-2">
						<v-progress-linear
							v-model="indexProgresso"
							height="25"
							:color="errorProgresso ? 'red' : 'primary'"
						>
							<span class="font-weight-bold white--text"
								>{{ Math.ceil(indexProgresso) }}%</span
							>
						</v-progress-linear>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import ClusterEspaco from "./_components/ClusterEspaco.vue";
import ExportacoesBtn from "./_components/ExportacoesBtn.vue";
import AprovacaoBtn from "./_components/AprovacaoBtn.vue";

export default {
	components: {
		ClusterEspaco,
		ExportacoesBtn,
		AprovacaoBtn,
	},
	data() {
		return {
			headers: [
				{ text: "ID JAVA", value: "id_java", align: "center" },
				{
					text: "Código RD do Espaço",
					value: "codigo",
					align: "center",
				},
				{ text: "RD Espaço", value: "nome_espaco", align: "center" },
				{ text: "Quantidade", value: "quantidade", align: "center" },
				{ text: "Mês", value: "mes", align: "center" },
			],
			dados: [],
			dadosUpdateDay: [],
			alertClusterType: "warning",
			mes: null,
			mesImportacao: null,
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			progresso: false,
			indexProgresso: 0,
			labelProgresso: null,
			errorProgresso: true,
			busca: null,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				mes: [(v) => !!v || "O campo mês é obrigatório."],
				mesImportacao: [
					(v) => !!v || "O campo mês é obrigatório.",
					(v) =>
						(!!v && v > this.mesAtual) ||
						"O mês deve ser posterior ao mês atual.",
				],
			},
			headerErros: [
				{ text: "Tipo", value: "tipo", align: "center" },
				{ text: "Linha", value: "linha", align: "center" },
				{ text: "ID JAVA", value: "id_java", align: "center" },
				{ text: "Código RD", value: "codigo_rd", align: "center" },
				{ text: "Quantidade", value: "quantidade", align: "center" },
				{ text: "Mês", value: "mes", align: "center" },
				{ text: "Descrição", value: "msg", align: "center" },
			],
			erros: [],
			errosLabels: {
				tipo: "Tipo",
				linha: "Linha",
				id_java: "ID JAVA",
				codigo_rd: "Código RD",
				quantidade: "Quantidade",
				mes: "Mês",
				msg: "Descrição",
			},
			erro: false,
			dialogo: false,
			dialogoTitulo: null,
			headerAuditar: [
				{ text: "Espaço", value: "espaco", align: "center" },
				{ text: "ID JAVA", value: "id_java", align: "center" },
				{ text: "Contrato", value: "id_contrato", align: "center" },
				{ text: "Acordo", value: "id_acordo", align: "center" },
				{ text: "Acordo RD", value: "acordo_rd", align: "center" },
				{
					text: "Acordos Contrato",
					value: "acordos_all_array",
					align: "center",
				},
				{ text: "Mês", value: "mes_int", align: "center" },
				{ text: "Ano", value: "ano", align: "center" },
				{ text: "Qtd. Atual", value: "ocupado", align: "center" },
				{ text: "Qtd. Futura", value: "futuro", align: "center" },
				{ text: "Saldo", value: "saldo", align: "center" },
				{ text: "Inv. Atual", value: "investimento", align: "center" },
				{
					text: "Inv. Futura",
					value: "investimento_final",
					align: "center",
				},
				{ text: "Bandeira", value: "bandeira", align: "center" },
				{ text: "Fornecedor", value: "fornecedor", align: "center" },
				{
					text: "Data Importação",
					value: "data_importacoa",
					align: "center",
				},
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			lista: {
				pendente: [],
			},
			paginationAuditar: {},
			buscaAuditar: null,
			auditadas: [],
			dialogConfirm: false,
			dialogConfirmTitulo: null,
			dialogConfirmTexto: null,
			dialogConfirmBotao: null,
			dialogConfirmBotaoColor: null,
			dialogConfirmFunction: null,
			data: [],
			ultimoHistorico: {
				nome: null,
				atualizacao: null,
				ano_mes: null,
				mes: null,
				processo: null,
			},
			dialogProgresso: false,
			dialogoImportarPendente: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	created() {
		this.mes = this.mesProximo;
		this.mesImportacao = this.mesProximo;
		this.validaUpdateCluster();
	},
	computed: {
		mesAtual() {
			return this.$moment().format("YYYY-MM");
		},
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
	},
	methods: {
		async filtrar() {
			this.validaUpdateCluster();

			if (!this.$refs.formFiltro.validate()) return;

			if (this.carregando) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			data.pagination = this.pagination;
			data.busca = this.busca;

			var response = await this.$http().post("/inventario/lista", {
				data: data,
			});

			this.dados = response.data.lista;
			this.total = response.data.lista.length
				? response.data.lista[0].total
				: 0;

			this.ultimoHistorico = response.data.ultimoHistorico.length
				? response.data.ultimoHistorico[0]
				: this.ultimoHistorico;

			this.lista.pendente = response.data.pendente.map((e, i) => ({
				index: i,
				...e,
			}));

			this.auditadas = [];

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			setTimeout(() => {
				this.carregando = false;
			}, 500);
		},
		async exportar(detalhado = false) {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { 
				mes: this.mes + "-01",
				detalhado: detalhado
			 };

			var response = await this.$http().post("/inventario/exportar", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		exportarDetalhado() {
			this.exportar(true);
		},
		async validaUpdateCluster() {
			this.dadosUpdateDay = [];
			this.alertClusterType = null;

			let _response = await this.$http().get(
				"/inventario/checaUpdateDay",
				{}
			);

			if (!_response.data.success) {
				this.$dialog.notify.error(
					"Ops! Falha ao conferir a atualização do cluster, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
			}

			this.dadosUpdateDay = _response.data.dados || [];
			const _data = this.dadosUpdateDay[0] || [
				{ validate_day: false, alteracao_cluster_filiais: false },
			];

			console.log("_data", _data);

			this.alertClusterType =
				_data.validate_day && !_data.alteracao_cluster_filiais
					? "success"
					: "warning";
		},
		async exportarImportacoes() {
			//if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			//var data = { mes: this.mes + "-01" };

			var response = await this.$http().post(
				"/inventario/exportarHistImportacoes",
				{
					data: {},
				}
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		abrirImportar() {
			this.arquivoCSV = null;
			this.progresso = false;
			this.erro = false;

			this.dialogoImportar = true;
		},
		async importar() {
			if (!this.$refs.form.validate()) return;

			this.carregando = true;

			this.erro = false;
			this.progresso = true;
			this.indexProgresso = 0;
			this.errorProgresso = false;
			this.labelProgresso = "Enviando arquivo.";

			var data = { mes: this.mesImportacao + "-01" };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = {};

			await this.$http().post("/inventario/importar", formData, {
				// onUploadProgress: (event) => {

				//     console.log(event.loaded, event.total)
				// },
				onDownloadProgress: (progressEvent) => {
					let retorno = progressEvent.currentTarget.response.split(
						";"
					);

					let obj = JSON.parse(retorno[retorno.length - 2]);

					this.indexProgresso = obj.index;
					this.labelProgresso = obj.label;

					response.sucesso = obj.success ? obj.success : null;
					response.erros = obj.erros ? obj.erros : null;
				},
			});

			this.carregando = false;

			this.filtrar();

			if (response.sucesso) {
				this.dialogoImportar = false;
			} else {
				this.progresso = false;
				this.erros = response.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
		async auditar() {
			this.auditadas = [];

			this.paginationAuditar = {};

			this.dialogo = true;
		},
		async confirmRemover(item) {
			this.dialogConfirmFunction = this.remover;

			if (item) {
				this.dialogConfirmTitulo = `Remover Filial`;
				this.dialogConfirmTexto = `Deseja realmente remover a filial ${item.id_java} do acordo ${item.id_acordo}?`;
				this.dialogConfirmBotao = `Remover Filial do Acordo`;
				this.dialogConfirmBotaoColor = `error`;
				this.dialogConfirm = true;
			} else {
				this.dialogConfirmTitulo = `Remover Filiais`;
				this.dialogConfirmTexto = `
					Deseja realmente remover as filiais selecionadas de seus respectivos acordos? Lembre-se que será removido os acordos desses mês e dos meses futuros
					atrelados ao mesmo contrato, favor verificar a coluna [Demais Acordos]!
				`;
				this.dialogConfirmBotao = `Remover Filiais dos Acordos`;
				this.dialogConfirmBotaoColor = `error`;
				this.dialogConfirm = true;
			}

			let _data = item ? [item] : this.auditadas.map((e) => ({ ...e }));
			this.data = [];

			_data.map((e) => {
				for (var [key, id_acordo] of e.acordos_all_array.entries()) {
					this.data.push({
						id_acordo: id_acordo,
						id_espaco: e.id_espaco,
						id_filial: e.id_filial,
						id_java: e.id_java,
						quantidade: e.quantidade,
						mes: e.mes_all_array[key],
						valor: e.valor,
						valor_novo: e.valor_novo,
					});
				}
			});

			/* this.data = this.data.map((e) => ({
				id_acordo: e.id_acordo,
				id_espaco: e.id_espaco,
				id_filial: e.id_filial,
				id_java: e.id_java,
				quantidade: e.quantidade,
				ocupado: e.ocupado,
				futuro: e.futuro,
				mes: e.mes,
				valor: e.valor,
				valor_novo: e.valor_novo,
			}));  */
		},
		async remover() {
			this.carregando = true;

			this.dialogConfirm = false;

			await this.$http().post("/inventario/remover", { data: this.data });

			this.carregando = false;

			this.filtrar();
		},
		abrirImportarPendente() {
			this.arquivoCSV = null;
			this.progresso = false;
			this.erro = false;

			try {
				this.$refs.formPendente.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.dialogoImportarPendente = true;
		},
		async importarPendente() {
			if (!this.$refs.formPendente.validate()) return;

			this.carregando = true;

			this.erro = false;
			this.progresso = true;
			this.indexProgresso = 0;
			this.labelProgresso = "Enviando arquivo.";

			var data = {};

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.formPendente.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = {};

			await this.$http().post("/inventario/importarPendente", formData, {
				// onUploadProgress: (event) => {

				//     console.log(event.loaded, event.total)
				// },
				onDownloadProgress: (progressEvent) => {
					let retorno = progressEvent.currentTarget.response.split(
						";"
					);

					let obj = JSON.parse(retorno[retorno.length - 2]);

					this.indexProgresso = obj.index;
					this.labelProgresso = obj.label;

					response.sucesso = obj.sucesso ? obj.sucesso : null;
					response.erros = obj.erros ? obj.erros : null;
				},
			});

			this.carregando = false;

			this.filtrar();

			if (response.sucesso) {
				this.dialogoImportarPendente = false;
			} else {
				this.progresso = false;
				this.erros = response.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
		async exportarPendente() {
			this.carregando = true;

			var response = await this.$http().post(
				"/inventario/exportarPendente",
				{ data: {} }
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		confirmReprovar() {
			this.dialogConfirmTitulo = `Reprovar Importação`;
			this.dialogConfirmTexto = `Deseja realmente reprovar a importação para o mês ${this.ultimoHistorico.ano_mes}?`;
			this.dialogConfirmBotao = `Reprovar Importação`;
			this.dialogConfirmBotaoColor = `error`;
			this.dialogConfirm = true;

			this.dialogConfirmFunction = this.reprovar;
		},
		async reprovar() {
			this.carregando = true;

			this.dialogConfirm = false;

			await this.$http().post("/inventario/reprovar", {
				data: this.ultimoHistorico,
			});

			this.carregando = false;

			this.filtrar();
		},
		confirmAprovar() {
			let _number =
				ultimoHistorico.processo == "Primeira Aprovação" ? 2 : 1;

			this.dialogConfirmTitulo = `Aprovar ${_number}º Importação`;
			this.dialogConfirmTexto = `Deseja realmente aprovar a importação para o mês ${this.ultimoHistorico.ano_mes}?`;
			this.dialogConfirmBotao = `Aprovar Importação`;
			this.dialogConfirmBotaoColor = `primary`;
			this.dialogConfirm = true;

			this.dialogConfirmFunction = this.aprovar;
		},
		async aprovarMes() {
			if (this.ultimoHistorico.processo == "Importação") {
				this.carregando = true;

				const _respHist1 = await this.$http().post(
					"/inventario/gravaHistorico",
					{
						data: this.ultimoHistorico,
					}
				);

				if (_respHist1.data.length > 0) {
					this.ultimoHistorico = _respHist1.data[0];
				}

				this.carregando = false;

				return false;
			}

			if (this.ultimoHistorico.processo != "Primeira Aprovação")
				return false;

			this.dialogProgresso = true;
			this.indexProgresso = 0;
			this.errorProgresso = false;
			this.labelProgresso = "Enviando dados.";
			this.carregando = true;
			var error = false;

			const _mesesInventario = await this.$http().post(
				"/inventario/mesesInventario",
				{ data: this.ultimoHistorico }
			);

			//console.log(_mesesInventario.data);

			for (const [index, item] of _mesesInventario.data.entries()) {
				let _data = this.$helpers.cloneObj(this.ultimoHistorico);
				_data.mes_inventario = item.mes;

				var _percent = Math.round(
					((index + 1) / _mesesInventario.data.length) * 100
				);

				let _response = await this.processarAprovacao(_data, _percent);

				console.log("percent=>", _percent);

				if (!_response.success) {
					error = true;
					this.carregando = false;
					break;
				}
			}

			if (error) return false;

			const _respHist2 = await this.$http().post(
				"/inventario/gravaHistorico",
				{
					data: this.ultimoHistorico,
				}
			);

			console.log("_respHist2=>", _respHist2);

			this.dialogProgresso = false;
			this.carregando = false;
			this.filtrar();
		},
		processarAprovacao(data, percent) {
			return new Promise((resolve) => {
				this.$http().post(
					"/inventario/aprovar",
					{ data: data },
					{
						onDownloadProgress: (progressEvent) => {
							let retorno = progressEvent.currentTarget.response.split(
								";"
							);

							let obj = JSON.parse(retorno[retorno.length - 2]);

							this.indexProgresso = percent;
							this.labelProgresso = obj.label;

							console.log("obj=>", obj);

							if (obj.index == 100) {
								if (obj.success) {
									resolve(obj);
								} else {
									this.errorProgresso = true;
									this.indexProgresso = 100;
									this.labelProgresso = obj.label;
									resolve(obj);
								}
							}
						},
					}
				);
			});
		},
		async excluidas(tipo, isTranfer = false, isOrganico = 2) {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = {
				tipo: tipo,
				mes: this.mes + "-01",
				transferir: isTranfer,
				organico: isOrganico,
			};

			var response = await this.$http().post("/inventario/excluidas", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async exporCancelados() {
			this.carregando = true;

			var response = await this.$http().post(
				"/inventario/exportAcordoCancelado",
				{
					data: {},
				}
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async historico() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var response = await this.$http().post("/inventario/historico", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		exportAction(obj) {
			switch (obj.fnc) {
				case "excluidas":
					this.excluidas(
						obj.params.tipo,
						obj.params.isTransfer,
						obj.params.isOrganico
					);
					break;
				default:
					this[obj.fnc]();
					break;
			}
		},
		itemsAcordo(item) {
			let _mes = this.$helpers.cloneObj(item.mes_all_array);
			let _saldo = this.$helpers.cloneObj(item.saldo_all_array);

			return item.acordos_all_array.map((val, key) => ({
				text: `${val} - ${this.$moment(_mes[key]).format(
					"MM/YY"
				)} (saldo ${_saldo[key]})`,
				value: val,
				disabled: true,
			}));
		},
	},
};
</script>
<style>
.myselect.v-text-field input::placeholder {
	color: white !important;
}
</style>
