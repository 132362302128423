<template>    
    <v-dialog
         v-model="dialogo"
         width="90%"            
     >  
         <v-card>
             <v-card-title class="subtitle-1 primary white--text">
                 Importar Base de Filiais
             </v-card-title>
             <v-card-text>
                 <v-btn 
                     color="primary"
                     @click="exportarModelo()"
                     class="my-3"
                 >
                     <v-icon left>mdi-microsoft-excel</v-icon>
                     Baixar modelo de importação
                 </v-btn>
                 <v-alert
                     text
                     type="info"
                     border="left"
                     style="font-size: 12px;"
                 >
                     <ul>
                         <li>
                             A base utilizada para importação deve ser parecido ao modelo extraída acima (Modelo de Importação).                             
                         </li>
                         <li>
                             A coluna <strong>id_java</strong> (ID JAVA) é obrigatória (<strong>e o nome deve ser identico ao da base exportada</strong>), as demais colunas podem ser excluidas.                          
                         </li>
                         <li>
                             Só é indicado alterações nas colunas <strong>quantidade_ano_mês</strong>. <strong>Se outra coluna for alterada a linha pode ser desconsiderada.</strong>
                         </li>
                         <li>
                             Para adicionar uma <strong>filial</strong> ao <strong>acordo</strong> para um determinado <strong>mês/ano</strong>, 
                             adicione <strong>1</strong> na coluna <strong>quantidade_ano_mês</strong> para o <strong>mês/ano</strong> desejado.
                         </li>
                         <li>
                             Se uma coluna <strong>quantidade_ano_mês</strong> for excluida a sistema assumira seus valores como <strong>1</strong> para todas as linhas. 
                         </li>
                         <li>
                             Para remover uma <strong>filial</strong> do <strong>acordo</strong> para um determinado <strong>mês/ano</strong>, 
                             adicione <strong>0</strong> na coluna <strong>quantidade_ano_mês</strong> para o <strong>mês/ano</strong> desejado.
                         </li>
                         <li>
                             Tudo que for diferente de <strong>1</strong> nas colunas <strong>quantidade_ano_mês</strong> será considerado como <strong>0</strong>.
                         </li>
                         <li>
                             Só é permitido adicionar <strong>filiais</strong> que tenham <strong>oportunidade maior que zero</strong> 
                             para o <strong>mês/ano</strong> na coluna <strong>oportunidade_ano_mês</strong>. 
                             <strong>Se a oportunidade não for maior que zero a filial será desconsiderada para o mês/ano.</strong>
                         </li>
                     </ul>  
                 </v-alert> 
                 <v-row no-gutters>  
                     <v-col cols="8" class="px-2">
                         <v-form v-model="isCsv">
                             <v-file-input 
                                 label="Arquivo CSV" 
                                 outlined 
                                 dense
                                 v-model="csvModelo"
                                 :rules="regra.csv"
                                 accept=".csv"
                             ></v-file-input>
                         </v-form>
                     </v-col> 
                     <v-col cols="4" class="px-2">
                         <v-btn
                             color="primary"
                             :disabled="!isCsv"
                             @click="importarFiliais()"
                         >
                             <v-icon left>mdi-upload</v-icon> 
                             Importar Filiais
                         </v-btn>
                     </v-col> 
                 </v-row> 
                 <v-alert 
                     type="error" 
                     transition="scale-transition"
                     dismissible
                     v-model="alerta"
                 >
                     {{alertaMesagem}}
                 </v-alert>
                 <v-expand-transition>
                     <div v-show="erroModelo">                            
                         <v-row no-gutters class="mb-0">  
                             <v-col cols="6">
                                 <download-csv
                                     :data="listaErro"
                                     :labels="listaErroLabels"
                                     name= "erros.csv"
                                     delimiter=";"
                                 >
                                     <v-btn color="primary">
                                         <v-icon left>mdi-microsoft-excel</v-icon>
                                         Exportar Erros
                                     </v-btn>
                                 </download-csv>
                             </v-col>
                             <v-col cols="6" align="end">
                                 <v-btn
                                     color="primary"
                                     @click="ignorarErrosFiliais()"
                                 >
                                     <v-icon left>mdi-alert-remove</v-icon> 
                                     Ignorar Erros
                                 </v-btn>
                             </v-col>
                         </v-row> 
                         <v-data-table                            
                             :headers="HeaderErro"
                             :items="listaErro"
                             :items-per-page="5"
                             :options.sync="paginationErro" 
                             class="elevation-0 data-table-2"
                         ></v-data-table>
                     </div>
                 </v-expand-transition>
             </v-card-text>
             <v-divider></v-divider>
             <v-card-actions>                    
                 <v-spacer></v-spacer>
                 <v-btn
                     color="primary"
                     @click="dialogo = false"
                 >
                     <v-icon left>mdi-close</v-icon>
                     Fechar
                 </v-btn>
             </v-card-actions>
         </v-card>        
         <v-overlay :value="carregando" :z-index="300">
             <v-progress-circular indeterminate size="64"></v-progress-circular>
         </v-overlay>        
     </v-dialog>
 </template>
 
 <script>
 
 export default {
     name: 'importarFiliais',
     data() {
         return {  
             csv: null,
             carregando: false,
             acordo: {},
             dados: [],  
             dadosTemp: [],          
             dialogo: false, 
             csvModelo: null,
             isCsv: false,
             alerta: false,
             alertaMesagem: null,
             HeaderErro: [
                 { text: 'Descrição do Erro', value: 'msg', align: 'left'},
             ],
             listaErro: [],
             listaErroLabels: {msg: 'Descrição do Erro'}, 
             erroModelo: false,
             paginationErro: {},
             regra: { 
                 csv: [
                     v => !!v || 'Selecione um arquivo.',
                     v => (v && [
                         '.csv', 
                         '.CSV'
                     ].includes(v.name.substr(-4))) || 'O arquivo deve ser do tipo CSV.',
                 ] ,
             },   
         }
     },
     methods: {
         exportarModelo() {
 
             this.$emit('exportarModelo')
         },
         getPeriodo(campo = 'quantidade') {
 
             var mesAtual = new Date(this.acordo.mesInicial+'-15')
             var mesFinal = new Date(this.acordo.mesFinal+'-15')
 
             var periodo = []
 
             while (mesAtual <= mesFinal) {
                 
                 periodo.push(campo+'_'+mesAtual.getFullYear()+'_'+(mesAtual.getMonth() + 1))
                 
                 mesAtual.setMonth(mesAtual.getMonth() + 1);
             }
 
             return periodo
         },
         abrir(acordo, dados) {
                       
             this.dados = dados.map(a => ({...a}))  
             this.acordo = {...acordo} 
             
             this.csvModelo = null
             this.alerta = false
             this.alertaMesagem = null
             this.erroModelo = false
             this.listaErro = []
             this.dadosTemp = []     
             this.dialogo = true            
         },
         async importarFiliais() {
            
             this.alerta = false
             this.alertaMesagem = null
             this.erroModelo = false
             this.listaErro = []
             this.dadosTemp = []
             
             const parseCsv = async (csv) => {
                 return new Promise(resolve => {
                     this.$papa.parse(csv, {
                         delimiter: ";",
                         header: true,
                         dynamicTyping: true,
                         skipEmptyLines: true,
                         complete: function(results) {
                             resolve(results)
                         },
                     })
                 })
             }
 
             var csv = await parseCsv(this.csvModelo)
 
             this.csv = csv
           
             if(csv.errors.length){
 
                 this.alerta = true
                 this.alertaMesagem = 'Erro ao ler o arquivo CSV. Exporte novamente o modelo e importe o modelo editado.'
             }
             else{
                // Se for do tipo moveldermo, precisamos conferir as colunas enviadas no arquivo
                if (this.acordo.metodo_criacao == 'MD') {
                    let espacosModelDermo = []
                    
                        espacosModelDermo = this.acordo.moveisDermo.map(item => {
                            return item.id_espaco
                        })
                    
                    const colunasAusentes = [];
                    for (var espacosDermo of espacosModelDermo) {
                       
                        for (const periodo of this.getPeriodo('')) {
                            const coluna = `${espacosDermo}_qtde${periodo}`
                            if (!csv.meta.fields.includes(coluna)) {
                                colunasAusentes.push(coluna);
                                this.alerta = true
                            }
                        }
                    }
                    if (this.alerta) {
                        this.alertaMesagem = `As seguintes colunas não foram encontradas no arquivo: ${colunasAusentes.join(', ')}. Exporte novamente o modelo e importe o modelo editado.`
                        return
                    }
                }



             
                 this.dadosTemp = this.dados.map(a => ({...a}))
 
                 
 
                 for (const f of this.dadosTemp) {
                     
                     for (const campo of this.getPeriodo('')) {
 
                         f.comprometido = 0
                         f.alterado = 1
                         f['quantidade'+campo] = false
                     }
                 }
 
                 var linha = 2
                 var erroLinha = null
 
                 for (const e of csv.data) {
 
                     erroLinha = ' - Erro linha '+linha
                     linha++
 
                     var index = 0;
 
                     if (this.acordo.metodo_criacao == 'MD') {
 
                         let espacosModelDermo = []
 
                         if (this.acordo.metodo_criacao == 'MD') {
                             espacosModelDermo = this.acordo.moveisDermo.map(item => {
                                 return item.id_espaco
                             })
                         } 
 
                         for (var espacoDermo of espacosModelDermo) {
 
                             index = this.dados.findIndex(element => element.id_java == e.id_java && ((espacoDermo !== '') && (element.id_espaco == espacoDermo)))
 
                             let prefixo = (espacoDermo !== '') ? espacoDermo + '_' : ''                       
         
                             if(index >= 0){
                             
                                 this.dadosTemp[index].comprometido = 0
                                 this.dadosTemp[index].alterado = 1
         
                                 for (const campo of this.getPeriodo('')) {
          
                                     if(e[prefixo + 'qtde'+campo] === 1 || e[prefixo + 'qtde'+campo] == undefined){
                                     
                                         if(this.dados[index]['oportunidade'+campo]*1 > 0) {
                                             
                                             this.dadosTemp[index]['quantidade'+campo] = true
                                             this.dadosTemp[index].comprometido = 1
                                         }
                                         else {
         
                                             this.listaErro.push({
                                                 msg: `A oportunidade não é maior que zero para a filial (id_java = ${e.id_java}), para o campo quantidade${campo}.`+erroLinha                                
                                             })
                                         }
                                     }
                                     else{
         
                                         this.dadosTemp[index]['quantidade'+campo] = false
                                     }
                                 }
                             }
                             else{
         
                                 this.listaErro.push({
                                     msg: `A filial (id_java = ${e.id_java}) não foi encontrada.`+erroLinha                                
                                 })
                             }
                         }
                     } else {
 
                        index = this.dados.findIndex(element => element.id_java == e.id_java)

                        if(index >= 0){
                        
                            this.dadosTemp[index].comprometido = 0
                            this.dadosTemp[index].alterado = 1

                            for (const campo of this.getPeriodo('')) {

                                if(e['quantidade'+campo] === 1 || e['quantidade'+campo] == undefined){
                                
                                    if(this.dados[index]['oportunidade'+campo]*1 > 0) {
                                        
                                        this.dadosTemp[index]['quantidade'+campo] = true
                                        this.dadosTemp[index].comprometido = 1
                                    }
                                    else {

                                        this.listaErro.push({
                                            msg: `A oportunidade não é maior que zero para a filial (id_java = ${e.id_java}), para o campo quantidade${campo}.`+erroLinha                                
                                        })
                                    }
                                }
                                else{

                                    this.dadosTemp[index]['quantidade'+campo] = false
                                }
                            }
                        }
                        else{

                            this.listaErro.push({
                                msg: `A filial (id_java = ${e.id_java}) não foi encontrada.`+erroLinha                                
                            })
                        }
 
                     }
 
                 }
 
                 if(this.listaErro.length > 0){
 
                     this.paginationErro.page = 1
                     this.erroModelo = true
                 }
                 else{
 
                     this.dados = this.dadosTemp.map(a => ({...a}))
 
                     this.$emit('importar') 
 
                     this.dialogo = false
                 }
             }                          
         },
         ignorarErrosFiliais() {
             
             this.dados = this.dadosTemp.map(a => ({...a}))
 
             this.$emit('importar') 
 
             this.dialogo = false            
         },
     }
 }
 </script>