var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-5 pt-3 pb-0 br-15 w-100",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1"},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Inventário")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"input-button input-cinza",attrs:{"label":"Pesquisar","outlined":"","dense":"","hide-details":""},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}},[[_c('v-icon',{attrs:{"slot":"append","color":"white"},slot:"append"},[_vm._v("mdi-magnify")])]],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('MesInput',{staticClass:"pb-0",attrs:{"label":"Mês","required":"","hide-details":""},model:{value:(_vm.mesFiltro),callback:function ($$v) {_vm.mesFiltro=$$v},expression:"mesFiltro"}})],1),_c('div',{staticClass:"d-flex py-3 px-4"},[_c('v-btn',{staticClass:"new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1),_c('v-btn',{staticClass:"ml-5 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.abrirImportacao()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Importar ")],1)],1)],1)],1),_c('v-card',{staticClass:"px-5 py-5 br-15 w-100 mt-5",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0 tabela",attrs:{"headers":_vm.headers,"items":_vm.dados,"items-per-page":10,"footer-props":_vm.tableConfig,"header-props":{ sortIcon: 'mdi-chevron-down' },"options":_vm.pagination,"show-select":"","multi-sort":""},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.mes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.mes).format("MM/YYYY"))+" ")]}},{key:"item.semana_1",fn:function(ref){
var item = ref.item;
return [(!item.semana_1)?_c('span',{staticStyle:{"color":"#ccc !important"}},[_vm._v(_vm._s(item.quantidade))]):_c('span',[_vm._v(_vm._s(item.semana_1))])]}},{key:"item.semana_2",fn:function(ref){
var item = ref.item;
return [(!item.semana_2)?_c('span',{staticStyle:{"color":"#ccc !important"}},[_vm._v(_vm._s(item.quantidade))]):_c('span',[_vm._v(_vm._s(item.semana_2))])]}},{key:"item.semana_3",fn:function(ref){
var item = ref.item;
return [(!item.semana_3)?_c('span',{staticStyle:{"color":"#ccc !important"}},[_vm._v(_vm._s(item.quantidade))]):_c('span',[_vm._v(_vm._s(item.semana_3))])]}},{key:"item.semana_4",fn:function(ref){
var item = ref.item;
return [(!item.semana_4)?_c('span',{staticStyle:{"color":"#ccc !important"}},[_vm._v(_vm._s(item.quantidade))]):_c('span',[_vm._v(_vm._s(item.semana_4))])]}},{key:"item.semana_5",fn:function(ref){
var item = ref.item;
return [(!item.semana_5)?_c('span',{staticStyle:{"color":"#ccc !important"}},[_vm._v(_vm._s(item.quantidade))]):_c('span',[_vm._v(_vm._s(item.semana_5))])]}}],null,true)})],1),_c('importacao',{ref:"importacao",on:{"filtrar":_vm.filtrar}}),_c('v-overlay',{attrs:{"value":_vm.carregando}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }