var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formFiltro"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Mês","rules":_vm.regra.mes,"required":"","outlined":"","dense":"","type":"month"},model:{value:(_vm.filtro.mes),callback:function ($$v) {_vm.$set(_vm.filtro, "mes", $$v)},expression:"filtro.mes"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Filial","items":_vm.lista.filial,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_filial.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_filial.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_filial.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_filial),callback:function ($$v) {_vm.$set(_vm.filtro, "id_filial", $$v)},expression:"filtro.id_filial"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Bandeira","items":_vm.lista.bandeira,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.bandeira.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.bandeira.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.bandeira.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.bandeira),callback:function ($$v) {_vm.$set(_vm.filtro, "bandeira", $$v)},expression:"filtro.bandeira"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Fornecedor","items":_vm.lista.fornecedor,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(
								_vm.filtro.id_fornecedor.length === 1 && index === 0
							)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
								_vm.filtro.id_fornecedor.length > 1 && index === 0
							)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_fornecedor.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_fornecedor),callback:function ($$v) {_vm.$set(_vm.filtro, "id_fornecedor", $$v)},expression:"filtro.id_fornecedor"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Espaço","items":_vm.lista.espaco,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.id_espaco.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_espaco.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_espaco.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_espaco),callback:function ($$v) {_vm.$set(_vm.filtro, "id_espaco", $$v)},expression:"filtro.id_espaco"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Espaço Macro","items":_vm.lista.espaco_macro,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(
								_vm.filtro.espaco_macro.length === 1 && index === 0
							)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.espaco_macro.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.espaco_macro.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.espaco_macro),callback:function ($$v) {_vm.$set(_vm.filtro, "espaco_macro", $$v)},expression:"filtro.espaco_macro"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Cluster","items":_vm.lista.cluster,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.id_cluster.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.id_cluster.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_cluster.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_cluster),callback:function ($$v) {_vm.$set(_vm.filtro, "id_cluster", $$v)},expression:"filtro.id_cluster"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Região","items":_vm.lista.regiao,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.regiao.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.regiao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.regiao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.regiao),callback:function ($$v) {_vm.$set(_vm.filtro, "regiao", $$v)},expression:"filtro.regiao"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Perfil","items":_vm.lista.perfil,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.perfil.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.perfil.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.perfil.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.perfil),callback:function ($$v) {_vm.$set(_vm.filtro, "perfil", $$v)},expression:"filtro.perfil"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Tamanho","items":_vm.lista.tamanho,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.tamanho.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.tamanho.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.tamanho.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.tamanho),callback:function ($$v) {_vm.$set(_vm.filtro, "tamanho", $$v)},expression:"filtro.tamanho"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Grupo","items":_vm.lista.grupo,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.grupo.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.grupo.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.grupo.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.grupo),callback:function ($$v) {_vm.$set(_vm.filtro, "grupo", $$v)},expression:"filtro.grupo"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Subgrupo","items":_vm.lista.subgrupo,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.subgrupo.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.subgrupo.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.subgrupo.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.subgrupo),callback:function ($$v) {_vm.$set(_vm.filtro, "subgrupo", $$v)},expression:"filtro.subgrupo"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Categoria","items":_vm.lista.categoria,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(_vm.filtro.categoria.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.categoria.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.categoria.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.categoria),callback:function ($$v) {_vm.$set(_vm.filtro, "categoria", $$v)},expression:"filtro.categoria"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Subcategoria","items":_vm.lista.subcategoria,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
							var item = ref.item;
							var index = ref.index;
return [(
								_vm.filtro.subcategoria.length === 1 && index === 0
							)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.subcategoria.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.subcategoria.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.subcategoria),callback:function ($$v) {_vm.$set(_vm.filtro, "subcategoria", $$v)},expression:"filtro.subcategoria"}})],1),_c('v-col',{staticClass:"px-2 ml-auto pb-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","align":"end"}},[_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1),_c('v-col',{staticClass:"px-2 d-flex justify-start",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"VB zerado "},on:{"change":_vm.ajusteVbZerado},model:{value:(_vm.filtro.vbZerado),callback:function ($$v) {_vm.$set(_vm.filtro, "vbZerado", $$v)},expression:"filtro.vbZerado"}}),_c('v-checkbox',{staticClass:"ml-3 mt-0",attrs:{"label":"Cubo+Movel Dermo "},on:{"change":_vm.ajusteCuboMovelDermo},model:{value:(_vm.filtro.cuboMovelDermo),callback:function ($$v) {_vm.$set(_vm.filtro, "cuboMovelDermo", $$v)},expression:"filtro.cuboMovelDermo"}}),_c('v-checkbox',{staticClass:"ml-3 mt-0",attrs:{"label":"Apenas Campanhas"},on:{"change":_vm.ajusteApenasCampanhas},model:{value:(_vm.filtro.apenasCampanhas),callback:function ($$v) {_vm.$set(_vm.filtro, "apenasCampanhas", $$v)},expression:"filtro.apenasCampanhas"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }