<template>
    <v-form ref="formFiltro">
            <v-row class="mt-3">
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <MesInput
                        label="Período Inicial"
                        v-model="filtro.mesInicial"
                        :rules="regra.mesInicial"
                        required
                        outlined
                        dense
                        type="month"
                    ></MesInput>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <MesInput
                        label="Período Final"
                        v-model="filtro.mesFinal"
                        :rules="regra.mesInicial"
                        required
                        outlined
                        dense
                        type="month"
                    ></MesInput>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                    <v-autocomplete
                        label="Usuário"
                        v-model="filtro.id_usuario"
                        :items="lista.usuario"
                        class="input-cinza select"
                        :menu-props="menuProps"
                        multiple
                        outlined
                        dense
                    >
                        <template v-slot:selection="{ item, index }">
                            <div
                                v-if="
                                    filtro.id_usuario.length === 1 &&
                                    index === 0
                                "
                                style="
                                    width: 70% !important;
                                    white-space: nowrap !important;
                                    text-overflow: ellipsis !important;
                                    overflow: hidden;
                                "
                            >
                                {{ item.text }}
                            </div>
                            <span
                                v-if="
                                    filtro.id_usuario.length > 1 && index === 0
                                "
                                class="grey--text caption mr-1"
                                >{{
                                    filtro.id_usuario.length
                                }}
                                selecionados</span
                            >
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="3"
                    class="px-2 ml-auto pb-2"
                    align="end"
                >
                    <v-btn class="ml-2 mb-1" color="primary" @click="limpar()">
                        <v-icon left>mdi-filter-off</v-icon> Limpar
                    </v-btn>
                    <v-btn class="ml-2 mb-1" color="primary" @click="filtrar()">
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <v-overlay :value="overlay" :z-index="300">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
    </v-form>
</template>

<script>
import useFiltros from '../../../hooks/useFiltros'

export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros('/planejamentoEcommerce/gerencial/filtros_acessos_logs')

        return { isLoading, isError, isFetching, lista, error, refetch }
    },
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            filtro: {},
            regra: {
                mesInicial: [
                    (v) => !!v || 'O campo período inicial é obrigatório',
                ],
                mesFinal: [
                    (v) => !!v || 'O campo período final é obrigatório',
                    (v) =>
                        (!!v && v >= this.filtro.mesInicial) ||
                        'O período final deve ser igual ou posterior ao período inicial',
                ],
            },
        }
    },
    async created() {
        this.iniciaFiltro()

        await new Promise((resolve) => setTimeout(resolve, 100))

        this.filtrar()
    },
    computed: {
        overlay() {
            return Object.keys(this.lista).length === 0 && this.isFetching
        },
    },
    methods: {
        iniciaFiltro() {
            this.filtro = {
                mesInicial: this.$moment().format('YYYY-MM'),
                mesFinal: this.$moment().format('YYYY-MM'),
                id_usuario: [],
            }
        },
        limpar() {
            this.iniciaFiltro()

            this.filtrar()
        },
        filtrar() {
            this.$emit('filtrar')
        },
        validate() {
            return this.$refs.formFiltro.validate()
        },
        resetValidation() {
            try {
                this.$refs.formFiltro.resetValidation()
            } catch (error) {
                //console.log(error)
            }
        },
    },
}
</script>