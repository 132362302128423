<template>
  <div>
    <v-btn class="new-button ml-4" color="primary" @click="abrirDialogo">
      <v-icon left>mdi-plus-thick</v-icon>
      Adicionar
    </v-btn>
    <v-dialog content-class="br-15" scrollable v-model="dialog" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title>
            Adicionar aviso
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialog = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form ref="formCriar">
            <v-row no-gutters>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="px-2">

                <input-id-java
                    label="Filial"
                    v-model="filtro.id_filial"
                    :items="lista.filial"
                />

           
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="px-2">
                <v-autocomplete
                  label="Região"
                  v-model="filtro.regiao"
                  :items="lista.regiao"
                  multiple
                  outlined
                  class="input-cinza"
                  :menu-props="menuProps"
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <div
                      v-if="filtro.regiao.length === 1 && index === 0"
                      style="
                        width: 70% !important;
                        white-space: nowrap !important;
                        text-overflow: ellipsis !important;
                        overflow: hidden;
                      "
                    >
                      {{ item.text }}
                    </div>
                    <span
                      v-if="filtro.regiao.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.regiao.length }} selecionados</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="px-2">
                <v-select
                  label="Cluster"
                  :items="lista.cluster"
                  v-model="filtro.id_cluster"
                  multiple
                  outlined
                  class="input-cinza"
                  :menu-props="menuProps"
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <span
                      v-if="filtro.id_cluster.length === 1 && index === 0"
                      >{{ item.text }}</span
                    >
                    <span
                      v-if="filtro.id_cluster.length > 1 && index === 0"
                      class="grey--text caption mr-1"
                      >{{ filtro.id_cluster.length }} selecionados</span
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" xl="6" class="px-2">
                <v-select
                  label="Bandeira"
                  :items="[`DROGASIL`, 'RAIA']"
                  v-model="filtro.bandeira"
                  outlined
                  class="input-cinza"
                  :menu-props="menuProps"
                  dense
                  multiple
                >
                </v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="px-2 pt-0 pb-3"
              >
                <v-switch
                  v-model="alertaAtualizacao"
                  label="Alerta atualização"
                  hide-details
                  class="mt-0"
                ></v-switch>
              </v-col>
              <v-col md="12" class="px-2 mt-3">
                <v-textarea
                  outlined
                  class="input-cinza"
                  dense
                  v-model="titulo"
                  hide-details
                  rows="1"
                  label="titulo"
                  :rules="tituloRegra"
                ></v-textarea>
                <p :class="titulo.length > 40 && 'falha-validacao'">
                  {{ titulo.length }}/40 caracteres
                </p>
              </v-col>
              <v-col md="12" class="px-2">
                <v-textarea
                  outlined
                  class="input-cinza"
                  dense
                  v-model="mensagem"
                  hide-details
                  rows="3"
                  label="Mensagem"
                  :rules="mensagemRegra"
                ></v-textarea>
                <p :class="mensagem.length > 1000 && 'falha-validacao'">
                  {{ mensagem.length }}/1000 caracteres
                </p>
              </v-col>
              <v-col md="12" class="px-2">
                <v-file-input
                  label="Arquivo PDF ou imagem"
                  outlined
                  class="input-cinza"
                  dense
                  v-model="pdfModelo"
                  :rules="regra.pdf"
                  accept="application/pdf, image/*"
                ></v-file-input>
              </v-col>
            </v-row>
            <p>
              Esse aviso será enviado para {{ quantidadeDeLojas }}
              {{ quantidadeDeLojas === 1 ? "loja" : "lojas" }}
              <v-btn
                class="ml-2 my-1"
                color="primary"
                small
                @click="dialogListarFiliais = true"
              >
                ver lojas
              </v-btn>
            </p>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-3 mb-3 new-button"
            color="primary"
            @click="criarAviso()"
          >
            <v-icon left>mdi-plus-thick</v-icon>
            Adicionar aviso
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="br-15"
      v-model="dialogListarFiliais"
      width="600"
      scrollable
    >
      <v-card v-if="dialogListarFiliais">
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title>
            Filiais selecionadas
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogListarFiliais = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-row>
            <v-col
              class="filial-item"
              cols="6"
              v-for="filial in listarFiliaisSelecionadas"
              :key="filial.value"
            >
              <li>
                {{ filial.text }} <br />
                {{ filial.regiao }}
              </li>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  inject: ["listaCampos"],
  components: {},

  data: () => ({
    lista: {
      filial: [],
    },
    menuProps: {
      bottom: true,
      offsetY: true,
      contentClass: "select-outline",
      transition: "slide-y-transition",
    },
    alertaAtualizacao: false,
    dialogListarFiliais: false,
    dialog: false,
    carregando: false,
    mensagem: null,
    titulo: '',
    pdfModelo: null,
    regra: {
      pdf: [
        (v) => (v) =>
          !v ||
          [".pdf", ".PDF"].includes(v.name.substr(-4)) ||
          "O arquivo deve ser do tipo PDF.",
      ],
    },
    mensagemRegra: [
      (v) => !!v || "O campo mensagem é obrigatório",
      (v) => v.length <= 1000 || "Máximo de 1000 caracteres",
    ],
    tituloRegra: [
      (v) => !!v || "O campo titulo é obrigatório",
      (v) => v.length <= 40 || "Máximo de 40 caracteres",
    ],
    filtro: { id_filial: [] },
  }),
  methods: {
    iniciaFiltro() {
      this.filtro = {
        id_cluster: [],
        id_filial: [],
        regiao: [],
        bandeira: [],
      };
      this.mensagem = "";
      this.alertaAtualizacao = false;
    },
    abrirDialogo() {
      // reseta o form
      try {
        this.$refs.formCriar.reset();
      } catch (error) {
        console.log(error);
      }
      this.iniciaFiltro();
      this.titulo = "Aviso importante!";

      this.dialog = true;
    },

    async criarAviso() {
      if (!this.$refs.formCriar.validate()) return;
      this.carregando = true;
      const filiais = this.filtrarFiliais().map((f) => f.value);
      const formData = new FormData();
      formData.append("files", this.pdfModelo);

      const data = {
        mensagem: this.mensagem,
        filiais,
        tipo: this.alertaAtualizacao ? "atualizacao" : "aviso",
        titulo: this.titulo,
      };

      formData.append("data", JSON.stringify(data));

      const response = await this.$http().post("/app/avisos/criar", formData);

      if (response.data.success === true) {
        this.$dialog.notify.success("Aviso criado com sucesso", {
          position: "top-right",
          timeout: 5000,
        });
        this.dialog = false;
        this.iniciaFiltro();
        this.$emit("filtrar");
        this.carregando = false;
      } else {
        this.$dialog.notify.error("Erro ao criar aviso", {
          position: "top-right",
          timeout: 5000,
        });
        this.carregando = false;
      }
    },
    filtrarFiliais() {
      const lojasAfetadas = this.lista.filial.filter((loja) => {
        /// Verifica se
        const includeFiliais =
          this.filtro.id_filial.length > 0
            ? this.filtro.id_filial.includes(loja.value)
            : true;
        const includeRegioes =
          this.filtro.regiao.length > 0
            ? this.filtro.regiao.includes(loja.regiao)
            : true;
        const includeClusters =
          this.filtro.id_cluster.length > 0
            ? this.filtro.id_cluster.includes(loja.id_cluster)
            : true;
        const includeBandeira =
          this.filtro.bandeira.length > 0
            ? this.filtro.bandeira.includes(loja.bandeira)
            : true;
        return (
          includeFiliais && includeRegioes && includeClusters && includeBandeira
        );
      });
      return lojasAfetadas;
    },
  },

  computed: {
    quantidadeDeLojas() {
      return this.filtrarFiliais().length;
    },
    listarFiliaisSelecionadas() {
      return this.filtrarFiliais();
    },
  },
  async created() {
    this.iniciaFiltro();
    var lista = await this.listaCampos;
    this.lista.filial = lista.filial;
    this.lista.regiao = lista.regiao;
    this.lista.cluster = lista.cluster;
  },
};
</script>
<style scoped>
.filial-item {
  border-bottom: 1px solid #ccc;
}
.falha-validacao {
  color: red;
}
</style>
