<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Espaços</v-toolbar-title>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="exportar()"   
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                </v-btn>               
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="abrirImportar()"   
                >
                    <v-icon left>mdi-upload</v-icon> Importar                    
                </v-btn>              
            </v-col>            
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="12" md="6" lg="8" xl="8">
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" align="end">                     
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>     
                </v-col>            
            </v-row>          
            <v-data-table
                :headers="headers"
                :items="dados"
                item-key="id_espaco"
                :server-items-length="total" 
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >   
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"   
                        @click="abrirEditar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="600"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Editar Espaço
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form v-model="validaForm"> 
                        <v-row no-gutters class="mt-4">                                
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Código RD"
                                    v-model="espaco.codigo"
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="ID Espaço"
                                    v-model="espaco.id_espaco"
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Espaço"
                                    v-model="espaco.nome"
                                ></v-text-field> 
                            </v-col>                                              
                            <v-col cols="6" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-checkbox
                                    v-model="espaco.organico"
                                    label="Espaço Orgânico"
                                ></v-checkbox>
                            </v-col>   
                            <v-col cols="6" sm="12" md="6" lg="6" xl="6" class="px-2"> 
                                <v-checkbox
                                    v-model="espaco.habilitacampanha"
                                    label="Habilita acordo em campanha"
                                ></v-checkbox>
                            </v-col>                                       
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Espaço Macro"
                                    v-model="espaco.espaco_macro"
                                ></v-text-field> 
                            </v-col>                        
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2"> 
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Fantasia"
                                    v-model="espaco.apelido"
                                ></v-text-field> 
                            </v-col>                        
                        </v-row>  
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        :disabled="!validaForm"
                        @click="editar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogoImportar"
            width="90%"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Importar CSV
                </v-card-title>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <li>
                                A base utilizada para importação deve ser parecida com base exportada. As colunas <strong>codigo_rd</strong> (Código RD), 
                                <strong>organico</strong> (Orgânico) e <strong>espaco_macro</strong> (Espaço Macro) são obrigatórias 
                                (<strong>e os nomes devem ser identicos aos da base exportada</strong>), as demais colunas podem ser excluidas.
                            </li>
                            <li>
                                Na coluna <strong>organico</strong> (Orgânico) coloque <strong>1</strong> para <strong>Sim</strong> e <strong>0</strong> para <strong>Não</strong>, 
                                qualquer valor diferente de <strong>0</strong> e <strong>1</strong> será considerado como <strong>Não</strong>.
                            </li>
                            <li>
                                Na coluna <strong>visivel_simulador</strong> coloque <strong>1</strong> para <strong>Sim</strong> e <strong>0</strong> para <strong>Não</strong>, 
                                qualquer valor diferente de <strong>0</strong> e <strong>1</strong> será considerado como <strong>Não</strong>.
                            </li>
                        </ul>  
                    </v-alert> 
                    <v-row no-gutters>  
                        <v-col cols="8" class="px-2">
                            <v-form ref="form" v-model="isCsv">
                                <v-file-input 
                                    label="Arquivo CSV" 
                                    outlined 
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col> 
                        <v-col cols="4" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCsv"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon> 
                                Importar Arquivo
                            </v-btn>
                        </v-col> 
                    </v-row> 
                    <v-expand-transition>
                        <div v-show="erro">                            
                            <v-row no-gutters class="mb-0">  
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name= "erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left>mdi-microsoft-excel</v-icon>
                                            Exportar Erros
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row> 
                            <v-data-table                            
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros" 
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>                    
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportar=false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
	// components: {
		// AddGrupoEspaco,
	// },
	data() {
		return {
			headers: [
				{ text: "Código RD", value: "codigo", align: "center" },
				{ text: "ID Espaço", value: "id_espaco", align: "center" },
				{ text: "Espaço", value: "nome", align: "center" },
				{ text: "Orgânico", value: "organicoStr", align: "center" },
				{
					text: "Espaço Macro",
					value: "espaco_macro",
					align: "center",
				},
				{
					text: "Grupo Espaço",
					value: "espaco_grupo",
					align: "center",
				},
                {
					text: "Fantasia",
					value: "apelido",
					align: "center",
				},
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			dados: [],
			espaco: {
				id_espaco: null,
				codigo: null,
				nome: null,
				organico: null,
				espaco_macro: null,
				id_espaco_grupo: null,
			},
			espaco_grupos: [],
			openAddGrupoEspaco: false,
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	created() {},
	computed: {
		isCubo() {
			return (this.espaco.nome || "").toLowerCase().includes("cubo");
		},
	},
	methods: {
		async getEspacoGrupos() {
			this.espaco_grupos = [];
			let _res = await this.$http().post("/espaco/espacoGrupo", {});

			if (_res.data.success) this.espaco_grupos = _res.data.data;

			if (!_res.data.success) {
				this.$dialog.notify.error(
					"Ops! Falha ao buscar os grupos dos espaços.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
			}
		},
		async filtrar() {
			this.carregando = true;

			var data = {};

			data.pagination = this.pagination;
			data.busca = this.busca;

			this.getEspacoGrupos();

			var response = await this.$http().post("/espaco/lista", {
				data: data,
			});

			this.dados = response.data;
			this.total = response.data.length ? response.data[0].total : 0;

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar(false);
			}

			for (const e of this.dados) {
				e.organicoStr = e.organico ? "Sim" : "Não";
			}

			this.carregando = false;
		},
		async exportar() {
			this.carregando = true;

			var data = {};

			var response = await this.$http().post("/espaco/exportar", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		abrirEditar(item) {
			this.espaco = { ...item };

			this.dialogo = true;
		},
		async editar() {
			this.carregando = true;

			var data = this.espaco;

			var response = await this.$http().post("/espaco/editar", {
				data: data,
			});

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogo = false;
			} else {
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}
		},
		abrirImportar() {
			this.arquivoCSV = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/espaco/importar",
				formData
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogoImportar = false;
			} else {
				this.filtrar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
		updateGrupos(grupo) {
			this.espaco_grupos.push(grupo);
			this.espaco.id_espaco_grupo = grupo.id_espaco_grupo;
		},
	},
};
</script>
