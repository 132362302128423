<template>
  <v-container fluid class="mt-0 pt-0">
    <v-card class="" elevation="1">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-toolbar-title class="ml-2 mt-2">Tablóide</v-toolbar-title>
        </v-col>
        <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
          <v-btn class="ml-2 my-1" color="primary" @click="novo()">
            <v-icon left>mdi-plus-thick</v-icon> Adicionar
          </v-btn>
          <v-btn class="ml-2 my-1" color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
        </v-col>
      </v-row>

      <v-form ref="formFiltro">
        <v-row no-gutters class="mb-2">
          <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
            <v-text-field
              v-if="lista.usuarioTipo == 'fornecedor'"
              label="Fornecedor"
              v-model="simulacao.fornecedor"
              disabled
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-if="lista.usuarioTipo == 'rd'"
              label="Fornecedor"
              v-model="simulacao.id_fornecedor"
              :items="lista.fornecedor"
              :rules="regra.id_fornecedor"
              outlined
              dense
              clearable
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="3" class="px-2">
            <v-select
              label="Ano"
              v-model="simulacao.ano"
              :items="lista.anos"
              :rules="regra.ano"
              outlined
              dense
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            align="end"
            class="px-2"
          >
            <v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
              <v-icon left>mdi-filter</v-icon> Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <div class="scrollsettings">
        <v-data-table
          :headers="headers"
          :items="dados"
          item-key="id_simulacao_ecommerce"
          :server-items-length="total"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 25, 50, 100],
          }"
          :options.sync="pagination"
          multi-sort
          class="fix-column elevation-0 px-2"
        >
          <template v-slot:header="{}">
            <thead>
              <tr>
                <th
                  :colspan="8"
                  class="v-data-table-header text-center lighten-1"
                >
                  TOTAL INVESTIDO : {{ dados.valor_total }}
                </th>

                <th
                  :colspan="9"
                  class="v-data-table-header text-center warning lighten-1"
                  style="height: 25px"
                >
                  VERÃO
                </th>
                <th
                  :colspan="15"
                  class="v-data-table-header text-center accent darken-1"
                  style="height: 25px"
                >
                  INVERNO
                </th>
                <th
                  :colspan="12"
                  class="v-data-table-header text-center warning lighten-1"
                  style="height: 25px"
                >
                  VERÃO
                </th>
              </tr>

              <tr>
                <th
                  :colspan="8"
                  class="v-data-table-header text-center lighten-1"
                ></th>

                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary darken-1"
                  style="height: 25px"
                >
                  Janeiro
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary"
                  style="height: 25px"
                >
                  Fevereiro
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary darken-1"
                  style="height: 25px"
                >
                  Março
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary"
                  style="height: 25px"
                >
                  Abril
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary darken-1"
                  style="height: 25px"
                >
                  Maio
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary"
                  style="height: 25px"
                >
                  Junho
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary darken-1"
                  style="height: 25px"
                >
                  Julho
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary"
                  style="height: 25px"
                >
                  Agosto
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary darken-1"
                  style="height: 25px"
                >
                  Setembro
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary"
                  style="height: 25px"
                >
                  Outubro
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary darken-1"
                  style="height: 25px"
                >
                  Novembro
                </th>
                <th
                  :colspan="3"
                  class="v-data-table-header text-center primary"
                  style="height: 25px"
                >
                  Dezembro
                </th>
              </tr>
            </thead>
          </template>

          <template
            v-for="index in 12"
            v-slot:[`item.valor_${index}`]="{ item }"
          >
            <div v-if="parseInt(item['ativacao_' + index]) >= 1" :key="index">
              {{ item["valor_" + index] }}
            </div>
            <div v-else :key="index">
              {{ item["valor_" + index] }}
            </div>
          </template>

          <template
            v-for="index in 12"
            v-slot:[`item.desconto_${index}`]="{ item }"
          >
            <div
              :key="index"
              @click="
                lista.usuarioTipo != 'fornecedor' &&
                !statusAcesso(item.id_status_simulacao, 'aprovar_reprovar')
                  ? editarTipo(
                      item,
                      'desconto_' + index,
                      'Desconto de ' + meses[index - 1]
                    )
                  : false
              "
              :style="
                lista.usuarioTipo != 'fornecedor'
                  ? 'cursor: pointer; width: 90%; height: 20px;text-decoration: underline;'
                  : 'width: 90%; height: 20px;'
              "
            >
              {{ item["desconto_" + index] }}
            </div>
          </template>

          <template
            v-for="index in 12"
            v-slot:[`item.ativacao_${index}`]="{ item }"
          >
            <div
              :key="index"
              class="edit-style"
              @click="
                !statusAcesso(item.id_status_simulacao, 'aprovar_reprovar')
                  ? editarTipo(
                      item,
                      'ativacao_' + index,
                      'Ativação de ' + meses[index - 1]
                    )
                  : false
              "
            >
              {{ item["ativacao_" + index] }}
            </div>
          </template>

          <template v-slot:[`item.obs`]="{ item }">
            <div
              @click="
                !statusAcesso(item.id_status_simulacao, 'aprovar_reprovar')
                  ? editarTipo(item, 'obs', 'Observação')
                  : false
              "
              style="
                cursor: pointer;
                width: 90%;
                height: 20px;
                text-decoration: underline;
              "
            >
              {{ item["obs"] }}
            </div>
          </template>

          <template v-slot:[`item.botao`]="{ item }">
            <v-icon
              v-if="item.ano >= anoProximo"
              small
              class="mr-2"
              color="error"
              @click="confirmExcluir(item)"
              :disabled="statusAcesso(item.id_status_simulacao, 'excluir')"
              title="Excluir"
            >
              mdi-delete-forever
            </v-icon>
          </template>

          <template v-slot:[`body.append`]>
            <tr>
              <td class="text-center"></td>
              <td class="text-center">
                <b>TOTAL:</b>
              </td>
              <td class="text-center">
                {{ dados.valor_tabloide_total }}
              </td>
              <td class="text-center">
                {{ dados.totalDesconto1 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao1 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes1 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto2 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao2 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes2 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto3 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao3 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes3 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto4 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao4 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes4 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto5 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao5 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes5 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto6 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao6 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes6 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto7 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao7 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes7 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto8 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao8 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes8 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto9 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao9 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes9 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto10 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao10 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes10 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto11 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao11 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes11 }}
              </td>

              <td class="text-center">
                {{ dados.totalDesconto12 }}
              </td>
              <td class="text-center">
                {{ dados.totalAtivacao12 }}
              </td>
              <td class="text-center">
                {{ dados.totalMes12 }}
              </td>

              <td class="text-center">
                {{ dados.valor_total }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <v-dialog v-model="editar.dialogo" width="600">
      <v-card>
        <v-card-title class="subtitle-1 primary white--text">
          Editar {{ editar.titulo }}
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="formEditar">
            <v-row no-gutters class="mt-4">
              <v-col
                cols="12"
                class="px-2"
                v-if="editar.tipo.split('_')[0] == 'ativacao'"
              >
                <v-text-field
                  outlined
                  dense
                  :label="editar.titulo"
                  v-model="editar.valor"
                  type="number"
                  clearable="clearable"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                class="px-2"
                v-if="editar.tipo.split('_')[0] == 'desconto'"
              >
                <v-text-field
                  outlined
                  dense
                  :label="editar.titulo"
                  v-model="editar.valor"
                  type="number"
                  clearable="clearable"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="px-2" v-if="editar.tipo == 'obs'">
                <v-text-field
                  outlined
                  dense
                  :label="editar.titulo"
                  v-model="editar.valor"
                  type="text"
                  clearable="clearable"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="pr-4" @click="editar.dialogo = false">
            <v-icon left>mdi-close</v-icon> Fechar
          </v-btn>
          <v-btn color="primary" class="pr-4" @click="editarValor()">
            <v-icon left>mdi-content-save</v-icon> Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogo" width="90%">
      <v-card>
        <v-card-title class="subtitle-1 primary white--text">
          Nova simulação
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form v-model="validaForm">
            <v-row no-gutters class="mt-4">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-autocomplete
                  :items="lista.bandeira"
                  outlined
                  dense
                  label="Bandeira"
                  :rules="RulesBadeira"
                  v-model="simulacao.bandeira"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-autocomplete
                  label="Grupo"
                  v-model="simulacao.codigo_grupo"
                  :items="lista.grupo"
                  item-text="grupo"
                  item-value="codigo_grupo"
                  :rules="regra.grupo"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-autocomplete
                  label="Tipo"
                  v-model="simulacao.id_espaco"
                  :items="lista.espaco"
                  :rules="regra.id_espaco"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-autocomplete
                  label="Micro Espaço"
                  v-model="simulacao.id_precotabloide"
                  :items="Tabloides"
                  item-text="tipo_anuncio"
                  item-value="id_precotabloide"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="6" lg="6" xl="6" class="px-2">
                <v-autocomplete
                  label="Dimensoes"
                  v-model="simulacao.id_precotabloide"
                  :items="Dimensoes"
                  item-text="dimensoes"
                  item-value="id_precotabloide"
                  outlined
                  dense
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-textarea
                  outlined
                  dense
                  label="Observação"
                  v-model="simulacao.obs"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="pr-4" @click="dialogo = false">
            <v-icon left>mdi-close</v-icon> Fechar
          </v-btn>
          <v-btn
            color="primary"
            class="pr-4"
            :disabled="!validaForm"
            @click="criar()"
          >
            <v-icon left>mdi-content-save</v-icon> Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirm.dialogo" width="600">
      <v-card>
        <v-card-title class="subtitle-1 primary white--text">
          {{ confirm.titulo }}
        </v-card-title>
        <v-card-text class="mt-4">
          {{ confirm.texto }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="pr-4" @click="confirm.dialogo = false">
            <v-icon left>mdi-close</v-icon> Fechar
          </v-btn>
          <v-btn :color="confirm.color" class="pr-4" @click="confirm.funcao">
            <v-icon left>mdi-close</v-icon> {{ confirm.botao }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="alerta"
      :color="alertaColor"
      :timeout="alertaTimeout"
      top
      style="z-index: 400"
    >
      <v-alert :type="alertaColor">
        {{ alertaTexto }}
      </v-alert>
      <v-btn dark text class="float-right" @click="alerta = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headersGroup0: [{ text: null, colspan: 8, class: "" }],

      headersGroup: [{ text: null, colspan: 8, class: "" }],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      headers: [
        {
          text: "",
          value: "botao",
          align: "center",
          sortable: false,
          width: 50,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          width: 150,
        },
        {
          text: "Grupo",
          value: "grupo",
          align: "center",
          sortable: false,
          width: 150,
        },
        {
          text: "Master Espaço",
          value: "espaco_macro",
          align: "center",
          sortable: false,
          width: 150,
        },
        {
          text: "Micro Espaço",
          value: "tipo_anuncio",
          align: "center",
          sortable: false,
          width: 150,
        },
        {
          text: "Bandeira",
          value: "bandeira",
          align: "center",
          width: 150,
        },
        {
          text: "Observação",
          value: "obs",
          align: "center",
          width: 150,
        },
        {
          text: "Valor Tablóide",
          value: "valor_tabloide",
          align: "center",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_1",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_1",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "R$",
          value: "valor_1",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_2",
          align: "center",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_2",
          align: "center",
          width: 150,
        },
        { text: "R$", value: "valor_2", align: "center", width: 150 },

        {
          text: "Desconto",
          value: "desconto_3",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_3",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "R$",
          value: "valor_3",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_4",
          align: "center",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_4",
          align: "center",
          width: 150,
        },
        { text: "R$", value: "valor_4", align: "center", width: 150 },

        {
          text: "Desconto",
          value: "desconto_5",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_5",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "R$",
          value: "valor_5",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_6",
          align: "center",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_6",
          align: "center",
          width: 150,
        },
        { text: "R$", value: "valor_6", align: "center", width: 150 },

        {
          text: "Desconto",
          value: "desconto_7",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_7",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "R$",
          value: "valor_7",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_8",
          align: "center",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_8",
          align: "center",
          width: 150,
        },
        { text: "R$", value: "valor_8", align: "center", width: 150 },

        {
          text: "Desconto",
          value: "desconto_9",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_9",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "R$",
          value: "valor_9",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_10",
          align: "center",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_10",
          align: "center",
          width: 150,
        },
        { text: "R$", value: "valor_10", align: "center", width: 150 },

        {
          text: "Desconto",
          value: "desconto_11",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_11",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
        {
          text: "R$",
          value: "valor_11",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },

        {
          text: "Desconto",
          value: "desconto_12",
          align: "center",
          width: 150,
        },
        {
          text: "Ativação",
          value: "ativacao_12",
          align: "center",
          width: 150,
        },
        { text: "R$", value: "valor_12", align: "center", width: 150 },

        {
          text: "Total",
          value: "valor_total",
          align: "center",
          class: "primary darken-1",
          width: 150,
        },
      ],
      dados: [],
      mes: null,
      simulacao: {
        ano: null,
        id_simulacao_tabloide: null,
        id_espaco: null,
        bandeira: null,
        id_fornecedor: null,
        valor_1: 0,
        valor_2: 0,
        valor_3: 0,
        valor_4: 0,
        valor_5: 0,
        valor_6: 0,
        valor_7: 0,
        valor_8: 0,
        valor_9: 0,
        valor_10: 0,
        valor_11: 0,
        valor_12: 0,
      },
      listagem: {
        bandeira: [],
        plano: ["Anual", "Avulso"],
      },
      pagination: {},
      paginationErros: {},
      total: null,
      carregando: false,
      busca: null,
      dialogo: false,
      dialogoEditar: false,
      dialogoImportar: false,
      validaForm: false,
      alerta: false,
      alertaTexto: "",
      alertaColor: "error",
      alertaTimeout: 3000,
      arquivoCSV: null,
      options: {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      lista: {
        macrofornecedor: [],
      },
      tipocargo: null,
      regra: {
        desconto: [
          (v) =>
            (!!v && v >= 0 && v <= 100) ||
            "O desconto deve ser um numero inteiro ente 0 e 100.",
        ],
        csv: [
          (v) => !!v || "Selecione um arquivo.",
          (v) =>
            (!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
            "O arquivo deve ser do tipo CSV.",
        ],
        ano: [(v) => !!v || "O campo ano é obrigatório."],
        id_fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório."],
        grupo: [(v) => !!v || "O campo Grupo é obrigatório."],
        valor: [(v) => !!v || "O campo Valor não pode vazio."],
        id_espaco: [(v) => !!v || "O campo espaço é obrigatório."],
      },
      isCsv: false,
      headerErros: [{ text: "Descrição do Erro", value: "msg", align: "left" }],
      erros: [],
      errosLabels: { msg: "Descrição do Erro" },
      erro: false,
      confirm: {
        dialogo: false,
        titulo: null,
        texto: null,
        funcao: null,
        color: null,
        botao: null,
      },
      obj: {},
      obs: {
        dialogo: false,
        valor: null,
      },
      editar: {
        dialogo: false,
        valor: null,
        titulo: null,
        tipo: "",
      },
      item: {},
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.lista.fornecedor) this.filtrar();
      },
      deep: true,
    },
    "simulacao.ano": {
      handler() {
        //   if(this.simulacao.ano && this.lista.tabloide ){
        //         this.listagem.tabloide = this.lista.tabloide.filter(item => parseInt(item.ano) == this.simulacao.ano)
        //      }
      },
      deep: true,
    },
  },
  async created() {
    this.carregando = true;

    this.simulacao.ano = this.anoProximo;

    var response = await this.$http().post("/simulacao/tabloide/filtros", {
      data: {},
    });

    this.lista = response.data;

    if (this.lista.usuarioTipo == "fornecedor") {
      this.simulacao.id_fornecedor = this.lista.fornecedor[0].id_fornecedor;
      this.simulacao.fornecedor = this.lista.fornecedor[0].fornecedor;

      this.filtrar();
    } else {
      setTimeout(() => {
        this.carregando = false;
      }, 1000);
    }
  },

  computed: {
    anoProximo() {
      return new Date().getFullYear();
    },
    mesProximo() {
      return this.$moment().add(1, "months").format("YYYY-MM");
    },
    cargo() {
      return this.$store.state.auth.cargo;
    },
    RulesBadeira() {
      return [
        (v) => !!v || "O campo Bandeira pelo plano escolhido não pode vazio.",
      ];
    },
    Tabloides() {
      var retorno = [];
      if (this.simulacao.ano && this.lista.tabloide) {
        retorno = this.lista.tabloide.filter(
          (item) => parseInt(item.ano) == this.simulacao.ano
        );
      }
      return retorno;
    },
    Dimensoes() {
      var retorno = [];
      if (this.simulacao.id_precotabloide) {
        retorno = this.Tabloides.filter(
          (item) =>
            parseInt(item.id_precotabloide) == this.simulacao.id_precotabloide
        );
      }
      return retorno;
    },
  },
  methods: {
    row_classes(item) {
    },
    statusAcesso(id_status_simulacao, acesso) {
      if (this.lista.status && id_status_simulacao) {
        var status = this.lista.status.find(
          (e) => e.id_status_simulacao == id_status_simulacao
        );

        if (id_status_simulacao == 2 && this.lista.usuarioTipo == "rd") {
          return false;
        } else if (id_status_simulacao == 3 && this.lista.usuarioTipo == "rd") {
          return false;
        } else {
          return status[acesso];
        }
      } else {
        return false;
      }
    },
    confirmExcluir(item) {
      this.obj = item;

      this.confirm.titulo = "Excluir Linha";
      this.confirm.texto = "Deseja realmente excluir a linha da simulação?";
      this.confirm.color = "error";
      this.confirm.botao = "Excluir";
      this.confirm.funcao = this.excluir;
      this.confirm.dialogo = true;
    },
    async excluir() {
      this.carregando = true;

      this.confirm.dialogo = false;

      var data = this.obj;

      var response = await this.$http().post("/simulacao/tabloide/excluir", {
        data: data,
      });

      this.carregando = false;

      if (response.data.sucesso) {
        this.filtrar();

        this.dialogo = false;
      } else {
        this.erros = response.data.erros;
        this.pagination.page = 1;
        this.erro = true;
      }
    },

    async filtrar() {
      if (!this.$refs.formFiltro.validate()) return;

      this.carregando = true;

      var data = {};

      data.simulacao = this.simulacao;
      data.pagination = this.pagination;

      if (this.simulacao.id_fornecedor) {
        data.id_fornecedor = this.simulacao.id_fornecedor;
      }

      if (this.simulacao.ano) {
        data.ano = this.simulacao.ano;
      }

      var response = await this.$http().post("/simulacao/tabloide/lista", {
        data: data,
      });

      this.dados = response.data;

      this.total = response.data.length ? response.data[0].total : 0;

      for (let i = 1; i <= 12; i++) {
        this.dados["totalMes" + i] = response.data
          .map((x) =>
            parseFloat(
              x["valor_" + i].replace(/[^0-9,]*/g, "").replace(",", ".")
            )
          )
          .reduce((a, b) => a + b, 0);
        this.dados["totalMes" + i] = this.dados["totalMes" + i].toLocaleString(
          "pt-BR",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      for (let i = 1; i <= 12; i++) {
        this.dados["totalDesconto" + i] = response.data
          .map((x) => parseFloat(x["desconto_" + i]))
          .reduce((a, b) => a + b, 0);
      }

      for (let i = 1; i <= 12; i++) {
        this.dados["totalAtivacao" + i] = response.data
          .map((x) => parseInt(x["ativacao_" + i]))
          .reduce((a, b) => a + b, 0);
      }

      const valor_total = response.data
        .map((x) =>
          parseFloat(x.valor_total.replace(/[^0-9,]*/g, "").replace(",", "."))
        )
        .reduce((a, b) => a + b, 0);
      this.dados.valor_total = valor_total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });

      this.dados.valor_tabloide_total = response.data
        .map((x) =>
          parseFloat(
            x.valor_tabloide.replace(/[^0-9,]*/g, "").replace(",", ".")
          )
        )
        .reduce((a, b) => a + b, 0)
        .toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });

      if (this.total == 0 && this.pagination.page > 1) {
        this.pagination.page = 1;
        this.filtrar();
      }

      this.carregando = false;
    },
    async exportar() {
      if (!this.$refs.formFiltro.validate()) return;

      this.carregando = true;

      var data = {};

      data.simulacao = this.simulacao;

      var response = await this.$http().post("/simulacao/tabloide/exportar", {
        data: data,
      });

      window.location = this.$http("baseURL") + response.data.url;

      this.carregando = false;
    },
    async novo() {
      if (!this.$refs.formFiltro.validate()) return;

      this.carregando = true;

      var data = {
        id_fornecedor: this.simulacao.id_fornecedor,
        ano: this.simulacao.ano,
        usuarioTipo: this.lista.usuarioTipo,
      };
      // var lista = (
      //   await this.$http().post("/simulacao/aprovacao/bloquearAcessos", {
      //     data: data,
      //   })
      // ).data;

      // if (
      //   lista.campanha.length > 0 ||
      //   lista.encommerce.length > 0 ||
      //   lista.pdv.length > 0 ||
      //   lista.tabloide.length > 0
      // ) {
      //   this.alerta = true;
      //   this.alertaColor = "error";
      //   this.alertaTimeout = -1;
      //   this.alertaTexto =
      //     "Existe simulações aprovadas pelo fornecedor para este ano";
      // } else {
      //   this.dialogo = true;
      // }
      this.dialogo = true;
      this.carregando = false;
    },
    async criar() {
      this.carregando = true;

      var response = await this.$http().post("/simulacao/tabloide/criar", {
        data: this.simulacao,
      });

      this.carregando = false;

      if (response.data.sucesso) {
        this.filtrar();

        (this.simulacao = {
          ...this.simulacao,
          bandeira: null,
          codigo_grupo: null,
          id_precotabloide: null,
          id_espaco: null,
          obs: null,
        }),
          (this.dialogo = false);
      } else {
        this.alertaTexto = response.data.msg;
        this.alerta = true;
        this.pagination.page = 1;
        this.erro = true;
      }
    },
    abrirEditar(item) {
      this.simulacao = { ...item };
      this.dialogoEditar = true;
    },

    editarTipo(item, tipo, titulo) {
      this.editar.dialogo = true;

      setTimeout(() => {
        this.editar.tipo = tipo;
        this.editar.titulo = titulo;

        setTimeout(() => {
          switch (this.editar.tipo.split("_")[0]) {
            case "valor":
              this.editar.valor = parseFloat(
                item[tipo].replace(/[^0-9,]*/g, "").replace(",", ".")
              );
              break;
            case "desconto":
              this.editar.valor = parseFloat(item[tipo]);
              break;
            case "ativacao":
              this.editar.valor = parseFloat(item[tipo]);
              break;

            default:
              this.editar.valor = item[tipo];
          }
        }, 100);
      }, 100);

      this.item = { ...item };
    },

    async editarValor() {
      this.carregando = true;

      var data = {
        id_simulacao_tabloide: this.item.id_simulacao_tabloide,
        tipo: this.editar.tipo,
        obj: {},
      };
      switch (this.editar.tipo.split("_")[0]) {
        case "valor":
          data.obj[this.editar.tipo] = parseFloat(this.editar.valor);
          break;
        case "desconto":
          data.obj[this.editar.tipo] = parseFloat(this.editar.valor);
          break;
        case "ativacao":
          data.obj[this.editar.tipo] = parseFloat(this.editar.valor);
          break;

        default:
          data.obj[this.editar.tipo] = this.editar.valor;
      }

      var response = await this.$http().post("/simulacao/tabloide/editar", {
        data: data,
      });

      this.carregando = false;

      if (response.data.sucesso) {
        (this.simulacao = {
          ...this.simulacao,
          bandeira: null,
          valor_1: 0,
          valor_2: 0,
          valor_3: 0,
          valor_4: 0,
          valor_5: 0,
          valor_6: 0,
          valor_7: 0,
          valor_8: 0,
          valor_9: 0,
          valor_10: 0,
          valor_11: 0,
          valor_12: 0,
          desconto_1: 0,
          desconto_2: 0,
          desconto_3: 0,
          desconto_4: 0,
          desconto_5: 0,
          desconto_6: 0,
          desconto_7: 0,
          desconto_8: 0,
          desconto_9: 0,
          desconto_10: 0,
          desconto_11: 0,
          desconto_12: 0,
          ativacao_1: 0,
          ativacao_2: 0,
          ativacao_3: 0,
          ativacao_4: 0,
          ativacao_5: 0,
          ativacao_6: 0,
          ativacao_7: 0,
          ativacao_8: 0,
          ativacao_9: 0,
          ativacao_10: 0,
          ativacao_11: 0,
          ativacao_12: 0,
        }),
          this.filtrar();
        this.editar.dialogo = false;
      } else {
        this.alerta = true;
        this.alertaColor = "error";
        this.alertaTimeout = -1;
        this.alertaTexto = response.data.msg;
      }
    },
  },
};
</script>
<style>
.fix-column tbody tr td:nth-child(-n + 2),
.fix-column thead tr th:nth-child(-n + 2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  /* z-index: 9998; */
}

.fix-column tbody tr td:nth-child(-n + 2) {
  background-color: #fff;
}

.fix-column thead tr th:nth-child(-n + 2) {
  background-color: var(--v-primary-base);
}

.fix-column tbody tr td:nth-child(1),
.fix-column thead tr th:nth-child(1) {
  left: 0;
}

.fix-column tbody tr td:nth-child(2),
.fix-column thead tr th:nth-child(2) {
  left: 50px;
}

.fix-column thead tr th:not(:nth-child(-n + 2)) {
  border-left: 1px solid;
  border-image: linear-gradient(
    to bottom,
    transparent 25%,
    grey 25%,
    grey 50%,
    grey 50%,
    grey 75%,
    transparent 75%
  );
  border-image-slice: 1;
}

.edit-style {
  background-color: #ccc;
  cursor: pointer;
  width: 90%;
  text-decoration: underline;
  padding: 5px;
  border-radius: 10px;
}
</style>
