<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2">Preços PDV</v-toolbar-title>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					@click="exportar('exportarHistorico')"
				>
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar Histórico
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					@click="exportar('exportarHistImportacoes')"
				>
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar <br />
					Edições / Importacões
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					@click="abrirImportar()"
				>
					<v-icon left>mdi-upload</v-icon> Importar
				</v-btn>
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					<v-text-field
						v-model="busca"
						append-icon="mdi-magnify"
						label="Pesquisar"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						<v-text-field
							label="Mês"
							v-model="mes"
							:rules="regra.mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-icon
						v-if="item.mes >= mesProximo"
						small
						class="mr-2"
						color="primary"
						@click="abrirEditar(item)"
						title="Editar"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog v-model="dialogo" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Editar
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="ID Espaço"
									v-model="custo.id_espaco"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Código Espaço"
									v-model="custo.codigo_espaco"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Espaço"
									v-model="custo.espaco"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="ID Cluster"
									v-model="custo.id_cluster"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Código Cluster"
									v-model="custo.codigo_cluster"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Cluster"
									v-model="custo.cluster"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Mês"
									v-model="custo.mes_nome"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<vuetify-money
									outlined
									dense
									label="Custo Unitário"
									v-model="custo.custo_unitario"
									:options="options"
									clearable="clearable"
								></vuetify-money>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<vuetify-money
									outlined
									dense
									label="Custo Especial"
									v-model="custo.custo_especial"
									:options="options"
									clearable="clearable"
								></vuetify-money>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						:disabled="!validaForm"
						@click="editar()"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV
				</v-card-title>
				<v-card-text>
					<v-row no-gutters>
						<v-col
							cols="12"
							sm="12"
							md="3"
							lg="3"
							xl="3"
							class="mb-0 mt-2"
						>
							<v-text-field
								label="Mês"
								v-model="mes_importacao"
								required
								outlined
								dense
								:min="mesProximo"
								type="month"
							></v-text-field>
						</v-col>
					</v-row>
					<v-alert
						class="ma-1"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								A base utilizada para importação deve ser
								parecida com base exportada.
							</li>
							<li>
								As colunas
								<strong>codigo_espaco</strong> (Código Espaço),
								<strong>codigo_cluster</strong> (Código
								Cluster), <strong>custo_unitario</strong> (Custo
								Unitário),
								<strong>custo_especial</strong> (Custo Especial)
								são obrigatórias (<strong
									>e os nomes devem ser identicos aos da base
									exportada</strong
								>), as demais colunas podem ser excluidas.
							</li>
							<li>
								As colunas
								<b>custo_unitario e custo_especial</b> devem
								estar no formato moeda "<b>R$ 0.000,00</b>" com
								virgula "," como separador decimal e ponto "."
								como separador de milhar.
							</li>
							<li>
								A coluna <strong>mes</strong> não é considerada
								na importação.
							</li>
							<li>
								O <strong>mês</strong> considerado na importação
								é o mês selecionano no
								<strong>filtro da lista de Preços PDV</strong>.
							</li>
						</ul>
					</v-alert>
					<v-row>
						<v-col cols="8" class="px-2">
							<v-form ref="form" v-model="isCsv">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="4" class="px-2">
							<v-btn
								color="primary"
								:disabled="
									!isCsv || mes_importacao < mesProximo
								"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Erros
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{ text: "ID Espaço", value: "id_espaco", align: "center" },
				{
					text: "Código Espaço",
					value: "codigo_espaco",
					align: "center",
				},
				{ text: "Espaço", value: "espaco", align: "center" },
				{ text: "ID Cluster", value: "id_cluster", align: "center" },
				{
					text: "Código Cluster",
					value: "codigo_cluster",
					align: "center",
				},
				{ text: "Cluster", value: "cluster", align: "center" },
				{ text: "Mês", value: "mes_nome", align: "center" },
				{
					text: "Custo Unitário",
					value: "custo_unitario_nome",
					align: "center",
				},
				{
					text: "Custo Especial",
					value: "custo_especial_nome",
					align: "center",
				},
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			dados: [],
			mes: null,
			mes_importacao: null,
			custo: {
				id_espaco: null,
				codigo_espaco: null,
				espaco: null,
				id_cluster: null,
				codigo_cluster: null,
				cluster: null,
				mes_nome: null,
				custo_unitario: null,
				custo_especial: null,
			},
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			lista: {
				macrofornecedor: [],
			},
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				mes: [(v) => !!v || "O campo mês é obrigatório."],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
			options: {
				locale: "pt-BR",
				prefix: "R$",
				suffix: "",
				length: 11,
				precision: 2,
			},
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	async created() {
		this.mes = this.mesProximo;
		this.mes_importacao = this.mesProximo;
	},
	computed: {
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
	},
	methods: {
		async filtrar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			data.pagination = this.pagination;
			data.busca = this.busca;

			var response = await this.$http().post("/custo/lista", {
				data: data,
			});

			this.dados = response.data;
			this.total = response.data.length ? response.data[0].total : 0;

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
		async exportar(rotaApi = "exportar") {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var response = await this.$http().post("/custo/" + rotaApi, {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		abrirEditar(item) {
			this.custo = { ...item };

			this.dialogo = true;
		},
		async editar() {
			this.carregando = true;

			var data = this.custo;

			var response = await this.$http().post("/custo/editar", {
				data: data,
			});

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();

				this.dialogo = false;
			} else {
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}
		},
		abrirImportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.arquivoCSV = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.mes_importacao =
				this.mes < this.mesProximo ? this.mesProximo : this.mes;
			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;

			var data = { mes: this.mes_importacao + "-01" };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post("/custo/importar", formData);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogoImportar = false;
			} else {
				this.filtrar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
	},
};
</script>
