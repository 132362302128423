<template>
  <v-container fluid>
    <v-toolbar-title>Logs</v-toolbar-title>
    
    <v-row>
      <v-col cols="3" class="">
        Log de solicitações de guias
      </v-col>
      <v-col cols="3" class="">
        <v-text-field
          label="Período Inicial"
          v-model="filtro.mesInicial"
          :rules="regra.mesInicial"
          :min="'2022-07'"
          required
          outlined
          dense
          type="month"
        ></v-text-field>        
      </v-col>
      <v-col cols="3" class="">
        <v-text-field
          label="Período Final"
          v-model="filtro.mesFinal"
          :rules="regra.mesFinal"
          :min="'2022-07'"
          required
          outlined
          dense
          type="month"
        ></v-text-field>
      </v-col>
      <v-col cols="3" class="">
        <v-btn color="primary" @click="exportarLogGuia()">
          <v-icon left>mdi-microsoft-excel</v-icon>
          Exportar log
        </v-btn>
      </v-col>
      <!-- <v-btn
					class="ml-2 mb-1"
					color="primary"
					@click="abrirImportacao()"
				>
					<v-icon left>mdi-upload</v-icon> Importar justificativas
				</v-btn> -->
    </v-row>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      carregando: false,
      filtro: {},
      regra: {
        mesInicial: [
          (v) => !!v || "Selecione um período inicial para o filtro",
          (v) =>
            (!!v && v >= "2021-06") ||
            "O período inicial deve ser igual ou posterior a junho de 2021",
        ],
        mesFinal: [
          (v) => !!v || "Selecione um período final para o filtro",
          (v) =>
            (!!v && v >= "2021-06") ||
            "O período final deve ser igual ou posterior a junho de 2021",
          (v) =>
            (!!v && v >= this.filtro.mesInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
      },
    };
  },  
  methods: {
    async exportarLogGuia() {
      this.carregando = true;
			var data = {};

			data.mesInicial = this.filtro.mesInicial;
			data.mesFinal = this.filtro.mesFinal;

      var response = await this.$http().post("/app/logs/exportarLogUsoGuia", {
				data: data,
			});

      window.location = this.$http("baseURL") + response.data.url;

      this.carregando = false;
    },
  },
};
</script>

<style>
</style>