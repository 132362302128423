<template>
    <v-container fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row class="">
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Pesquisas</v-toolbar-title
                    >
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        class="input-button input-cinza"
                        label="Pesquisar"
                        v-model="busca"
                        outlined
                        dense
                        hide-details
                    >
                        <template>
                            <v-icon slot="append" color="white"
                                >mdi-magnify</v-icon
                            >
                        </template>
                    </v-text-field>
                </v-col>
 
                <div class="d-flex py-3 px-4">
                    <v-btn
                        color="primary"
                        class="new-button mr-3"
                        @click="$refs.formulario.adicionar()"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Adicionar
                    </v-btn>
                </div>
            </v-row>
        </v-card>
        <Formulario ref="formulario" @filtrar="filtrar"></Formulario>

        <v-card class="pa-5 my-5 br-15 w-100" elevation="0">
            <v-data-table
                :headers="headers"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }"
                :search="busca"
                :items="pesquisas"
                class="elevation-0 tabela"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu
                        offset-y
                        min-width="180"
                        content-class=" acordo-menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                color="#f2f2f2"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                                style="min-width: unset"
                            >
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.exibir(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exibir pesquisa</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.replicar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-autorenew</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Replicar
                                            pesquisa</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="dialogoEditarPeriodo(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar período</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item link @click="exportar(item)">
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-microsoft-excel</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exportar
                                            respostas</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item link @click="exportarComImagem(item)">
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-microsoft-powerpoint</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exportar
                                            respostas (powerpoint)</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog content-class="br-15" v-model="dialog" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Editar período </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialog = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-row>
                        <v-col cols="6" class="pb-0">
                            <DataInput
                                v-model="novoPeriodo.data_inicio"
                                :min="new Date().toISOString().substring(0, 10)"
                                type="date"
                                label="Data de Início"
                                outlined
                                dense
                            ></DataInput>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                            <DataInput
                                v-model="novoPeriodo.data_fim"
                                type="date"
                                label="Data final"
                                :min="novoPeriodo.data_inicio"
                                outlined
                                dense
                            ></DataInput>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button px-6 mb-3 mr-2"
                        @click="editarPeriodo()"
                    >
                        Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogPPT" width="80%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Exportando PPTX
				</v-card-title>
				<v-row no-gutters>
					<v-col cols="12" class="px-8 pt-8 pb-2">
						Serão exportados arquivos com ate 100 respostas cada.
						<br />
						Total de respostas: {{ filiais }}
						<br />
						Total de arquivos: {{ Math.ceil(filiais / 100) }}
						<br />
						Escrevendo arquivo {{ arquivo }}.
					</v-col>
					<v-col cols="12" class="px-8 pb-8">
						<v-progress-linear v-model="index" height="25">
							<span class="font-weight-bold white--text"
								>{{ Math.ceil(index) }}%</span
							>
						</v-progress-linear>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
    </v-container>
</template>
<script>
import Formulario from "./Formulario.vue";
export default {
    components: {
        Formulario,
    },

    data() {
        return {
            app: true,
            pesquisas: [],
            arquivo: 0,
            dialogPPT: false,
            index: 0,
            filiais: 0,
            carregando: false,
            dialog: false,
            novoPeriodo: {
                id_pesquisa: null,
                data_inicio: null,
                data_fim: null,
            },
            busca: "",
            headers: [
                {
                    text: "",
                    value: "actions",
                    class: "th-separador",
                    width: "50px",
                },
                { text: "Título", value: "titulo", class: "th-separador" },
                {
                    text: "Filiais",
                    value: "filiais",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                {
                    text: "Repostas",
                    value: "respostas",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                {
                    text: "Perguntas",
                    value: "perguntas",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },

                {
                    text: "Data inicio",
                    value: "data_inicio",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                {
                    text: "Data fim",
                    value: "data_fim",
                    class: "th-separador",
                    align: "center",
                    width: "100px",
                },
                // { text: 'Ações', value: 'actions', sortable: false },
            ],
        };
    },
    created() {
        this.filtrar();
    },
    methods: {
        dialogoEditarPeriodo(pesquisa) {
            this.dialog = true;
            this.novoPeriodo.id_pesquisa = pesquisa.id_pesquisa;
            this.novoPeriodo.data_inicio = this.$moment(
                pesquisa.data_inicio,
                "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
            this.novoPeriodo.data_fim = this.$moment(
                pesquisa.data_fim,
                "DD/MM/YYYY"
            ).format("YYYY-MM-DD");
        },
        async filtrar() {
            try {
                const { data } = await this.$http().post(
                    "/app/pesquisas/lista"
                );

                this.pesquisas = data.map((pesquisa) => {
                    pesquisa.data_inicio = this.$moment(
                        pesquisa.data_inicio
                    ).format("DD/MM/YYYY");
                    pesquisa.data_fim = this.$moment(pesquisa.data_fim).format(
                        "DD/MM/YYYY"
                    );
                    return pesquisa;
                });
            } catch (error) {
                console.log(error);
            }
        },
        async editarPeriodo() {
            this.carregando = true;

            const data = {
                id_pesquisa: this.novoPeriodo.id_pesquisa,
                data_inicio: this.novoPeriodo.data_inicio,
                data_fim: this.novoPeriodo.data_fim,
            };

            const response = await this.$http().post(
                "/app/pesquisas/editarPeriodo",
                data
            );

            if (response.status == 200 && response.data.success == true) {
                this.$dialog.notify.success("Período editado com sucesso", {
                    position: "top-right",
                    timeout: 5000,
                });
                this.dialog = false;
                this.filtrar();
            } else {
                const mensagem =
                    response.data.mensagem || "Falha ao editar período";
                this.$dialog.notify.error(mensagem, {
                    position: "top-right",
                    timeout: 5000,
                });
            }
            this.carregando = false;
        },
        async exportar(pesquisa) {
            this.carregando = true;
            try {
                const { data } = await this.$http().post(
                    "/app/pesquisas/exportar",
                    {
                        idPesquisa: pesquisa.id_pesquisa,
                    }
                );
                if (data.url) {
                    window.location = this.$http("baseURL") + data.url;
                }
                console.log(data);
            } catch (error) {
                this.$dialog.notify.error("Falha ao exportar respostas", {
                    position: "top-right",
                    timeout: 5000,
                    width: "",
                    style: `width: ${window.innerWidth * 0.8}px`,
                });
            }
            this.carregando = false;
        },
        loadImage(path) {
            return new Promise((resolve, reject) => {
                const img = new Image()
                img.crossOrigin = 'anonymous' // to avoid CORS if used with Canvas
                img.src = path 
                img.addEventListener("load", () => {
                    console.log(img)
                resolve(img)
                })
                img.addEventListener("error", (e) => {
                    console.log(e)
                reject(e)
                })
            })
        },
        async exportarComImagem(pesquisa) {
            this.filiais = 0
            this.arquivo = 1
            this.index = 0
            this.carregando = true;
            this.dialogPPT = true;
            
            const { data } = await this.$http().post(
                "/app/pesquisas/exportarCompleto",
                {
                    idPesquisa: pesquisa.id_pesquisa,
                }
            );

            const respostasComImagem = data.respostas.filter(resposta => resposta.imagem_resposta) 
            
            respostasComImagem.map(resposta => {
                for(var pergunta of data.perguntas) {
                    if (resposta.id_pergunta == pergunta.id_pergunta) {
                        resposta.pergunta = pergunta
                    }
                }
            })
            
            var respostaContainers = _.chunk(respostasComImagem,100)

        //     console.log(respostasComImagem)

        //     this.carregando = false;
        //     this.dialogPPT = false;
        
        // },
        // async espere () {




            // var filiaisRespostas = [...new Set(data.respostas.map(item => item.id_filial))].map(item => {
            //     for (const resposta of data.respostas) {
            //         if (resposta.id_filial == item) {
            //             item = {id_filial:item,nome:resposta.nome,id_java:resposta.id_java}
            //         }
            //     }
            //     return item
            // });

            // const validasResposta = data.respostas.filter(resposta => _.find(data.imagensRespostas,{id_resposta:resposta.id_resposta}))
            // data.respostas = validasResposta
            // const validasFiliais = filiaisRespostas.filter(filialResposta => _.find(validasResposta,{id_filial:filialResposta.id_filial}))

            // const chunkFiliais = _.chunk(validasFiliais,100)

            var pptx = [];

            var hora = this.$moment().format("DD-MM-YYYY_hh-mm-ss");

            // for (let i = 1; i <= Math.ceil(filiais.length / 100); i++) {
            
            // }

        //     console.log({validasResposta,validasFiliais,chunkFiliais})

        //     this.carregando = false;
        //     this.dialogPPT = false;

        // },
        // async espere () {    

            const genTitle = (id_java,nome,titulo, slide) => {

                slide.addText("", {
                    x: 1.3,
                    y: 0,
                    w: 8.7,
                    h: 0.7,
                    fill: "#00774D",
                });
                
                let texto = "";
                texto += "Raia Drogasil";
                texto += "\n";
                texto += id_java + " - " + nome;
                texto += "\n";
                texto += titulo;

                slide.addText(texto, {
                    x: 1.5,
                    y: 0,
                    w: 5,
                    h: 0.7,
                    color: "#ffffff",
                    fontSize: 9,
                    fill: "#00774D",
                    bold: true,
                });

                slide.addImage({
                    path: require("@/assets/images/login/logo_branco.png"),
                    x: 9,
                    y: 0,
                    w: 0.7,
                    h: 0.7,
                });

                slide.addImage({
                    path: require("@/assets/images/login/logo_trade_quadrado.png"),
                    x: 0.3,
                    y: 0,
                    w: 0.7,
                    h: 0.7,
                });

                return slide
            }

            const genSlide = async (i,respostas) => {

                let resposta = respostas[i]

                // console.log(i,filiais,data.respostas)

                // let respostas = data.respostas.filter(item => ((item.id_filial == filial.id_filial) && (item.id_pergunta == pergunta.id_pergunta))) 

                // resposta = resposta ? resposta[0] : null
                // console.log(respostas)
                // if (respostas.length == 0) return false

                
                this.index = Math.ceil(100 * (i / respostas.length))

                let slide = pptx[this.arquivo].pptx.addSlide();

                slide = genTitle(resposta.id_java,resposta.nome,data.pesquisa.titulo, slide)

                // pergunta
                let altura = 0;
                let comprimento = 3.5;


                    if (resposta.pergunta.imagem) {
    
                        altura = 2.5;
    
                        slide.addImage({
                            path: this.$arquivoProtegido( resposta.pergunta.imagem),
                            x: 0.2,
                            y: 0.8,
                            w: comprimento,
                            h: altura,
                            sizing: { type: "contain", w: 3.5, h: 2.5  } 
                        });
    
                    }
    
                    let perguntaTitulo = resposta.pergunta.titulo + (resposta.pergunta.obrigatoria ? ' *' : '')
    
                    slide.addText(perguntaTitulo, {
                        x: 0.2,
                        y: 0.8 + altura,
                        w: comprimento,
                        h: 0.8,
                        color: "#000000",
                        fontSize: 9,
                        bold: true,
                    });


                //reposta 
                // console.log('respostas',respostas)
                // if (respostas) {
                    if (resposta.pergunta.tipo_pergunta == 'texto') {
                        // let imagem = data.imagensRespostas.filter(item => {
                        //     return item.id_resposta == respostas[0].id_resposta
                        // })
                        // if (imagem.length > 0) {
                            slide.addText('Resposta da farmácia', {
                                x: 4,
                                y: 0.8,
                                w: 5.7,
                                h: 0.3,
                                color: "#ffffff",
                                fill: "#00774D",
                                fontSize: 9,
                                bold: true,
                            });
                            // console.log(respostas,respostas[0].texto)
                            slide.addText(respostas[0].texto == undefined ? '' : respostas[0].texto, {
                                x: 4,
                                y: 1.1,
                                w: 5.7,
                                h: 0.5,
                                color: "#000000",
                                fill: "#7cbba5",
                                fontSize: 9,
                                bold: true,
                            });

                            // console.log(data.imagensRespostas,respostas[0])
                            
                            // console.log(imagem)
                            
                            slide.addText('Imagem resposta da farmácia', {
                                x: 4,
                                y: 2.7,
                                w: 5.7,
                                h: 0.3,
                                color: "#ffffff",
                                fill: "#00774D",
                                fontSize: 9,
                                bold: true,
                            });
                            slide.addImage({
                                path:this.$arquivoProtegido(resposta.imagem_resposta),
                                x: 4,
                                y: 3,
                                w: 5.7,
                                h: 4.5,
                                sizing: { type: "contain", w: 5.5, h: 2.5 } 
                            });
                        // }

                    } 
                    else  {
                        // let imagem = data.imagensRespostas.filter(item => {
                        //     return item.id_resposta == respostas[0].id_resposta
                        // })
                       

                            slide.addText('Resposta da farmácia', {
                                x: 4,
                                y: 0.8,
                                w: 5.7,
                                h: 0.3,
                                color: "#ffffff",
                                fill: "#00774D",
                                fontSize: 9,
                                bold: true,
                            });

                            let y = 1.1
                            let ind = 0
                            // for (var resposta of respostas) {
                                // let opcao = data.perguntasOpcoes.filter(item => {
                                //     return item.id_opcao == resposta.id_opcao
                                // })
                                // // console.log(opcao)
                                // slide.addText(opcao[0].titulo, {
                                //     x: 4,
                                //     y: y,
                                //     w: 5.7,
                                //     h: 0.2,
                                //     color: "#000000",
                                //     fill: ind % 2 ? "#7cbba5" : "#bcd7cd",
                                //     fontSize: 9,
                                //     bold: true,
                                // });
                                // ind++
                                // y = y + 0.2
                            // }

                            // // console.log(data.imagensRespostas,respostas[0])
                            // let imagem = data.imagensRespostas.filter(item => {
                            //     return item.id_resposta == respostas[0].id_resposta
                            // })
                            // // console.log(imagem)
                            // if (imagem.length > 0) {
                            slide.addText('Imagem resposta da farmácia', {
                                x: 4,
                                y: 2.7,
                                w: 5.7,
                                h: 0.3,
                                color: "#ffffff",
                                fill: "#00774D",
                                fontSize: 9,
                                bold: true,
                            });
                            slide.addImage({
                                path: this.$arquivoProtegido( resposta.imagem_resposta),
                                x: 4,
                                y: 3,
                                w: 5.7,
                                h: 4.5,
                                sizing: { type: "contain", w: 5.5, h: 2.5 } 
                            });
                        


    
                    } 
                // }
       

            }

            for (var respostas of respostaContainers) {

                pptx[this.arquivo] = {};
                pptx[this.arquivo].nome = `Pesquisas_Respostas_${hora}_arquivo_${this.arquivo}.pptx`;
                pptx[this.arquivo].pptx = new this.$pptxgen();
                pptx[this.arquivo].pptx.author = "IPDV";
                pptx[this.arquivo].pptx.company = "Raia Drogasil";
                pptx[this.arquivo].pptx.subject = "Pesquisas";
                pptx[this.arquivo].pptx.title = "Fotos Pesquisas";

                console.log('iniciando',this.perguntas)

                for (let i = 0; i < respostas.length; i++) {
                    // for (let p = 0; p < data.perguntas.length; p++) {

                    // console.log('filial -> ' , i)


                        // console.log('pergunta -> ' , p)

                        genSlide(i,respostas)

                        
                    // }  
                    
                }    
                
                await pptx[this.arquivo].pptx
                    .writeFile({ fileName: pptx[this.arquivo].nome })
                    .then((data) => {

                        pptx[this.arquivo] = null;

                        console.log('fechou arquivo')

                       

                        
                    })
                    .catch((err) => {
                        console.error(err);

                        this.carregando = false;
                        this.dialogPPT = false;
                    });

                this.arquivo++;    

            } 
            
            this.carregando = false;
            this.dialogPPT = false;

            if (chunkFiliais.length == 0) {
                this.alerta = true;
                this.alertaColor = "info";
                this.alertaTimeout = 3000;
                this.alertaTexto = "Nenhum voto encontrado";
            }

        }
    }
};
</script>
