<template>
	<div class="indicador">
		<div class="indicador-icon">
			<!-- <v-icon>mdi-percent-circle-outline</v-icon> -->
			<v-icon size="36">{{ icone }}</v-icon>
		</div>
		<div class="indicador-text">
			<span>{{ titulo }} </span>
		</div>
		<div class="indicador-separador"></div>
		<div class="indicador-value">
			<span>{{ valor }}</span>
		</div>
		<v-overlay :absolute="true" :value="load">
			<v-progress-circular indeterminate size="40" />
		</v-overlay>
	</div>
</template>

<script>
export default {
	props: ["titulo", "valor", "icone", "load"],

	methods: {
		formatarValor(valor) {
			return Number(valor).toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL",
			});
		},
	},
};
</script>

<style scoped>
.indicador {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.5rem;
	border-bottom: 1px solid #e0e0e0;
	background: #fff;
	border-radius: 15px;
	position: relative;
	overflow: hidden;
}
.indicador::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	background: #eaeaea;
	transition: all 0.4s;
}
.indicador:hover::before {
	background: var(--v-primary-base);
}
.indicador-icon {
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	position: relative;
	padding: 8px;
	margin-top: 6px;
	box-shadow: 0 0 10px #0002;
}
.indicador-text {
	font-weight: 500;
	color: #000;
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 0.9rem;
}
.indicador-separador {
	width: 25px;
	height: 1px;
	background: #e0e0e0;
	margin-top: 5px;
}
.indicador-value {
	font-size: 1rem;
	font-weight: 500;
	color: var(--v-primary-base);
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>
