<template>
  <div class="indicadores">
    <v-row>

      <v-col md="4" @click="alterarStatus(1)">
        <Indicador
          titulo="Aguardando Aprovação Trade"
          :valor="indicadores[1]"
          icone="$alertCircleCheckOutline"
          :class="{
            active: status.includes(1),
          }"
        />
      </v-col>
      <v-col md="4" @click="alterarStatus(29)">
        <Indicador
          titulo="Espaço Reservado"
          :valor="indicadores[29]"
          icone="$circleSlice"
          :class="{
            active: status.includes(29),
          }"
        />
      </v-col>
      <v-col md="4" @click="alterarStatus(15)">
        <Indicador
          titulo="Aguardando Gestão de Contratos"
          :valor="indicadores[15]"
          icone="$documentAlertOutline"
          :class="{
            active: status.includes(15),
          }"
        />
      </v-col>
      <v-col md="4" @click="alterarStatus(16)">
        <Indicador
          titulo="Contratos Emitidos"
          :valor="indicadores[16]"
          icone="$documentCheck"
          :class="{
            active: status.includes(16),
          }"
        />
      </v-col>
      <v-col md="4" @click="alterarStatus(28)">
        <Indicador
          titulo="Acordos Cancelados"
          :valor="indicadores[28]"
          icone="$documentRemove"
          :class="{
            active: status.includes(28),
          }"
        />
      </v-col>
      <v-col md="4" @click="alterarStatus(2)">
        <Indicador
          titulo="Acordos Reprovados"
          :valor="indicadores[2]"
          icone="$documentMinusOutline"
          :class="{
            active: status.includes(2),
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Indicador from "../_components/Indicador.vue";

export default {
  components: {
    Indicador,
  },
  props: ["indicadores", "filtro"],
  computed: {
    status: function () {
      return this.filtro?.filtro?.id_status || [];
    },
  },
  methods: {
    alterarStatus: function (status) {
      if (this.status.includes(status)) {
        this.filtro.filtro.id_status = this.status.filter((s) => s != status);
      } else {
        this.filtro.filtro.id_status = [status];
      }
      this.filtro.filtrar();
    },
  },
};
</script>
