<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2"
					>Bases SFTP</v-toolbar-title
				>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						
						 <v-text-field
						 no-title
							label="Dia"
							v-model="diaPesquisa"
							required
							outlined
							dense
							type="date"
						></v-text-field> 
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				item-key="id_ponta_tematica"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-btn icon :href="linkProtegido(item.s3Link)" target="_blank">
						<v-icon
							small
							class="mr-2"
							color="primary"
							title="Baixar CSV"
						>
							mdi-download
						</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog v-model="dialogo" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Editar Ponta Temática
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						:disabled="!validaForm"
						@click="editar()"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import moment from 'moment'
export default {
	data() {
		return {
			headers: [
				{ text: "DATA CRIAÇÃO", value: "data_criacao", align: "center" },
				{ text: "ARQUIVO", value: "arquivo", align: "left" },
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			dados: [],
			diaPesquisa: moment().format('YYYY-MM-DD'),
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.diaPesquisa) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	methods: {
		linkProtegido(url){
			return this.$arquivoProtegido(url)
		},
		async filtrar() {
			this.carregando = true;

			var data = {pagination: this.pagination};
			data.diaPesquisa = this.diaPesquisa;

			var response = await this.$http().post("/api/basessftplogs", {
				data: data
			});

			this.dados = response.data;
			this.total = response.data.length ? response.data[0].total : 0;

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
	},
};
</script>
