import percentageCircle from "../assets/icons/percentage-circle-icon.vue";
import expandIcon from "../assets/icons/expand-icon.vue";
import tagDiscount from "../assets/icons/tag-discount.vue";
import moneyCircle from "../assets/icons/money-circle.vue";
import alertCircleCheckOutline from "../assets/icons/alert-circle-check-outline.vue";
import circleSlice from '../assets/icons/circle-slice.vue';
import documentAlertOutline from '../assets/icons/document-alert-outline.vue';
import documentCheck from '../assets/icons/document-check.vue';
import documentMinusOutline from '../assets/icons/document-minus-outline.vue';
import documentRemove from '../assets/icons/document-remove.vue';
import checkboxLocked from '../assets/icons/checkbox-locked.vue';

const icons = {
  percentageCircle: {
    component: percentageCircle,
  },
  expandIcon:{
    component: expandIcon,
  },
  tagDiscount: {
    component: tagDiscount,
  },
  moneyCircle: {
    component: moneyCircle,
  },
  alertCircleCheckOutline: {
    component: alertCircleCheckOutline
  },
  circleSlice: {
    component: circleSlice
  },
  documentAlertOutline: {
    component: documentAlertOutline
  },
  documentCheck: {
    component: documentCheck
  },
  documentMinusOutline: {
    component: documentMinusOutline
  },
  documentRemove: {
    component: documentRemove
  },
  checkboxLocked: {
    component: checkboxLocked
  }
};

export default icons;
