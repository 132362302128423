

<template>
  <div class="mes-box-card" v-bind:class="{ active: active }">
    <div class="title mb-2 d-flex">
      {{ mes }}
      <v-icon v-if="icon === 'verao'" size="14" class="mx-2" color="orange"
        >mdi-white-balance-sunny</v-icon
      >
      <v-icon v-if="icon === 'inverno'" color="blue" size="14" class="mx-2" 
        >mdi-snowflake</v-icon
      >
    </div>
    <v-autocomplete
      outlined
      dense
      item-text="Cota"
      item-value="aaa"
      class="input-cinza pt-3"
      label="Cota"
      :value="active && value[mes].cota"
      :disabled="!active"
      :items="cotas"
      :rules="[v => !!v || !active || 'Campo obrigatório']"
      v-on:input="updateCotas($event)"
      :menu-props="menuProps"
    ></v-autocomplete>
		<!-- <v-text-field
			class="input-cinza pt-3"
			v-if="!esconderDesconto"
			label="Desconto"
			type="number"
			:value="active && value[mes].desconto.toString()"
			v-on:input="updateDesconto($event)"
			:rules="(active && regra.desconto) || []"
			:disabled="!active"
			suffix="%"
			outlined
			dense
		></v-text-field> -->
    <vuetify-money
      v-if="!esconderDesconto"
      label="Desconto (R$)"
      :value="active && value[mes].desconto.toString()" 
			v-on:input="updateDesconto($event)"
      class="input-cinza pt-3"
      :disabled="!active"
      outlined
      dense
      backgroundColor=""
    />
  </div>
</template>

<script>
export default {
  // components: {VuetifyMoneyPerson},
  props: {
    mes: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
    active: {
      type: Boolean,
      default: false,
    },
    esconderLojas: {
      type: Boolean,
      default: false,
    },
    esconderDesconto: {
      type: Boolean,
      default: true,
    },
    replicarValores: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: "mdi-calendar-blank",
    },
  },
  data() {
    
    return {
      cotas: ["DIAMANTE", "OURO", "PRATA", "BRONZE", "ADESAO"],
      menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
      regra: {
        desconto: [
          (v) =>
            (!!v && v >= 0 && v <= 100) ||
            "O desconto deve ser um numero inteiro ente 0 e 100.",
        ],
        filiais: [
          (v) =>
            (!!v && v >= 50) ||
            "O número de lojas deve ser um numero inteiro igual ou maior que 50.",
        ],
      },
    };
  },
  methods: {
    updateDesconto: function (valueInput) {
      if (this.replicarValores) {
        Object.keys(this.value).forEach((mes) => {
          this.value[mes].desconto = valueInput;
        });
      } else {
        this.value[this.mes]["desconto"] = valueInput;
      }
      this.$emit("input", { ...this.value });
    },
    updateCotas: function (valueInput) {
      if (this.replicarValores) {
        Object.keys(this.value).map((mes) => {
          this.value[mes].cota = valueInput;
        });
      } else {
        this.value[this.mes]["cota"] = valueInput;
      }
      this.$emit("input", { ...this.value });
    },
  },
};
</script>
<style>
.mes-box-card {
  border: 3px solid #f2f2f2;
  border-radius: 8px;
  padding: 15px 15px 0 15px;
  background-color: #fff;
  margin-bottom: 15px;
}
.mes-box-card.active {
  border-color: var(--v-primary-base);
}
.mes-box-card .title {
  font-weight: normal;
  text-transform: capitalize;
}
</style>