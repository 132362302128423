<template>
    <v-form ref="form" class="desconto">

        <v-row no-gutters class="mt-4 h-100">
            <v-col cols="3" class="px-2">
                <vuetify-money
                    placeholder="Desconto (R$)"
                    v-model="descontoCampo.desconto"
                    class="input-cinza"
                    outlined
                    dense
                />
            </v-col>
            <v-col cols="3" class="px-2">
                <v-btn
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="carregarDesconto()"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Carregar Desconto
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="listaHeaderDesconto"
            :items="value.valores"
            :item-class="investimentoClass"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
        >
            <template
                v-for="header in listaHeaderDesconto.filter((header) =>
                    header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
            >
                {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.percentual`]="{ item }">
                <v-text-field
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-mask="'###'"
                    class="input-cinza"
                    :placeholder="`${item.desconto_padrao}% (Desconto padrão)`"
                    v-model="item.percentual"
                    @input="calculaDesconto()"
                ></v-text-field>
            </template>
            <template v-slot:[`item.desconto`]="{ item }">
                <vuetify-money
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-model="item.desconto"
                    class="input-cinza"
                    @input="calculaDesconto()"
                />
            </template>
            <template v-slot:[`item.valor_rh`]="{ item }">
                <vuetify-money
                    v-if="item.mes != 'Total' && item.retroativo"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-model="item.valor_rh"
                    :rules="regras.valor_rh"
                    class="input-cinza"
                    @input="calculaDesconto()"
                />
            </template>
            <template v-slot:[`item.justificativas`]="{ item }">
                <v-select
                    placeholder="Justificativa"
                    v-model="item.justificativa"
                    :items="justificativas"
                    item-text="justificativa"
                    item-value="id_justificativa"
                    dense
                    outlined
                    hide-details
                    class="input-cinza"
                    :rules="regras.justificativa"
                    :menu-props="menuProps"
                    @change="atualizaDescontos(item)"
                    v-if="
                        ((item.percentual != '' &&
                            item.percentual != undefined) ||
                            (item.desconto != '' &&
                                item.desconto != undefined &&
                                item.desconto != 0)) &&
                        item.mes != 'Total'
                    "
                    required
                ></v-select>
            </template>
            <template slot="body.append">
                <tr class="total">
                    <td :colspan="1">Total</td>
                    <td>{{ formataReal(totalCusto) }}</td>
                    <td :colspan="acordoRetroativo? 3 : 2"></td>
                    <td>{{ formataReal(totalInvestimento) }}</td>
                </tr>
            </template>
        </v-data-table>

        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>
<script>
export default {
    props: ["value"],

    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            periodo: null,
            acordo: {},
            valores: [],
            somaFiliais: [],
            somaFiliaisValor: [],
            dialogoCamposFaltando: false,
            desconto: [],
            descontoCampo: {
                percentual: null,
                desconto: null,
            },
            listaHeaderDescontoa: null,
            regras: {
                justificativa: [(v) => !!v || "O campo espaço é obrigatório"],
                valor_rh: [(v) => !!v || "O campo valor é obrigatório"],
            },
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false /* doesn't work with directive */,
            },
            carregando: false,
            semAcesso: false,
            mesDesconto: [],
            justificativas: [],
            primeiraAlteracao: true,
        };
    },
    async created() {
        const descontosJustificativas = (
            await this.$http().post("acordo/justificativa")
        ).data;
        this.justificativas = descontosJustificativas.map((i) => {
            return {
                justificativa: i.justificativa,
                id_justificativa: i.id_justificativa,
            };
        });
    },
    computed: {
        listaHeaderDesconto() {
            const header = [
                {
                    text: "Mês",
                    value: "mes",
                    align: "center",
                    class: "th-separador",
                },

                {
                    text: "Custo",
                    value: "valor",
                    align: "center",
                    class: "th-separador min-width-100",
                    formatter: this.formataReal,
                },
                // {
                //     text: "Percentual de desconto (%)",
                //     value: "percentual",
                //     align: "center",
                //     class: "th-separador",
                //     width: "14%",
                // },
                {
                    text: "Desconto (R$)",
                    value: "desconto",
                    align: "center",
                    class: "th-separador min-width-100",
                    width: "20%",
                    formatter: this.formataReal,
                },
                {
                    text: "Justificativa",
                    value: "justificativas",
                    align: "center",
                    class: "th-separador min-width-100",
                    width: "20%",

                    // width: "14%",
                },
                {
                    text: "Valor RH",
                    value: "valor_rh",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Investimento (R$)",
                    value: "investimento",
                    align: "center",
                    class: "th-separador",
                    formatter: this.formataReal,
                },
            ];
                console.log(this.value.valores)
            // Remove a coluna Valor RH se o acordo não for retroativo
            if(this.value.valores){

                if (!this.value.valores.some((item) => item.retroativo)) {
                    header.splice(4, 1);
                }
            }

            return header;
        },
        acordoRetroativo() {
            if(!this.value.valores) return false;
            return this.value.valores.some((item) => item.retroativo);
        },
        totalCusto() {
            if (this.value.valores.length > 0) {
                console.log("total custo", this.value.valores);
                console.table(this.value.valores);
                return this.value.valores.reduce((a, b) => a + +b.valor, 0);
            }
            return 0;
        },
        totalInvestimento() {
            if (this.value.valores) {
                return this.value.valores.reduce(
                    (a, b) => a + Number(b.investimento),
                    0
                );
            }
            return 0;
        },

        mostrarPreAcordo() {
            if (this.acordo.idStatus === 30) {
                return true;
            }
            const data = this.$moment().format("YYYY-MM");
            if (!this.acordo.idStatus && data !== this.acordo.mesInicial) {
                return true;
            }
            return false;
        },
        camposFaltando() {
            const campos = [];
            if (
                !this.value.clustersNoAcordo ||
                this.value.clustersNoAcordo.length === 0
            ) {
                campos.push("Salve as filiais antes de prosseguir");
            }
            if (!this.value.id_campanha) {
                campos.push("Nome da campanha");
            }
            // if (!this.value.mesInicial) {
            //     campos.push("Mês Inicial");
            // }
            // if (!this.value.mesFinal) {
            //     campos.push("Mês Final");
            // }
            if (!this.value.periodo) {
                campos.push("Período");
            }

            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
    },

    methods: {
        formataReal(valor) {
            return Number(valor).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        limpaDescontos() {
            console.log("limpaDescontos");
            this.value.valores = [];
        },
        atualizaDescontos(item) {
            if (this.primeiraAlteracao) {
                for (const d of this.value.valores) {
                    if (d.mes != "Total") {
                        d.desconto = item.desconto;
                        d.percentual = item.percentual;
                        d.justificativa = item.justificativa;
                    }
                }
            }
            this.calculaDesconto();
            this.primeiraAlteracao = false;
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },

        getPeriodo(campo = "quantidade") {
            const mesAtual = new Date(this.value.mesInicial + "-15");
            const mesFinal = new Date(this.value.mesFinal + "-15");

            const periodo = [];

            while (mesAtual <= mesFinal) {
                periodo.push(
                    campo +
                        "_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1)
                );

                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return periodo;
        },

        async carregaValores() {
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
                return;
            }

            if (this.value.valores.length > 0) {
                return;
            }
            this.dialogoCamposFaltando = false;
            this.carregando = true;

            const data = {
                ...this.value,
            };

            const valores = await this.$http().post(
                "/acordoCampanhas/valores",
                {
                    data,
                }
            );

            if (!valores.data) {
                this.$dialog.notify.error(
                    "Não há valores para o período selecionado",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.carregando = false;
                return;
            }

            if (valores.data.length === 0) {
                this.$dialog.notify.error(
                    "Não há valores para o período selecionado",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.carregando = false;
                return;
            }
            this.value.valores = valores.data.map((custo) => {
                return {
                    mes: this.$moment(custo.mes).format("MM/YYYY"),
                    valor: custo.valor,
                    desconto: custo.desconto || 0,
                    justificativa: custo.id_justificativa,
                    id_acordo: custo.id_acordo,
                    investimento: custo.investimento || custo.valor,
                    valor_rh: custo.valor_rh,
                    retroativo: custo.retroativo,
                };
            });
            console.log("retorno valores", valores.data);

            this.carregando = false;
        },

        async criaDesconto() {
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
                return;
            }
            //Reativar depois
            // if (this.value.valores.length > 0) {
            //     return;
            // }

            this.dialogoCamposFaltando = false;

            this.carregando = true;

            const data = {
                ...this.value,
            };

            this.carregando = false;
        },

        carregarDesconto() {
            for (const d of this.value.valores) {
                if (d.mes != "Total") {
                    d.percentual = this.descontoCampo.percentual;
                    d.desconto = this.descontoCampo.desconto;
                }
            }

            this.calculaDesconto();
        },
        calculaDesconto() {
            for (const d of this.value.valores) {
                d.investimento = d.valor - d.desconto;
            }
        },
        validate() {
            if (this.value.valores.length == 0) {
                return false;
            }

            const validaValores = !this.value.valores.some(
                (v) => v.investimento < 0
            );
            const validaCampos = this.$refs.form.validate();

            if (validaValores && validaCampos) {
                return true;
            }
            return false;
        },
        investimentoClass(item) {
            if (item.mes == "Total") {
                return "";
            } else {
                return item.investimento < 0
                    ? "style-investimento-negativo"
                    : "";
            }
        },
    },
};
</script>


<style >
.desconto {
}
.dialogo-erro {
    color: red;
    z-index: 1000;
    position: absolute;
    width: 700px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-top: 100px;
}
</style>