<template>
	<v-menu offset-y close-on-click max-height="320" >
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" dark v-bind="attrs" v-on="on" class="ml-2 my-1">
				<v-icon left>mdi-microsoft-excel</v-icon>
				Exportações
				<v-icon right>mdi-menu-down</v-icon>
			</v-btn>
		</template>

		<v-list>
			<v-list-item
				v-for="(item, index) in items"
				:key="index"
				link
				@click="action(item)"
			>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			dialogoExcluidas: false,
			items: [
				{ title: "Cluster", fnc: "exportar_cluster" },
				{ title: "Modelo Espaços", fnc: "exportar_espacos" },
			],
		};
	},
	computed: {},
	methods: {
		action(obj) {
			this.$emit("export-action", obj);
		},
	},
};
</script>

<style></style>
