<template>
  <v-form ref="formFiltro" class="filtros-acordos">
    <v-divider class="mt-2 mb-3"></v-divider>
    <v-row>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <MesInput
          v-model="filtro.mesInicial"
          :min="'2021-06'"
          label="Período Inicial"
          :change="periodoInicialChange"
          required
        />
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <MesInput
          v-model="filtro.mesFinal"
          :min="'2021-06'"
          label="Período Final"
          :change="periodoFinalChange"
          required
        />
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-text-field
          label="Número do acordo"
          v-model="filtro.id_acordo"
          class="input-cinza"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col sm="6" md="4" lg="3" class="pb-0">
        <v-text-field
          label="Número do acordo RD"
          v-model="filtro.acordo_rd"
          class="input-cinza"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-expansion-panels flat v-model="mostrarFiltros" accordion>
      <v-expansion-panel>
        <v-expansion-panel-content eager class="p-0 m-0">
          <v-row>
            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-text-field
                label="Número do contrato"
                v-model="filtro.id_contrato"
                class="input-cinza"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-text-field
                label="Número do Contrato RD"
                v-model="filtro.contrato"
                class="input-cinza"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-select
                label="Bandeira"
                v-model="filtro.bandeira"
                :items="lista.bandeira"
                :menu-props="menuProps"
                class="input-cinza"
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="filtro.bandeira.length === 1 && index === 0">{{
                    item.text
                  }}</span>
                  <span
                    v-if="filtro.bandeira.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ filtro.bandeira.length }} selecionados</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-autocomplete
                label="Espaço"
                v-model="filtro.id_espaco"
                :items="lista.espaco"
                :menu-props="menuProps"
                class="input-cinza"
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="filtro.id_espaco.length === 1 && index === 0"
                class="autocomplete-selecao"
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="filtro.id_espaco.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ filtro.id_espaco.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>

            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-autocomplete
                label="Fornecedor"
                v-model="filtro.id_fornecedor"
                :items="lista.fornecedor"
                :menu-props="menuProps"
                class="input-cinza"
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <div
                    v-if="filtro.id_fornecedor.length === 1 && index === 0"
                    style="
                      width: 70% !important;
                      white-space: nowrap !important;
                      text-overflow: ellipsis !important;
                      overflow: hidden;
                    "
                  >
                    {{ item.text }}
                  </div>
                  <span
                    v-if="filtro.id_fornecedor.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ filtro.id_fornecedor.length }} selecionados</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-select
                label="Status"
                :items="lista.status"
                v-model="filtro.id_status"
                :menu-props="menuProps"
                class="input-cinza"
                multiple
                outlined
                dense
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="filtro.id_status.length === 1 && index === 0">{{
                    item.text
                  }}</span>
                  <span
                    v-if="filtro.id_status.length > 1 && index === 0"
                    class="grey--text caption mr-1"
                    >{{ filtro.id_status.length }} selecionados</span
                  >
                </template>
              </v-select>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="py-0">
              <v-autocomplete
                  label="Usuario"
                  v-model="filtro.id_usuario_criacao"
                  :items="lista.usuarios"
                  multiple
                  :menu-props="menuProps"
                  outlined
                  dense
                  class="input-cinza"
              >
                  <template v-slot:selection="{ item, index }">
                      <div
                          v-if="
                              filtro.id_usuario_criacao.length === 1 &&
                              index === 0
                          "
                          style="
                              width: 70% !important;
                              white-space: nowrap !important;
                              text-overflow: ellipsis !important;
                              overflow: hidden;
                          "
                      >
                          {{ item.text }}
                      </div>
                      <span
                          v-if="
                              filtro.id_usuario_criacao.length > 1 &&
                              index === 0
                          "
                          class="grey--text caption mr-1"
                          >{{
                              filtro.id_usuario_criacao.length
                          }}
                          selecionados</span
                      >
                  </template>
              </v-autocomplete>
  
              
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider class="mt-0 mb-3"></v-divider>

    <v-row>
      <v-col cols="3" class="px-2 ml-auto">
        <div class="new-button d-flex align-center" text>
          <span @click="expandirFiltros" style="cursor: pointer"
            >Personalizar Filtros</span
          >
          <v-btn
            style="background: #e6e6e6; width: 20px; height: 20px"
            fab
            icon
            class="ml-3"
            @click="expandirFiltros"
            x-small
          >
            <v-icon>{{
              mostrarFiltros == 0 ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="9" class="ml-auto" align="end">
        <v-btn class="ml-2 new-button" color="yellow" dark @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
        </v-btn>
        <v-btn class="ml-5 new-button" color="primary" @click="filtrar()">
          <v-icon left>mdi-filter</v-icon> Filtrar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import MesInput from "../../../components/MesInput.vue";
export default {
  name: "Filtro",
  components: {
    MesInput,
  },
  inject: ["listaCampos"],

  data() {
    return {
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      mostrarFiltros: null,
      filtro: {
        mesInicial: this.$moment().format("YYYY-MM"),
        mesFinal: this.$moment().format("YYYY-MM"),
      },
      lista: {},
    };
  },
  async created() {
    this.carregando = true;
    var lista = await this.listaCampos;
    lista.status = lista.status.sort(function (a, b) {
      return a.text.localeCompare(b.text);
    });
    this.lista.bandeira = lista.bandeira;
    this.lista.espaco = lista.espaco;
    this.lista.fornecedor = lista.fornecedor;
    this.lista.status = lista.status;
    this.lista.usuarios = lista.usuarios;

    this.iniciaFiltro();
    this.carregando = false;
  },
  methods: {
    iniciaFiltro() {
      this.filtro = {
        mesInicial: this.$moment().format("YYYY-MM"),
        mesFinal: this.$moment().format("YYYY-MM"),
        bandeira: [],
        id_espaco: [],
        id_status: [],
        id_usuario_criacao: []
      };
    },
    expandirFiltros() {
      this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
      //   setTimeout(() => {
      //     this.validate();
      //   }, 100);
    },
    validate() {
      return this.$refs.formFiltro.validate();
    },
    limpar() {
      this.iniciaFiltro();
      this.$emit("limpar");
    },
    filtrar() {
      this.$emit("filtrar");
    },
    periodoInicialChange(valor){
      if(this.filtro.mesFinal < valor ){
        this.filtro.mesFinal = valor;
      }
    },
    periodoFinalChange(valor){
      if(this.filtro.mesInicial > valor ){
        this.filtro.mesInicial = valor;
      }
    }
  },
};
</script>

<style >
.filtros-acordos .v-expansion-panel-content__wrap {
  padding: 0;
}

.v-date-picker-table--month td {
  height: 0 !important;
}
.v-date-picker-table.v-date-picker-table--month {
  height: 205px;
}
</style>