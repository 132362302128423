<template>
	<div>
		<v-dialog v-model="dialogAprovar" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogTitulo }}
				</v-card-title>
				<v-card-text class="mt-4">
					{{ dialogTexto }}
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="dialogAprovar = false">
						Fechar
					</v-btn>
					<v-btn color="primary" @click="aprovarSimulacao('aprovar')">
						Aprovar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogAprovarGeral" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogTitulo }}
				</v-card-title>
				<v-card-text class="mt-4">
					{{ dialogTexto }}
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="dialogAprovarGeral = false">
						Fechar
					</v-btn>
					<v-btn
						color="primary"
						@click="aprovarSimulacaoGeral('aprovarGeral')"
					>
						Aprovar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogAprovarRD" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogTitulo }}
				</v-card-title>
				<v-card-text class="mt-4">
					{{ dialogTexto }}
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="dialogAprovarRD = false">
						Fechar
					</v-btn>
					<v-btn
						color="primary"
						@click="aprovarSimulacaoGeralRD('aprovarGeralRD')"
					>
						Aprovar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogReprovar" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					{{ dialogTitulo }}
				</v-card-title>
				<v-card-text class="mt-4">
					{{ dialogTexto }}
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="dialogReprovar = false">
						Fechar
					</v-btn>
					<v-btn
						color="primary"
						@click="aprovarSimulacao('reprovar')"
					>
						Reprovar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			item: {},
			id_acordo: null,
			id_simulacao_ecommerce: null,
			id_fornecedor: null,
			dialog: false,
			dialogImagem: false,
			dialogRenovar: false,
			dialogHistorico: false,
			dialogEditar: false,
			dialogAprovar: false,
			dialogAprovarGeral: false,
			dialogAprovarRD: false,
			dialogRevisao: false,
			dialogReprovar: false,
			dialogCancelar: false,
			dialogLiberar: false,
			dialogTitulo: null,
			dialogTexto: null,
			htmlPlanograma: null,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			carregando: false,
			regra: {
				planograma: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(v &&
							[
								".pdf",
								".PDF",
								".jpg",
								".JPG",
								".png",
								".PNG",
								"jpeg",
								"JPEG",
							].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo PDF, JPG ou PNG.",
				],
				justificativa: [(v) => !!v || "Selecione uma justificativa."],
				renovar: [
					(v) =>
						!!v || "Selecione a data final de renovação do acordo.",
					(v) =>
						(v && v >= this.mesAtual) ||
						"A data final de renovação do acordo deve ser igual ou posterior ao mês atual",
				],
			},
			filePlanograma: null,
			isPlanograma: false,
			justificativa: null,
			observacao: null,
			isJustificativa: false,
			justificativas: [],
			headersHistorico: [
				{
					text: "Processo",
					value: "processo",
					sortable: false,
					align: "center",
				},
				{
					text: "Detalhes",
					value: "detalhe",
					sortable: false,
					align: "center",
				},
				{
					text: "Data",
					value: "data",
					sortable: false,
					align: "center",
				},
				{
					text: "Usuário",
					value: "usuario",
					sortable: false,
					align: "center",
				},
			],
			dessertsHistorico: [],
			termino: null,
			mesFinal: null,
			isRenovar: false,
			dialogoErro: false,
			listaHeaderErro: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			listaErro: [],
			listaErroLabels: { msg: "Descrição do Erro" },
			paginationErro: {
				page: 1,
			},
			dadosProdutos: [],
			headersProdutos: [
				{
					text: "Código",
					value: "codigo",
					sortable: false,
					align: "center",
				},
				{
					text: "Marca",
					value: "marca",
					sortable: false,
					align: "center",
				},
				{
					text: "Produto",
					value: "nome",
					sortable: false,
					align: "center",
				},
				{ text: "VB", value: "vb", sortable: false, align: "center" },
			],
			ano: null,
		};
	},

	computed: {
		mesAtual() {
			return this.$moment().format("YYYY-MM");
		},
	},
	methods: {
		async aprovarGeralRD(item) {
			this.justificativa = null;
			this.observacao = null;
			this.dialogAprovarRD = true;

			if (item) {
				this.id_fornecedor = item.id_fornecedor;
				this.ano = item.ano;
				this.dialogTitulo = "Aprovar todas simulações ";
				this.dialogTexto = "Deseja realmente aprovar a simulação.";
			} else {
				this.id_fornecedor = null;
				this.dialogTitulo = "Aprovar simulações";
				this.dialogTexto =
					"Deseja realmente aprovar a(s) simulação(ões) selecionada(os).";
			}
		},

		async aprovarSimulacaoGeralRD(tipo) {
			var data = {
				tipo: tipo,
				id_fornecedor: this.id_fornecedor,
				ano: this.ano,
			};

			if (tipo == "reprovar") {
				data.justificativa = this.justificativa;
				data.observacao = this.observacao;
			}

			this.$emit("aprovarSimulacaoGeralRD", data);

			this.dialogAprovarRD = false;
			this.dialogAprovarRD = false;
		},

		async aprovarGeral(item) {
			this.justificativa = null;
			this.observacao = null;
			this.dialogAprovarGeral = true;

			if (item) {
				this.id_fornecedor = item.id_fornecedor;
				this.ano = item.ano;
				this.dialogTitulo = "Aprovar todas simulações ";
				this.dialogTexto = "Deseja realmente aprovar a simulação.";
			} else {
				this.id_fornecedor = null;
				this.dialogTitulo = "Aprovar simulações";
				this.dialogTexto =
					"Deseja realmente aprovar a(s) simulação(ões) selecionada(os).";
			}
		},

		async aprovarSimulacaoGeral(tipo) {
			var data = {
				tipo: tipo,
				id_fornecedor: this.id_fornecedor,
				ano: this.ano,
			};

			if (tipo == "reprovar") {
				data.justificativa = this.justificativa;
				data.observacao = this.observacao;
			}

			this.$emit("aprovarSimulacaoGeral", data);

			this.dialogAprovarGeral = false;
			this.dialogReprovarGeral = false;
		},
	},
};
</script>
