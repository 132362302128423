<template>
    <div>
        <v-card elevation="0" @click="abrirDialog">
            <v-img
                :src="this.$arquivoProtegido(auditoria.imagem)"
                aspect-ratio="1"
                class="grey lighten-2"
            >
                <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
            <v-card-text>
                {{ auditoria.id_java }} - {{ auditoria.nome }}
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialog"
            content-class="br-15"
            width="unset"
            scrollable
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title> Detalhes da Auditoria </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialog = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-2">
                    <div class="d-flex">
                        <div class="flex-grow mr-3">
               
                            <image-zoom 
                            click-zoom
                            img-class="img-fluid"
                            click-message="Clique para ampliar"
                            zoom-amount="3"
                            :regular=" this.$arquivoProtegido(auditoria.imagem)"></image-zoom>
                        </div>

                        <v-card elevation="0" class="resumo" width="400">
                            <p>
                                Acordo:
                                <strong>{{ auditoria.id_acordo }}</strong>
                            </p>
                            <p>
                                Acordo RD:
                                <strong>{{ auditoria.acordo_rd }}</strong>
                            </p>
                            <p>
                                Contrato:
                                <strong>{{ auditoria.id_contrato }}</strong>
                            </p>
                            <p>
                                Bandeira:
                                <strong>{{ auditoria.bandeira }}</strong>
                            </p>
                            <p>
                                Espaço:
                                <strong>{{ auditoria.espaco }}</strong>
                            </p>
                            <p>
                                Espaço macro:
                                <strong>{{ auditoria.espaco_macro }}</strong>
                            </p>
                            <p>
                                Filial:
                                <strong>{{ auditoria.id_java }} - {{ auditoria.nome }}</strong>
                            </p>
                            <p>
                                Cluster:
                                <strong>{{ auditoria.cluster }}</strong>
                            </p>
                            <p>
                                Fornecedor:
                                <strong>{{ auditoria.fornecedor }}</strong>
                            </p>
                            <p>
                                CNPJ:
                                <strong>{{ auditoria.cnpj }}</strong>
                            </p>
                            <p>
                                Produtos:
                                <ul>
                                    <li v-for="produto in auditoria.produtos" :key="produto.codigo">
                                    <strong>{{ produto.codigo }} </strong>   - {{ produto.produto }} (VB: {{ produto.vb }})
                                     
                                    </li>
                                </ul>
                            </p>

                            <p>
                                Data de execução:
                                <strong>{{ $moment(auditoria.data_execucao).format("DD/MM/YYYY - HH:mm:ss") }}</strong>
                            </p>
                            <p>
                                Observação:
                                <strong>{{ auditoria.observacao }}</strong>
                            </p>
                        </v-card>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        auditoria: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        abrirDialog() {
            this.dialog = true;
        },
    },
};
</script>
<style scoped>
.resumo {
    padding: 10px 20px;
    border-radius: 8px;
    background: #eaeaea;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 0.8rem !important;
    color: #808080;
}
.resumo p {
    margin: 0;
}
</style>