var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","content-class":"br-15","width":"80%"},model:{value:(_vm.dialogHistorico),callback:function ($$v) {_vm.dialogHistorico=$$v},expression:"dialogHistorico"}},[_c('v-card',[_c('v-toolbar',{staticClass:"primary-degrade white--text fix-flex",attrs:{"elevation":"0","dark":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.dialogTitulo)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","width":"24px","height":"24px","elevation":"0","light":""},on:{"click":function($event){_vm.dialogHistorico = false}}},[_c('v-icon',{attrs:{"size":"18px","color":"primary"}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pa-5 pb-o"},[_c('v-data-table',{staticClass:"elevation-0 tabela",attrs:{"headers":_vm.headersHistorico,"items":_vm.dessertsHistorico,"item-key":"id","footer-props":{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100],
                        itemsPerPageText: 'Linhas por páginas',
                        ofText: 'de',
                        pageText: '{0}-{1} de {2}',
                    },"items-per-page":5},scopedSlots:_vm._u([{key:"item.detalhe",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.processo)+" "),(item.dados)?_c('v-chip',{attrs:{"small":""},on:{"click":function($event){return _vm.abrirDetalhes(item)}}},[_vm._v(" Ver Detalhes ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","content-class":"br-15","width":"80%"},model:{value:(_vm.dialogoDetalhes),callback:function ($$v) {_vm.dialogoDetalhes=$$v},expression:"dialogoDetalhes"}},[_c('v-card',[_c('v-toolbar',{staticClass:"primary-degrade white--text fix-flex",attrs:{"elevation":"0","dark":""}},[_c('v-toolbar-title',[_vm._v(" Detalhes do Processo ")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","width":"24px","height":"24px","elevation":"0","light":""},on:{"click":function($event){_vm.dialogoDetalhes = false}}},[_c('v-icon',{attrs:{"size":"18px","color":"primary"}},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pa-5 pb-o"},[_c('v-data-table',{staticClass:"elevation-0 tabela",attrs:{"headers":_vm.headersDetalhe,"items":_vm.detalhesProcesso,"item-key":"id","footer-props":{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100],
                        itemsPerPageText: 'Linhas por páginas',
                        ofText: 'de',
                        pageText: '{0}-{1} de {2}',
                    },"items-per-page":5},scopedSlots:_vm._u([{key:"item.detalhe",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.processo)+" "),(item.dados)?_c('v-chip',{staticClass:"white--text",attrs:{"color":"primary","small":""}},[_vm._v(" Ver Detalhes ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.carregando,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }