<template>
    <div>
        <v-dialog
            scrollable
            content-class="br-15"
            v-model="dialogHistorico"
            width="80%"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        {{ dialogTitulo }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogHistorico = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div class="pa-5 pb-o">
                    <v-data-table
                        :headers="headersHistorico"
                        :items="dessertsHistorico"
                        item-key="id"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                        :items-per-page="5"
                        class="elevation-0 tabela"
                    >
                        <template v-slot:[`item.detalhe`]="{ item }">
                            {{ item.processo }}
                            <v-chip
                                small
                                v-if="item.dados"
                                @click="abrirDetalhes(item)"
                            >
                                Ver Detalhes
                            </v-chip>
                        </template>
                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>
        
        <v-dialog
            scrollable
            content-class="br-15"
            v-model="dialogoDetalhes"
            width="80%"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                       Detalhes do Processo
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoDetalhes = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div class="pa-5 pb-o">
                    <v-data-table
                        :headers="headersDetalhe"
                        :items="detalhesProcesso"
                        item-key="id"
                        :footer-props="{
                            'items-per-page-options': [5, 10, 15, 25, 50, 100],
                            itemsPerPageText: 'Linhas por páginas',
                            ofText: 'de',
                            pageText: '{0}-{1} de {2}',
                        }"
                        :items-per-page="5"
                        class="elevation-0 tabela"
                    >
                        <template v-slot:[`item.detalhe`]="{ item }">
                            {{ item.processo }}
                            <v-chip
                                color="primary"
                                class="white--text"
                                small
                                v-if="item.dados"
                            >
                                Ver Detalhes
                            </v-chip>
                        </template>
                    </v-data-table>
                </div>
            </v-card>
        </v-dialog>
        
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
export default {
    data: () => ({
        dialogHistorico: false,
        dialogoDetalhes: false,
        carregando: false,
        dialogTitulo: 'Histórico',
        detalhesProcesso: [],
        dessertsHistorico: [],
        headersDetalhe:[
            {
                text: 'Campo',
                value: 'key',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Valor anterior',
                value: 'value1',
                sortable: false,
                // align: 'center',
            },
            {
                text: 'Novo valor',
                value: 'value2',
                sortable: false,
                // align: 'center',
            },
    
        ],
        headersHistorico: [
            {
                text: 'Processo',
                value: 'processo',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Detalhes',
                value: 'detalhe',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Data',
                value: 'data',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Usuário',
                value: 'usuario',
                sortable: false,
                align: 'center',
            },
        ],
    }),

    methods: {
        abrirDetalhes(item) {
            this.dialogoDetalhes = true;
            this.detalhesProcesso = item.dados;


        },
        async abrir(item) {
            this.carregando = true

            this.dessertsHistorico = []

            this.dialogTitulo =
                'Histórico (Planejamento : ' + item.id_planejamento + ')'

            var response = await this.$http().post(
                '/planejamentoEcommerce/historico',
                {
                    data: { id_planejamento: item.id_planejamento },
                }
            )

            this.dessertsHistorico = response.data

            this.dialogHistorico = true
            this.carregando = false
        },
    },
}
</script>

<style>
</style>