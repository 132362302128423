<template>
  <div v-if="!file">
    <div class="drop" @drag="onChange">
      <div :class="['dropZone', dragging ? 'dropZone-over' : '']">
        <div class="dropZone-info">
          <span class=""
            >{{ label }}, ou então
            <span class="clickable">clique aqui</span>
            para selecionar.</span
          >
        </div>
        <input
          @dragenter="dragging = true"
          @dragleave="dragging = false"
          type="file"
          :accept="accept"
          @change="onChange"
        />
      </div>
    </div>
  </div>
  
  <div v-else class="dropZone-uploaded">
    <div class="dropZone-uploaded-info d-flex">
      <span> {{ file.name }} </span>
      <span class="removeFile ml-3" @click="removeFile">
        <v-icon size="18" color="#ccc">mdi-close-circle</v-icon>
      </span>
    </div>

  </div>
</template>
<script>
export default {
  name: "ArquivoInput",
  props: ["value", "label", "rules", "change", "accept"],
  data() {
    return {
      file: null,
      dragging: false,
    };
  },
  methods: {
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    createFile(file) {
      this.file = file;
      //   if (this.extension !== "csv") {
      //     console.log(file);
      //     this.alerta = true;
      //     this.alertaMesagem = "O arquivo deve ser do tipo CSV.";
      //     this.dragging = false;
      //     this.file = "";
      //     return;
      //   }

      console.log(this.file);
      this.$emit("input", file);

      this.dragging = false;
    },
    removeFile() {
      this.file = "";
        this.$emit("input", "");
    },
  },
};
</script>
<style>
.instrucoes {
  border: 1px solid #ddd;
  padding: 10px 15px;
  border-radius: 6px;
  color: #808080;
}
.instrucoes h4 {
  color: var(--v-primary-base);
  margin-bottom: 6px;
}

.dropZone {
  width: 100%;
  height: 90px;
  position: relative;
  color: #808080;
}

.dropZone:hover {
  /* border: 2px solid #2e94c4; */
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50px;
  transform: translate(0, -50%);
  padding: 10px 15px;
  border: 2px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 6px;
}

.dropZone-title {
  color: #808080;
  margin-top: 20px;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: blue;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over .dropZone-info {
  border: 2px dashed #ccc;
}

.dropZone-uploaded {
  width: 100%;
  height: 50px;
  margin: 20px 0;
  padding: 10px 15px;

  border: 2px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 6px;
  position: relative;
}

.dropZone-uploaded-info {
  display: flex;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.removeFile:hover {
  color: red;
  cursor: pointer;
}
.clickable {
  cursor: pointer;
  text-decoration: underline;
}
</style>
