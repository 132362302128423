<template>
    <v-dialog content-class="br-15" v-model="dialogGuiaPDF" width="90%" scrollable>
        <v-card height="600">
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title> Guia PDF </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    light
                    @click="dialogGuiaPDF = false"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="mt-4 pt-4">
                <v-row no-gutters>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <MesInput
                            label="Período"
                            v-model="filtro.mes"
                            required
                        ></MesInput>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-select
                            label="Bandeira"
                            v-model="filtro.bandeira"
                            :items="lista.bandeira"
                            outlined
                            :menu-props="menuProps"
                            class="input-cinza"
                            dense
                            clearable
                            @change="filtro.id_filial = []"
                        >
                            <!-- <template v-slot:selection="{ item, index }">
								<span
									v-if="
										filtro.bandeira.length === 1 &&
											index === 0
									"
									>{{ item.text }}</span
								>
								<span
									v-if="
										filtro.bandeira.length > 1 &&
											index === 0
									"
									class="grey--text caption mr-1"
									>{{
										filtro.bandeira.length
									}}
									selecionados</span
								>
							</template> -->
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-autocomplete
                            label="Fornecedor"
                            v-model="filtro.id_fornecedor"
                            :items="lista.fornecedoresGerenciados"
                            :menu-props="menuProps"
                            multiple
                            outlined
                            class="input-cinza"
                            @change="changed"
                            dense
                        >
                            <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                    <v-list-item-action>
                                    <v-icon :color="lista.fornecedoresGerenciados.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                    <v-list-item-title>Selecione Todos</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider />
                            </template>
                            <template v-slot:selection="{ item, index }">
                                <div
                                    v-if="
                                        filtro.id_fornecedor.length === 1 &&
                                        index === 0
                                    "
                                    style="
                                        width: 70% !important;
                                        white-space: nowrap !important;
                                        text-overflow: ellipsis !important;
                                        overflow: hidden;
                                    "
                                >
                                    {{ item.text }}
                                </div>
                                <span
                                    v-if="
                                        filtro.id_fornecedor.length > 1 &&
                                        index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.id_fornecedor.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-select
                            label="Cluster"
                            :items="lista.cluster"
                            v-model="filtro.id_cluster"
                            multiple
                            outlined
                            :menu-props="menuProps"
                            class="input-cinza"
                            dense
                        >
                            <template v-slot:selection="{ item, index }">
                                <span
                                    v-if="
                                        filtro.id_cluster.length === 1 &&
                                        index === 0
                                    "
                                    >{{ item.text }}</span
                                >
                                <span
                                    v-if="
                                        filtro.id_cluster.length > 1 &&
                                        index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.id_cluster.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-autocomplete
                            label="Espaço"
                            v-model="filtro.id_espaco"
                            :items="lista.espaco"
                            multiple
                            :menu-props="menuProps"
                            outlined
                            class="input-cinza"
                            dense
                        >
                            <template v-slot:selection="{ item, index }">
                                <div
                                    v-if="
                                        filtro.id_espaco.length === 1 &&
                                        index === 0
                                    "
                                    style="
                                        width: 70% !important;
                                        white-space: nowrap !important;
                                        text-overflow: ellipsis !important;
                                        overflow: hidden;
                                    "
                                >
                                    {{ item.text }}
                                </div>
                                <span
                                    v-if="
                                        filtro.id_espaco.length > 1 &&
                                        index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.id_espaco.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-select
                            label="Status"
                            :items="lista.statusGuia"
                            v-model="filtro.status"
                            multiple
                            outlined
                            class="input-cinza"
                            :menu-props="menuProps"
                            dense
                        >
                            <template v-slot:selection="{ item, index }">
                                <span
                                    v-if="
                                        filtro.status.length === 1 &&
                                        index === 0
                                    "
                                    >{{ item }}</span
                                >
                                <span
                                    v-if="
                                        filtro.status.length > 1 && index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.status.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-text-field
                            label="Número do acordo"
                            outlined
                            class="input-cinza"
                            dense
                            v-model="filtro.id_acordo"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        <v-text-field
                            label="Número do acordo RD"
                            outlined
                            class="input-cinza"
                            dense
                            v-model="filtro.acordo_rd"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2" v-if="usuario.id_cargo == 2">
                        <v-autocomplete
                            label="Fornecedores por usuários"
                            v-model="filtro.id_usuario"
                            :items="lista.fornecedoresGerenciadosPorUsuarios"
                            :menu-props="menuProps"
                            multiple
                            outlined
                            class="input-cinza"
                            @change="changed"
                            dense
                        >
                            
                            
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="4"
                        xl="4"
                        class="px-2 ml-auto"
                        align="end"
                    >
                        <v-btn
                            class="ml-2 mb-1 new-button"
                            color="yellow"
							dark
                            @click="limpar()"
                        >
                            <v-icon left>mdi-filter-off</v-icon> Limpar
                        </v-btn>
                        <v-btn
                            class="ml-4 mb-1 new-button"
                            color="primary"
                            @click="filtrar()"
                        >
                            <v-icon left>mdi-filter</v-icon> Filtrar
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2 mb-4">
                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                        Filiais: {{ filiais }}
                    </v-col>
                    <v-col cols="12" sm="6" md="2" lg="2" xl="2" class="px-2">
                        Paginas: {{ paginas }}
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="px-2">
                        Tempo estimado: {{ tempo }}
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="px-2">
                        <v-autocomplete
                            label="Filial"
                            v-model="filtro.id_filial"
                            :items="filiaisLista"
                            multiple
                            outlined
                            class="input-cinza"
                            dense
                            :menu-props="menuProps"
                        >
                            <template v-slot:selection="{ item, index }">
                                <div
                                    v-if="
                                        filtro.id_filial.length === 1 &&
                                        index === 0
                                    "
                                    style="
                                        width: 70% !important;
                                        white-space: nowrap !important;
                                        text-overflow: ellipsis !important;
                                        overflow: hidden;
                                    "
                                >
                                    {{ item.text }}
                                </div>
                                <span
                                    v-if="
                                        filtro.id_filial.length > 1 &&
                                        index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.id_filial.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="8"
                        lg="8"
                        xl="8"
                        class="px-2 ml-auto"
                        align="end"
                    >
                        <v-btn
                            class="ml-2 mb-1 new-button"
                            color="primary"
                            @click="gerar()"
                        >
                            <v-icon left>mdi-folder-zip-outline</v-icon> Gerar
                            arquivo zip (por loja)
                        </v-btn>
                        <v-btn
                            class="ml-4 mb-1 new-button"
                            color="primary"
                            @click="gerar('email')"
                            :disabled="!email"
                            v-if="usuarioTipo === 'rd'"
                        >
                            <v-icon left>mdi-mail</v-icon> Enviar por e-mail
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2 mb-4">
                    <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pt-2">
                        Paginas consolidado: {{ lista.consolidado[0].paginas }}
                        <br/>
                        Tempo estimado consolidado:
                        {{ Math.ceil(lista.consolidado[0].tempo / 1000) }}
                        segundos
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="8"
                        lg="8"
                        xl="8"
                        class="px-2 ml-auto"
                        align="end"
                    >
                        <v-btn class="ml-4 mb-1 new-button" color="primary" @click="gerar('emailfornecedor')" :disabled="!email" v-if="usuarioTipo === 'rd'">
                            <v-icon left="">mdi-mail</v-icon> Envio guia e extrato
                        </v-btn> 
                        <v-btn
                            class="ml-4 mb-1 new-button"
                            color="primary"
                            @click="gerar('consolidado')"
                        >
                            <v-icon left>mdi-file-pdf-box</v-icon> Gerar
                            Consolidado (único)
                        </v-btn>
                        <v-btn
                            class="ml-4 mb-1 white--text new-button"
                            color="orange"
                            @click="gerar('sftp')"
                            v-if="usuario.id_cargo == 2"
                        >
                            <v-icon left>mdi-cloud-upload</v-icon> ENVIAR SFTP 
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row> </v-row>
                <v-row no-gutters class="mt-10" v-show="progresso">
                    <v-col cols="12" class="px-2">
                        <v-progress-linear v-model="index" height="25">
                            <span class="font-weight-bold white--text"
                                >{{ Math.ceil(index) }}%</span
                            >
                        </v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
            <!-- <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="dialogGuiaPDF = false">
                    Fechar
                </v-btn>
            </v-card-actions> -->
        </v-card>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-overlay :value="carregandoProgesso" opacity="0.10"> </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    inject: ["listaCampos", "usuarioTipo"],
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            dialogGuiaPDF: false,
            carregando: false,
            filtro: {
                id_filial: [],
            },
            lista: {
                filial: [],
                consolidado: [{ paginas: 0, tempo: 0 }],
            },
            index: 0,
            progresso: false,
            carregandoProgesso: false,
            email: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
        };
    },
    async created() {
        var lista = await this.listaCampos;

        this.lista.bandeira = lista.bandeira;
        this.lista.fornecedoresGerenciados = lista.fornecedoresGerenciados;
        this.lista.fornecedoresGerenciadosPorUsuarios = lista.fornecedoresGerenciadosPorUsuarios
        this.lista.cluster = lista.cluster;
        this.lista.espaco = lista.espaco;
        this.lista.status = lista.status;
        this.lista.statusGuia = ["NOVO", "ALTERADO", "MANTIDO", "CANCELADO"];

        this.iniciaFiltro();
    },
    computed: {
        marcoTodosFornecedores () {
            return this.filtro.id_fornecedor.length === this.lista.fornecedoresGerenciados.length
        },
        marcoAlgunsFornecedores () {
            return this.filtro.id_fornecedor.length > 0 && !this.marcoTodosFornecedores
        },
        icon () {
            if (this.marcoTodosFornecedores) return 'mdi-close-box'
            if (this.marcoAlgunsFornecedores) return 'mdi-checkbox-blank-outline'
            return 'mdi-checkbox-blank-outline'
        },
        filiaisLista() {
            return this.lista.filial.filter(
                (e) => (this.filtro.bandeira || e.bandeira) == e.bandeira
            );
        },
        filiais() {
            return this.filtro.id_filial.length
                ? this.filtro.id_filial.length
                : this.filiaisLista.length;
        },
        paginas() {
            if (this.filtro.id_filial.length) {
                let _dados = this.filiaisLista
                    .filter((e) => this.filtro.id_filial.includes(e.value))
                    .map((e) => e.paginas * 1)
                    .reduce((a, c) => a + c, 0);

                return _dados;
            } else {
                return this.filiaisLista.length
                    ? this.filiaisLista
                          .map((e) => e.paginas * 1)
                          .reduce((a, c) => a + c, 0)
                    : 0;
            }
        },
        usuario() {
            return this.$store.getters.getUsuario;
        },
        tempo() {
            if (this.filtro.id_filial.length) {
                return (
                    Math.ceil(
                        this.filiaisLista
                            .filter((e) =>
                                this.filtro.id_filial.includes(e.value)
                            )
                            .map((e) => e.tempo * 1)
                            .reduce((a, c) => a + c, 0) / 1000
                    ) + " segundos"
                );
            } else {
                return this.filiaisLista.length
                    ? Math.ceil(
                          this.filiaisLista
                              .map((e) => e.tempo * 1)
                              .reduce((a, c) => a + c, 0) / 1000
                      ) + " segundos"
                    : 0 + " segundos";
            }
        },
    },
    methods: {
        changed () {
            // var el = this.$refs.selector.$el.getElementsByClassName('v-select__slot')[0]
        },
        toggle () {
      
            this.$nextTick(() => { 
            
                var text = "" 
                
                if (this.marcoTodosFornecedores) {
                    this.filtro.id_fornecedor = []
                    text = "Nenhum fornecedore"
                } else {
                    this.filtro.id_fornecedor = this.lista.fornecedoresGerenciados.map(item => {
                        return item.value
                    })
                    text = "Todos Fornecedores"
                }
                
                
            })
        },
        iniciaFiltro() {
            this.filtro = {
                mes: this.$moment().format("YYYY-MM"),
                bandeira: null,
                id_fornecedor: [],
                id_cluster: [],
                id_espaco: [],
                id_filial: [],
                id_status: [],
                id_usuario: [],
                status: [],
                id_acordo: null,
                acordo_rd: null,
            };
            if (this.lista.fornecedoresGerenciados.length == 1) {
                this.filtro.id_fornecedor = [this.lista.fornecedoresGerenciados[0].value];
            }

            this.progresso = false;
        },
        guiaPDF() {
            this.dialogGuiaPDF = true;

            this.iniciaFiltro();

            this.filtrar();
            
            this.$gtag.pageview({ page_title: `Guia PDF` })

        },
        limpar() {
            this.iniciaFiltro();

            this.filtrar();
        },
        async filtrar() {
            this.carregando = true;

            if (this.filtro.id_usuario.length > 0) {
                for(const item of this.filtro.id_usuario) {
                    for(const itemProcurado of this.lista.fornecedoresGerenciadosPorUsuarios) {
                        if (item == itemProcurado.value) {
                            this.filtro.id_fornecedor = [...this.filtro.id_fornecedor,...itemProcurado.id_fornecedor]
                        }
                    }
                }
            }
            this.filtro.id_fornecedor.filter((value,index) => this.filtro.id_fornecedor.indexOf(value) !== index)

            var data = { ...this.filtro };

            data.mes = this.filtro.mes ? [this.filtro.mes + "-01"] : [];
            data.id_acordo = this.filtro.id_acordo
                ? [this.filtro.id_acordo]
                : [];
            data.acordo_rd = this.filtro.acordo_rd
                ? [this.filtro.acordo_rd]
                : [];
            data.bandeira = this.filtro.bandeira ? [this.filtro.bandeira] : [];

            delete data.id_usuario

            var response = await this.$http().post("/acordo/resumoGuiaPDF", {
                data: data,
            });

            this.filtro.id_filial = [];
            this.lista.filial = response.data.filial;
            this.lista.consolidado = response.data.consolidado;
            this.email = response.data.email;

            this.carregando = false;
        },
        async gerar(tipo = "zip") {
            var data = { ...this.filtro };

            if (this.filtro.id_usuario.length > 0) {
                for(const item of this.filtro.id_usuario) {
                    for(const itemProcurado of this.lista.fornecedoresGerenciadosPorUsuarios) {
                        if (item == itemProcurado.value) {
                            this.filtro.id_fornecedor = [...this.filtro.id_fornecedor,...itemProcurado.id_fornecedor]
                        }
                    }
                }
            }
            this.filtro.id_fornecedor.filter((value,index) => this.filtro.id_fornecedor.indexOf(value) !== index)

            data.mes = this.filtro.mes ? [this.filtro.mes + "-01"] : [];
            data.id_acordo = this.filtro.id_acordo
                ? [this.filtro.id_acordo]
                : [];
            data.acordo_rd = this.filtro.acordo_rd
                ? [this.filtro.acordo_rd]
                : [];
            data.tipo = tipo;
            data.bandeira = this.filtro.bandeira ? [this.filtro.bandeira] : [];

            delete data.id_usuario

            switch (tipo) {
                case "email":
                    this.carregando = true;

                    data.api = this.$http("baseURL");
                    var response = await this.$http().get("/acordo/guiaPDF/", {
                        params: data,
                    });

                    this.alerta = true;
                    this.alertaColor = response.data.sucesso ? "info" : "error";
                    this.alertaTimeout = -1;
                    this.alertaTexto = response.data.msg;

                    this.carregando = false;
                    break;
                case "emailfornecedor":
                    this.carregando = true;

                    data.api = this.$http("baseURL");
                    var responseFornecedor = await this.$http().get("/acordo/guiaPDF/", {
                        params: data,
                    });

                    this.alerta = true;
                    this.alertaColor = responseFornecedor.data.sucesso ? "info" : "error";
                    this.alertaTimeout = -1;
                    this.alertaTexto = responseFornecedor.data.msg;

                    this.carregando = false;
                    break;                    
                case "sftp":
                    this.carregando = true;

                    data.api = this.$http("baseURL");
                    var _resp = await this.$http().get("/acordo/guiaPDF/", {
                        params: data,
                    });

                    this.alerta = true;
                    this.alertaColor = _resp.data.sucesso
                        ? "success"
                        : "warning";
                    this.alertaTimeout = -1;
                    this.alertaTexto = _resp.data.msg;

                    this.carregando = false;
                    break;
                default:
                    this.carregandoProgesso = true;
                    this.index = 0;
                    this.progresso = true;
                    var url = "";
                    var msgError = "";

                    await this.$http().get("/acordo/guiaPDF/", {
                        params: data,
                        onDownloadProgress: (progressEvent) => {
                            let retorno =
                                progressEvent.currentTarget.response.split(";");

                            let _i =
                                retorno.length > 1 ? retorno.length - 2 : 0;
                            let obj = JSON.parse(retorno[_i]);

                            this.index = obj.index || 0;

                            if (obj.url) {
                                url = obj.url;
                            }

                            if (obj.msg) msgError = obj.msg;
                        },
                    });

                    if (url != "") {
                        window.location = this.$http("baseURL") + url;
                    }

                    this.progresso = false;
                    this.carregandoProgesso = false;

                    if (msgError != "") {
                        this.alerta = true;
                        this.alertaColor = "error";
                        this.alertaTimeout = -1;
                        this.alertaTexto = msgError;
                    }
                    break;
            }
        },
    },
};
</script>
