<template>
  <v-card class="br-15 pa-3 px-5 overflow-hidden" elevation="0">
    <InfoBox expands>
      <span class="titulo"> Orientações de Execução: </span> Visualização das
      orientações para a simulação, clicar para visualizar o PDF no navegador.
    </InfoBox>
    <h2 class="text-center" style="font-weight: normal">
      Orientações de Execução
    </h2>
    <p class="sub-title mb-5">
      Acesse o PDF abaixo para vizualizar <br />
      o material completo
    </p>
    <div style="height: 220px">
      <div class="center-box">
        <div class="bg"></div>
        <v-img
          src="@/assets/images/livro.png"
          height="174"
          class="livro"
          contain
        ></v-img>
      </div>
      <div class="d-flex justify-center mt-5">
        <v-btn
          class=""
          :disabled="empty"
          :href="link"
          target="_blank"
          color="primary"
        >
          Acessar Material</v-btn
        >
      </div>
      <Overlay :overlay="false" :error="error" :empty="empty"></Overlay>
    </div>
  </v-card>
</template>

<script>
import colors from "vuetify/es5/util/colors";
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox,
  },
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      link: null,
    };
  },
  computed: {
    anoAtual() {
      return this.$moment().format("YYYY");
    },
    anoAnterior() {
      return this.$moment().subtract(1, "year").format("YYYY");
    },
  },
  methods: {
    async getDados() {
      this.overlay = true;
      this.error = false;
      this.empty = false;

      const response = await this.$http().post("/simulacao/resumo/orientacoes", {
        data: {
          id_fornecedor: this.fornecedor.value,
          ano_atual: this.ano,
        },
      });
      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        this.error = true;
        return;
      }

      if (response.data.length == 0) {
        this.empty = true;
        return (this.overlay = false);
      }

      this.link = this.$assinarUrl( response.data[0].dados);
      this.overlay = false;
    },
  },
  created() {},
  watch: {
    $props: {
      handler() {
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>
<style scoped>
.sub-title {
  font-size: 0.8rem;
  color: #808080;
  text-align: center;
}
.center-box {
  width: 100%;
  height: 156px;
  margin: 10px 0;
  position: relative;
}
.center-box .bg {
  content: "";
  width: 100%;
  height: calc(100% - 40px);
  margin: 20px 0;
  border-radius: 8px;

  background: #f2f2f2;
  position: absolute;
}

.livro {
  margin-top: -10px;
}
</style>
