var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-toolbar-title',{staticClass:"ml-2 mt-2"},[_vm._v("Permissões")])],1)],1),_c('v-card',{staticClass:"mt-2 py-4 px-2",attrs:{"elevation":"1"}},[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-select',{attrs:{"label":"Tipo de Permissão","items":_vm.lista.menu,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.id_menuBusca),callback:function ($$v) {_vm.id_menuBusca=$$v},expression:"id_menuBusca"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4","align":"end"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","dense":"","outlined":"","hide-details":""},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dados,"items-per-page":5,"search":_vm.busca,"footer-props":{
                'items-per-page-options': [5, 10, 15, 25, 50, 100]
            },"options":_vm.pagination,"multi-sort":"","show-select":"","item-key":"id_usuario"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.botao",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary","title":"Editar"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"ml-4 mr-2",attrs:{"small":"","color":"primary","title":"Redefinir Senha"},on:{"click":function($event){_vm.dialogoRedefinir=true; _vm.usuario=Object.assign({}, item);}}},[_vm._v(" mdi-lock-reset ")])]}}],null,true),model:{value:(_vm.permissoes),callback:function ($$v) {_vm.permissoes=$$v},expression:"permissoes"}}),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4","lg":"4","xl":"4"}},[_c('v-select',{attrs:{"label":"Tipo de Permissão","items":_vm.lista.menu,"multiple":"","outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(_vm.id_menu.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.id_menu.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.id_menu.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.id_menu),callback:function ($$v) {_vm.id_menu=$$v},expression:"id_menu"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2","lg":"2","xl":"2","align":"end"}},[_c('v-btn',{staticClass:"pr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.salvar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Salvar Permissões ")],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.carregando,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{staticStyle:{"z-index":"400"},attrs:{"color":_vm.alertaColor,"timeout":_vm.alertaTimeout,"top":""},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_c('v-alert',{attrs:{"type":_vm.alertaColor}},[_vm._v(" "+_vm._s(_vm.alertaTexto)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta=false}}},[_vm._v(" Fechar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }