var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formFiltro"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{staticClass:"input-cinza",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","dense":"","outlined":"","hide-details":""},model:{value:(_vm.filtro.busca),callback:function ($$v) {_vm.$set(_vm.filtro, "busca", $$v)},expression:"filtro.busca"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('MesInput',{attrs:{"label":"Período Inicial","rules":_vm.regra.mesInicial,"min":'2022-07',"required":""},model:{value:(_vm.filtro.mesInicial),callback:function ($$v) {_vm.$set(_vm.filtro, "mesInicial", $$v)},expression:"filtro.mesInicial"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('MesInput',{attrs:{"label":"Período Final","rules":_vm.regra.mesFinal,"min":'2022-07',"required":""},model:{value:(_vm.filtro.mesFinal),callback:function ($$v) {_vm.$set(_vm.filtro, "mesFinal", $$v)},expression:"filtro.mesFinal"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Filial","items":_vm.lista.filial,"menu-props":_vm.menuProps,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_filial.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_filial.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_filial.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_filial),callback:function ($$v) {_vm.$set(_vm.filtro, "id_filial", $$v)},expression:"filtro.id_filial"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Região","items":_vm.lista.regiao,"menu-props":_vm.menuProps,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.regiao.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.regiao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.regiao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.regiao),callback:function ($$v) {_vm.$set(_vm.filtro, "regiao", $$v)},expression:"filtro.regiao"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Cluster","items":_vm.lista.cluster,"multiple":"","outlined":"","menu-props":_vm.menuProps,"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_cluster.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.id_cluster.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_cluster.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_cluster),callback:function ($$v) {_vm.$set(_vm.filtro, "id_cluster", $$v)},expression:"filtro.id_cluster"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Status","items":_vm.lista.status,"multiple":"","outlined":"","menu-props":_vm.menuProps,"dense":""},model:{value:(_vm.filtro.status),callback:function ($$v) {_vm.$set(_vm.filtro, "status", $$v)},expression:"filtro.status"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Bandeira","items":['DROGASIL', 'RAIA'],"multiple":"","outlined":"","menu-props":_vm.menuProps,"dense":""},model:{value:(_vm.filtro.bandeira),callback:function ($$v) {_vm.$set(_vm.filtro, "bandeira", $$v)},expression:"filtro.bandeira"}})],1),_c('v-col',{staticClass:"ml-auto py-0",attrs:{"cols":"12","align":"end"}},[_c('v-btn',{staticClass:"ml-4 new-button",attrs:{"color":"yellow","dark":""},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-4 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }