<template>
    <div>
        <v-form ref="formFiltro" @submit.prevent>
            <v-row>
                <v-col cols="12" sm="12" md="2" lg="2">
                    <v-toolbar-title class="page-title">Resumo</v-toolbar-title>
                </v-col>
                <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                    <!-- <v-text-field
                        v-if="dataUsuarioTipo == 'fornecedor'"
                        label="Fornecedor"
                        v-model="filtros.fornecedor"
						class="input-cinza"
                        disabled
                        outlined
                        dense
                    /> -->
                    <v-autocomplete
                        
                        label="Fornecedor"
                        :return-object="false"
                        class="input-cinza"
                        v-model="fornecedorProvider.id_fornecedor"
                        :items="dataFornecedor"
                        :rules="regra.obg_fornecedor"
                        :menu-props="menuProps"
                        outlined
                        
                        dense
                    />
                </v-col>
                <v-col cols="6" sm="6" md="3" lg="3" xl="3">
                    <v-select
                        label="Ano"
                        v-model="fornecedorProvider.ano"
                        class="input-cinza"
                        :menu-props="menuProps"
                        :items="dataAno"
                        :rules="regra.obrigatorio"
                        outlined
                        dense
                    />
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        :disabled="false"
                        :loading="false"
                        @click="submitForm('filtrar')"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-n2 mb-4"></v-divider>

            <v-row>
                <v-col class="py-0" cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-row>
                        <v-col cols="1" class="mt-1">
                            <InfoBox expands>
                                <span class="titulo"
                                    >Exportar consolidado:</span
                                >
                                Esta exportação traz todas as informações atuais
                                de todas as simulações realizadas até o momento,
                                onde é possivel ter a visualização separada em
                                guias por tipo e categoria de simulação.
                                <v-divider class="my-3"></v-divider>
                                <span class="titulo">Exportar aprovados:</span>
                                Esta exportação é ideal para resgatar o
                                histórico das simulações que foram aprovadas
                                pelo fornecedor, caso as mesmas precisem ser
                                visualizadas ou analisadas novamente. Nesta
                                exportação também é possivel visualizar todos os
                                tipos de simulação no mesmo arquivo, seprados
                                por guias.
                            </InfoBox>
                        </v-col>
                        <v-col cols="11">
                            <v-btn
                                class="new-button w-100"
                                color="primary"
                                :loading="carregando"
                                @click="submitForm('exportar')"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar Consolidado
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-btn
                        class="new-button w-100"
                        color="primary"
                        :loading="carregandoAprovado"
                        @click="exportarAprovados()"
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon>
                        Exportar Aprovados
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <Aprovacao
                        :usuarioTipo="dataUsuarioTipo"
                        :id_fornecedor="filtros.id_fornecedor"
                        :ano="filtros.ano"
                    />
                </v-col>
            </v-row>
        </v-form>
        <Overlay :overlay="overlay" />

        <v-dialog v-model="dialogo" width="500px">
            <v-card class="radius-10">
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text"
                >
                    <v-toolbar-title class="px-1"
                        >Exportar aprovados</v-toolbar-title
                    >
                    <v-spacer></v-spacer>

                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        color="#fff"
                        @click="dialogo = false"
                    >
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="px-5 py-5">
                    <v-btn
                        v-for="(item, index) in exportarVersoes"
                        :key="index"
                        class="new-button w-100 my-3"
                        color="primary"
                        :loading="carregandoAprovado"
                        @click="exportarAprovadosVersao(item)"
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon>
                        Exportar versão {{ item.versao }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

    <!-- </v-card> -->
    <!-- </div> -->
</template>

<script>
import Overlay from "./Overlay.vue";
import Aprovacao from "./../../aprovacao/Aprovacao.vue";
import InfoBox from "./InfoBox.vue";

export default {
    components: {
        Overlay,
        Aprovacao,
        InfoBox,
    },
    inject: ["fornecedorProvider"],
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            dialogo: false,
            carregando: false,
            carregandoAprovado: false,
            overlay: false,
            dataFornecedor: [],
            exportarVersoes: [],
			fornecedor: {
				text:''
			},
            dataAno: [parseInt(this.$moment().format("YYYY"))],
            dataUsuarioTipo: "fornecedor",
            filtros: this.fornecedorProvider,
            regra: {
                // obrigatorio: [(v) => !!v || "Campo obrigatório."],
                obrigatorio: [function(v) {
                    console.log(v,!!v)
                    return !!v || "Campo obrigatório."
                }],
                // obg_fornecedor: [(v) => !!v || "Campo obrigatório."],
                obg_fornecedor: [function(v) {
                    console.log(v,!!v)
                    return !!v || "Campo obrigatório."
                }],
            },
        };
    },
    // watch: {
    // 	"filtros.fornecedor"(newVal) {
    // 		this.$emit("changeFornecedor", newVal);
    // 	},
    // },
    methods: {
        async getFiltros() {
            this.overlay = true;
            const _responseFornecedor = await this.$http().post(
                "/simulacao/resumo/filtroFornecedores",
                {
                    data: {},
                }
            );

            const _responseAnos = await this.$http().post(
                "/simulacao/resumo/filtroAnosResmo",
                {
                    data: {},
                }
            );

            //console.log("Filtros=>", response);

            if (
                (_responseFornecedor || { status: 500 }).status != 200 ||
                (_responseAnos || { status: 500 }).status != 200
            ) {
                this.overlay = false;
                return this.$dialog.notify.warning(
                    "Ops! Falha ao carregar os filtros, verifique a sua internet e tente novamente!",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
            }

            this.dataFornecedor = _responseFornecedor.data.fornecedor;
            this.dataUsuarioTipo = _responseFornecedor.data.usuarioTipo;

            if (_responseAnos.data.length > 0) {
                this.dataAno = _responseAnos.data;
            }

            // if (!this.fornecedorProvider.id_fornecedor) {
            //     if (this.dataUsuarioTipo == "rd") {
            //         this.fornecedorProvider.id_fornecedor =
            //             this.dataFornecedor[0].value;
            //     } else if (this.dataUsuarioTipo == "fornecedor") {
            //         this.fornecedorProvider.id_fornecedor =
            //             this.dataFornecedor[0].id_fornecedor;
            //         this.fornecedorProvider.fornecedor = this.dataFornecedor[0].fornecedor;
            //     }
            // }
            // if (!this.fornecedorProvider.id_fornecedor) {
                // if (this.dataUsuarioTipo == "rd") {
                    this.fornecedorProvider.id_fornecedor =
                        this.dataFornecedor[0].value;
                // } else if (this.dataUsuarioTipo == "fornecedor") {
                    // this.fornecedorProvider.id_fornecedor =
                        // this.dataFornecedor[0].id_fornecedor;
                    this.fornecedorProvider.fornecedor = this.dataFornecedor[0].text;
                // }
            // }
            // console.log('passou aqui', this.fornecedorProvider)


            setTimeout(() => {

                this.overlay = false;
    
                this.submitForm("filtrar");
            },1000)

        },
        submitForm(action) {
            // console.log(this.fornecedorProvider)
            const validForm = this.$refs.formFiltro.validate();
            if (!validForm) return;

            // console.log(validForm, this.filtros);

            switch (action) {
                case "filtrar":
                    this.$emit("filtrar", this.filtros);
                    break;
                case "exportar":
                    this.exportar();
                    break;
            }
        },
        async exportar() {
            this.carregando = true;
            const fonercedor = this.dataFornecedor.find(
                (item) => item.value == this.filtros.id_fornecedor
            );
            const _simulacao = {
                usuarioTipo: this.dataUsuarioTipo,
                id_fornecedor: this.filtros.id_fornecedor,
                fornecedor: fonercedor.text,
                ano: this.filtros.ano,
            };

            //console.log(_simulacao);

            var response = await this.$http().post(
                "/simulacao/resumo/exportar",
                {
                    data: { simulacao: _simulacao },
                }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async exportarAprovadosVersao(item) {
            if (!this.$refs.formFiltro.validate()) return;

            this.carregandoAprovado = true;
            const fonercedor = this.dataFornecedor.find(
                (item) => item.value == this.filtros.id_fornecedor
            );
            const _simulacao = {
                usuarioTipo: this.dataUsuarioTipo,
                id_fornecedor: this.filtros.id_fornecedor,
                fornecedor: fonercedor.text,
                ano: this.filtros.ano,
                versao: item.versao,
                usuario_aprovacao: item.usuario_aprovacao,
                data_aprovacao: item.data_aprovacao,
            };

            var response = await this.$http().post(
                "/simulacao/resumo/exportarAprovados",
                { data: { simulacao: _simulacao } }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregandoAprovado = false;
        },
        async exportarAprovados() {
            this.dialogo = true;
            const _exportarVersoes = await this.$http().post(
                "/simulacao/resumo/exportarVersoes",
                {
                    data: {
                        ano: this.filtros.ano,
                        id_fornecedor: this.filtros.id_fornecedor,
                    },
                }
            );
            if (_exportarVersoes.data) {
                this.exportarVersoes = _exportarVersoes.data;
            }
            // return
        },
    },
    created() {
        this.getFiltros();
    },
};
</script>

<style></style>
