<template>
    <v-container class="pt-0" fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col cols="8">
                    <v-toolbar-title class="page-title"
                        >Acordos Comerciais</v-toolbar-title
                    >
                </v-col>
                <v-col cols="2">
                    <v-tooltip top :disabled="!travaInventario">
                        <template v-slot:activator="{ on }">
                            <div     v-on="on">
                    <v-btn
                        class="new-button w-100"
                        color="primary"
                        :disabled="travaInventario" 
                        @click="$refs.formulario.novo()"
                        v-if="usuarioTipo == 'rd'"
                    >
                        <v-icon left>mdi-plus-thick</v-icon> Criar acordo
                    </v-btn>
                </div>
                        </template>
                        <span>Criação de acordo desabilitada até a finalização da aprovação do inventário.</span>
                        </v-tooltip>
                </v-col>
        
                <v-col cols="2">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                class="new-button w-100"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportar('acordos')">
                                <v-list-item-title>Acordos</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportar('produtosPDV')">
                                <v-list-item-title>Produtos PDV</v-list-item-title>
                            </v-list-item>
                            <v-list-item  v-if="usuarioTipo != 'cubo'" @click="exportar('produtosEcommerce')">
                                <v-list-item-title>Produtos Ecommerce</v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="usuarioTipo != 'cubo'" @click="exportar('fornecedor')">
                                <v-list-item-title>Fornecedor</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <filtro ref="filtro" @filtrar="filtrar" @limpar="limpar"></filtro>
        </v-card>
        <indicadores
            v-if="usuarioTipo == 'rd'"
            ref="indicadores"
            :indicadores="indicadores"
            :filtro="$refs.filtro"
        ></indicadores>
        <formulario ref="formulario" @filtrar="filtrar"></formulario>
        <v-card elevation="0" class="br-15 pa-5 involucroAcordo">
            <v-data-table
                v-model="acordoSelecionado"
                :headers="headers"
                :items="acordos"
                :server-items-length="totalAcordos"
                item-key="id_acordo_campanha"
                show-select
                fixed-header
                multi-sort
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 50, 100, 500, -1],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                }"
                :options.sync="pagination"
                class="tabela paginacao-esquerda flex-table"
            >
                <template
                    v-slot:[`item.data-table-select`]="{
                        item,
                        isSelected,
                        select,
                    }"
                >
                    <v-simple-checkbox
                        :ripple="false"
                        :value="isSelected"
                        :disabled="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                        "
                        :on-icon="
                            (!fnLiberar &&
                                !statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (fnLiberar &&
                                (!statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != mesAtual))
                                ? 'mdi-checkbox-blank-outline'
                                : 'mdi-checkbox-marked'
                        "
                        @input="select($event)"
                    ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu
                        offset-y
                        min-width="180"
                        content-class=" acordo-menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                color="#f2f2f2"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                                style="min-width: unset"
                            >
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) &&
                                        fnAprovar &&
                                        usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="$refs.dialogo.reprovar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="error">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Reprovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) &&
                                        fnAprovar &&
                                        usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="$refs.dialogo.aprovar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="success"
                                            >mdi-check</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Aprovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        (item.mes_acordo > mesAtual ||
                                            (item.mes_acordo == mesAnterior &&
                                                item.retroativo)) &&
                                        statusAcesso(
                                            item.id_status,
                                            'editar'
                                        ) &&
                                        usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="$refs.formulario.editar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar Acordo
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        item.mes_acordo >= mesAtual &&
                                        statusAcesso(
                                            item.id_status,
                                            'replicar_cancelado'
                                        ) &&
                                        usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="
                                        $refs.formulario.replicarAcordo(
                                            item,
                                            'cancelado'
                                        )
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-autorenew</v-icon
                                        >
                                    </v-list-item-icon>

                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Replicar
                                            Cancelado</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="$refs.dialogo.historico(item)"
                                    v-if="['rd', 'cubo'].includes(usuarioTipo)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-file-document-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Histórico</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.editar(item, true)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-file-document-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Exibir Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        item.termino == item.mes_acordo &&
                                        item.termino >= mesAnterior &&
                                        statusAcesso(
                                            item.id_status,
                                            'renovar'
                                        ) &&
                                        usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="$refs.dialogo.renovacao(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-update</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Renovar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.planograma"
                                    link
                                    @click="$refs.dialogo.planograma(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-image</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Planograma</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        statusAcesso(
                                            item.id_status,
                                            'editar_planograma'
                                        ) && ['rd', 'cubo'].includes(usuarioTipo))
                                    "
                                    link
                                    @click="
                                        $refs.dialogo.planogramaEditar(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-image-edit</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar
                                            Planograma</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.obs_departamento &&
                                        usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="
                                        $refs.dialogo.dialogDepartamento(item)
                                    "
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-clipboard-text-outline</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Obs.
                                            Departamento</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="
                                        item.obs_contrato && usuarioTipo == 'rd'
                                    "
                                    link
                                    @click="$refs.dialogo.dialogContrato(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="primary"
                                            >mdi-clipboard-text</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Obs. Contrato</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        statusAcesso(
                                            item.id_status,
                                            'cancelar'
                                        ) && usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="$refs.dialogo.cancelar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="error">mdi-close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Cancelar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="!travaInventario && (
                                        statusAcesso(
                                            item.id_status,
                                            'liberar'
                                        ) &&
                                        (item.mes_acordo == mesAtual ||
                                            item.retroativo) &&
                                        usuarioTipo == 'rd')
                                    "
                                    link
                                    @click="$refs.dialogo.liberar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="success"
                                            >mdi-check</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Liberar Acordo</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
                <!-- <template v-slot:[`item.oportunidade`]="{ item }">
					<v-btn
						small
						color="#f2f2f2"
						@click="oportunidadeAcordo(item)"
						class="grey--text"
					>
						<v-icon left>mdi-eye</v-icon> Visualizar
					</v-btn>
				</template> -->
            </v-data-table>

            <div
                class="table-footer-prepend d-flex pl-2 pt-4 align-center justify-end"
            >
                <v-btn
                    v-if="!fnLiberar"
                    class="new-button ml-5"
                    :disabled="travaInventario  || (
                        !acordoSelecionado.filter((e) =>
                            statusAcesso(e.id_status, 'aprovar_reprovar')
                        ).length || !fnAprovar)
                    "
                    color="primary"
                    @click="$refs.dialogo.aprovar()"
                >
                    <v-icon left>mdi-check-circle</v-icon> Aprovar Acordos
                </v-btn>
                <v-btn
                    v-if="!fnLiberar"
                    class="new-button ml-5"
                    :disabled="travaInventario  || (
                        !acordoSelecionado.filter((e) =>
                            statusAcesso(e.id_status, 'aprovar_reprovar')
                        ).length || !fnAprovar)
                    "
                    color="error"
                    @click="$refs.dialogo.reprovar()"
                >
                    <v-icon left>mdi-close-circle</v-icon> Reprovar acordos
                </v-btn>
                <v-btn
                    v-if="fnLiberar"
                    class="new-button ml-5"
                    :disabled="
                        !acordoSelecionado.filter(
                            (e) =>
                                statusAcesso(e.id_status, 'liberar') &&
                                e.mes_acordo == mesAtual &&
                                        usuarioTipo == 'rd'
                        ).length
                    "
                    color="primary"
                    @click="$refs.dialogo.liberar()"
                >
                    <v-icon left>mdi-check</v-icon> Liberar acordos
                </v-btn>
            </div>

            <dialogo
                ref="dialogo"
                @aprovarAcordo="aprovarAcordo"
                @liberarAcordo="liberarAcordo"
                @filtrar="filtrar"
                @renovar="renovar"
            ></dialogo>

            <formulario ref="formulario" @filtrar="filtrar"></formulario>

            <v-overlay :value="carregando">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>

            <v-snackbar
                v-model="alerta"
                :color="alertaColor"
                :timeout="alertaTimeout"
                top
                style="z-index: 400"
            >
                <v-alert :type="alertaColor">
                    {{ alertaTexto }}
                </v-alert>
                <v-btn dark text class="float-right" @click="alerta = false">
                    Fechar
                </v-btn>
            </v-snackbar>

            <!-- <guiapdf ref="guiapdf"></guiapdf> -->
        </v-card>
    </v-container>
</template>

<script>
import Filtro from "./Filtro.vue";
import Dialogo from "./Dialogo.vue";
import Indicadores from "./Indicadores.vue";
import Formulario from "./formulario/Formulario.vue";
import useFiltros from "../../../hooks/useFiltros";

export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros("/acordoCampanhas/listaCampos");

        return { isLoading, isError, isFetching, lista, error, refetch };
    },
    components: {
        Filtro,
        Dialogo,
        Indicadores,
        Formulario,
        // Guiapdf,
    },
    provide() {
        return {
            listaCampos: this.listaCampos(),
            usuarioTipo: this.usuarioTipo,
        };
    },
    data() {
        return {
            headers: [
                {
                    text: "Menu",
                    value: "actions",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Acordo",
                    value: "id_acordo_campanha",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Acordo RD",
                    value: "acordo_rd",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Fornecedor",
                    value: "fornecedor",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Espaço",
                    value: "espaco_pdv",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Espaço Macro",
                    value: "espaco_macro_pdv",
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "Bandeira",
                //     value: "bandeira",
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Mês",
                    value: "mes",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Ano",
                    value: "ano",
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "Oportunidade",
                //     value: "oportunidade",
                //     sortable: false,
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Contrato",
                    value: "id_contrato_campanha",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Contrato RD",
                    value: "contrato",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Parcela",
                    value: "parcela",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },

                {
                    text: "Campanha",
                    value: "campanha",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cluster/Rede",
                    value: "cluster_rede",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cota",
                    value: "cota",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Just. Desconto",
                    value: "justificativa",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Just. Reprov.",
                    value: "justificativa_reprovacao",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Just. Cancel.",
                    value: "justificativa_cancelamento",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Produtos",
                    value: "produtos",
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "VB",
                //     value: "vb",
                //     sortable: false,
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Filiais",
                    value: "filiais",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Visivel",
                    value: "visivel",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Valor s/ Desc.",
                    value: "valor",
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "% Padrão",
                //     value: "desconto_padrao",
                //     align: "center",
                //     class: "th-separador   ",
                // },
                // {
                //     text: "% Fora",
                //     value: "desconto_percentual",
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Desconto",
                    value: "desconto",
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "Total Desc.",
                //     value: "desconto_total",
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Invest.",
                    value: "investimento",
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cluster Acordo",
                    value: "cluster_nome",
                    sortable: false,
                    align: "center",
                    class: "th-separador   ",
                },
                // {
                //     text: "Cluster Filiais",
                //     value: "cluster",
                //     sortable: false,
                //     align: "center",
                //     class: "th-separador   ",
                // },
                // {
                //     text: "Modelo Check-out Acordo",
                //     value: "check_out_modelo",
                //     sortable: false,
                //     align: "center",
                //     class: "th-separador   ",
                // },
                // {
                //     text: "Modelo Check-out Filiais",
                //     value: "check_out_organico",
                //     sortable: false,
                //     align: "center",
                //     class: "th-separador   ",
                // },
                {
                    text: "Id Acordo PDV",
                    value: "id_acordo_pdv",
                    sortable: true,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Usuário que Criou",
                    value: "usuario_criacao",
                    sortable: true,
                    align: "center",
                    class: "th-separador   ",
                },
                {
                    text: "Cód. Fornecedor",
                    value: "codigo",
                    align: "center",
                    class: "th-separador   ",
                },
            ],
            acordos: [],
            usuarioTaipo: "rd",
            indicadores: {},
            planograma: [],
            statusIndicadores: [],
            totalAcordos: 0,
            pagination: {},
            carregando: false,
            acordoSelecionado: [],
            justificativas: [],
            status: [],
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            fnAprovar: false,
            fnLiberar: false,
        };
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        mesAnterior() {
            return this.$moment().subtract(1, "months").format("YYYY-MM");
        },
        mesProximo() {
            return this.$moment().add(1, "months").format("YYYY-MM");
        },
        usuarioTipo() {
            const usuario = this.$store.getters.getUsuario;
            if (usuario.id_fornecedor) {
                return "fornecedor";
            }
            if(usuario.cargo == "Cubo"){
                return "cubo";
            }
            return "rd";
        },
        travaInventario() {
            if(this.lista.inventarioUltimoHistorico){
                return this.lista.inventarioUltimoHistorico.importacaoAndamento
            }
            return true
        },

    },
    async beforeCreate() {
        var response = await this.$http().post("/acordo/status", {});

        this.status = response.data;

        response = await this.$http().post("/acordo/permissao", { id_menu: 2 });

        this.fnAprovar = response.data;
    },
    watch: {
        pagination: {
            handler() {
                this.filtrar(false);
            },
            deep: true,
        },
    },
    methods: {
        async filtrar(paginaInicial = true) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            this.acordoSelecionado = [];

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial
                ? filtro.mesInicial
                : this.$moment().format("YYYY-MM");
            data.mesFinal = filtro.mesFinal
                ? filtro.mesFinal
                : this.$moment().format("YYYY-MM");
            data.tipoPeriodo = filtro.tipoPeriodo ? filtro.tipoPeriodo : -1;

            data.cluster_rede = filtro.cluster_rede;
        
            data.id_espaco_pdv = filtro.id_espaco_pdv;
            data.espaco_macro = filtro.espaco_macro;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo_campanha = filtro.id_acordo_campanha
                ? [filtro.id_acordo_campanha]
                : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_contrato_campanha = filtro.id_contrato_campanha
                ? [filtro.id_contrato_campanha]
                : [];
            data.clusters = filtro.id_cluster ? filtro.id_cluster : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];
            data.visivel = filtro.visivel ? [filtro.visivel] : [];
            data.planograma = filtro.planograma;
            data.statusIndicadores = this.statusIndicadores;

            data.id_usuario_criacao = filtro.id_usuario_criacao

            if (paginaInicial) this.pagination.page = 1;

            data.pagination = this.pagination;

            var response = await this.$http().post("/acordoCampanhas/lista", {
                data: data,
            });

            this.acordos = response.data.lista;
            this.totalAcordos = response.data.total;
            this.indicadores = response.data.indicadores;

            this.fnLiberar = ((data.id_status[0] == 29) && (([1,2].includes(this.$store.getters.getUsuario.id_cargo)))) == 29 ? true : false;

            this.carregando = false;
        },
        statusAcesso(id_status, acesso) {
            if (this.status) {
                if (id_status === 30 && acesso === "editar_planograma")
                    return false;

                var status = this.status.find((e) => e.id_status == id_status);

                return status[acesso];
            } else {
                return false;
            }
        },
        limpar() {
            this.planograma = [];
            this.statusIndicadores = [];
            // this.fnLiberar = false;

            this.filtrar();
        },
        async exportar(tipoExportacao) {
            if (!this.$refs.filtro.validate()) return;

            const urls = {
                acordos: "/acordoCampanhas/exportar",
                produtosPDV: "/acordoCampanhas/exportarProdutosPDV",
                produtosEcommerce: "/acordoCampanhas/exportarProdutosEcommerce",
                fornecedor: "/acordoCampanhas/exportarAcordosFornecedor",
            }


            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_espaco = filtro.id_espaco;
            data.espaco_macro = filtro.espaco_macro;
            data.id_fornecedor = filtro.id_fornecedor;
            data.id_status = filtro.id_status ? filtro.id_status : [];
            data.id_acordo_campanha = filtro.id_acordo_campanha
                ? [filtro.id_acordo_campanha]
                : [];
            data.acordo_rd = filtro.acordo_rd ? [filtro.acordo_rd] : [];
            data.id_contrato_campanha = filtro.id_contrato_campanha
                ? [filtro.id_contrato_campanha]
                : [];
            data.id_cluster = filtro.id_cluster ? filtro.id_cluster : [];
            data.contrato = filtro.contrato ? [filtro.contrato] : [];
            data.visivel = filtro.visivel ? [filtro.visivel] : [];
            data.planograma = this.planograma;
            data.statusIndicadores = this.statusIndicadores;

            var response = await this.$http().post(
                urls[tipoExportacao],
                {
                    data: data,
                }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async aprovarAcordo(data) {
            this.carregando = true;

            data.acordos = data.id_acordo_campanha
                ? [data.id_acordo_campanha]
                : this.acordoSelecionado
                      .filter((e) =>
                          this.statusAcesso(e.id_status, "aprovar_reprovar")
                      )
                      .map((a) => a.id_acordo_campanha);

            var response = await this.$http().post(
                "/acordoCampanhas/aprovarAcordo",
                {
                    data: data,
                }
            );

            if (response.data.sucesso) {
                this.filtrar(false);
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }

            this.acordoSelecionado = [];
            this.carregando = false;
        },
        async liberarAcordo(data) {
            this.carregando = true;
            console.log(data);
            data.acordos = data.id_acordo_campanha
                ? [data.id_acordo_campanha]
                : this.acordoSelecionado
                      .filter(
                          (e) =>
                              this.statusAcesso(e.id_status, "liberar") &&
                              e.mes_acordo == this.mesAtual
                      )
                      .map((a) => a.id_acordo_campanha);

            var response = await this.$http().post(
                "/acordoCampanhas/liberarAcordo",
                {
                    data: data,
                }
            );

            if (response.data.sucesso) {
                await this.filtrar(false);
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }

            this.acordoSelecionado = [];
            this.carregando = false;
        },
        renovar() {
            this.$refs.formulario.editar(data.item);

        },

        async listaCampos() {
            var response = await this.$http().post(
                "/acordoCampanhas/listaCampos",
                {}
            );

            return response.data;
        },
    },
};
</script>

<style>
</style>