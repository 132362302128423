<template>
  <v-form>
    <v-menu v-model="menu1" :close-on-content-click="false" offset-y bottom width="0" content-class="select-outline"
      nudge-top="25px" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field readonly :placeholder="placeholder" v-bind:label="label" v-model="inputValue" v-bind:rules="rules"
          :class="{
            select: true,
            'input-cinza': true,
            active: menu1,
          }" v-bind:required="required" v-bind:disabled="disabled" outlined dense v-bind="attrs" v-on="on"
          @click:clear="date = null">
        </v-text-field>
      </template>
      <v-divider class="mt-0"></v-divider>
      <v-date-picker type="month" :min="min" :max="max" locale="pt-BR" v-model="cmpValue" @change="handleChange"
        color="primary" class="input-mes" multiple width="auto" :readonly="readonly" no-title></v-date-picker>
      <v-divider class="mt-0 mb-3"></v-divider>
      <v-row no-gutters>
        <v-col cols="6" class="pa-3">
          <v-btn v-if="!readonly" class="w-100" color="yellow" dark @click="limpar()">
            <v-icon left>mdi-filter-off</v-icon> Limpar
          </v-btn>
        </v-col>
      </v-row>
    </v-menu>
  </v-form>
</template>

<script>
export default {
  name: "MesInputMultiple",
  model: { prop: "value", event: "input" },
  props: [
    "value",
    "rules",
    "min",
    "max",
    "placeholder",
    "label",
    "required",
    "disabled",
    "change",
    "prepend-icon",
    "readonly"
  ],
  data() {
    return {
      mes: [],
      menu1: false,
    };
  },
  computed: {
    inputValue: {
      get: function () {
        if (this.value.length == 1) {
          return this.$moment(this.value[0], "YYYY-MM").format("MMMM [de] YYYY").capitalize()
        } else if (this.value.length > 1) {
          return `${this.value.length} meses selecionados`
        } else {
          return ""
        }
      }
    },
    cmpValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit("input", this.ordenarMeses(newValue));
      },
    },
  },
  methods: {

    limpar() {
      this.mes = null;
      this.$emit("input", []);
    },
    handleChange() {
      this.$emit("change", this.value);
      if(this.change)
        this.change(this.value);
    },
    // Orderna meses selecionados
    ordenarMeses(meses) {
      const mesesOrdenados = meses.map((m) => this.$moment(m, "YYYY-MM"));
      mesesOrdenados.sort((a, b) => {
        if (a.isBefore(b)) return -1;
        if (a.isAfter(b)) return 1;
        return 0;
      });
      return mesesOrdenados.map((m) => m.format("YYYY-MM"));

    },
  },
};
</script>

<style>
.input-cinza.active .v-input__slot {
  border-bottom: none;
}

.input-cinza.active fieldset legend {
  width: 0 !important;
}

.input-mes .v-date-picker-header__value button {
  font-weight: 500;
  color: var(--v-primary-base) !important;
}

.input-mes,
.input-mes .v-picker__body {
  width: 100% !important;
}

.input-mes .v-btn__content {
  text-transform: capitalize;
}

.input-mes input {
  text-transform: uppercase !important;
}
</style>