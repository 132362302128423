<template>
    <v-form ref="form" class="desconto">
        <v-row no-gutters class="mt-4 h-100">
            <v-col cols="3" class="px-2">
                <v-text-field
                    label="Desconto (%)"
                    v-model="descontoCampo.percentual"
                    class="input-cinza"
                    outlined
                    dense
                    v-mask="'###'"
                ></v-text-field>
            </v-col>
            <v-col cols="3" class="px-2">
                <vuetify-money
                    placeholder="Desconto (R$)"
                    v-model="descontoCampo.desconto"
                    class="input-cinza"
                    outlined
                    dense
                />
            </v-col>
            <v-col cols="3" class="px-2">
                <v-btn
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="carregarDesconto()"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Carregar Desconto
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="listaHeaderDesconto"
            :items="value.desconto"
            :item-class="investimentoClass"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
            :items-per-page="-1"

        >
            <template
                v-for="header in listaHeaderDesconto.filter((header) =>
                    header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ header, value }"
            >
                {{ header.formatter(value) }}
            </template>
            <template v-slot:[`item.percentual`]="{ item }">
                <v-text-field
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-mask="'###'"
                    class="input-cinza"
                    :placeholder="`0%`"
                    v-model="item.percentual"
                    @input="calculaDesconto()"
                ></v-text-field>
            </template>
            <template v-slot:[`item.desconto`]="{ item }">
                <vuetify-money
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    hide-details
                    v-model="item.desconto"
                    class="input-cinza"
                    @input="calculaDesconto()"
                />
            </template>
            <template v-slot:[`item.investimento`]="{ item }">
                <vuetify-money
                    v-if="item.mes != 'Total'"
                    :ripple="false"
                    dense
                    outlined
                    clearable
                    hide-details
                    :placeholder="formataReal(item.investimento)"
                    v-model="item.valor_personalizado"
                    class="input-cinza"
                  
                />
            </template>
            <template v-slot:[`item.justificativas`]="{ item }">
                <v-select
                    placeholder="Justificativa"
                    v-model="item.justificativa"
                    :items="justificativas"
                    item-text="justificativa"
                    item-value="id_justificativa"
                    dense
                    outlined
                    hide-details
                    class="input-cinza"
                    :rules="regras.justificativa"
                    :menu-props="menuProps"
                    @change="atualizaDescontos(item)"
                    v-if="
                        ((item.percentual != '' &&
                            item.percentual != undefined) ||
                            (item.desconto != '' &&
                                item.desconto != undefined &&
                                item.desconto != 0)) &&
                        item.mes != 'Total'
                    "
                    required
                ></v-select>
            </template>
            <template slot="body.append">
                <tr class="total">
                    <td :colspan="multiplaBandeira ? 2 : 1">Total</td>
                    <td>{{ formataReal(totalCusto) }}</td>
                    <td colspan="3"></td>

                    <td>{{ formataReal(totalDesconto) }}</td>
                    <td>{{ formataReal(totalInvestimento) }}</td>
                </tr>
            </template>
        </v-data-table>

        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <div v-if="dialogoNaoHaFiliaisFuturas" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Atenção não há filiais futuras para os seguintes meses desejados:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="mes in mesFaltandoFiliais"
                            :key="mes.mes"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    mes.mes
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
export default {
    props: ["value"],

    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            periodo: null,
            acordo: {},
            somaFiliais: [],
            somaFiliaisValor: [],
            dialogoCamposFaltando: false,
            desconto: [],
            descontoCampo: {
                percentual: null,
                desconto: null,
            },
            listaHeaderDescontoa: null,
            regras: {
                justificativa: [(v) => !!v || "O campo espaço é obrigatório"],
            },
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false /* doesn't work with directive */,
            },
            carregando: false,
            semAcesso: false,
            mesDesconto: [],
            justificativas: [],
            primeiraAlteracao: true,
            dialogoNaoHaFiliaisFuturas: false,
            erroFaltaFiliais: false,
            mesFaltandoFiliais: []
        };
    },
    async created() {
        const descontosJustificativas = (
            await this.$http().post("acordo/justificativa")
        ).data;
        this.justificativas = descontosJustificativas.map((i) => {
            return {
                justificativa: i.justificativa,
                id_justificativa: i.id_justificativa,
            };
        });
    },
    computed: {
        listaHeaderDesconto() {
            const header = [
                {
                    text: "Mês",
                    value: "mes",
                    align: "center",
                    class: "th-separador",
                },

                {
                    text: "Custo",
                    value: "valor",
                    align: "center",
                    class: "th-separador min-width-100",
                    formatter: this.formataReal,
                },
                {
                    text: "Percentual de desconto (%)",
                    value: "percentual",
                    align: "center",
                    class: "th-separador",
                    width: "14%",
                },
                {
                    text: "Desconto (R$)",
                    value: "desconto",
                    align: "center",
                    class: "th-separador",
                    width: "14%",
                    formatter: this.formataReal,
                },
                {
                    text: "Justificativa",
                    value: "justificativas",
                    align: "center",
                    class: "th-separador",
                    width: "14%",
                },
                {
                    text: "Total de Desconto (R$)",
                    value: "total",
                    align: "center",
                    class: "th-separador",
                    formatter: this.formataReal,
                },
                {
                    text: "Investimento (R$)",
                    value: "investimento",
                    align: "center",
                    class: "th-separador",
                    formatter: this.formataReal,
                },
            ];

            if (this.multiplaBandeira) {
                header.splice(1, 0, {
                    text: "Bandeira",
                    value: "bandeira",
                    align: "center",
                    class: "th-separador",
                });
            }
            return header;
        },
        multiplaBandeira() {
            if (this.value.bandeira) {
                return this.value.bandeira.length > 1;
            }
            return false;
        },
        totalCusto() {
            if (this.value.desconto) {
                return this.value.desconto.reduce((a, b) => a + b.valor, 0);
            }
            return 0;
        },
        totalInvestimento() {
            if (this.value.desconto) {
                return this.value.desconto.reduce(
                    (a, b) => a + Number(b.valor_personalizado || b.investimento ),
                    0
                );
            }
            return 0;
        },
        totalDesconto() {
            if (this.value.desconto) {
                return this.value.desconto.reduce((a, b) => a + b.total, 0);
            }
            return 0;
        },
        mostrarPreAcordo() {
            if (this.acordo.idStatus === 30) {
                return true;
            }
            const data = this.$moment().format("YYYY-MM");
            if (!this.acordo.idStatus && data !== this.acordo.mesInicial) {
                return true;
            }
            return false;
        },
        camposFaltando() {
            const campos = [];
            if (!this.value.id_fornecedor) {
                campos.push("Fornecedor");
            }
            if (!this.value.mesInicial) {
                campos.push("Mês Inicial");
            }
            if (!this.value.mesFinal) {
                campos.push("Mês Final");
            }
            if (!this.value.id_espaco) {
                campos.push("Espaço");
            }

            if (this.value.bandeira?.length === 0) {
                campos.push("Bandeira");
            }

            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            if (this.dialogoNaoHaFiliaisFuturas) {
                return true
            } 
            return false;
        },
    },

    methods: {
        async mesesFuturosSemFiliais() {
            var data = {
                mesInicial: this.value.mesInicial,
                mesFinal: this.value.mesFinal, 
            }
            console.log(this.value.bandeira)
            if (this.value.bandeira?.length > 0) {
                data['bandeira'] = this.value.bandeira
            }
            const response = await this.$http().post(
                "/acordoEcommerce/checkExistenciaFiliais",
                {
                    data
                }
            );
            this.dialogoNaoHaFiliaisFuturas = !response.data.haFiliaisParaTodosPeriodos
            this.erroFaltaFiliais = !response.data.haFiliaisParaTodosPeriodos
            this.mesFaltandoFiliais = response.data.meses
            console.log(this.erroNaoHaFiliais)
            this.erroNaoHaFiliais = !response.data.haFiliaisParaTodosPeriodos    
            return response.data.haFiliaisParaTodosPeriodos
        }, 
        formataReal(valor) {
            return Number(valor).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        atualizaDescontos(item) {
            if (this.primeiraAlteracao) {
                for (const d of this.value.desconto) {
                    if (d.mes != "Total") {
                        d.desconto = item.desconto;
                        d.percentual = item.percentual;
                        d.justificativa = item.justificativa;
                    }
                }
            }
            this.calculaDesconto();
            this.primeiraAlteracao = false;
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },

        getPeriodo(campo = "quantidade") {
            const mesAtual = new Date(this.value.mesInicial + "-15");
            const mesFinal = new Date(this.value.mesFinal + "-15");

            const periodo = [];

            while (mesAtual <= mesFinal) {
                periodo.push(
                    campo +
                        "_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1)
                );

                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return periodo;
        },
        getDesconto(periodo) {
            var mes =
                periodo.split("_")[1] +
                "-" +
                ("00" + periodo.split("_")[2]).substr(-2) +
                "-01";
            if (this.mesDesconto.length == 0) return 0;

            return this.mesDesconto.find((e) => e.mes.substr(0, 10) == mes)
                .desconto;
        },

        async montaTabela() {
            const periodo = this.getPeriodo();
            this.desconto = [];

            for (const p of periodo) {
                this.desconto.push({
                    mes: p.split("_")[1] + "-" + p.split("_")[2],
                    desconto_padrao: this.getDesconto(p),
                    percentual: null,
                    desconto: null,
                    justificativa: null,
                    total: null,
                    investimento: null,
                });
            }
        },
        async criaDesconto() {
            await this.mesesFuturosSemFiliais()
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
                return;
            }
            if (this.value.desconto.length > 0) {
                return;
            }

            this.dialogoCamposFaltando = false;

            this.carregando = true;

            const data = {
                ...this.value,
            };

            const custo = await this.$http().post("/acordoEcommerce/custo", {
                data,
            });

            this.value.desconto = custo.data.map((desconto) => {
                const retorno = {
                    mes: desconto.mes.substr(0, 10),
                    valor: desconto.valor.toFixed(2),
                    percentual: desconto.desconto_percentual,
                    desconto: desconto.desconto || 0,
                    desconto_padrao: 0,
                    valor_personalizado: desconto.valor_personalizado || null,
                    justificativa: desconto.id_justificativa,
                    id_acordo: desconto.id_acordo,
                    bandeira: desconto.bandeira,
                };
                if(desconto.desconto === 0){
                    retorno.desconto = null;
                }
                if(desconto.desconto_percentual === 0){
                    retorno.percentual = null;
                }
                if (desconto.desconto_percentual) {
                    retorno.total =
                        (desconto.valor * desconto.desconto_percentual) / 100 +
                        (desconto.desconto || 0);
                }
                 else {
                    retorno.total =
                        (desconto.valor * 0) / 100 +
                        (desconto.desconto || 0);
                }

                retorno.investimento = retorno.valor - retorno.total;
                return retorno;
            });
            this.primeiraAlteracao = true;
            // }
            this.carregando = false;
        },

        carregarDesconto() {
            for (const d of this.value.desconto) {
                if (d.mes != "Total") {
                    d.percentual = this.descontoCampo.percentual;
                    d.desconto = this.descontoCampo.desconto;
                }
            }

            this.calculaDesconto();
        },
        calculaDesconto() {
            for (const d of this.value.desconto) {
                if (
                    d.percentual > 0 ||
                    d.percentual === "0" ||
                    d.percentual === 0
                ) {
                    d.total =
                        (d.valor * d.percentual) / 100 + Number(d.desconto);
                } else {
                    d.total =
                        (d.valor * d.desconto_padrao) / 100 +
                        Number(d.desconto);
                }
                d.investimento = (d.valor - d.total);

                if (
                    (d.percentual === 0 ||
                        d.percentual === "0" ||
                        d.percentual == undefined ||
                        d.percentual === "") &&
                    (d.desconto === 0 ||
                        d.desconto === "0" ||
                        d.desconto == undefined ||
                        d.desconto === "")
                ) {
                    d.justificativa = "";
                }
            }
        },
        validate() {
            if (this.value.desconto.length == 0) {
                return false;
            }

            const validaValores = !this.value.desconto.some(
                (v) => v.investimento < 0
            );
            const validaCampos = this.$refs.form.validate();

            if (validaValores && validaCampos) {
                return true;
            }
            return false;
        },
        investimentoClass(item) {
            if (item.mes == "Total") {
                return "";
            } else {
                return item.investimento < 0
                    ? "style-investimento-negativo"
                    : "";
            }
        },
    },
};
</script>


<style >
.desconto {
}
.dialogo-erro {
    color: red;
    z-index: 1000;
    position: absolute;
    width: 700px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    margin-top: 100px;
}
</style>