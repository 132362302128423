import { useQuery } from "vue-query";
import { http } from "../config/http";


export default function useFiltros(url) {

    const query =  useQuery(
        url,
        async () => {
            // await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await http().post(url);
            if (response.erro) {
                throw new Error(response.erro);
            }
            return response.data;
        },
        {
            placeholderData: {},
            // initialData : {},
            // staleTime: 8000,

        }
    );

    return {
        ...query,
        lista: query.data,        
    };
}
