import Vue from 'vue'
import vuetify from './vuetify'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
    context: { vuetify },
    confirm: {
        tile: true,
        persistent: true,
        waitForResult: false,
        icon: false, // to disable icon just put false
        width: 500
    },
    warning: {
        tile: true,
        persistent: true,
        waitForResult: false,
        icon: false, // to disable icon just put false
        width: 500
    },
    error: {},
    prompt: {}
})

export default VuetifyDialog
