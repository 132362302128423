<template>
  <div id="teste">
     </div>
</template>

<script>
export default {

  created() {
    window.location.href =  this.$arquivoProtegido(this.$route.query.url)

  },
}
</script>

