<template>
    <v-container fluid>
        <v-toolbar-title>Home</v-toolbar-title>
    </v-container>
</template>

<script>
import  useFiltros  from "../hooks/useFiltros";

export default {
    setup() {
		const { isLoading, isError, isFetching, data, error, refetch } = useFiltros("/acordo/listaCampos");
        return {
            isLoading,
            isError,
            isFetching,
            lista: data ,
            error,
            refetch,
            
        };
    },
    data() {
        return {
            chartOptions: {
                credits: {
                    enabled: false,
                },
                title: {
                    text: "lala",
                },
                series: [
                    {
                        data: [1, 2, 3],
                    },
                ],
            },
        };
    },
    computed: {
        carregando(){
            return  Object.keys(this.lista).length === 0 && this.isFetching
        }
    },
    created() {
        //
    },
    methods: {
        //
    },
};
</script>