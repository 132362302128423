var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"br-15 pa-3 px-5 pb-5 overflow-hidden",attrs:{"elevation":"0"}},[_c('InfoBox',{attrs:{"expands":""}},[_c('span',{staticClass:"titulo"},[_vm._v("Valores e ativações mensal: ")]),_vm._v(" Nesta tabela é possivel avaliar os valores e ativações dos investimentos por mês, nas visões: PDV, Tablóide, Campanha e Ecommerce. ")]),_c('h2',{staticClass:"text-center",staticStyle:{"font-weight":"normal"}},[_vm._v(" Valores e Ativações mensal ("+_vm._s(_vm.ano)+") ")]),_c('div',{staticClass:"involucroValorAtivacaoMensal"},[(!_vm.error && !_vm.empty && !_vm.overlay)?[_c('v-simple-table',{staticClass:"mt-3 tabela-valores-ativacao flex-table",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',{staticClass:"v-data-table-header"},[_c('th',{staticClass:"text-center min-width-150"},[_vm._v("Master Espaço")]),_vm._l((_vm.months),function(item,index){return _c('th',{key:index,class:("text-center th-separador text-capitalize min-width-150 " + (_vm.estacaoDoAno(
                  index
                )) + "-dark")},[_vm._v(" "+_vm._s(item)+" "),(_vm.estacaoDoAno(index) === 'verao')?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"#c28f5b","size":"18"}},[_vm._v(" mdi-white-balance-sunny ")]):_vm._e(),(_vm.estacaoDoAno(index) === 'inverno')?_c('v-icon',{staticClass:"mx-1",attrs:{"color":"#1e7199","size":"18"}},[_vm._v(" mdi-snowflake ")]):_vm._e()],1)}),_c('th',{staticClass:"text-center v-data-table-header min-width-100"},[_vm._v(" Total ")])],2)]),_c('tbody',[_c('tr',[_c('th',{staticClass:"text-center v-data-table-header teal darken-2",staticStyle:{"padding":"5px !important"},attrs:{"colspan":"14"}},[_vm._v(" Valores ")])]),_vm._l((_vm.filterData('Valor')),function(f,index){return _c('tr',{key:'a' + index},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(f.ferramenta)+" ")]),_vm._l((f.valores),function(v,index){return _c('td',{key:'b' + index,class:{
                  'text-center': true,
                }},[_c('span',[_c('formataReal',{attrs:{"valor":v}})],1)])}),_c('td',{class:{
                  'text-center': true,
                }},[_c('formataReal',{attrs:{"valor":_vm.sumRow(f.valores)}})],1)],2)}),_c('tr',{staticClass:"table-footer"},[_c('td',{staticClass:"text-center"},[_vm._v("Total:")]),_vm._l((_vm.months),function(m,i){return _c('td',{key:'c' + i,staticClass:"text-center"},[_c('formataReal',{attrs:{"valor":_vm.sumCols('Valor', i)}})],1)}),_c('td',{staticClass:"text-center"},[_c('formataReal',{attrs:{"valor":_vm.sumCols('Valor', 'all')}})],1)],2),_c('tr',[_c('th',{staticClass:"text-center v-data-table-header teal darken-2",staticStyle:{"padding":"5px !important"},attrs:{"colspan":"14"}},[_vm._v(" Ativações ")])]),_vm._l((_vm.filterData('#Ativações')),function(f,index){return _c('tr',{key:'d' + index},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(f.ferramenta)+" ")]),_vm._l((f.valores),function(v,index){return _c('td',{key:'e' + index,class:{
                  'text-center': true,
                }},[_c('span',[_vm._v(" "+_vm._s(v)+" ")])])}),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.sumRow(f.valores))+" ")])],2)}),_c('tr',{staticClass:"table-footer"},[_c('td',{staticClass:"text-center"},[_vm._v("Total:")]),_vm._l((_vm.months),function(m,i){return _c('td',{key:'c' + i,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.sumCols("#Ativações", i).toLocaleString())+" ")])}),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.sumCols("#Ativações", "all").toLocaleString())+" ")])],2)],2)]},proxy:true}],null,false,1495408753)})]:_vm._e(),_c('Overlay',{attrs:{"overlay":_vm.overlay,"error":_vm.error,"empty":_vm.empty}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }