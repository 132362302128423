import Vue from "vue";
import Vuetify from "vuetify/lib";
import customIcons from "./customIcons";
Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});

export default new Vuetify({

  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: "#1e988a",
          darken1: "#1a796e",
          darken2: "#165a53",
          darken3: "#123d3b",
          darken4: "#0d2625",
          lighten1: "#1da497",
          lighten2: "#2eb2a9",
          lighten3: "#4fc7be",
          lighten4: "#7fded5",
          

        },
        yellow: "#ffc07a",
        red: "#ff5252",
      },
    },
  },
  icons: {
    values: customIcons,
  },
});
