<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="4" class="py-0">
                <MesInput
                    label="Período Inicial"
                    v-model="value.mesInicial"
                    :rules="regra.mesInicial"
                    :change="periodoInicialChange"
                    :min="mesAtual"
                    :disabled="value.fnEditar || value.fnReplicar"
                    required
                ></MesInput>
            </v-col>
            <v-col cols="4" class="py-0">
                <MesInput
                    label="Período Final"
                    v-model="value.mesFinal"
                    :rules="regra.mesFinal"
                    :change="periodoFinalChange"
                    :min="mesAtual"
                    :disabled="value.fnEditar || value.fnReplicar"
                    required
                ></MesInput>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Produto"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="id_produtoBusca"
                    :items="lista.produto"
                    :search-input.sync="buscaProdutoFornecedor"
                    :loading="isLoadingProduto"
                    @change="produtoChange()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    clearable
                >
                    <template v-slot:no-data>
                        <p
                            v-if="
                                !buscaProdutoFornecedor ||
                                (buscaProdutoFornecedor &&
                                    buscaProdutoFornecedor.length <= 4)
                            "
                            class="ml-4 mt-4"
                        >
                            Insira pelo menos 4 caracteres para listar os
                            produtos.
                        </p>
                        <p v-else-if="isLoadingProduto" class="ml-4 mt-4">
                            Buscando produtos...
                        </p>
                        <p v-else class="ml-4 mt-4">Nada encontrado</p>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>            
        <v-row >    
            <v-col cols="4" class="py-0">
                <v-text-field
                    label="Fornecedor"
                    class="input-cinza"
                    v-model="value.fornecedor"
                    :rules="regra.fornecedor"
                    :disabled="value.fnEditar || value.fnReplicar"
                    outlined
                    dense
                    readonly
                    required
                ></v-text-field>
            </v-col>
			<v-col cols="4" class="py-0">
                <v-select
                    label="Bandeira"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.bandeira"
                    :items="lista.bandeira"
                    :rules="regra.bandeira"
                    @change="
                    buscarPontasTematicas()
                    limpaFiliais()
                    "
                    :disabled="value.fnEditar || value.fnReplicar"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Tipo de acordo"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="value.metodo_criacao"
                    :items="tiposAcordos"
                    :disabled="value.fnEditar || value.fnReplicar"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
        </v-row>            
        <v-row v-if="value.metodo_criacao !== 'MD'">             
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Espaço"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="id_espaco"
                    :items="value.metodo_criacao == 'PD' ? lista.espaco : lista.espacoOrganicos"
                    :rules="regra.espaco"
                    @change="
                        setInformacoesAdicionais($event)
                        limpaFiliais($event)
                    "
                    :disabled="value.fnEditar || value.fnReplicar"
                    :menu-props="menuProps"
                    required
                ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0" v-if="activeModelo">
                <v-select
                    label="Modelo Check-out"
                    class="input-cinza"
                    outlined
                    dense
                    v-model="value.modelo_ck"
                    :items="lista.modelo"
                    :rules="regra.modelo"
                    @change="limpaFiliais()"
                    :disabled="(value.fnEditar || value.fnReplicar) && value.modelo_ck !== null"
                    :menu-props="menuProps"
                    required
                />
            </v-col>
            <v-col
                cols="4"
                class="py-0 d-flex align-top"
                v-if="activeFlagTeste"
            >
                <v-checkbox
                    v-model="value.flag_teste"
                    label="Acordo de teste"
                    class="my-0"
                    @change="limpaFiliais()"
                    :disabled="value.fnEditar || value.fnReplicar"
                />
                <div class="ml-5">
                    <InfoBox>
                        <p>
                            Se ativar essa opção, será possível selecionar as
                            lojas disponíveis para criar um o acordo de teste na
                            próxima etapa, caso contrário, por se tratar de um
                            espaço orgânico, as lojas já serão selecionadas
                            automaticamente, sem a opção de editá-las.
                        </p></InfoBox
                    >
                </div>
            </v-col>
        </v-row>            
        <v-row v-else>    
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Espaço Movel Dermo"
                    class="input-cinza"
                    outlined
                    multiple
                    dense
                    v-model="ids_espaco_dermo"
                    :items="lista.moveisDermo"
                    :rules="regra.espaco"
                    @change="
                        changed
                    "
                    :disabled="value.fnEditar || value.fnReplicar"
                    :menu-props="menuProps"
                    required>
                    <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle">
                            <v-list-item-action>
                            <v-icon :color="lista.espaco.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                            <v-list-item-title>Selecione Todos</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                    </template>
                    <template v-slot:selection="{ item, index }">
                        <div
                            v-if="
                                acordo.value.length === 1 &&
                                index === 0
                            "
                            style="
                                width: 70% !important;
                                white-space: nowrap !important;
                                text-overflow: ellipsis !important;
                                overflow: hidden;
                            "
                        >
                            {{ item.text }}
                        </div>
                        <span
                            v-if="
                                acordo.value.length > 1 &&
                                index === 0
                            "
                            class="grey--text caption mr-1"
                            >{{
                                acordo.value.length
                            }}
                            selecionados</span
                        >
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>            
        <v-row>   

            <v-col cols="6" v-if="value.metodo_criacao == 'MD'" class="py-0">
            
                <v-data-table
                    :headers="listaHeader"
                    :items="value.moveisDermo" 
                    item-key="id_produto"
                    class="elevation-0 tabela"
                    hide-default-footer
                    disable-sort
                    
                >
                    <template v-slot:[`item.testeira`]="{ item }">
                        <v-text-field
                            :ripple="false"
                            dense
                            outlined
                            class="input-cinza"
                            v-model="item.testeira"
                            :rules="regra.testeira"
                            hide-details
                            :disabled="value.fnEditar || value.fnReplicar"
                        ></v-text-field>
                    </template>
                </v-data-table>
            </v-col>    



            <v-col :cols="value.metodo_criacao !== 'MD' ? 12 : 6" class="py-0">
                <v-textarea
                    outlined
                    v-model="value.obs_departamento"
                    class="input-cinza"
                    dense
                    rows="3"
                    label="Observação Departamento"
                ></v-textarea>
            
                <v-textarea
                    outlined
                    v-model="value.obs_contrato"
                    class="input-cinza"
                    dense
                    rows="3"
                    label= "Observação do Contrato"
                ></v-textarea>
            </v-col>
        </v-row>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
import smartId from "smart-id"
export default {
    inject: ["listaCampos"],
    props: ["value"],
    data() {
        return {
            listaHeader: [
                {
                    text: "Espaço",
                    value: "text",
                    align: "left",
                    class: "th-separador",
                    width: "235px",
                },
                {
                    text: "Informação testeira",
                    value: "testeira",
                    align: "left",
                }
            ],
            id_espaco: null,
            ids_espaco_dermo: [],
            tiposAcordos: [
                {value:'PD',text:'Padrão'},
                {value:'RG',text:'Acordo por região'},
                {value:'MD',text:'Movel dermo'}
            ],
            acordo: {
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                fornecedor: null,
                bandeira: null,
                modelo_ck: null,
                flag_teste: false,
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                planograma: null,
                comentario: null,
                fnEditar: false,
                fnLeitura: false,
                metodo_criacao: 'PD',
                regioesSelecionadas: [],
                assinatura: null,
                testeira: '',
                moveisDermo: [],
            },
            lista: {
                bandeira: [],
                espaco: [],
                modelo: ["ANTIGO", "NOVO"],
            },
            id_produtoBusca: null,
            buscaProdutoFornecedor: null,
            isLoadingProduto: false,
            regra: {
                mesInicial: [
                    (v) => !!v || "O campo período inicial é obrigatório",
                    (v) =>
                        (v && v >= this.mesAtual) ||
                        this.value.fnLeitura ||
                        "O período inicial deve ser igual ou posterior ao mês atual",
                ],
                mesFinal: [
                    (v) => !!v || "O campo período final é obrigatório",
                    (v) =>
                        (v && v >= this.value.mesInicial) ||
                        "O período final deve ser igual ou posterior ao período inicial",
                ],
                espaco: [(v) => !!v || "O campo espaço é obrigatório"],
                fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
                bandeira: [(v) => !!v || "O campo bandeira é obrigatório"],
                modelo: [
                    (v) =>
                        !!v ||
                        this.value.fnLeitura ||
                        //this.value.fnReplicar ||
                        "O campo modelo check-out é obrigatório",
                ],
                testeira: [
                    (v) => this.value.metodo_criacao == 'MD' && !!v || "O campo espaço é obrigatório"
                ]
            },
            semAcesso: false,
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.lista.bandeira = lista.bandeira;
        this.lista.espaco = lista.espaco.map(item => {
            return {...item,testeira: ''}
        });
        this.lista.espaco = lista.espaco.filter(item => {
            var found = false
            for (let itemCubo of lista.cubosNaoPermitidosNovosAcordos) {
                if (item.id_espaco == itemCubo.id_espaco) found = true
            }
            return !found
        });
        this.lista.espacoOrganicos = lista.espaco.filter(item => item.organico);
        this.lista.moveisDermo =  lista.espaco.filter(item => item.espaco_macro == 'Móvel Dermo');
        this.buscarPontasTematicas()
        this.id_espaco = this.value.id_espaco
        //this.ids_espaco_dermo = this.id_espaco
        
        console.log('created')
    },
    computed: {
        marcoTodosEspacos () {
            return this.ids_espaco_dermo.length === this.lista.moveisDermo.length
        },
        marcoAlgunsEspacos () {
            return this.ids_espaco_dermo.length > 0 && !this.marcoTodosEspacos
        },
        icon () {
            if (this.marcoTodosEspacos) return 'mdi-close-box'
            if (this.marcoAlgunsEspacos) return 'mdi-checkbox-blank-outline'
            return 'mdi-checkbox-blank-outline'
        },
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        activeModelo() {
            if (!this.value.fnEditar) this.clearVarAcordo("modelo_ck");

            let _espacoMacro =
                this.lista.espaco
                    .filter((e) => e.value == this.value.id_espaco)
                    .map((e) => e.espaco_macro)[0] || "";

            return _espacoMacro.toLowerCase() == "check-out";
        },
        activeFlagTeste() {
            let _organico =
                this.lista.espaco
                    .filter((e) => e.value == this.value.id_espaco)
                    .map((e) => e.organico)[0] || 0;

            return _organico;
        },
    },
    watch: {
        async buscaProdutoFornecedor(val) {
            if (!val) {
                this.id_produtoBusca = null;
                return;
            }

            if (val.length < 4) return;

            if (this.isLoadingProduto) return;

            this.isLoadingProduto = true;

            var data = { produto: val };

            var response = await this.$http().post("/acordo/buscaProdutos", {
                data: data,
            });

            this.lista.produto = response.data;

            this.isLoadingProduto = false;
        },
        id_produtoBusca(val) {
            var produto = this.lista.produto.find((e) => e.value == val);

            this.value.id_fornecedor = produto ? produto.id_fornecedor : null;
            this.value.fornecedor = produto ? produto.fornecedor : null;
        },
    },
    methods: {
        changed () {
            console.log('changed',this.ids_espaco_dermo)
            if (!this.value.moveisDermo) this.value.moveisDermo = []
            for (let espaco of this.ids_espaco_dermo) {
                var espacoChecado = _.find(this.value.moveisDermo,{id_espaco: espaco})
                console.log(espaco,espacoChecado,this.lista.moveisDermo)
                if (espacoChecado == undefined) {
                    console.log((_.find(this.lista.moveisDermo,{id_espaco: espaco})))
                    this.value.moveisDermo.push(_.find(this.lista.moveisDermo,{id_espaco: espaco}))
                    
                }
            }
            this.value.moveisDermo = this.value.moveisDermo.filter(item => {
                return this.ids_espaco_dermo.includes(item.id_espaco)
            })
            if (this.value.moveisDermo.length > 0) { 
                this.setInformacoesAdicionais(this.value.moveisDermo[0].id_espaco) 
            }
            console.log(this.value.moveisDermo)
        },
        toggle () {
            this.$nextTick(() => { 
                var text = "" 
                if (this.marcoTodosEspacos) {
                    this.ids_espaco_dermo = []
                    text = "Nenhum espaço"
                } else {
                    this.ids_espaco_dermo = this.lista.moveisDermo.map(item => {
                        return item.value
                    })
                    text = "Todos espaços"
                }
                
                
            })
        },
        showFlagTeste() {
            const text = `
				Se ativar essa opção, será possível selecionar as lojas disponíveis para criar um o acordo\n
				de teste na próxima etapa, caso contrário, por se tratar de um espaço orgânico,\n
				as lojas já serão selecionadas automaticamente,
				sem a opção de editá-las.
			`;

            this.$dialog.confirm({
                text: text,
                title: "Ajuda",
                actions: [{ text: "OK", color: "teal" }],
                color: "teal lighten-2",
            });
        },
        clearVarAcordo(key) {
            this.acordo[key] = null;
        },
        setAcordo(acordo) {
            this.acordo = { ...acordo, value: acordo.id_espaco };
            this.id_espaco = this.acordo.id_espaco
            // this.ids_espaco_dermo = this.acordo.id_espaco
            // this.changed()
            console.log('setAcordo',this.acordo.fnEditar)
            if (this.acordo.fnEditar || this.acordo.fnLeitura || this.acordo.fnReplicar)  {
                console.log('modo')
                if (this.acordo.metodo_criacao == 'MD') {
                    console.log('ajuste')
                    this.ids_espaco_dermo = this.acordo.espacosLote
                    console.log(this.ids_espaco_dermo)
                    if (!this.acordo.moveisDermo) this.acordo.moveisDermo = []
                    for (let espaco of this.ids_espaco_dermo) {
                        var espacoChecado = _.find(this.acordo.moveisDermo,{id_espaco: espaco})
                        console.log(espaco,espacoChecado,this.lista.moveisDermo)
                        if (espacoChecado == undefined) {
                            console.log((_.find(this.lista.moveisDermo,{id_espaco: espaco})))
                            this.acordo.moveisDermo.push(_.find(this.lista.moveisDermo,{id_espaco: espaco}))
                            
                        }
                    }
                    this.acordo.moveisDermo = this.acordo.moveisDermo.filter(item => {
                        return this.ids_espaco_dermo.includes(item.id_espaco)
                    })
                    if (this.acordo.moveisDermo.length > 0) { 
                        this.setInformacoesAdicionais(this.acordo.moveisDermo[0].id_espaco) 
                    }
                    console.log('linhas moveis dermo',this.acordo.moveisDermo)
                    // this.acordo.moveisDermo[0].testeira = acordo.testeira
                  for (const item of this.acordo.moveisDermo) {
                    const testeira = this.acordo.testeirasLote.find(e => e.id_espaco == item.id_espaco)
                    console.log("Testa",testeira)
                    item.testeira = testeira.testeira;
                  }
                }
                // console.log('teste')
                // this.changed()
            }
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        limpaCadastro() {
            this.id_produtoBusca = null;
            this.buscaProdutoFornecedor = null;
            this.lista.produto = [];
            this.acordo = {
                mesInicial: null,
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                modelo_ck: null,
                flag_teste: false,
                fornecedor: null,
                bandeira: null,
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                planograma: null,
                comentario: null,
                fnEditar: false,
                //moveisDermo: [],
                metodo_criacao: 'PD'
            };
            this.id_espaco = null
            this.ids_espaco_dermo = null
            // this.value.moveisDermo = []
            this.lista.moveisDermo = this.lista.moveisDermo.map(item => {
                return {...item,testeira: ''}
            })
        },
        async buscarPontasTematicas() {
            console.log('ponta tematica')
			if ((!this.value.mesInicial) || (!this.value.mesFinal)) {
				return;
			}

			if (this.isLoadingPontasTematicas) return;

			this.isLoadingPontasTematicas = true;

			let espaco = this.lista.espaco.filter(item => !item.ponta_tematica)

            response = {data: {lista:[]}}
            if (this.value.bandeira) {
                var data = { 
                    mesInicial: this.value.mesInicial + '-01',
                    mesFinal: this.value.mesFinal + '-01',
                    bandeira: this.value.bandeira
                };

                var response = await this.$http().post("/pontatematica/pontasTematicasNoPeriodo", {
                    data: data,
                });
            } else {
                console.log('vo nao')
            }	

			this.lista.espaco = [ ...espaco, ...response.data.lista.map(item => {
				var id = smartId.make()
				return {...item, value: id }
			})]

			this.isLoadingPontasTematicas = false;
		},
		setInformacoesAdicionais(event) {
			const espacoSeleciondo = this.lista.espaco.filter(item => (item.value == event))
			this.value.id_espaco = espacoSeleciondo[0].id_espaco
			this.value.id_espaco_tematico = espacoSeleciondo[0].id_espaco_tematico
			this.value.ponta_tematica = espacoSeleciondo[0].ponta_tematica
			this.value.prateleira_nome = espacoSeleciondo[0].ponta_tematica ? espacoSeleciondo[0].text : null
			this.value.prateleira = espacoSeleciondo[0].prateleira
			this.value.tema = espacoSeleciondo[0].tema
            this.value.id_ponta_tematica = espacoSeleciondo[0].id_ponta_tematica
            console.log(espacoSeleciondo[0].mecanismo_preco_id)
            this.value.mecanismo_preco_id = espacoSeleciondo[0].mecanismo_preco_id
            // this.value,moveisDermo = 
		},
        limpaFiliais() {
            this.$emit("limpaFiliais");
        },
        limpaProdutos() {
            this.$emit("limpaProdutos");
        },
        limpaDesconto() {
            this.$emit("limpaDescontos");
        },
        validate() {
            return this.$refs.form.validate();
        },
        produtoChange() {
            this.limpaDesconto();
            this.limpaProdutos();
        },
        resetValidation() {
            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },

        periodoInicialChange(valor) {
            this.buscarPontasTematicas()
            this.limpaDesconto();
            this.limpaFiliais();
            if (this.value.mesFinal < valor) {
                this.value.mesFinal = valor;
            }
        },
        periodoFinalChange(valor) {
            console.log('periodo final')
            this.buscarPontasTematicas()
            this.limpaDesconto();
            this.limpaFiliais();
            if (this.value.mesInicial > valor) {
                this.value.mesInicial = valor;
            }
        },
        confiraTesteira(value) {
            var status = true
            if (this.value.moveisDermo.length > 0) {
                for (var item of this.value.moveisDermo) {
                    console.log(item)
                    if (status) {
                        if (!item.testeira || item.testeira.length == 0) {
                            status = false
                        }
                    }
                }
            } else {
                status = false
            }
            return status
        }
    },
};
</script>
