<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4">
        <v-toolbar-title class="ml-2 mt-2"> </v-toolbar-title>
      </v-col>
    </v-row>
    <v-card class="mt-2 pt-4 px-2" elevation="1">
      <filtro ref="filtro" @filtrar="filtrar"></filtro>
    </v-card>

    <v-card class="my-4 px-2" elevation="1">
      <v-row no-gutters>
        <v-col cols="6" class="py-4 px-4">
          <v-toolbar-title class="grey--text"> </v-toolbar-title>
        </v-col>
        <v-col cols="6" align="end" class="py-2">
          <v-btn class="ml-2 my-1" color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
          </v-btn>
        </v-col>
      </v-row>
      <div class="involucroGerencialSimulador">
        <v-data-table
          :headers="headers"
          :items="dados"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 15, 25, 50, 100],
          }"
          multi-sort
          class="elevation-0 tabela-gerencial flex-table"
        >
          <template v-slot:header="{}">
            <thead class="header0">
              <tr>
                <th
                  v-for="(h, i) in headersGroup"
                  :key="i"
                  :colspan="h.colspan"
                  :class="h.class"
                  align="center"
                >
                  {{ h.text }}
                </th>
              </tr>
            </thead>
          </template>
          <template
            v-for="key in periodo"
            v-slot:[`item.simulado-${key}`]="{ item }"
          >
            <div :key="key">{{ formataMoeda(item[`simulado-${key}`]) }}</div>
          </template>
          <template
            v-for="key in periodo"
            v-slot:[`item.realizado-${key}`]="{ item }"
          >
            <div :key="key">{{ formataMoeda(item[`realizado-${key}`]) }}</div>
          </template>
          <template
            v-for="key in periodo"
            v-slot:[`item.realxplan-${key}`]="{ item }"
          >
            <div :key="key">{{ item[`realxplan-${key}`] || 0 }}%</div>
          </template>
          <template v-for="key in periodo" v-slot:[`item.diff-${key}`]="{ item }">
            <div
              :class="item[`diff-${key}`] < 0 ? 'valor-negativo' : 'text-success'"
              :key="key"
            >
              {{ formataMoeda(item[`diff-${key}`]) }}
            </div>
          </template> 
          <template v-slot:[`body.append`]>
          <tr class="table-footer">
                <td class="text-center pl-4 bg-1" :colspan="colspan">Total:</td>
                <template v-for="key in periodo">
                  <td class="text-center" :key="'simulado-' + key">
                    {{ formataMoeda(somaColuna('simulado-' + key)) }}
                  </td>
                  <td class="text-center" :key="'realizado-' + key">
                    {{ formataMoeda(somaColuna('realizado-' + key)) }}
                  </td>
                  <td class="text-center" :key="'realxplan-' + key">
                    {{ mediaColuna('simulado-' + key,'realizado-' + key) + '%' }}
                  </td>
                  <td class="text-center" :key="'diff-' + key">
                    {{ formataMoeda(somaColuna('diff-' + key)) }}
                  </td>
                </template>
                
              </tr>
              
            </template> 
        </v-data-table>
      </div>
    </v-card>

    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="alerta"
      :color="alertaColor"
      :timeout="alertaTimeout"
      top
      style="z-index: 400"
    >
      <v-alert :type="alertaColor">
        {{ alertaTexto }}
      </v-alert>
      <v-btn dark text class="float-right" @click="alerta = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Filtro from "./Filtro.vue";

export default {
  name: 'ListaSimuladorGerencial',
  components: {
    Filtro,
  },
  data() {
    return {
      headers: [],
      dados: [],
      periodo: [],
      carregando: false,
      alerta: false,
      alertaTexto: "",
      alertaColor: "error",
      alertaTimeout: 3000,
      colspan: 1,
      headersGroup: [],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
    };
  },

  provide() {
    return {
      listaCampos: this.listaCampos(),
    };
  },
  /* created() {
		this.filtrar();
	}, */
  methods: {
    async listaCampos() {
      this.carregando = true;

      var response = await this.$http().post(
        "/simulacao/gerencial/filtros",
        {}
      );

      this.carregando = false;

      

      return response.data;
    },
    somaColuna(coluna) {
      // console.log(coluna)
      let total = this.dados.reduce((a,e) => a += isNaN(parseFloat(e[coluna])) ? 0 : parseFloat(e[coluna]),0)
      // console.log(total)
      for (let dado of this.dados) {
        // console.log(dado[coluna], parseFloat(dado[coluna]))
      }
      return total 
    },
    mediaColuna(planejado,realizado) {
      let somaPlanejado = this.dados.reduce((a,e) => a += isNaN(parseFloat(e[planejado])) ? 0 : parseFloat(e[planejado]),0)
      let somaRealizado = this.dados.reduce((a,e) => a += isNaN(parseFloat(e[realizado])) ? 0 : parseFloat(e[realizado]),0)
      // console.log(soma,this.dados.lenght)
      return Math.ceil((somaRealizado/somaPlanejado)*100)
    },
    async filtrar(filtros) {
      this.carregando = true;

      var data = filtros;

      var response = await this.$http().post("/simulacao/gerencial/lista", {
        data: data,
      });
      this.periodo = [];
      this.calcPeriodo(filtros.dataInicial, filtros.dataFinal);
      // console.log(response.data)
      this.dados = response.data;
      //   / Calcular total de cada linha
      this.dados = this.dados.map((item) => {
        item[`simulado-total`] = 0;
        item[`realizado-total`] = 0;
        item[`realxplan-total`] = 0;
        item[`diff-total`] = 0;
        this.periodo.forEach((periodo) => {
          if (periodo !== "total") {
            item[`simulado-total`] += item[`simulado-${periodo}`] || 0;
            item[`realizado-total`] += item[`realizado-${periodo}`] || 0;
          }
        });
        item[`diff-total`] = item[`realizado-total`] - item[`simulado-total`];
       const realxplan = Math.round(
          (item["realizado-total"] / item["simulado-total"]) * 100
        );
		item[`realxplan-total`] = isFinite(realxplan) ? realxplan : 0;
        return item;
      });
      this.Mountheaders(filtros);
      this.carregando = false;
    },

    async Mountheaders(filtros) {
      this.headersGroup = [];
      this.headers = [];
      this.periodo = [];
      this.calcPeriodo(filtros.dataInicial, filtros.dataFinal);

      // this.dados = [{ planejado: 1 }];]
      this.colspan = 1;
      if (filtros.agrupar) {
        filtros.agrupar.forEach((campo) => {
          // console.log(campo)
          if (campo == 'fornecedor') {
            this.headers.push({
              class: "text-capitalize",
              text: "Código Fornecedor",
              value: 'codigo',
              align: "center",
            });
          }
          this.headers.push({
            class: "text-capitalize",
            text: campo.replace(/_/g, " "),
            value: campo,
            align: "center",
          });
          this.colspan++;
        });
      }
      this.headersGroup.push({ text: ``, colspan:this.colspan,class: "whiteBG"  });
      this.headers.push({ text: "Visão", value: "visao", align: "center" });
      this.periodo.forEach((key) => {
        const [ano, mes] = key.split("-");
        const mesNome =
          key === "total"
            ? "Total do periodo selecionado"
            : `${this.meses[mes - 1]} - ${ano}`;
        this.headersGroup.push({
          text: mesNome,
          colspan: 4,
          align: "center",
          class: "headerMes text-center",
        });
        this.headers.push({
          text: "Planejado",
          value: `simulado-${key}`,
          align: "center",
        });
        this.headers.push({
          text: "Realizado",
          value: `realizado-${key}`,
          align: "center",
        });
        this.headers.push({
          text: "Real VS Plan",
          value: `realxplan-${key}`,
          align: "center",
        });
        this.headers.push({
          text: "Diferença",
          value: `diff-${key}`,
          align: "center",
        });
      }); // Fim do forEach
    },
    calcPeriodo(dataInicial, dataFinal) {
      const [anoInicial, mesInicial] = dataInicial.split("-");
      const [anoFinal, mesFinal] = dataFinal.split("-");

      let ano = anoInicial;
      while (ano <= anoFinal) {
        let mesInicio = +ano === +anoInicial ? mesInicial : 1;
        let mesFim = +ano === +anoFinal ? mesFinal : 12;
        this.periodo.push(
          ...Array.from(
            Array(mesFim - mesInicio + 1),
            (_, i) => +ano + "-" + (i + Number(mesInicio))
          )
        );
        ano++;
      }
      this.periodo.push("total");
    },
    async exportar() {
      if (!this.$refs.filtro.validate()) return;

      this.carregando = true;
      var filtro = this.$refs.filtro.filtro;

      var response = await this.$http().post("/simulacao/gerencial/exportar", {
        data: filtro,
      });

      window.location = this.$http("baseURL") + response.data.url;

      this.carregando = false;
    },
    formataMoeda(valor = 0) {
      return valor.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
};
</script>

<style>
.headerMes {
  background: var(--v-primary-base);
  text-align: center;
  border-left: thin solid rgba(0, 0, 0, 0.12);
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}
.valor-negativo {
  color: red;
  padding: 5px;
  padding: 5px;
}
.tabela-gerencial td,
.tabela-gerencial th {
  border-left: thin solid rgba(0, 0, 0, 0.1);
}
.involucroGerencialSimulador {
  height: 650px;
  display: flex;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.involucroGerencialSimulador .flex-table {
  display: flex;
  flex-direction: column;
  height: 620px;
  width: 100%;
}

.involucroGerencialSimulador .flex-table > div {
  width: 100%;
}

.involucroGerencialSimulador thead {
  position: -webkit-sticky;
	position: sticky;
	overflow: hidden;
	z-index: 4;
}

.involucroGerencialSimulador .header0 {
	top: 0px;
    z-index: 4;
} 

.involucroGerencialSimulador .v-data-table-header {
	top: 48px;
    z-index: 4;
} 

.involucroGerencialSimulador .v-data-table__wrapper {
	overflow-y: auto !important;
} 

.whiteBG {
  background-color: white;
}

</style>
