<template>
    <v-form ref="formFiltro" class="filtros-acordos">
        <v-divider class="mt-2 mb-3"></v-divider>
        <v-row>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <MesInput
                    label="Período Inicial"
                    v-model="filtro.mesInicial"
                    v-if="filtro.tipoPeriodo < 1"
                    :rules="regra.mesInicial"
                    :change="periodoInicialChange"
                    required
                ></MesInput>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <MesInput
                    label="Período Final"
                    v-model="filtro.mesFinal"
                    v-if="filtro.tipoPeriodo < 1"
                    :rules="regra.mesFinal"
                    :change="periodoFinalChange"
                    required
                ></MesInput>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <v-text-field
                    label="Número do acordo"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="filtro.id_acordo_campanha"
                ></v-text-field>
            </v-col>
            <v-col sm="6" md="4" lg="3" class="pb-0">
                <v-text-field
                    label="Número do acordo RD"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="filtro.acordo_rd"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-expansion-panels flat v-model="mostrarFiltros" accordion>
            <v-expansion-panel>
                <v-expansion-panel-content eager class="p-0 m-0">
                    <v-row>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-text-field
                                label="Número do contrato"
                                outlined
                                dense
                                class="input-cinza"
                                v-model="filtro.id_contrato_campanha"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-text-field
                                label="Número do contrato RD"
                                outlined
                                dense
                                class="input-cinza"
                                v-model="filtro.contrato"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-select
                                label="Cluster/Rede"
                                v-model="filtro.cluster_rede"
                                :items="['Cluster', 'Rede']"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span
                                        v-if="
                                            filtro.cluster_rede.length === 1 &&
                                            index === 0
                                        "
                                        >{{ item }}</span
                                    >
                                    <span
                                        v-if="
                                            filtro.cluster_rede.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.cluster_rede.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-select>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-autocomplete
                                label="Espaço"
                                v-model="filtro.id_espaco_pdv"
                                :items="lista.espaco.filter((i) => i.habilitacampanha == 1)"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            filtro.id_espaco_pdv.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            filtro.id_espaco_pdv.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_espaco_pdv.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-autocomplete
                                label="Espaço Macro"
                                v-model="filtro.espaco_macro"
                                :items="lista.espaco_macro"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            filtro.espaco_macro.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            filtro.espaco_macro.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.espaco_macro.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-autocomplete
                                label="Fornecedor"
                                v-model="filtro.id_fornecedor"
                                :items="lista.fornecedor"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <div
                                        v-if="
                                            filtro.id_fornecedor.length === 1 &&
                                            index === 0
                                        "
                                        style="
                                            width: 70% !important;
                                            white-space: nowrap !important;
                                            text-overflow: ellipsis !important;
                                            overflow: hidden;
                                        "
                                    >
                                        {{ item.text }}
                                    </div>
                                    <span
                                        v-if="
                                            filtro.id_fornecedor.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_fornecedor.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-select
                                label="Status"
                                :items="lista.status"
                                v-model="filtro.id_status"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span
                                        v-if="
                                            filtro.id_status.length === 1 &&
                                            index === 0
                                        "
                                        >{{ item.text }}</span
                                    >
                                    <span
                                        v-if="
                                            filtro.id_status.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_status.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-select>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-select
                                label="Cluster"
                                :items="lista.cluster"
                                v-model="filtro.id_cluster"
                                multiple
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span
                                        v-if="
                                            filtro.id_cluster.length === 1 &&
                                            index === 0
                                        "
                                        >{{ item.text }}</span
                                    >
                                    <span
                                        v-if="
                                            filtro.id_cluster.length > 1 &&
                                            index === 0
                                        "
                                        class="grey--text caption mr-1"
                                        >{{
                                            filtro.id_cluster.length
                                        }}
                                        selecionados</span
                                    >
                                </template>
                            </v-select>
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                        <v-autocomplete
                            label="Usuario"
                            v-model="filtro.id_usuario_criacao"
                            :items="lista.usuarios"
                            multiple
                            :menu-props="menuProps"
                            outlined
                            dense
                            class="input-cinza"
                        >
                            <template v-slot:selection="{ item, index }">
                                <div
                                    v-if="
                                        filtro.id_usuario_criacao.length === 1 &&
                                        index === 0
                                    "
                                    style="
                                        width: 70% !important;
                                        white-space: nowrap !important;
                                        text-overflow: ellipsis !important;
                                        overflow: hidden;
                                    "
                                >
                                    {{ item.text }}
                                </div>
                                <span
                                    v-if="
                                        filtro.id_usuario_criacao.length > 1 &&
                                        index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.id_usuario_criacao.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-autocomplete>
            
                        
                        </v-col>
                        <v-col sm="6" md="4" lg="3" class="pb-0">
                            <v-select
                                label="Visível"
                                v-model="filtro.visivel"
                                :items="['Sim','Não']"
                                :menu-props="menuProps"
                                outlined
                                dense
                                class="input-cinza"
                            >
                            </v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                            xl="3"
                            class="px-2"
                            v-show="false"
                        >
                            <v-select
                                label="Tipo de Período"
                                v-model="filtro.tipoPeriodo"
                                :items="lista.tiposPeriodo"
                                outlined
                                dense
                                class="input-cinza"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mt-0 mb-3"></v-divider>
        <v-row>
            <v-col cols="3" class="px-2 ml-auto">
                <div class="new-button d-flex align-center" text>
                    <span @click="expandirFiltros" style="cursor: pointer"
                        >Personalizar Filtros</span
                    >
                    <v-btn
                        style="background: #e6e6e6; width: 20px; height: 20px"
                        fab
                        icon
                        class="ml-3"
                        @click="expandirFiltros"
                        x-small
                    >
                        <v-icon>{{
                            mostrarFiltros == 0
                                ? "mdi-chevron-up"
                                : "mdi-chevron-down"
                        }}</v-icon>
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="9" class="ml-auto" align="end">
                <v-btn
                    class="ml-2 new-button"
                    color="yellow"
                    dark
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
                </v-btn>
                <v-btn
                    class="ml-5 new-button"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-form>
</template>

<script>
export default {
    inject: ["listaCampos"],
    data() {
        return {
            carregando: false,
            mostrarFiltros: null,
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            lista: {
                bandeira: [],
                espaco: [],
                fornecedore: [],
                status: [],
                tiposPeriodo: [
                    { value: 1, text: "Atualizados a 1 dia" },
                    { value: 3, text: "Atualizados a 3 dias" },
                    { value: 5, text: "Atualizados a 5 dias" },
                    { value: 10, text: "Atualizados a 10 dias" },
                    { value: 15, text: "Atualizados a 15 dias" },
                    { value: 30, text: "Atualizados a 30 dias" },
                    { value: -1, text: "Acordos por período" },
                ],
            },
            filtro: {},
            regra: {
                mesInicial: [
                    (v) =>
                        (this.filtro.tipoPeriodo < 1 && !!v) ||
                        "O campo período inicial é obrigatório",
                ],
                mesFinal: [
                    (v) =>
                        (this.filtro.tipoPeriodo < 1 && !!v) ||
                        "O campo período final é obrigatório",
                    (v) =>
                        (this.filtro.tipoPeriodo < 1 &&
                            !!v &&
                            v >= this.filtro.mesInicial) ||
                        "O período final deve ser igual ou posterior ao período inicial",
                ],
            },
        };
    },
    async created() {
        this.carregando = true;
        var lista = await this.listaCampos;
        lista.status = lista.status.sort(function (a, b) {
            return a.text.localeCompare(b.text);
        });
        this.lista.bandeira = lista.bandeira;
        this.lista.espaco = lista.espacoFiltro;
        this.lista.espaco_macro = lista.espaco_macro;
        this.lista.fornecedor = lista.fornecedor;
        this.lista.status = lista.status;
        this.lista.cluster = lista.cluster;
        this.lista.usuarios = lista.usuarios;
        this.iniciaFiltro();
        this.carregando = false;
    },
    computed: {},
    methods: {
        iniciaFiltro() {
            this.filtro = {
                mesInicial: this.$moment().format("YYYY-MM"),
                mesFinal: this.$moment().format("YYYY-MM"),
                tipoPeriodo: -1,
                id_acordo_campanha: null,
                id_acordo_rd: null,
                id_contrato_campanha: null,
                contrato: null,
                cluster_rede: [],
                id_espaco_pdv: [],
                espaco_macro: [],
                id_fornecedor: [],
                id_status: [],
                id_cluster: [],
                planograma: [],
                visivel: null
            };
            if (this.lista.fornecedor.length == 1) {
                this.filtro.id_fornecedor = [this.lista.fornecedor[0].value];
            }
        },
        limpar() {
            this.iniciaFiltro();

            this.$emit("limpar");
        },
        filtrar() {
            this.$emit("filtrar");
        },
        validate() {
            return this.$refs.formFiltro.validate();
        },
        expandirFiltros() {
            this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
        },
        periodoInicialChange(valor) {
            if (this.filtro.mesFinal < valor) {
                this.filtro.mesFinal = valor;
            }
        },
        periodoFinalChange(valor) {
            if (this.filtro.mesInicial > valor) {
                this.filtro.mesInicial = valor;
            }
        },
        resetValidation() {
            try {
                this.$refs.formFiltro.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },
    },
};
</script>
