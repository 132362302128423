<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2">Filiais</v-toolbar-title>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					@click="exportarImportacoes()"
				>
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar<br />
					Importações e Edições
				</v-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					@click="abrirImportar()"
				>
					<v-icon left>mdi-upload</v-icon> Importar
				</v-btn>
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					<v-text-field
						v-model="busca"
						append-icon="mdi-magnify"
						label="Pesquisar"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						<v-text-field
							label="Mês"
							v-model="mes"
							:rules="regra.mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				item-key="id_filial"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-icon
						small
						class="mr-2"
						color="primary"
						@click="abrirEditar(item)"
						title="Editar"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog v-model="dialogo" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Editar Filial
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="ID JAVA"
									v-model="filial.id_java"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="ID Filial"
									v-model="filial.id_filial"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Bandeira"
									v-model="filial.bandeira"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Região"
									v-model="filial.regiao"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Cidade"
									v-model="filial.cidado"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Perfil"
									v-model="filial.perfil"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<!-- <v-text-field
                                    outlined
                                    dense 
                                    label="Tamanho"
                                    v-model="filial.tamanho"
                                ></v-text-field>  -->
								<v-select
									:items="['PP', 'P', 'M', 'G']"
									outlined
									dense
									clearable
									label="Tamanho"
									v-model="filial.tamanho"
								></v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="CNPJ"
									v-model="filial.cnpj"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Endereço"
									v-model="filial.endereco"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Número"
									v-model="filial.numero"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Bairro"
									v-model="filial.bairro"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Estado"
									v-model="filial.estado"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									disabled
									label="Mês"
									v-model="filial.mes_nome"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									label="Modelo de Check-Out"
									v-model="filial.check_out"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									label="E-mail"
									v-model="filial.email"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						:disabled="!validaForm"
						@click="editar()"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV para
					{{ $moment(mes).format("MMMM [de] YYYY") }}
				</v-card-title>
				<v-card-text>
					<v-alert
						class="mt-4"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								A base utilizada para importação deve ser
								parecida com base exportada.
							</li>
							<li>
								As colunas <strong>id_java</strong> (ID JAVA),
								<strong>check_out</strong> (Modelo de Check-Out)
								e <strong>email</strong> (E-mail) são
								obrigatórias (<strong
									>e os nomes devem ser identicos aos da base
									exportada</strong
								>), as demais colunas podem ser excluidas.
							</li>
							<li>
								Na coluna <strong>email</strong> os dados devem
								ser <strong>únicos</strong> (Não pode haver mais
								de uma filial com o mesmo e-mail).
							</li>
							<li>
								A coluna <strong>mes</strong> não é considerada
								na importação do <strong>check_out</strong>.
							</li>
							<li>
								O <strong>mês</strong> considerado na importação
								do <strong>check_out</strong> é o mês
								selecionano no
								<strong>filtro da lista de filiais</strong>.
							</li>
							<li>
								Os tipos de <strong>tamanho</strong> permitidos
								são <strong>PP, P, M e G</strong> ou campo
								vazio.
							</li>
						</ul>
					</v-alert>
					<v-row no-gutters>
						<v-col cols="8" class="px-2">
							<v-form ref="form" v-model="isCsv">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="4" class="px-2">
							<v-btn
								color="primary"
								:disabled="!isCsv"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Erros
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{ text: "Criação", value: "data_criacao", align: "center" },
				{ text: "Inventário", value: "inventario", align: "center" },
				{ text: "ID JAVA", value: "id_java", align: "center" },
				{ text: "ID Filial", value: "id_filial", align: "center" },
				{ text: "Versão App", value: "versao_app", align: "center" },
				{ text: "Bandeira", value: "bandeira", align: "center" },
				{ text: "Cluster", value: "cluster", align: "center" },
				{ text: "Região", value: "regiao", align: "center" },
				{ text: "Cidade", value: "cidade", align: "center" },
				{ text: "Perfil", value: "perfil", align: "center" },
				{ text: "Tamanho", value: "tamanho", align: "center" },
				{ text: "Mês", value: "mes_nome", align: "center" },
				{
					text: "Modelo de Check-Out",
					value: "check_out",
					align: "center",
				},
				{ text: "E-mail", value: "email", align: "center" },
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			dados: [],
			mes: null,
			filial: {
				id_filial: null,
				id_java: null,
				bandeira: null,
				regiao: null,
				cidade: null,
				perfil: null,
				tamanho: null,
				cnpj: null,
				endereco: null,
				numero: null,
				bairro: null,
				estado: null,
				check_out: null,
				email: null,
			},
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				mes: [(v) => !!v || "O campo mês é obrigatório."],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	created() {
		this.mes = this.mesAtual;
	},
	computed: {
		mesAtual() {
			return this.$moment().format("YYYY-MM");
		},
	},
	methods: {
		async filtrar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			data.pagination = this.pagination;
			data.busca = this.busca;

			var response = await this.$http().post("/filial/lista", {
				data: data,
			});

			this.dados = response.data;

			for (const d of this.dados) {
				d.data_criacao = this.$moment(d.data_criacao).format(
					"DD/MM/YYYY"
				);
			}

			this.total = response.data.length ? response.data[0].total : 0;

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
		async exportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var response = await this.$http().post("/filial/exportar", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		abrirEditar(item) {
			this.filial = { ...item };

			this.dialogo = true;
		},
		async editar() {
			this.carregando = true;

			var data = this.filial;

			var response = await this.$http().post("/filial/editar", {
				data: data,
			});

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();

				this.dialogo = false;
			} else {
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}
		},
		abrirImportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.arquivoCSV = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/filial/importar",
				formData
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogoImportar = false;
			} else {
				this.filtrar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
		async exportarImportacoes() {
			this.carregando = true;

			var response = await this.$http().post(
				"/filial/exportarHistImportacoes",
				{ data: {} }
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
	},
};
</script>
