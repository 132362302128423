<template>
  <div>
    <v-dialog
      v-model="dialogForm"
      scrollable
      persistent
      content-class="br-15"
      width="900"
      :z-index="200"
    >
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title> Importação de inventário </v-toolbar-title>
          <v-spacer />
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            color="#fff"
            @click="dialogForm = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-5 pb-0">
          <v-row>
            <v-col>
              <v-alert
                v-if="erroImportacao"
                type="error"
                text
                icon="mdi-close-circle"
                class="justify-center"
              >
                <v-row align="center">
                  <v-col class="grow py-0"> {{ errosMensagem }}</v-col>
                  <v-col class="shrink py-0">
                    <v-btn
                      v-if="errosImportacao"
                      color="red"
                      @click="dialogErro = true"
                      small
                      style="font-size: 16px; font-weight: 500"
                      >Exibir erros</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-form ref="fromInventario">
            <v-row class="mt-5">
              <v-col cols="4" class="pb-0">
                <MesInput
                  label="Mês"
                  :min="proximoMes"
                  v-model="mesImportacao"
                  :rules="regra.mesImportacao"
                  required
                ></MesInput>
              </v-col>
              <v-col cols="8" class="pb-0">
                <v-file-input
                  label="Arquivo CSV"
                  class="input-cinza"
                  outlined
                  dense
                  v-model="arquivoCSV"
                  accept=".csv"
                  :rules="regra.csv"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
          <div class="instrucoes">
            <h4 color="primary">
              Para importar um novo inventário, siga as instruções abaixo.
            </h4>
            <ul>
              <li>
                A base utilizada para importação deve ser parecido ao modelo de
                exportação.
              </li>
              <li>
                As linhas de inventários anteriores que foram removidas serão
                excluídas do inventario.
              </li>
              <li>
                As colunas <strong>bandeira</strong> (RAIA ou DROGASIL),
                <strong>codigo_ativacao</strong> (Código da ativação),
                <strong>categoria</strong> (categoria) e
                <strong>quantidade</strong> (Quantidade padrão) são obrigatório,
                as demais colunas podem ser excluídas.
              </li>

              <li>
                As colunas <strong>semana_1</strong> a
                <strong>semana_5</strong> são colunas <strong>opcionais</strong>
                e só devem ser preenchidas caso a ativação tenha uma quantidade
                diferente da quantidade padrão.
              </li>
              <li>
                Na coluna
                <strong>quantidade</strong> (Quantidade) qualquer valor que não
                seja um
                <strong>número inteiro maior que 0</strong>
                (zero) será considerado como
                <strong>0</strong> (zero).
              </li>
              <li>
                Se houverem linhas duplicadas, ou seja, linhas com o mesmo
                <strong>codigo_ativacao</strong>, <strong>categoria</strong> e
                <strong>bandeira</strong>, a ultima linha será considerada e as
                anteriores serão desconsideradas.
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="new-button px-6 mb-3 mr-2"
            @click="importar()"
          >
            <v-icon left>mdi-upload</v-icon>
            Importar Arquivo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="br-15"
      v-model="dialogErro"
      width="800"
      :z-index="400"
    >
      <v-card>
        <v-toolbar elevation="0" dark class="error white--text fix-flex">
          <v-toolbar-title> Erros durante a importação </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogErro = false"
          >
            <v-icon size="18px" color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-data-table
            :headers="headerErros"
            class="elevation-0 tabela"
            :items="errosImportacao"
            :items-per-page="10"
            :footer-props="tableConfig"
            color="error"
          ></v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <download-csv
            :data="errosImportacao"
            :labels="errosLabels"
            name="erros_inventario_ecommerce.csv"
            delimiter=";"
          >
            <v-btn class="new-button px-4 mb-2" color="primary">
              <v-icon class="mr-3">mdi-microsoft-excel</v-icon>
              Exportar erros em CSV
            </v-btn>
          </download-csv>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      mesImportacao: this.$moment().add(1, "month").format("YYYY-MM"),
      dialogForm: false,
      dialogErro: false,
      carregando: false,
      arquivoCSV: null,
      erroImportacao: false,
      errosImportacao: [],
      headerErros: [
        { text: "Descrição", value: "msg", class: "th-separador" },
        { text: "Linha", value: "linha", align: "center", with: "100px" },
      ],
      errosLabels: {
        msg: "Descrição",
        linha: "Linha",
      },
      tableConfig: {
        itemsPerPageOptions: [10, 15, 25, 50, 100],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}",
      },
      errosMensagem: "",
      regra: {
        csv: [
          (v) => !!v || "Selecione um arquivo.",
          (v) =>
            (!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
            "O arquivo deve ser do tipo CSV.",
        ],
        mesImportacao: [
          (v) => !!v || "O campo mês é obrigatório.",
          (v) =>
            (!!v &&
              this.$moment(this.mesImportacao, "YYYY-MM") >=
                this.$moment().startOf("month").add(1, "month")) ||
            "O mês deve ser maior que o mês atual.",
        ],
      },
    };
  },
  created() {
    // this.novo();
  },
  computed: {
    proximoMes() {
      return this.$moment().add(1, "month").format("YYYY-MM");
    },
  },
  methods: {
    async importar() {
      if (!this.$refs.fromInventario.validate()) return;
      this.erroImportacao = false;
      this.errosImportacao = [];
      this.errosMensagem = "";

      this.carregando = true;

      const data = { mes: this.mesImportacao + "-01" };

      const formData = new FormData();

      formData.append("files", this.arquivoCSV);
      formData.append("data", JSON.stringify(data));

      const response = await this.$http().post(
        "/planejamentoEcommerce/inventario/importar",
        formData
      );

      if (response.status == 200 && response.data.error == false) {
        this.$emit("filtrar");
        this.dialogForm = false;
      } else {
        this.erroImportacao = true;
        this.errosImportacao = response.data?.erros;
        this.errosMensagem =
          response.data.mensagem || "Erro ao importar inventário";
      }

      console.log(response);
      this.carregando = false;
    },
    abrirImportacao() {
      this.dialogForm = true;
      this.mesImportacao = this.$moment().add(1, "month").format("YYYY-MM");
      this.arquivoCSV = null;
      this.erroImportacao = false;
      this.errosImportacao = [];
      this.errosMensagem = "";
    },
  },
};
</script>
