<template>
	<span>
		<v-badge
			bordered
			color="info"
			:content="1"
			:value="ultimoHistorico.processo == 'Primeira Aprovação'"
			overlap
			:title="ultimoHistorico.nome + ' - ' + ultimoHistorico.atualizacao"
		>
			<v-btn
				class="ml-3 my-1"
				color="primary"
				:disabled="
					lista.pendente.length == 0 &&
					(ultimoHistorico.processo == 'Importação' ||
						(ultimoHistorico.processo == 'Primeira Aprovação' &&
							ultimoHistorico.id_usuario !=
								this.$store.getters.getUsuario.id_usuario))
						? false
						: true
				"
				@click="showAprovacao()"
			>
				<v-icon left>mdi-check</v-icon> Aprovar
			</v-btn>
		</v-badge>

		<Modal
			v-model="showModal"
			:title="tileModal"
			:fullscreen="false"
			:maxWidth="'98%'"
			:hideClose="true"
			:load="load"
		>
			<template slot="body" v-if="showModal">
				<v-container fluid>
					<v-row>
						<v-col cols="12" md="12" lg="12">
							<b>
								Analise o cenário consolidado abaixo ( a partir
								do mês {{ this.ultimoHistorico.ano_mes }}), que
								será executado após as duas aprovações, e clique
								no botão aprovar se estiver de acordo:
							</b>
						</v-col>
					</v-row>
					<v-row>
						<v-col
							cols="3"
							v-for="item in cards"
							:key="item.key_value"
						>
							<v-alert
								:color="item.color"
								border="left"
								elevation="1"
								colored-border
								align="center"
								style="cursor: pointer;"
								@click="openDetails(item)"
							>
								<h2 :class="item.color + '--text'">
									{{ consolidado[item.key_value] }}
									{{ item.title }}
								</h2>
								<h4>{{ item.subtitle }}</h4>
							</v-alert>
						</v-col>
						<!-- <v-col cols="3">
							<v-alert
								color="red"
								border="left"
								elevation="1"
								colored-border
								align="center"
							>
								<h2 class="red--text">
									{{ consolidado.qtd_excluidos_organicos }}
									Filiais
								</h2>
								<h4>Excluídas (Acordos Orgânicos)</h4>
							</v-alert>
						</v-col>
						<v-col cols="3">
							<v-alert
								color="red"
								border="left"
								elevation="1"
								colored-border
								align="center"
							>
								<h2 class="red--text">
									{{ consolidado.qtd_excluidos_outros }}
									Filiais
								</h2>
								<h4>Excluídas (Acordos Outros)</h4>
							</v-alert>
						</v-col>
						<v-col cols="3">
							<v-alert
								color="red"
								border="left"
								elevation="1"
								colored-border
								align="center"
							>
								<h2 class="red--text">
									{{ consolidado.qtd_acordos_cancelados }}
									Acordos
								</h2>
								<h4>Cancelados</h4>
							</v-alert>
						</v-col>-->
					</v-row>
					<!-- <v-row>
						<v-col cols="12" md="12" lg="12">
							Deseja realmente aprovar a importação para o mês
							{{ this.ultimoHistorico.ano_mes }}?
						</v-col>
					</v-row> -->

					<v-divider></v-divider>
					<v-row>
						<v-col cols="12" align="end">
							<v-btn
								color="red"
								class="mr-3"
								dark
								@click="showModal = false"
							>
								Fechar
							</v-btn>

							<v-btn color="primary" @click="aprovar()">
								Aprovar importação
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</Modal>
	</span>
</template>

<script>
import Modal from "../../../../components/Modal.vue";

export default {
	components: { Modal },
	props: {
		ultimoHistorico: Object,
		lista: Object,
	},
	data() {
		return {
			showModal: false,
			load: false,
			consolidado: {},
			cards: [
				{
					color: "orange",
					key_value: "qtd_transferidos_organicos",
					title: "Filiais",
					subtitle: "Transferidas (Acordos Orgânicos)",
					transfer: true,
					organico: 1,
				},
				{
					color: "red",
					key_value: "qtd_excluidos_organicos",
					title: "Filiais",
					subtitle: "Excluídas (Acordos Orgânicos)",
					transfer: false,
					organico: 1,
				},
				{
					color: "red",
					key_value: "qtd_excluidos_outros",
					title: "Filiais",
					subtitle: "Excluídas (Acordos Outros)",
					transfer: false,
					organico: 0,
				},
				{
					color: "red",
					key_value: "qtd_acordos_cancelados",
					title: "Acordos",
					subtitle: "Cancelados",
					transfer: false,
				},
			],
		};
	},
	computed: {
		tileModal() {
			let _number =
				this.ultimoHistorico.processo == "Primeira Aprovação" ? 2 : 1;
			return `${_number}º Aprovação da Importação`;
		},
	},
	methods: {
		async showAprovacao() {
			this.showModal = true;
			this.load = true;

			var response = await this.$http().post(
				"/inventario/consolidado_inventario",
				{}
			);

			if (!response.data.success) {
				this.$dialog.notify.error(
					"Ops! Falha ao buscar o consolidado de aprovação, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);

				this.load = false;

				return false;
			}

			this.consolidado = response.data.data;
			this.load = false;
		},
		aprovar() {
			this.showModal = false;
			this.$emit("action-aprovar");
		},
		openDetails(obj) {
			if (obj.key_value == "qtd_acordos_cancelados") {
				let _obj = {
					fnc: "exporCancelados",
				};
				this.$emit("export-action", _obj);
			} else {
				let _obj = {
					fnc: "excluidas",
					params: {
						tipo: "pendente",
						isTransfer: obj.transfer,
						isOrganico: obj.organico,
					},
				};
				this.$emit("export-action", _obj);
			}
		},
	},
};
</script>

<style></style>
