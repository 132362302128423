<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2"
					>Lojas Ecommerce</v-toolbar-title
				>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn>
				
				<v-btn
					class="ml-2 my-1"
					color="primary"
					:disabled="mes < mesProximo"
					@click="abrirImportar()"
				>
					<v-icon left>mdi-upload</v-icon> Importar
				</v-btn>
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					<v-text-field
						v-model="busca"
						append-icon="mdi-magnify"
						label="Pesquisar"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						<v-text-field
							label="Mês"
							v-model="mes"
							:rules="regra.mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				item-key="id_fornecedor"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-icon
						v-if="item.mes >= mesProximo"
						small
						class="mr-2"
						color="primary"
						@click="abrirEditar(item)"
						title="Editar"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		
		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV para
					{{ $moment(mes).format("MMMM [de] YYYY") }}
				</v-card-title>
				<v-card-text>
					<v-alert
						class="mt-4"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								A coluna
								<strong>Id_Java</strong> (Código Java) é
								obrigatório, as demais colunas podem ser excluidas.
							</li>
						</ul>
					</v-alert>
					<v-row no-gutters>
						<v-col cols="3" sm="4" md="3" lg="3" xl="3">
							<v-text-field
								label="Mês"
								v-model="mesImportacao"
								:rules="regra.mesImportacao"
								:min="mesProximo"
								required
								outlined
								dense
								type="month"
							></v-text-field>
						</v-col>
						<v-col cols="8" class="px-2">
							<v-form ref="form" v-model="isCsv">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="4" class="px-2">
							<v-btn
								color="primary"
								:disabled="!isCsv"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Erros
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			headers: [
				{ text: "Id Java", value: "id_java", align: "center" },
				{ text: "Loja", value: "nome", align: "left" },
				{ text: "Mês", value: "mes", align: "center" },
			],
			dados: [],
			mes: null,
			mesImportacao: this.$moment()
				.add(1, "months")
				.format("YYYY-MM"),
			loja: {
				id_java: null,
				nome: null,
			},
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			lista: {
				lojas: [],
			},
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				mes: [(v) => !!v || "O campo mês é obrigatório."],
				mesImportacao: [
					(v) => !!v || "O campo mês é obrigatório",
					(v) =>
						(v && v > this.mesInicial) ||
						"O período inicial deve posterior ao mês atual",
				],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	async created() {
		this.mes = this.mesProximo;

		// var response = await this.$http().post("/lojasecommerce/lista", {
		// 	data: {},
		// });

		// this.lista.lojas = response.data;
	},
	computed: {
		mesInicial() {
			return this.$moment().format("YYYY-MM");
		},
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
	},
	methods: {
		async filtrar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			data.pagination = this.pagination;
			data.busca = this.busca;

			var response = await this.$http().post("/lojasecommerce/lista", {
				data: data,
			});

			this.dados = response.data;
			this.total = response.data.length ? response.data[0].total : 0;

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
		async exportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var response = await this.$http().post("/lojasecommerce/exportar", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async exportarImportacoes() {
			this.carregando = true;

			var response = await this.$http().post(
				"/lojasecommerce/exportarHistImportacoes",
				{ data: {} }
			);

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		abrirImportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.arquivoCSV = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;

			var data = { mes: this.mesImportacao + "-01" };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/lojasecommerce/importar",
				formData
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogoImportar = false;
			} else {
				this.filtrar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
	},
};
</script>
