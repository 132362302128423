var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formFiltro",on:{"submit":function($event){$event.preventDefault();return _vm.filtrar($event)}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('MesInput',{attrs:{"label":"Período Inicial","rules":_vm.regra.mesInicial,"required":""},model:{value:(_vm.filtro.mesInicial),callback:function ($$v) {_vm.$set(_vm.filtro, "mesInicial", $$v)},expression:"filtro.mesInicial"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('MesInput',{attrs:{"label":"Período Final","rules":_vm.regra.mesFinal,"required":""},model:{value:(_vm.filtro.mesFinal),callback:function ($$v) {_vm.$set(_vm.filtro, "mesFinal", $$v)},expression:"filtro.mesFinal"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('input-id-java',{attrs:{"label":"Filial","items":_vm.lista.filial},model:{value:(_vm.filtro.id_filial),callback:function ($$v) {_vm.$set(_vm.filtro, "id_filial", $$v)},expression:"filtro.id_filial"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Bandeira","items":_vm.lista.bandeira,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.bandeira.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.bandeira.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.bandeira.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.bandeira),callback:function ($$v) {_vm.$set(_vm.filtro, "bandeira", $$v)},expression:"filtro.bandeira"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Fornecedor","items":_vm.lista.fornecedor,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(
                            _vm.filtro.id_fornecedor.length === 1 && index === 0
                        )?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
                            _vm.filtro.id_fornecedor.length > 1 && index === 0
                        )?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_fornecedor.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_fornecedor),callback:function ($$v) {_vm.$set(_vm.filtro, "id_fornecedor", $$v)},expression:"filtro.id_fornecedor"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Espaço","items":_vm.lista.espaco,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.filtro.id_espaco.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_espaco.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_espaco.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_espaco),callback:function ($$v) {_vm.$set(_vm.filtro, "id_espaco", $$v)},expression:"filtro.id_espaco"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Espaço Macro","items":_vm.lista.espaco_macro,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(
                            _vm.filtro.espaco_macro.length === 1 && index === 0
                        )?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.espaco_macro.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.espaco_macro.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.espaco_macro),callback:function ($$v) {_vm.$set(_vm.filtro, "espaco_macro", $$v)},expression:"filtro.espaco_macro"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Cluster","items":_vm.lista.cluster,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.filtro.id_cluster.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.id_cluster.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_cluster.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_cluster),callback:function ($$v) {_vm.$set(_vm.filtro, "id_cluster", $$v)},expression:"filtro.id_cluster"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza",attrs:{"label":"Região","items":_vm.lista.regiao,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.filtro.regiao.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.regiao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.regiao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.regiao),callback:function ($$v) {_vm.$set(_vm.filtro, "regiao", $$v)},expression:"filtro.regiao"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Perfil","items":_vm.lista.perfil,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.filtro.perfil.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.perfil.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.perfil.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.perfil),callback:function ($$v) {_vm.$set(_vm.filtro, "perfil", $$v)},expression:"filtro.perfil"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Tamanho","items":_vm.lista.tamanho,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.filtro.tamanho.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.tamanho.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.tamanho.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.tamanho),callback:function ($$v) {_vm.$set(_vm.filtro, "tamanho", $$v)},expression:"filtro.tamanho"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{staticClass:"input-cinza",attrs:{"label":"Status de Execução","items":_vm.listaCampos.execucao,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(_vm.filtro.execucao.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.execucao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.execucao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.execucao),callback:function ($$v) {_vm.$set(_vm.filtro, "execucao", $$v)},expression:"filtro.execucao"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{staticClass:"input-cinza",attrs:{"label":"Número do acordo","outlined":"","dense":""},model:{value:(_vm.filtro.id_acordo),callback:function ($$v) {_vm.$set(_vm.filtro, "id_acordo", $$v)},expression:"filtro.id_acordo"}})],1),_c('v-col',{staticClass:"px-2 ml-auto pb-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","align":"end"}},[_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }