

<template>
  <div class="mes-box-card" v-bind:class="{ active: active }">
    <div class="title mb-2 d-flex">
      {{ mes }}
      <v-icon v-if="icon === 'verao'" size="14" class="mx-2"  color="orange"
        >mdi-white-balance-sunny</v-icon
      >
      <v-icon v-if="icon === 'inverno'" color="blue" size="14" class="mx-2" 
        >mdi-snowflake</v-icon
      >
    </div>
    <v-text-field
      class="input-cinza pt-3"
      v-if="!esconderDesconto"
      label="Desconto"
      type="number"
      :value="(active && (value[mes].desconto.toString()))"
      v-on:input="updateDesconto($event)"
      :rules="(active && regra.desconto) || []"
      :disabled="!active"
      suffix="%"
      outlined
      dense
    ></v-text-field>
    <v-text-field
      class="input-cinza"
      v-if="!esconderValor"
      label="Valor"
      type="number"
      :value="(active && value[mes].valor) || ''"
      v-on:input="updateValor($event)"
      :rules="(active && regra.valor) || []"
      :disabled="!active"
      outlined
      dense
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    mes: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
    active: {
      type: Boolean,
      default: false,
    },
    esconderValor: {
      type: Boolean,
      default: false,
    },
    esconderDesconto: {
      type: Boolean,
      default: true,
    },
    replicarValores: {
      type: Boolean,
      default: true,
    },

    icon: {
      type: String,
      default: "mdi-calendar-blank",
    },
  },
  data() {
    return {
      regra: {
        desconto: [
          (v) =>
            (!!v && v >= 0 && v <= 100) ||
            "O desconto deve ser um numero inteiro ente 0 e 100.",
        ],
        valor: [
          (v) =>
            (!!v && v >= 1) ||
            "O valor deve ser um numero inteiro igual ou maior que 1.",
        ],
      },
    };
  },
  methods: {
    updateDesconto: function (valueInput) {
      if (this.replicarValores) {
        Object.keys(this.value).forEach((mes) => {
          this.value[mes].desconto = valueInput;
        });
      } else {
        this.value[this.mes]["desconto"] = valueInput;
      }
      this.$emit("input", { ...this.value });
    },
    updateValor: function (valueInput) {
      if (this.replicarValores) {
        Object.keys(this.value).map((mes) => {
          this.value[mes].valor = valueInput;
        });
      } else {
        this.value[this.mes]["valor"] = valueInput;
      }
      this.$emit("input", { ...this.value });
    },
  },
};
</script>
<style>
.mes-box-card {
  border: 3px solid #f2f2f2;
  border-radius: 8px;
  padding: 15px 15px 0 15px;
  background-color: #fff;
  margin-bottom: 15px;
}
.mes-box-card.active {
  border-color: var(--v-primary-base);
}
.mes-box-card .title {
  font-weight: normal;
  text-transform: capitalize;
}
</style>