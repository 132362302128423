<template>
  <v-container fluid class="pt-0">
    <v-card class="px-5 py-3 br-15 w-100" elevation="0">
      <v-row>
        <v-col class="flex-grow-1">
          <v-toolbar-title class="page-title"
            >Cadastro de categorias</v-toolbar-title
          >
        </v-col>
        <v-col>
          <v-text-field
            class="input-button input-cinza"
            label="Pesquisar"
            v-model="pesquisa"
            outlined
            dense
            hide-details
          >
            <template>
              <v-icon slot="append" color="white">mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <div class="d-flex py-3 px-4">
          <v-btn class="new-button" color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
          <v-btn
            class="ml-5 new-button"
            color="primary"
            @click="abrirImportacao()"
          >
            <v-icon left>mdi-upload</v-icon> Importar
          </v-btn>
          <v-btn class="ml-5 new-button" color="primary" @click="novo()">
            <v-icon left>mdi-plus-thick</v-icon> Cadastrar
          </v-btn>
        </div>
      </v-row>
    </v-card>

    <v-card class="px-5 py-5 br-15 w-100 mt-5" elevation="0">
      <v-data-table
        class="elevation-0 tabela"
        :headers="headers"
        :items="dados"
        :items-per-page="10"
        :footer-props="tableConfig"
        :header-props="{ sortIcon: 'mdi-chevron-down' }"
        :search="pesquisa"
        v-model="selecionado"
        item-key="id_categoria"
        show-select
        multi-sort
      >
        <template v-slot:[`item.acoes`]="{ item }">
          <v-btn
            fab
            dark
            @click="editar(item)"
            class="mx-2 elevation-4"
            x-small
            color="light-blue "
          >
            <v-icon dark dense>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            @click="excluir([item])"
            class="mx-2 elevation-4"
            x-small
            color="error"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="table-footer-prepend">
        <v-btn
          color="error"
          class="new-button mt-3"
          v-if="selecionado.length > 0"
          dark
          @click="excluir(selecionado)"
        >
          Excluir selecionados
        </v-btn>
      </div>
    </v-card>
    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      content-class="br-15"
      scrollable
      v-model="dialogoExcluir"
      width="600"
    >
      <v-card>
        <v-toolbar elevation="0" dark class="error white--text fix-flex">
          <v-toolbar-title> Excluir categorias</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogoExcluir = false"
          >
            <v-icon size="20px" color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          Deseja realmente <span style="color: red">excluir</span>
          {{ listaExclusao.length }}
          {{ listaExclusao.length === 1 ? "categoria" : "categorias" }}? <br />
          <br />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="new-button mr-3 px-4 mb-4"
            @click="dialogoExcluir = false"
          >
            <v-icon class="mr-3">mdi-close-circle</v-icon>

            Não, não quero exluir
          </v-btn>
          <v-btn
            class="new-button px-4 mb-4 mr-4"
            color="primary"
            @click="confirmarExclusao()"
          >
            <v-icon class="mr-3">mdi-check-circle</v-icon>
            Sim, quero excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog content-class="br-15" v-model="dialogoForm" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title
            >{{
              form.id_categoria ? "Editar" : "Cadastrar"
            }}
            Categoria</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogoForm = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-form ref="form">
            <v-alert class="mb-4" v-if="alerta" dark color="error">
              {{ alertaMensagem }}
            </v-alert>
            <v-row class="pt-4">
              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  label="Nome"
                  v-model="form.nome"
                  class="input-cinza"
                  :rules="regra.nome"
                  outlined
                  dense
                ></v-text-field>
              </v-col>


              <v-col class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="ml-auto new-button"
                  @click="salvar()"
                >
                  <v-icon left>mdi-check-circle</v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="br-15" v-model="dialogoImportacao" width="900">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title> Importar Base de categorias </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogoImportacao = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5 pb-0">
          <div class="instrucoes">
            <h4 color="primary">
              Para importar uma base de categorias, siga as instruções abaixo
            </h4>
            <ul>
              <li>
                A base utilizada para importação deve ser parecido ao modelo
                extraída abaixo (Modelo de Importação).
              </li>
              <li>
                As colunas <strong>id_categoria</strong> (Código da categoria) e
                <strong>nome</strong> são <strong>obrigatórias</strong> , as
                demais colunas podem ser excluidas.
              </li>

              <li>
                As linhas sem valores para <strong>id_categoria</strong> (Código
                da categoria) é serão consideradas para
                <strong>inserção</strong>. Caso a linha possua um valor para
                <strong>id_categoria</strong> será considerada para
                <strong>edição</strong>.
              </li>
              <li>
                Recomendamos excluir as linhas que não sofrerão alteração, para
                evitar erros.
              </li>
            </ul>
          </div>
          <div v-if="!file">
            <div class="drop" @drag="onChange">
              <div :class="['dropZone', dragging ? 'dropZone-over' : '']">
                <div class="dropZone-info">
                  <span class=""
                    >Arraste seu Arquivo CSV, ou então
                    <span class="clickable">clique aqui</span> para
                    selecionar.</span
                  >
                </div>
                <input
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
                  type="file"
                  @change="onChange"
                />
              </div>
            </div>
          </div>
          <div v-else class="dropZone-uploaded">
            <div class="dropZone-uploaded-info d-flex">
              <span> {{ file.name }} </span>
              <span class="removeFile ml-3" @click="removeFile">
                <v-icon size="18" color="#ccc">mdi-close-circle</v-icon>
              </span>
            </div>
          </div>
          <v-alert
            type="error"
            transition="scale-transition"
            dismissible
            v-model="alerta"
          >
            {{ alertaMesagem }}
          </v-alert>
        </v-card-text>

        <v-card-actions class="pb-4 pt-0">
          <v-btn
            color="yellow"
            dark
            @click="exportar()"
            class="new-button ml-2"
          >
            <v-icon left>mdi-microsoft-excel</v-icon>
            Baixar modelo de importação
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="new-button mx-3 px-3"
            @click="salvarImportacao()"
          >
            <v-icon left>mdi-upload</v-icon>
            Importar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="carregando" :z-index="300">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "planejamento_categoria",

  data() {
    return {
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition"
      },
      listaCampos: {
        status: []
      },
      selecionado: [],
      carregando: false,
      pesquisa: "",
      dialogo: false,
      dialogoForm: false,
      dialogoExcluir: false,
      dialogoImportacao: false,
      alertaMesagem: "",
      erroModelo: false,
      listaErro: [],
      dragging: false,
      file: null,
      alerta: false,
      listaExclusao: [],
      alertaMensagem: "",
      form: {
        id_categoria: null,
        nome: null,
      },

      tableConfig: {
        itemsPerPageOptions: [5, 10, 20, 50, 100, -1],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}"
      },
      textFieldProps: {
        outlined: true,
        dense: true,
        class: "input-cinza",
        "hide-details": true
      },
      timeProps: {
        useSeconds: false,
        ampmInTitle: true,
        format: "24hr"
      },
      dados: [],
      regra: {
        nome: [v => !!v || "Campo Nome é obrigatório."],
        device: [v => v.length > 0 || "Campo Device é obrigatório."]
      },
      headers: [
        {
          text: "ID Categoria",
          value: "id_categoria",
          class: "th-separador min-width-100",
          align: "center"
        },
        {
          text: "Nome",
          value: "nome",
          class: "th-separador min-width-100",
          align: "center"
        },
        {
          text: "Ações",
          value: "acoes",
          class: "th-separador min-width-100",
          align: "center"
        }
      ]
    };
  },

  created() {
    this.filtrar();
  },
  computed: {
    extension() {
      return this.file
        ? this.file.name
            .split(".")
            .pop()
            .toLowerCase()
        : "";
    }
  },
  methods: {
    novo() {
      this.resetarForm();
      this.dialogoForm = true;
    },
    editar(item) {
      this.form.id_categoria = item.id_categoria;
      this.form.nome = item.nome;
      this.dialogoForm = true;
    },
    async salvar() {
      this.$refs.form.validate();
      const data = {
        id_categoria: this.form.id_categoria,
        nome: this.form.nome
      };

      const response = await this.$http().post(
        "/planejamentoEcommerce/categoria/salvar",
        data
      );

      if (response.status == 200) {
        this.$dialog.notify.success("Categoria salva com sucesso!");
        this.dialogoForm = false;
        this.filtrar();
      } else {
        this.$dialog.notify.error(
          response.data.mensagem || "Erro ao salvar Categoria!"
        );
      }
    },
    excluir(itens) {
      this.listaExclusao = itens;
      this.dialogoExcluir = true;
    },
    async confirmarExclusao() {
      const data = {
        id_categoria: this.listaExclusao.map(item => item.id_categoria)
      };

      const response = await this.$http().post(
        "/planejamentoEcommerce/categoria/excluir",
        data
      );

      if (response.status == 200) {
        this.$dialog.notify.success("Categoria excluída com sucesso!");
        this.dialogoExcluir = false;
        this.selecionado = [];
        this.listaExclusao = [];

        this.filtrar();
      } else {
        this.$dialog.notify.error(
          response.data.mensagem || "Erro ao excluir Categoria!"
        );
      }
    },

    resetarForm() {
      try {
        this.$refs.form.resetValidation();
      } catch (error) {
        console.log(error);
      }

      this.form = {
        id_categoria: null,
        nome: null
      };
    },
    async exportar() {
      const data = {};
      const response = await this.$http().post(
        "/planejamentoEcommerce/categoria/exportar",
        data
      );

      if (response.status == 200) {
        this.$dialog.notify.success("Categoria exportada com sucesso!");
        window.location = this.$http("baseURL") + response.data.url;
      } else {
        this.$dialog.notify.error(
          response.data.mensagem || "Erro ao exportar Categoria!"
        );
      }
    },
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    createFile(file) {
      this.file = file;
      if (this.extension !== "csv") {
        console.log(file);
        this.alerta = true;
        this.alertaMesagem = "O arquivo deve ser do tipo CSV.";
        this.dragging = false;
        this.file = "";
        return;
      }

      console.log(this.file);
      this.dragging = false;
    },
    removeFile() {
      this.file = "";
    },
    abrirImportacao() {
      this.dialogoImportacao = true;
      this.file = "";
    },
    async salvarImportacao() {
      this.carregando = true;

      // this.dialogoImportacao = false;
      const formData = new FormData();

      formData.append("files", this.file);

      const response = await this.$http().post(
        "/planejamentoEcommerce/categoria/importar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      if (response.status == 200) {
        this.$dialog.notify.success("Categoria importada com sucesso!");
        this.filtrar();
        this.dialogoImportacao = false;
      } else {
        this.$dialog.notify.error(
          response.data.mensagem || "Erro ao importar Categoria!"
        );
      }
      this.carregando = false;
    },
    async filtrar() {
      this.carregando = true;
      const data = {};
      const response = await this.$http().post(
        "/planejamentoEcommerce/categoria/lista",
        data
      );

      if (response.data && response.data.length > 0) {
        this.dados = response.data;
      } else {
        this.dados = [];
      }
      this.carregando = false;
    }
  }
};
</script>
