<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="4" class="py-0">
                <MesInputMultiple
                    label="Período"
                    v-model="value.periodo"
                    :rules="regra.periodo"
                    :min="mesAnterior"
                    :readonly="value.fnEditar"
                    required
                    outlined
                />
            </v-col>


            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Produto"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="id_produtoBusca"
                    :items="lista.produto"
                    :search-input.sync="buscaProdutoFornecedor"
                    :loading="isLoadingProduto"
                    @change="limpaDesconto()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    clearable
                >
                    <template v-slot:no-data>
                        <p
                            v-if="
                                !buscaProdutoFornecedor ||
                                (buscaProdutoFornecedor &&
                                    buscaProdutoFornecedor.length <= 4)
                            "
                            class="ml-4 mt-4"
                        >
                            Insira pelo menos 4 caracteres para listar os
                            produtos.
                        </p>
                        <p v-else-if="isLoadingProduto" class="ml-4 mt-4">
                            Buscando produtos...
                        </p>
                        <p v-else class="ml-4 mt-4">Nada encontrado</p>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-text-field
                    label="Fornecedor"
                    v-model="value.fornecedor"
                    :rules="regra.fornecedor"
                    :disabled="value.fnEditar"
                    outlined
                    class="input-cinza"
                    dense
                    readonly
                    required
                ></v-text-field>
            </v-col>
            <!-- <v-col cols="4" class="py-0">
                <v-select
                    label="Bandeira"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="value.bandeira"
                    :items="lista.bandeira"
                    :rules="regra.bandeira"
                    @change="limpaFiliais()"
                    :disabled="value.fnEditar"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col> -->

            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Cluster/Rede"
                    v-model="value.clusterRede"
                    :items="lista.clusterRede"
                    :menu-props="menuProps"
                    :rules="regra.categoria"
                    :disabled="value.fnEditar"
                    @change="limpaFiliais()"
                    class="input-cinza"
                    outlined
                    dense
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Nome da Campanha"
                    v-model="value.id_campanha"
                    :items='lista.nomeCampanhas'
                    :menu-props="menuProps"
                    :rules="regra.id_campanha"
                    :disabled="value.fnEditar"
                    @change="limpaFiliais()"
                    class="input-cinza"
                    outlined
                    dense
                >
                </v-autocomplete>
            </v-col>

            <v-col cols="4" class="py-0">
                <v-select
                    label="Cota"
                    outlined
                    class="input-cinza"
                    dense
                    v-model="value.cota"
                    :items="listaCotas"
                    :rules="regra.cota"
                    :disabled="!value.clusterRede || value.fnEditar"
                    :menu-props="menuProps"
                    @change="limpaFiliais()"

                    required
                ></v-select>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Espaço PDV"
                    v-model="value.id_espaco_pdv"
                    :items="listaEspacoPDV"
                    :menu-props="menuProps"
                    :rules="regra.espacoPDV"
                    :disabled="!value.cota || value.cota === 'ADESÃO' || value.fnEditar"
                    @change="limpaFiliais()"
                    class="input-cinza"
                    outlined
                    dense
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Espaço E-commerce"
                    v-model="value.id_espaco_ecommerce"
                    :items="listaEspacoEcommerce"
                    :menu-props="menuProps"
                    :rules="regra.espacoEcommerce"
                    :disabled="!value.cota || value.fnEditar"
                    @change="limpaDesconto()"
                    class="input-cinza"
                    outlined
                    dense
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    class="input-cinza"
                    v-model="value.obs_departamento"
                    dense
                    rows="3"
                    label="Observação Departamento"
                ></v-textarea>
            </v-col>
            <v-col cols="12" class="py-0">
                <v-textarea
                    outlined
                    class="input-cinza"
                    v-model="value.obs_contrato"
                    dense
                    rows="3"
                    label="Observação do Contrato"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>

export default {
    inject: ["listaCampos"],
    props: ["value"],
    data() {
        return {
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            lista: {
                // bandeira: [],
                espaco: [],
                custos: [],
                categorias: [],
                nomeCampanhas: [],
                cotas: ["ADESÃO", "BRONZE", "PRATA", "OURO", "DIAMANTE"],
                clusterRede: [
                    {
                        text: "Cluster",
                        value: "CLUSTER",
                    },
                    {
                        text: "Rede",
                        value: "REDE",
                    },
                ],
                plano: ["ANUAL", "AVULSO"],
                espacosCampanha: [
                    {
                        cota: "Diamante",
                        clusterRede: "Rede",
                        espacoPDV: [
                            {
                                espaco: "Cubo campanha - um quarto",
                                id_espaco: 4491,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Banner Home", id_espaco: 1 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Diamante",
                        clusterRede: "Cluster",
                        espacoPDV: [
                            {
                                espaco: "Cubo campanha - um quarto",
                                id_espaco: 4491,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 3 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Ouro",
                        clusterRede: "Rede",
                        espacoPDV: [
                            {
                                espaco: "Fila Única Módulo 1 - 1ª Prat A",
                                id_espaco: 633,
                            },
                            {
                                espaco: "Fila Única Módulo 2 - 1ª Prat A",
                                id_espaco: 669,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 4 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Ouro",
                        clusterRede: "Cluster",
                        espacoPDV: [
                            {
                                espaco: "Fila Única Módulo 1 - 1ª Prat A",
                                id_espaco: 633,
                            },
                            {
                                espaco: "Fila Única Módulo 2 - 1ª Prat A",
                                id_espaco: 669,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 5 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Prata",
                        clusterRede: "Rede",
                        espacoPDV: [
                            {
                                espaco: "Fila Única Módulo 1 - 2ª Prat A",
                                id_espaco: 645,
                            },
                            {
                                espaco: "Fila Única Módulo 2 - 2ª Prat A",
                                id_espaco: 681,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 6 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Prata",
                        clusterRede: "Cluster",
                        espacoPDV: [
                            {
                                espaco: "Fila Única Módulo 1 - 2ª Prat A",
                                id_espaco: 645,
                            },
                            {
                                espaco: "Fila Única Módulo 2 - 2ª Prat A",
                                id_espaco: 681,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 7 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Bronze",
                        clusterRede: "Rede",
                        espacoPDV: [
                            {
                                espaco: "Fila Única Módulo 1 - 3ª Prat A",
                                id_espaco: 657,
                            },
                            {
                                espaco: "Fila Única Módulo 2 - 3ª Prat A",
                                id_espaco: 687,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 8 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Bronze",
                        clusterRede: "Cluster",
                        espacoPDV: [
                            {
                                espaco: "Fila Única Módulo 1 - 3ª Prat A",
                                id_espaco: 657,
                            },
                            {
                                espaco: "Fila Única Módulo 2 - 3ª Prat A",
                                id_espaco: 687,
                            },
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 9 },
                        ],
                        mes: "01-03-2023",
                    },
                    {
                        cota: "Adesão",
                        clusterRede: "Rede",
                        espacoPDV: [
                            {
                                espaco: "Adesão Campanha",
                                id_espaco: 6195447,
                            }
                        ],
                        espacoEcommerce: [
                            { espaco: "Coleção campanha", id_espaco: 10 },
                        ],
                        mes: "01-03-2023",
                    },
                ],
            },
            id_produtoBusca: null,
            buscaProdutoFornecedor: null,
            isLoadingProduto: false,
            regra: {
                periodo: [
                v => v.length > 0 || "O campo período é obrigatório",
                    // (v) =>
                    //     (v && v >= this.mesAtual) ||
                    //     this.value.fnLeitura ||
                    //     "O período inicial deve ser igual ou posterior ao mês atual",
                ],
                // mesFinal: [
                //     (v) => !!v || "O campo período final é obrigatório",
                //     (v) =>
                //         (v && v >= this.value.periodo) ||
                //         "O período final deve ser igual ou posterior ao período inicial",
                // ],
                espacoPDV: [(v) =>this.value.cota == 'ADESÃO' || !!v || "O campo espaço PDV é obrigatório"],
                espacoEcommerce: [
                    (v) => !!v || "O campo espaço E-commerce é obrigatório",
                ],
                cota: [(v) => !!v || "O campo cota é obrigatório"],
                fornecedor: [(v) => !!v || "O campo fornecedor é obrigatório"],
                id_campanha: [(v) => !!v || "O campo Nome da campanha é obrigatório"],
                valor_rh: [
                    (v) => !!v || "O campo valor RH é obrigatório"
                ],
            },
            semAcesso: false,
        };
    },
    async created() {
        var lista = await this.listaCampos;
        // this.lista.bandeira = lista.bandeira;
        // this.lista.espaco = lista.espaco;
        this.lista.espaco = lista.espaco.filter(item => {
            var found = false
            for (let itemCubo of lista.cubosNaoPermitidosNovosAcordos) {
                if (item.id_espaco == itemCubo.id_espaco) found = true
            }
            return !found
        });
        this.lista.categorias = lista.categorias;
        this.lista.device = lista.device;
        this.lista.custos = lista.custos;
        this.lista.nomeCampanhas = lista.nomeCampanhas;
    },
    computed: {
        mesAtual() {
            return this.$moment().format("YYYY-MM");
        },
        acordoRetroativo(){
            if(this.value.fnEditar){
                return this.value.retroativo
            }
            if(this.mesAnterior === this.value.periodo){
                return true
            }
            return false

        },
        mesAnterior() {
            return this.$moment().subtract(1, "month").format("YYYY-MM");
        },
        compCotas() {
            if (!this.value.clusterRede) {
                return [];
            }
            // const cotas =this.lista.espacos[this.value.clusterRede.toLowerCase()]
            const cotas = this.lista.espacosCampanha.filter(
                (item) =>
                    item.clusterRede.toLowerCase() ===
                    this.value.clusterRede.toLowerCase()
            );
            return cotas;
        },
        compEspacoPDV() {
            if (!this.value.cota) {
                return [];
            }
            // const cotas = this.compCotas[this.value.cota.toLowerCase()]['pdv']
            const cotas = this.compCotas.filter(
                (item) =>
                    item.cota.toLowerCase() === this.value.cota.toLowerCase()
            )[0]["pdv"];
            return cotas;
        },
        compEspacoEcommerce() {
            if (!this.value.cota) {
                return [];
            }
            return [];
            // const cotas =this.compCotas[this.value.cota.toLowerCase()]['ecommerce']
            // return cotas
            //     ;
        },
        listaCotas() {
            return this.compCotas.map((item) => ({
                text: item.cota + " - " + item.clusterRede,
                value: item.cota.toUpperCase(),
            }));
        },
        listaEspacoPDV() {
            if (!this.value.cota) return [];

            const cota = this.compCotas.filter(
                (item) =>
                    item.cota.toLowerCase() === this.value.cota.toLowerCase()
            )[0];
            if (!cota) return [];

            return cota.espacoPDV.map((item) => ({
                text: item.espaco,
                value: item.id_espaco,
            }));
        },
        listaEspacoEcommerce() {
            if (!this.value.cota) return [];

            const cota = this.compCotas.filter(
                (item) =>
                    item.cota.toLowerCase() === this.value.cota.toLowerCase()
            )[0];

            if (!cota) return [];

            const espacosEcommerce = cota.espacoEcommerce.map((item) => ({
                text: item.espaco,
                value: item.id_espaco,
            }));
            return espacosEcommerce;
        },
    },
    watch: {
        listaEspacoEcommerce(val) {
            if (val.length == 1) {
                this.value.id_espaco_ecommerce = val[0].value;
            } else {
                if (
                    !val.some(
                        (val) => val.value == this.value.id_espaco_ecommerce
                    )
                ) {
                    delete this.value.id_espaco_ecommerce;
                }
            }
        },
        listaEspacoPDV(val) {
            if (val.length == 1) {
                this.value.id_espaco_pdv = val[0].value;
            } else {
                if (!val.some((val) => val.value == this.value.id_espaco_pdv)) {
                    delete this.value.id_espaco_pdv;
                }
            }
        },

        async buscaProdutoFornecedor(val) {
            if (!val) {
                this.id_produtoBusca = null;
                this.lista.produto = [];
                return;
            }

            if (val.length < 4) {
                this.lista.produto = [];
                return;
            }

            if (this.isLoadingProduto) return;

            this.isLoadingProduto = true;

            var data = { produto: val };

            var response = await this.$http().post("/acordo/buscaProdutos", {
                data: data,
            });

            this.lista.produto = response.data;

            this.isLoadingProduto = false;
        },

        id_produtoBusca(val) {
            var produto = this.lista.produto.find((e) => e.value == val);

            this.value.id_fornecedor = produto ? produto.id_fornecedor : null;
            this.value.fornecedor = produto ? produto.fornecedor : null;
            this.value.produto = produto ? produto.text : null;
            this.$emit("input", { ...this.value });
        },
    },
    methods: {
        clearVarAcordo(key) {
            this.acordo[key] = null;
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },

        limpaCadastro() {
            this.id_produtoBusca = null;
            this.buscaProdutoFornecedor = null;
            this.lista.produto = [];
            this.acordo = {
                periodo: [],
                mesFinal: null,
                id_espaco: null,
                id_fornecedor: null,
                fornecedor: null,
                // bandeira: [],
                obs_departamento: null,
                obs_contrato: null,
                visivel: null,
                comentario: null,
                fnEditar: false,
            };
        },
        limpaFiliais() {
            this.$emit("limpaFiliais");
            this.limpaDesconto();
        },
        limpaDesconto() {
            this.value.valores = [];
        },
        limpaProdutos() {
            this.$emit("limpaProdutos");
        },
        validate() {
            return this.$refs.form.validate();
        },
        reset() {
            return this.$refs.form.reset();
        },
        resetValidation() {
            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },
        periodoInicialChange(valor) {
            this.limpaFiliais();
            this.limpaDesconto();
            if (this.value.mesFinal < valor) {
                this.value.mesFinal = valor;
            }
        },
        periodoFinalChange(valor) {
            this.limpaFiliais();
            this.limpaDesconto();

            if (this.value.periodo > valor) {
                this.value.periodo = valor;
            }
        },
    },
};
</script>
