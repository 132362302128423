<template>
  <v-form>
    <v-menu
      v-model="menu1"
      :close-on-content-click="false"
      offset-y
      bottom
      width="0"
      content-class="select-outline"
      nudge-top="25px"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :placeholder="placeholder"
          v-bind:label="label"
          v-model="cmpValue"
          :rules="rules"
          :class="{
            select: true,
            'input-cinza': true,
            active: menu1,
          }"
          v-bind:required="required"
          v-bind:disabled="disabled"
          outlined
          dense
          v-bind="attrs"
          v-on="on"
          @click:clear="date = null"
        >
        </v-text-field>
      </template>
      <v-divider class="mt-0"></v-divider>
      <v-date-picker
        :min="min"
        :max="max"
        locale="pt-BR"
        :value="value"
        @change="handleChange"
        color="primary"
        class="input-mes pb-2"
        v-bind:first-day-of-week="firstDayOfWeek"
        no-title
        width="auto"
      ></v-date-picker>
    </v-menu>
  </v-form>
</template>
 
 <script>
export default {
  name: "MesInput",
  model: { prop: "value", event: "input" },

  props: [
    "value",
    "rules",
    "min",
    "max",
    "placeholder",
    "label",
    "required",
    "disabled",
    "change",
    "prepend-icon",
    "firstDayOfWeek",
  ],
  data() {
    return {
      mes: null,
      menu1: false,
    };
  },
  computed: {
    cmpValue: {
      get: function () {
        return this.value !== null && this.value !== ""
          ? this.$moment(this.value).format("DD/MM/YYYY")
          : "";
      },
      set: function (newValue) {
        if (
          newValue.length >= 10 &&
          this.$moment(newValue, "DD/MM/YYYY").isValid()
        ) {
          const parsed = this.$moment(newValue, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );

          this.change(parsed);
          this.$emit("input", parsed);
        }
      },
    },
  },
  methods: {
    handleChange(valor) {
      console.log("handleChange", valor);
      if (this.change) {
        this.change(valor);
      }
      this.menu1 = false;
      this.$emit("input", valor);
    },
  },
};
</script>
 
 <style>
.input-cinza.active .v-input__slot {
  border-bottom: none;
}

.input-cinza.active fieldset legend {
  width: 0 !important;
}

.input-mes .v-date-picker-header__value button {
  font-weight: 500;
  color: var(--v-primary-base) !important;
}

.input-mes,
.input-mes .v-picker__body {
  width: 100% !important;
}
.input-mes .v-btn__content {
  text-transform: capitalize;
}
.input-mes input {
  text-transform: uppercase !important;
}
.input-mes .theme--light.v-date-picker-table th {
  color: #ccc !important;
}
.input-mes .v-date-picker-table--date td {
  color: #ccc !important;
  padding: 0 !important;
}
</style>