var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formFiltro"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Período Inicial","rules":_vm.regra.dataInicial,"required":"","outlined":"","dense":"","type":"month"},model:{value:(_vm.filtro.dataInicial),callback:function ($$v) {_vm.$set(_vm.filtro, "dataInicial", $$v)},expression:"filtro.dataInicial"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Período Final","rules":_vm.regra.dataInicial,"required":"","outlined":"","dense":"","type":"month"},model:{value:(_vm.filtro.dataFinal),callback:function ($$v) {_vm.$set(_vm.filtro, "dataFinal", $$v)},expression:"filtro.dataFinal"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Visão","items":_vm.lista.visao,"rules":_vm.regra.visao,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.visao.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.visao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.visao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.visao),callback:function ($$v) {_vm.$set(_vm.filtro, "visao", $$v)},expression:"filtro.visao"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Colunas Agrupadas","items":_vm.lista.cols_agrupado,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.agrupar.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.agrupar.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.agrupar.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.agrupar),callback:function ($$v) {_vm.$set(_vm.filtro, "agrupar", $$v)},expression:"filtro.agrupar"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Bandeira","items":_vm.lista.bandeira,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.bandeira.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.bandeira.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.bandeira.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.bandeira),callback:function ($$v) {_vm.$set(_vm.filtro, "bandeira", $$v)},expression:"filtro.bandeira"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Fornecedor","items":_vm.lista.fornecedor,"rules":_vm.regra.fornecedor,"outlined":"","dense":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_fornecedor.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_fornecedor.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_fornecedor.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_fornecedor),callback:function ($$v) {_vm.$set(_vm.filtro, "id_fornecedor", $$v)},expression:"filtro.id_fornecedor"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Espaço","items":_vm.lista.espaco,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_espaco.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.id_espaco.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_espaco.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_espaco),callback:function ($$v) {_vm.$set(_vm.filtro, "id_espaco", $$v)},expression:"filtro.id_espaco"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Espaço Macro","items":_vm.lista.espaco_macro,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.espaco_macro.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.filtro.espaco_macro.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.espaco_macro.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.espaco_macro),callback:function ($$v) {_vm.$set(_vm.filtro, "espaco_macro", $$v)},expression:"filtro.espaco_macro"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Cluster","items":_vm.lista.cluster,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.filtro.id_cluster.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.filtro.id_cluster.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_cluster.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_cluster),callback:function ($$v) {_vm.$set(_vm.filtro, "id_cluster", $$v)},expression:"filtro.id_cluster"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Usuario","items":_vm.lista.usuarios,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(
                      _vm.filtro.id_usuario_criacao.length === 1 &&
                      index === 0
                  )?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
                      _vm.filtro.id_usuario_criacao.length > 1 &&
                      index === 0
                  )?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_usuario_criacao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_usuario_criacao),callback:function ($$v) {_vm.$set(_vm.filtro, "id_usuario_criacao", $$v)},expression:"filtro.id_usuario_criacao"}})],1),_c('v-col',{staticClass:"px-2 ml-auto pb-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","align":"end"}},[_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }