<template>
    <v-dialog content-class="br-15" v-model="dialogo" width="900">
        <v-card>
            <v-toolbar
                elevation="0"
                dark
                class="primary-degrade white--text fix-flex"
            >
                <v-toolbar-title>{{ titulo }} </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    width="24px"
                    height="24px"
                    elevation="0"
                    light
                    @click="dialogo = false"
                >
                    <v-icon size="18px" color="primary">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="listaHeader"
                    :items="planogramas"
                    item-key="slug"
                    class="elevation-0 tabela mt-4"
                    hide-default-footer
                    disable-sort
                    fixed-header
                    height="450px"
                    :items-per-page="1000"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.regiao }}</td>                        
                            <td class="text-center">
                                <div v-if="!item.file">
                                    
                                    <!-- <div class="drop" @drag="onChange($event,item.slug)">
                                        <div
                                            :class="[
                                                'dropZoneLocal',
                                                dragging ? 'dropZone-over' : '',
                                            ]"
                                        >
                                            <div class="dropZone-info">
                                                <span class=""
                                                    >Arraste seu Arquivo CSV, ou então
                                                    <span class="clickable">clique aqui</span>
                                                    para selecionar.</span
                                                >
                                            </div>
                                            <input
                                                name="files[]"
                                                @dragenter="dragging = true"
                                                @dragleave="dragging = false"
                                                type="file"
                                                @change="onChange($event,item.slug)"
                                            />
                                        </div>
                                    </div> -->
                                    <input
                                        :ref="item.slug"
                                        name="files[]"
                                        class="d-none"
                                        @dragenter="dragging = true"
                                        @dragleave="dragging = false"
                                        accept="image/*"
                                        type="file"
                                        @change="onChange($event,item.slug)"
                                    />
                                    <v-btn
                                        color="primary"
                                        class="new-button mr-2"
                                        @click="openFileDialog(item.slug)"
                                    >
                                        <v-icon left>mdi-upload</v-icon>
                                        buscar planograma...
                                    </v-btn>

                                </div>
                                <div v-else class="dropZone-uploaded">
                                    <div class="dropZone-uploaded-info d-flex justify-center">
                                        <span> {{ item.file.name }} </span>
                                        <span class="removeFile ml-3" @click="removeFile(item.slug)">
                                            <v-icon size="18" color="#8d082e"
                                                >mdi-close-circle</v-icon
                                            >
                                        </span>
                                    </div>
                                </div> 
                            </td>
                        </tr>
                    </template>
                </v-data-table> 
                
                <v-alert
                    type="error"
                    transition="scale-transition"
                    dismissible
                    v-model="alerta"
                >
                    {{ alertaMesagem }}
                </v-alert>
                <v-expand-transition>
                    <div v-show="erroModelo">
                        <v-row no-gutters class="mb-0">
                            <v-col cols="6">
                                <download-csv
                                    :data="listaErro"
                                    :labels="listaErroLabels"
                                    name="erros.csv"
                                    delimiter=";"
                                >
                                    <v-btn color="primary">
                                        <v-icon left
                                            >mdi-microsoft-excel</v-icon
                                        >
                                        Exportar Erros
                                    </v-btn>
                                </download-csv>
                            </v-col>
                            <v-col cols="6" align="end">
                                <v-btn
                                    color="primary"
                                    @click="ignorarErrosProdutos()"
                                >
                                    <v-icon left>mdi-alert-remove</v-icon>
                                    Ignorar Erros
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro"
                            class="elevation-0 data-table-2"
                        ></v-data-table>
                    </div>
                </v-expand-transition>
                
                <v-expand-transition>
                    <div v-show="erroModelo">
                        <v-row no-gutters class="mb-0">
                            <v-col cols="6">
                                <download-csv
                                    :data="listaErro"
                                    :labels="listaErroLabels"
                                    name="erros.csv"
                                    delimiter=";"
                                >
                                    <v-btn color="primary">
                                        <v-icon left
                                            >mdi-microsoft-excel</v-icon
                                        >
                                        Exportar Erros
                                    </v-btn>
                                </download-csv>
                            </v-col>
                            <v-col cols="6" align="end">
                                <v-btn
                                    color="primary"
                                    @click="ignorarErrosProdutos()"
                                >
                                    <v-icon left>mdi-alert-remove</v-icon>
                                    Ignorar Erros
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :headers="listaHeaderErro"
                            :items="listaErro"
                            :items-per-page="5"
                            :options.sync="paginationErro"
                            class="elevation-0 data-table-2"
                        ></v-data-table>
                    </div>
                </v-expand-transition>
            </v-card-text>
            <v-card-actions class="pb-4 pt-0">
                
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    class="new-button mr-2"
                    @click="dialogo = false"
                >
                    <v-icon left>mdi-close</v-icon>
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>
export default {
    name: "planogramasPorRegioes",
    props: ["value"],
    data() {
        return {
            carregando: false,
            // dadosTemp: [],
            // produtoServidor: [],
            dialogo: false,
            titulo: '',
            // csvModelo: null,
            // file: "",
            // isCsv: false,
            alerta: false,
            alertaMesagem: null,
            // dragging: false,
            listaHeader: [
                {
                    text: "Identificação",
                    value: "regiao",
                    align: "center",
                    class: "th-separador",
                    width: "235px",
                },
                {
                    text: "Planograma",
                    value: "slug",
                    align: "center",
                }
            ],
            planogramas: this.value,
            listaHeaderErro: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            listaErro: [],
            erroModelo: false,
            listaErroLabels: { msg: "Descrição do Erro" },
            paginationErro: {},
            // regra: {
            //     csv: [
            //         (v) => !!v || "Selecione um arquivo.",
            //         (v) =>
            //             (v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
            //             "O arquivo deve ser do tipo CSV.",
            //     ],
            // },
        };
    },
    methods: {
        // exportarModelo() {
        //     this.$emit("exportarModelo");
        // },
        openFileDialog(slug) {
            this.$refs[slug].click()
        },
        onChange(e,slug) {
            console.log(e)
            var files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                this.dragging = false;
                return;
            }

            this.createFile(files[0],slug);
        },
        createFile(file,slug) {
            this.planogramas = this.planogramas.map(item => {
                return {
                    ...item,
                    file: (item.slug == slug) ? file : item.file
                }
            })
            this.$emit('input',this.planogramas)
            //this.planograma[slug].file = file;
            // if (this.extension !== "csv") {
            //     console.log(file);
            //     this.alerta = true;
            //     this.alertaMesagem = "O arquivo deve ser do tipo CSV.";
            //     this.dragging = false;
            //     item.file = "";
            //     return;
            // }

            // console.log(item.file);
            this.dragging = false;
        },
        removeFile(slug) {
            // this.$refs[slug].value = null
            // this.file = "";
            this.planogramas = this.planogramas.map(item => {
                return {
                    ...item,
                    file: (item.slug == slug) ? null : item.file
                }
            })
        },
        abrir(planogramas,titulo) {
            console.log('planograma-> ',planogramas)
            this.titulo = titulo
            this.planogramas = planogramas
            this.csvModelo = null;
            this.alerta = false;
            this.alertaMesagem = null;
            this.erroModelo = false;
            this.listaErro = [];
            this.dialogo = true;
            this.dadosTemp = [];
        },
    },
    computed: {
        extension() {
            return this.file
                ? this.file.name.split(".").pop().toLowerCase()
                : "";
        },
    },
};
</script>
<style >
.instrucoes {
    border: 1px solid #ddd;
    padding: 10px 15px;
    border-radius: 6px;
    color: #808080;
}
.instrucoes h4 {
    color: var(--v-primary-base);
    margin-bottom: 6px;
}

.dropZoneLocal {
    width: 100%;
    height: 45px;
    position: relative;
    color: #808080;
}

.dropZone:hover {
    /* border: 2px solid #2e94c4; */
}

.dropZone:hover .dropZone-title {
    color: #1975a0;
}

.dropZone-info {
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 50px;
    transform: translate(0, -50%);
    padding: 10px 15px;
    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 6px;
}

.dropZone-title {
    color: #808080;
    margin-top: 20px;
}

.dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: blue;
}

.dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.dropZone-over .dropZone-info {
    border: 2px dashed #ccc;
}

.dropZone-uploaded {
    width: 100%;
    height: 35px;
    margin: 20px 0;
    padding: 10px 15px;

    border: 2px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 6px;
    position: relative;
}

.dropZone-uploaded-info {
    display: flex;
    align-items: center;
    color: #a8a8a8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
}

.removeFile:hover {
    color: red;
    cursor: pointer;
}
.clickable {
    cursor: pointer;
    text-decoration: underline;
}
</style>