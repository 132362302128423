var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"desconto"},[_c('v-row',{staticClass:"mt-4 h-100",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"3"}},[_c('vuetify-money',{staticClass:"input-cinza",attrs:{"placeholder":"Desconto (R$)","outlined":"","dense":""},model:{value:(_vm.descontoCampo.desconto),callback:function ($$v) {_vm.$set(_vm.descontoCampo, "desconto", $$v)},expression:"descontoCampo.desconto"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"ml-2 mb-1 new-button",attrs:{"color":"primary"},on:{"click":function($event){return _vm.carregarDesconto()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-down")]),_vm._v(" Carregar Desconto ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 tabela",attrs:{"headers":_vm.listaHeaderDesconto,"items":_vm.value.valores,"item-class":_vm.investimentoClass,"item-key":"nome","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([_vm._l((_vm.listaHeaderDesconto.filter(function (header) { return header.hasOwnProperty('formatter'); }
            )),function(header){return {key:("item." + (header.value)),fn:function(ref){
            var header = ref.header;
            var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.percentual",fn:function(ref){
            var item = ref.item;
return [(item.mes != 'Total')?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],staticClass:"input-cinza",attrs:{"ripple":false,"dense":"","outlined":"","hide-details":"","placeholder":((item.desconto_padrao) + "% (Desconto padrão)")},on:{"input":function($event){return _vm.calculaDesconto()}},model:{value:(item.percentual),callback:function ($$v) {_vm.$set(item, "percentual", $$v)},expression:"item.percentual"}}):_vm._e()]}},{key:"item.desconto",fn:function(ref){
            var item = ref.item;
return [(item.mes != 'Total')?_c('vuetify-money',{staticClass:"input-cinza",attrs:{"ripple":false,"dense":"","outlined":"","hide-details":""},on:{"input":function($event){return _vm.calculaDesconto()}},model:{value:(item.desconto),callback:function ($$v) {_vm.$set(item, "desconto", $$v)},expression:"item.desconto"}}):_vm._e()]}},{key:"item.valor_rh",fn:function(ref){
            var item = ref.item;
return [(item.mes != 'Total' && item.retroativo)?_c('vuetify-money',{staticClass:"input-cinza",attrs:{"ripple":false,"dense":"","outlined":"","hide-details":"","rules":_vm.regras.valor_rh},on:{"input":function($event){return _vm.calculaDesconto()}},model:{value:(item.valor_rh),callback:function ($$v) {_vm.$set(item, "valor_rh", $$v)},expression:"item.valor_rh"}}):_vm._e()]}},{key:"item.justificativas",fn:function(ref){
            var item = ref.item;
return [(
                    ((item.percentual != '' &&
                        item.percentual != undefined) ||
                        (item.desconto != '' &&
                            item.desconto != undefined &&
                            item.desconto != 0)) &&
                    item.mes != 'Total'
                )?_c('v-select',{staticClass:"input-cinza",attrs:{"placeholder":"Justificativa","items":_vm.justificativas,"item-text":"justificativa","item-value":"id_justificativa","dense":"","outlined":"","hide-details":"","rules":_vm.regras.justificativa,"menu-props":_vm.menuProps,"required":""},on:{"change":function($event){return _vm.atualizaDescontos(item)}},model:{value:(item.justificativa),callback:function ($$v) {_vm.$set(item, "justificativa", $$v)},expression:"item.justificativa"}}):_vm._e()]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"total"},[_c('td',{attrs:{"colspan":1}},[_vm._v("Total")]),_c('td',[_vm._v(_vm._s(_vm.formataReal(_vm.totalCusto)))]),_c('td',{attrs:{"colspan":_vm.acordoRetroativo? 3 : 2}}),_c('td',[_vm._v(_vm._s(_vm.formataReal(_vm.totalInvestimento)))])])])],2),(_vm.dialogoCamposFaltando)?_c('div',{staticClass:"dialogo-erro",attrs:{"width":"700px"}},[_c('v-card',{staticClass:"br-15"},[_c('v-toolbar',{staticClass:"error white--text fix-flex",attrs:{"elevation":"0","dark":""}},[_c('v-toolbar-title',[_vm._v(" Por favor retorne as etapas anteriores e preencha os seguintes campos:")])],1),_c('v-card-text',{staticClass:"pa-5"},[_c('v-list',_vm._l((_vm.camposFaltando),function(campo){return _c('v-list-item',{key:campo},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(campo))])],1)],1)}),1)],1)],1)],1):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.carregando}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-overlay',{attrs:{"value":_vm.mostraOverlay,"absolute":true,"opacity":0.6,"z-index":3}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }