<template>
    <v-card class="px-5 pt-5 py-3 br-15 w-100" elevation="0">
        <v-form ref="formFiltro" v-on:submit.prevent="filtrar">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Painel de fotos</v-toolbar-title
                    >
                </v-col>
                <v-col cols="3" class="py-0">
                    <mes-input
                        label="Período Inicial"
                        v-model="value.mesInicial"
                        :rules="regra.mesInicial"
                        required
                        outlined
                        dense
                        type="month"
                    ></mes-input>
                </v-col>
                <v-col cols="3" class="py-0">
                    <mes-input
                        label="Período Final"
                        v-model="value.mesFinal"
                        :rules="regra.mesFinal"
                        required
                        outlined
                        dense
                        type="month"
                    ></mes-input>
                </v-col>
                <v-col cols="3" class="py-0">
                    <v-autocomplete
                        label="Filial"
                        v-model="value.id_filial"
                        :items="lista.filial"
                        class="input-cinza"
                        :menu-props="menuProps"
                        chips
                        multiple
                        outlined
                        dense
                        @focus="filialFocus = true"
                        @blur="filialFocus = false"
                        :search-input.sync="search"
                    >
                        <template v-slot:selection="{ item, index }">
                            {{ item.id_java
                            }}{{
                                value.id_filial.length - 1 == index
                                    ? ""
                                    : ",  "
                            }}{{
                                value.id_filial.length - 1 == index &&
                                filialFocus
                                    ? ",  "
                                    : " "
                            }}
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-expansion-panels flat v-model="mostrarFiltros" accordion>
                <v-expansion-panel>
                    <v-expansion-panel-content eager class="p-0 m-0">
                        <v-row>
                            <v-col sm="6" md="4" lg="3" class="pb-0">
                                <v-text-field
                                    label="Número do acordo"
                                    outlined
                                    dense
                                    class="input-cinza"
                                    v-model="value.id_acordo"
                                ></v-text-field>
                            </v-col>
                            <v-col sm="6" md="4" lg="3" class="pb-0">
                                <v-text-field
                                    label="Número do acordo RD"
                                    outlined
                                    dense
                                    class="input-cinza"
                                    v-model="value.acordo_rd"
                                ></v-text-field>
                            </v-col>
                            <v-col sm="6" md="4" lg="3" class="pb-0">
                                <v-text-field
                                    label="Número do contrato"
                                    outlined
                                    dense
                                    class="input-cinza"
                                    v-model="value.id_contrato"
                                ></v-text-field>
                            </v-col>
                            <v-col sm="6" md="4" lg="3" class="pb-0">
                                <v-text-field
                                    label="Número do contrato RD"
                                    outlined
                                    dense
                                    class="input-cinza"
                                    v-model="value.contrato"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-select
                                    label="Bandeira"
                                    v-model="value.bandeira"
                                    :items="lista.bandeira"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                value.bandeira.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item.text }}</span
                                        >
                                        <span
                                            v-if="
                                                value.bandeira.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.bandeira.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete
                                    label="Fornecedor"
                                    v-model="value.id_fornecedor"
                                    :items="lista.fornecedor"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <div
                                            v-if="
                                                value.id_fornecedor.length ===
                                                    1 && index === 0
                                            "
                                            class="autocomplete-selecao"
                                        >
                                            {{ item.text }}
                                        </div>
                                        <span
                                            v-if="
                                                value.id_fornecedor.length >
                                                    1 && index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.id_fornecedor.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete
                                    label="Espaço"
                                    v-model="value.id_espaco"
                                    :items="lista.espaco"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <div
                                            v-if="
                                                value.id_espaco.length === 1 &&
                                                index === 0
                                            "
                                            class="autocomplete-selecao"
                                        >
                                            {{ item.text }}
                                        </div>
                                        <span
                                            v-if="
                                                value.id_espaco.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.id_espaco.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete
                                    label="Espaço Macro"
                                    v-model="value.espaco_macro"
                                    :items="lista.espaco_macro"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <div
                                            v-if="
                                                value.espaco_macro.length ===
                                                    1 && index === 0
                                            "
                                            class="autocomplete-selecao"
                                        >
                                            {{ item.text }}
                                        </div>
                                        <span
                                            v-if="
                                                value.espaco_macro.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.espaco_macro.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-select
                                    label="Cluster"
                                    :items="lista.cluster"
                                    v-model="value.id_cluster"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                value.id_cluster.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item.text }}</span
                                        >
                                        <span
                                            v-if="
                                                value.id_cluster.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.id_cluster.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-autocomplete
                                    label="Região"
                                    v-model="value.regiao"
                                    :items="lista.regiao"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <div
                                            v-if="
                                                value.regiao.length === 1 &&
                                                index === 0
                                            "
                                            class="autocomplete-selecao"
                                        >
                                            {{ item.text }}
                                        </div>
                                        <span
                                            v-if="
                                                value.regiao.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.regiao.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-select
                                    label="Perfil"
                                    :items="lista.perfil"
                                    v-model="value.perfil"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                value.perfil.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item.text }}</span
                                        >
                                        <span
                                            v-if="
                                                value.perfil.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.perfil.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="3" class="py-0">
                                <v-select
                                    label="Tamanho"
                                    :items="lista.tamanho"
                                    v-model="value.tamanho"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                value.tamanho.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item.text }}</span
                                        >
                                        <span
                                            v-if="
                                                value.tamanho.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                value.tamanho.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-divider class="mt-0 mb-3"></v-divider>
            <v-row>
                <v-col cols="3" class="px-2 ml-auto">
                    <div class="new-button d-flex align-center" text>
                        <span @click="expandirFiltros" style="cursor: pointer"
                            >Personalizar Filtros</span
                        >
                        <v-btn
                            style="
                                background: #e6e6e6;
                                width: 20px;
                                height: 20px;
                            "
                            fab
                            icon
                            class="ml-3"
                            @click="expandirFiltros"
                            x-small
                        >
                            <v-icon>{{
                                mostrarFiltros == 0
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                            }}</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col
                    cols="9"
                    class="ml-auto d-flex align-center justify-end"
                    align="end"
                >
                    <v-btn class="" color="indigo" dark @click="mudarColunas()">
                        <v-icon dark>
                            {{
                                value.colunas == 3
                                    ? "mdi-arrow-expand"
                                    : "mdi-arrow-collapse"
                            }}</v-icon
                        >
                    </v-btn>
                    <v-col cols="2" class="py-0">
                        <v-select
                            label="Fotos por página"
                            v-model="value.pagination.itemsPerPage"
                            :items="[12, 24, 36, 48, 60, 72, 84, 96]"
                            class="input-cinza py-0 my-0"
                            :menu-props="menuProps"
                            outlined
                            hide-details=""
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="3" class="py-0">
                        <v-select
                            label="Ordem"
                            v-model="value.pagination.sortBy"
                            :items="['Recentes','Id java','Acordos']"
                            class="input-cinza py-0 my-0"
                            :menu-props="menuProps"
                            outlined
                            hide-details=""
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-btn
                        class="ml-2 new-button"
                        color="yellow"
                        dark
                        @click="limpar()"
                    >
                        <v-icon left>mdi-filter-off</v-icon> Limpar Filtros
                    </v-btn>
                    <v-btn
                        class="ml-5 new-button"
                        color="primary"
                        type="submit"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <v-overlay :value="overlay" :z-index="300">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-form>
    </v-card>
</template>

<script>
import useFiltros from "@/hooks/useFiltros";
import MesInput from "../../../components/MesInput.vue";

export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros("/gerencial/filtros");

        return { isLoading, isError, isFetching, lista, error, refetch };
    },
    props: ["value"],
    data() {
        return {
            search: "",
            filialFocus: false,
            mostrarFiltros: null,
            config: {
                itensPorPagina: 12,
                sortBy: 'Recentes'
            },

            listaCampos: {
                execucao: [
                    { text: "Pendente", value: ["null"] },
                    { text: "Auditado", value: ["Sim", "Não"] },
                    { text: "Executado", value: ["Sim"] },
                    { text: "Não executado", value: ["Não"] },
                ],
            },
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            filtro: {},
            regra: {
                mesInicial: [
                    (v) => !!v || "Selecione um período inicial para o filtro",
                ],
                mesFinal: [
                    (v) => !!v || "Selecione um período final para o filtro",
                    (v) =>
                        (!!v && v >= this.value.mesInicial) ||
                        "O período final deve ser igual ou posterior ao período inicial",
                ],
            },
        };
    },

    computed: {
        overlay() {
            return Object.keys(this.lista).length === 0 && this.isFetching;
        },
    },
    methods: {
        expandirFiltros() {
            this.mostrarFiltros = this.mostrarFiltros == 0 ? null : 0;
        },
        selectFilial() {
            console.log(this.value.id_filial);
        },
        limpar() {
            this.$emit("limpar");
        },
        filtrar() {
            this.value.pagination.page = 1;
            this.$emit("filtrar");
        },
        validate() {
            return this.$refs.formFiltro.validate();
        },
        resetValidation() {
            try {
                this.$refs.formFiltro.resetValidation();
            } catch (error) {
                //console.log(error)
            }
        },
        selectItemsFromList(data) {
            if (!data || !data.includes(",")) return;
            const id_java = data
                .split(",")
                .map((id) => Number(id.trim()))
                .filter((id) => !isNaN(id) && id > 0);

            const id_filial = this.lista.filial
                .filter((item) => id_java.includes(item.id_java))
                .map((item) => item.value);

            // remove duplicates before pushing

            const final = id_filial.filter(
                (item) => !this.value.id_filial.includes(item)
            );

            this.value.id_filial.push(...final);

            this.search = "";
        },

        mudarColunas() {
            this.value.colunas = this.value.colunas == 3 ? 4 : 3;
        },
    },
    watch: {
        search(newSearch) {
            if (!this.value.id_filial.some((item) => item.name === newSearch)) {
                this.selectItemsFromList(newSearch);
            }
        },
    },
};
</script>
