<template>
	<v-dialog
		:value="value"
		@input="$emit('input', $event)"
		:fullscreen="fullscreen"
		:scrollable="false"
		persistent
		:hide-overlay="fullscreen"
		:transition="transition"
		:retain-focus="false"
		:content-class="$style.dialogHidden"
		:max-width="maxWidth"
	>
		<v-card class="pa-1 ma-0" tile>
			<v-toolbar
				height="50"
				:style="styleToolbarHeader"
				:color="colorToolbar"
			>
				<v-toolbar-title :style="{ color: colorTextToolbar }">{{
					title
				}}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn
					icon
					@click.stop="$emit('input', false)"
					:style="{ color: colorTextToolbar }"
					v-if="!hideClose"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<slot name="body"></slot>
		</v-card>
		<v-overlay :value="load" absolute>
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		{{ progress }}
		<v-overlay :value="progress != null" absolute class="text-center">
			<v-progress-circular
				:rotate="-90"
				:size="100"
				:width="15"
				:value="progress"
				color="white"
				class="text-center"
			>
				{{ progress }} </v-progress-circular
			><br /><br />
			{{ progressLabel }}
		</v-overlay>
	</v-dialog>
</template>

<script>
export default {
	props: {
		load: {
			type: Boolean,
			default: false,
		},
		progress: {
			type: Number,
			default: null,
		},
		progressLabel: String,
		title: String,
		transition: {
			type: String,
			default: 'dialog-bottom-transition"',
		},
		value: Boolean,
		hideClose: {
			type: Boolean,
			default: false,
		},
		fullscreen: {
			type: Boolean,
			default: true,
		},
		maxWidth: String,
		colorToolbar: {
			type: String,
			default: "teal lighten-2",
		},
		colorTextToolbar: {
			type: String,
			default: "#ffffff",
		},
		styleToolbarHeader: Object,
	},
	data() {
		return {};
	},
};
</script>
<style module>
.dialogHidden {
	overflow: auto !important;
}
</style>
