<template>
    <div class="d-flex">
        <div class="info-tooltip" v-if="pendencias.length > 0">
            <InfoBox expands>
                <p>
                    Foi inserido/atualizado as áreas de informações e
                    orientações, favor verificar e clicar neste botão para dar o
                    de acordo nas informações de negociação.
                </p>
            </InfoBox>
        </div>
        <div class="flex-grow w-100">
            <v-btn
                :disabled="btnDisabled"
                :color="pendencias.length > 0 ? 'orange': 'primary'"
                class="w-100 new-button"
                @click="aprovar"
            >
                {{ textoBotao }}
            </v-btn>
        </div>

        <v-dialog content-class="br-15" v-model="dialogo" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    color="orange"
                    class="fix-flex black--text"
                >
                    <v-toolbar-title> Pendências encontradas </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogo = false"
                    >
                        <v-icon size="18px">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    <p>
                        Foram encontradas pendencias nas Informações gerais e ou
                        orientações de execução.
                    </p>
                    <p>Por favor verifique e confirme estar de acordo.</p>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import InfoBox from "./InfoBox.vue";
export default {
    components: {
        InfoBox,
    },
    props: {
        ano: Number,
        fornecedor: Object,
        params: Object,
    },
    data() {
        return {
            dialogo: false,
            overlay: true,
            error: false,
            loading: false,
            btnDisabled: true,
            textoBotao: "Estou de acordo",
            dados: [],
            tipoUsuario: null,
        };
    },
    watch: {
        $props: {
            handler() {
                if (this.ano != null) this.getDados();
            },
            deep: true,
            immediate: false,
        },
    },
    computed: {
        pendencias() {
            return this.dados.filter((item) => item.data_aprovacao == null);
        },
    },
    methods: {
        async aprovar() {
            if(this.pendencias.length === 0) {
                return;
            }
            // this.loading = true;
            this.textoBotao = "Aguarde...";
            this.btnDisabled = true;
            const response = await this.$http().post(
                "/simulacao/resumo/aprovarPendencias",
                {
                    data: {
                        id_fornecedor: this.fornecedor.value,
                        ano_atual: this.ano,
                        pendencias: this.pendencias,
                    },
                }
            );
            if ((response || { status: 500 }).status != 200) {
                this.textoBotao = "Estou de acordo";
                this.btnDisabled = false;

                return;
            }
            this.dados = []
            this.textoBotao = "Informações aprovadas";
            this.btnDisabled = false;

            // this.loading = false;
        },

        async getDados() {
            this.dialogo = false;
            this.textoBotao = "Aguarde...";
            const response = await this.$http().post(
                "/simulacao/resumo/aprovacoesPendentes",
                {
                    data: {
                        id_fornecedor: this.fornecedor.value,
                        ano_atual: this.ano,
                    },
                }
            );

            if ((response || { status: 500 }).status != 200) {
                this.overlay = false;
                this.error = true;
                return;
            }

            if (response.data.retorno) {
                this.dados = response.data.retorno;
            } else {
                this.dados = [];
            }
            this.tipoUsuario = response.data.tipoUsuario;
            await new Promise((resolve) => setTimeout(resolve, 100));

            if (this.pendencias.length > 0) {
                if (this.tipoUsuario == "fornecedor") {
                    this.dialogo = true;
                    this.btnDisabled = false;
                    this.textoBotao = "Estou de acordo";
                } else if (this.tipoUsuario == "rd") {
                    this.btnDisabled = true;
                    this.textoBotao = "Existem pendencias";
                }
            } else {
                this.btnDisabled = false;
                this.textoBotao = "Informações aprovadas";
            }
        },
    },
};
</script>

<style >
.info-tooltip {
    display: block;
    width: 45px;
    margin-top: 5px;
}
</style>