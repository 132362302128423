<template>
    <v-card>
        <v-card-title class="subtitle-1 primary white--text">
            Configurar Envios de Guias e Extratos
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers" :items="texto_email_envio" sort-by="posicao" class="elevation-1">
                <template v-slot:top>



                    <v-toolbar flat>
                        <v-toolbar-title>Texto do email de envio</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-checkbox v-model="enviar_guia_consolidado" label="Enviar consolidado" class="mx-4 mt-4" />
                        <v-spacer></v-spacer>
                        <v-checkbox v-model="enviar_extrato" label="Enviar Extrato" class="mx-4 mt-4" />
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                    Nova linha
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field v-model="editedItem.linha" outlined dense
                                                    label="linha"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="blue darken-1" text @click="save">
                                        Salvar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5">Voce tem certeza, deseja deletar esse item?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Sim</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                    <v-icon v-if="item.posicao > 1" small @click="upItem(item)">
                        mdi-arrow-up-bold
                    </v-icon>
                    <v-icon small v-if="item.posicao < texto_email_envio.length" @click="downItem(item)">
                        mdi-arrow-down-bold
                    </v-icon>
                </template>
                <template v-slot:no-data>

                </template>
            </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="primary" class="pr-4" @click="saveProcess">
                <v-icon left>mdi-folder</v-icon> Salvar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="pr-4" @click="handleInput">
                <v-icon left>mdi-close</v-icon> Fechar
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import smartId from "smart-id"
import _ from "underscore"
export default {

    data: () => ({
        dialog: false,
        dialogDelete: false,
        enviar_extrato: true,
        enviar_guia: false,
        enviar_guia_consolidado: true,
        headers: [
            { text: 'Linhas', value: 'linha', sortable: false },
            // { text: 'pos', value: 'posicao', sortable: false, width: '90px' },
            { text: '', value: 'actions', sortable: false, width: '90px' },
        ],
        texto_email_envio: [],
        editedIndex: -1,
        editedItem: {
            linha: '',
        },
        defaultItem: {
            linha: '',
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nova linha' : 'Edite linha'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    mounted() {
        this.initialize()
    },

    methods: {
        handleInput(e) {
            this.$emit('input', false)
        },
        async initialize() {
            var response = await this.$http().get('/fornecedoresporusuario/configenvioguia/dados')
            console.log(response)
            this.texto_email_envio = JSON.parse(response.data.texto_email_envio)
            this.enviar_guia = false
            this.enviar_guia_consolidado = response.data.enviar_guia_consolidado
            this.enviar_extrato = response.data.enviar_extrato
        },
        async saveProcess() {
            var response = await this.$http().post('/fornecedoresporusuario/configenvioguia/salve', {
                data: {
                    texto_email_envio: JSON.stringify(this.texto_email_envio),
                    enviar_guia: false,
                    enviar_guia_consolidado: this.enviar_guia_consolidado,
                    enviar_extrato: this.enviar_extrato
                }
            })
            this.$emit('input', false)
        },
        editItem(item) {
            this.editedIndex = this.texto_email_envio.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.editedIndex = this.texto_email_envio.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.texto_email_envio.splice(this.editedIndex, 1)
            this.texto_email_envio = this.reindex(this.texto_email_envio)
            this.closeDelete()
        },
        upItem(item) {
            let texto_email_envio = this.texto_email_envio
            this.texto_email_envio = texto_email_envio.map(texto => {
                return {
                    ...texto,
                    posicao: (item.posicao == texto.posicao) ? texto.posicao - 1 : ((item.posicao - 1) == texto.posicao) ? item.posicao : texto.posicao
                }
            })
        },
        downItem(item) {
            let texto_email_envio = this.texto_email_envio
            this.texto_email_envio = texto_email_envio.map(texto => {
                return {
                    ...texto,
                    posicao: (item.posicao == texto.posicao) ? texto.posicao + 1 : ((item.posicao + 1) == texto.posicao) ? item.posicao : texto.posicao
                }
            })
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        reindex(texto) {
            _.sortBy(texto, 'posicao')
            console.log(texto)
            texto = texto.map((item, index) => {
                return { ...item, posicao: index + 1 }
            })
            return texto
        },
        save() {
            console.log(this.texto_email_envio)
            if (this.editedIndex > -1) {
                Object.assign(this.texto_email_envio[this.editedIndex], this.editedItem)
            } else {
                let posicao = 1000
                let texto_email_envio = this.texto_email_envio
                texto_email_envio.push({
                    ...this.editedItem,
                    id: smartId.make(),
                    posicao
                })
                this.texto_email_envio = this.reindex(texto_email_envio)

            }
            this.close()
        },
    },
}
</script>