import store from '@/store'
import { http } from '../config/http'

export const arquivoProtegido = (url) => {
    const baseUrl = http('baseURL');
    const token = store.getters.getToken;
    return `${baseUrl}/assinarUrl/?token=${token}&arquivo=${url}`
}
export const assinarUrl = (url) => {

    const base = window.location.origin;
    return `${base}/arquivo?url=${url}`
}
