<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      :md="layoutCompacto ? 6 : 12"
      :lg="layoutCompacto ? 6 : 12"
      :xl="layoutCompacto ? 6 : 12"
      class=""
    >
      <v-card class="my-0 br-15 pa-5 pt-3" elevation="0">
        <v-icon
          style="position: absolute; top: 15px; left: 20px; z-index: 2"
          left
          @click="mudaLayout()"
          title="Layout compacto"
          color="#bbb"
          >{{
            layoutCompacto ? "mdi-arrow-expand" : "mdi-arrow-collapse"
          }}</v-icon
        >

        <div class="grafico">
          <div class="grafico-frame"></div>
          <highcharts :options="graficoMes" ref="grafico1"></highcharts>
        </div>

        <v-row class="mt-2">
          <v-col cols="6" class="pb-0">
            <div class="legenda-oportunidades">
              <div class="comprometido"></div>
              Comprometido
            </div>
          </v-col>
          <v-col cols="6" class="pb-0">
            <div class="legenda-oportunidades">
              <div class="oportunidade"></div>
              Oportunidades
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      :md="layoutCompacto ? 6 : 12"
      :lg="layoutCompacto ? 6 : 12"
      :xl="layoutCompacto ? 6 : 12"
    >
      <v-card class="my-0 br-15 pa-5 pt-3" elevation="0">
        <v-icon
          style="position: absolute; top: 15px; left: 20px; z-index: 2"
          left
          @click="mudaLayout()"
          title="Layout compacto"
          color="#bbb"
          >{{
            layoutCompacto ? "mdi-arrow-expand" : "mdi-arrow-collapse"
          }}</v-icon
        >
        <div class="grafico">
          <div class="grafico-frame"></div>
          <highcharts :options="graficoAno" ref="grafico2"></highcharts>
        </div>
        <v-row class="mt-2">
          <v-col cols="6" class="pb-0">
            <div class="legenda-oportunidades">
              <div class="comprometido"></div>
              Comprometido
            </div>
          </v-col>
          <v-col cols="6" class="pb-0">
            <div class="legenda-oportunidades">
              <div class="oportunidade"></div>
              Oportunidades
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["totais"],
  data() {
    return {
      layoutCompacto: true,
      graficoMes: {
        chart: {
          height: 400,
          type: "column",
          marginTop: 60,
          marginBottom: 60,
          backgroundColor: "transparent",
          scrollablePlotArea: {
            scrollPositionX: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          text: "Oportunidades - Visualização Mensal",
        },

        yAxis: {
          gridLineWidth: 0,
          title: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
        },
        plotOptions: {
          series: {
            borderRadiusTopLeft: "8px",
            borderRadiusTopRight: "8px",
            groupPadding: 0.1,
            pointPadding: 0.25,
            maxPointWidth: 60,
            borderWidth: 0,

            states: {
              pointPadding: 0.2,
              inactive: {
                opacity: 1,
              },
            },
            dataLabels: {
              enabled: true,
              allowOverlap: false,
              useHTML: true,
              formatter: function () {
                console.log(this);
                return `<div class="stackLabels text-center my-0 py-0"><div style="color: ${this.color}">${this.y}</div>${this.point.percent}%</div>`;
              },
            },
          },
        },
        xAxis: {
          categories: [],
          lineColor: "transparent",
          labels: {
            autoRotation: 0,
            style: {
              whiteSpace: "nowrap",
            },
          },
        },
        exporting: {
          scale: 1,
          chartOptions: {
            chart: {
              backgroundColor: "#fff",
              marginBottom: 80,
            },
            legend: {
              enabled: true,
            },
          },
          xAxis: {
            lineColor: "#ccc",
          },
          yAxis: {
            stackLabels: {
              formatter: function () {
                return ""; //graficoMesGlobal[this.stack][this.x];
              },
            },
          },
        },

        legend: {
          enabled: false,
        },
        series: [
          {
            name: "Comprometido",
            stack: "comprometido",
            color: this.$vuetify.theme.themes.light.yellow,
            dataLabels: {
              style: {
                textOutline: this.$vuetify.theme.themes.light.yellow,
              },
            },
            data: [],
          },
          {
            name: "Oportunidade",
            stack: "oportunidade",
            color: this.$vuetify.theme.themes.light.primary.base,
            dataLabels: {
              style: {
                textOutline: this.$vuetify.theme.themes.light.primary.base,
              },
            },
            data: [],
          },
        ],
      },
      graficoAno: {
        chart: {
          height: 400,
          type: "column",
          marginTop: 60,
          marginBottom: 60,
          backgroundColor: "transparent",
          scrollablePlotArea: {
            scrollPositionX: 0,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        title: {
          text: "Oportunidades - Visualização Anual",
        },

        yAxis: {
          gridLineWidth: 0,
          title: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
        },
        plotOptions: {
          series: {
            borderRadiusTopLeft: "8px",
            borderRadiusTopRight: "8px",
            groupPadding: 0.1,
            pointPadding: 0.25,
            maxPointWidth: 60,
            borderWidth: 0,
            states: {
              pointPadding: 0.2,
              inactive: {
                opacity: 1,
              },
            },
            dataLabels: {
              enabled: true,
              allowOverlap: false,
              useHTML: true,
              formatter: function () {
                console.log(this);
                return `<div class="stackLabels text-center my-0 py-0"><div style="color: ${this.color}">${this.y}</div>${this.point.percent}%</div>`;
              },
            },
          },
        },
        xAxis: {
          categories: [],
          lineColor: "transparent",
          labels: {
            autoRotation: 0,
            style: {
              whiteSpace: "nowrap",
            },
          },
        },
        exporting: {
          scale: 1,
          chartOptions: {
            chart: {
              backgroundColor: "#fff",
              marginBottom: 80,
            },
            legend: {
              enabled: true,
            },
          },
          yAxis: {
            stackLabels: {
              formatter: function () {
                return graficoAnoGlobal[this.stack][this.x];
              },
            },
          },
        },

        legend: {
          enabled: false,
        },
        series: [
          {
            name: "Comprometido",
            stack: "comprometido",
            color: this.$vuetify.theme.themes.light.yellow,
            dataLabels: {
              style: {
                textOutline: this.$vuetify.theme.themes.light.yellow,
              },
            },
            data: [],
          },
          {
            name: "Oportunidade",
            stack: "oportunidade",
            color: this.$vuetify.theme.themes.light.primary.base,
            dataLabels: {
              style: {
                textOutline: this.$vuetify.theme.themes.light.primary.base,
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    totais: {
      handler: function (val) {
        this.limpaGraficos();
        if (
          this.totais?.graficoMensal &&
          this.totais.graficoMensal.length > 0
        ) {
          this.totais?.graficoMensal.forEach((item) => {
            this.graficoMes.xAxis.categories.push(
              this.$moment(item.mes).format("MMM  YYYY")
            );
            item.total = item.comprometido + item.oportunidade;
            const comprometidoPorcentagem =
              (item.comprometido * 100) / item.total;
            const oportunidadePorcentagem =
              (item.oportunidade * 100) / item.total;
            this.graficoMes.series[0].data.push({
              y: item.comprometido,
              percent: +comprometidoPorcentagem.toFixed(2),
              color: this.$vuetify.theme.themes.light.yellow,
            });
            this.graficoMes.series[1].data.push({
              y: item.oportunidade,
              percent: +oportunidadePorcentagem.toFixed(2),
              color: this.$vuetify.theme.themes.light.primary.base,
            });
          });
        }

        ///Monta grafico anual
        if (this.totais?.totalAnual && this.totais.totalAnual.length > 0) {
          this.totais.totalAnual.forEach((total) => {
            this.graficoAno.xAxis.categories.push(total.ano);
            total.total = total.comprometido + total.oportunidade;
            const comprometidoPorcentagem =
              (total.comprometido * 100) / total.total;
            const oportunidadePorcentagem =
              (total.oportunidade * 100) / total.total;
            this.graficoAno.series[0].data.push({
              y: total.comprometido,
              percent: +comprometidoPorcentagem.toFixed(2),
              color: this.$vuetify.theme.themes.light.yellow,
            });
            this.graficoAno.series[1].data.push({
              y: total.oportunidade,
              percent: +oportunidadePorcentagem.toFixed(2),
              color: this.$vuetify.theme.themes.light.primary.base,
            });
          });
        }

        ///Monta grafico anual
        setTimeout(() => {
          this.$refs.grafico1.chart.reflow();
          this.$refs.grafico2.chart.reflow();
        }, 100);
      },
      deep: true,
    },
  },

  methods: {
    limpaGraficos() {
      this.graficoMes.xAxis.categories = [];
      this.graficoMes.series[0].data = [];
      this.graficoMes.series[1].data = [];
      this.graficoAno.xAxis.categories = [];
      this.graficoAno.series[0].data = [];
      this.graficoAno.series[1].data = [];
    },
    mudaLayout() {
      this.layoutCompacto = !this.layoutCompacto;

      setTimeout(() => {
        this.$refs.grafico1.chart.reflow();
        this.$refs.grafico2.chart.reflow();
      }, 100);
    },
  },
};
</script>