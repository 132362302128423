<template>
    <div>
        <v-card class="br-15 pa-3 px-5 overflow-hidden" elevation="0">
            <InfoBox expands
                ><span class="titulo">Informações gerais: </span> Visualização
                das informações para realizar as simulações.</InfoBox
            >
            <h2 class="text-center mb-4" style="font-weight: normal">
                Informações gerais
            </h2>

            <div style="height: 300px">
                <div v-if="!empty" class="text-box">
                    <div class="text-box-content">
                        <div class="text-box-text">
                            <div v-html="beneficios"></div>
                        </div>
                    </div>
                </div>

                <Overlay :overlay="overlay" :error="error" :empty="empty" />
            </div>
        </v-card>
    </div>
</template>

<script>
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
    components: {
        Overlay,
        InfoBox,
    },
    props: {
        ano: Number,
        fornecedor: Object,
        params: Object,
    },
    data() {
        return {
            overlay: true,
            error: false,
            empty: false,
            index: 0,
            fullscreen: false,
            beneficios: "",
        };
    },
    methods: {
        fileMin(file) {
            const _ext = file.substr(-4);
            return file.replace(_ext, "_resize" + _ext);
        },
        async getDados() {
            this.overlay = true;
            this.error = false;
            this.empty = false;
            this.beneficios = "";

            const response = await this.$http().post(
                "/simulacao/resumo/informacoesGerais",
                {
                    data: {
                        id_fornecedor: this.fornecedor.value,
                        ano_atual: this.ano,
                    },
                }
            );

            if ((response || { status: 500 }).status != 200) {
                this.overlay = false;
                this.error = true;
                return;
            }

            if (response.data.length == 0) {
                this.empty = true;
                return (this.overlay = false);
            }

            this.beneficios = response.data[0].dados;
            if (this.beneficios == "") {
                this.empty = true;
            }
            this.overlay = false;
        },
    },
    created() {},
    watch: {
        $props: {
            handler() {
                if (this.ano != null) this.getDados();
            },
            deep: true,
            immediate: false,
        },
    },
};
</script>
<style scoped>
div[role="button"] {
    display: none !important;
}
.text-box {
    border: 2px solid #f2f2f2;
    border-radius: 10px 0 0 10px;
    padding: 1.2rem;
    overflow-y: scroll;
    height: 280px;
}
.text-box .text-box-title {
    font-size: 18px;
    color: var(--v-primary-base) !important;
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.text-box .text-box-text {
    color: #666;
}
</style>
