<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2"
					>PONTA TEMÁTICA - VINCULO</v-toolbar-title
				>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar
				</v-btn>
				<exportacoes-btn  @export-action="exportAction($event)"></exportacoes-btn>
				<v-btn
					class="ml-2 my-1"
					color="primary"
					:disabled="mes < mesProximo"
					@click="abrirImportar()"
				>
					<v-icon left>mdi-upload</v-icon> Importar
				</v-btn>
			</v-col>
		</v-row>
		<v-card class="mt-2 py-4 px-2" elevation="1">
			<v-row no-gutters class="mb-2">
				<v-col cols="12" sm="12" md="4" lg="4" xl="4">
					<v-text-field
						v-model="busca"
						append-icon="mdi-magnify"
						label="Pesquisar"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3"> </v-col>
				<v-col cols="12" sm="12" md="3" lg="3" xl="3">
					<v-form ref="formFiltro">
						<v-text-field
							label="Mês"
							v-model="mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-form>
				</v-col>
				<v-col cols="12" sm="12" md="2" lg="2" xl="2" align="end">
					<v-btn class="ml-2 my-1" color="primary" @click="filtrar()">
						<v-icon left>mdi-filter</v-icon> Filtrar
					</v-btn>
				</v-col>
			</v-row>
			<v-data-table
				:headers="headers"
				:items="dados"
				item-key="id_ponta_tematica"
				:server-items-length="total"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				multi-sort
				class="elevation-0"
			>
				<template v-slot:[`item.botao`]="{ item }">
					<v-icon
						v-if="item.mes >= mesProximo"
						small
						class="mr-2"
						color="primary"
						@click="abrirEditar(item)"
						title="Editar"
					>
						mdi-pencil
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog v-model="dialogo" width="600">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Editar Ponta Temática
				</v-card-title>
				<v-card-text class="mt-4">
					<v-form v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-select
									label="Bandeira"
									:items="bandeiras"
									item-text="bandeira"
									item-value="bandeira"
									v-model="pontatematica.bandeira"
									clearable
									outlined
									dense
								>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="6"
								lg="6"
								xl="6"
								class="px-2"
							>
								<v-text-field
									label="Mês"
									v-model="pontatematica.mes"
									:rules="regra.mes"
									:min="mesInicial"
									required
									outlined
									dense
									type="month"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-select
									label="Cluster"
									:items="clusters"
									item-text="nome"
									item-value="id_cluster"
									v-model="pontatematica.id_cluster"
									clearable
									outlined
									dense
								>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-select
									label="Ponta Temática"
									:items="pontasTematicas"
									item-text="nome"
									item-value="id_espaco"
									v-model="pontatematica.id_espaco_tematico"
									clearable
									outlined
									dense
								>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-select
									label="Ponta de Gondola"
									:items="pontasGondolas"
									item-text="nome"
									item-value="id_espaco"
									v-model="pontatematica.id_espaco"
									clearable
									outlined
									dense
								>
								</v-select>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								lg="12"
								xl="12"
								class="px-2"
							>
								<v-text-field
									outlined
									dense
									label="Tema"
									v-model="pontatematica.tema"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogo = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
					<v-btn
						color="primary"
						class="pr-4"
						:disabled="!validaForm"
						@click="editar()"
					>
						<v-icon left>mdi-content-save</v-icon> Salvar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV para
					{{ $moment(mes).format("MMMM [de] YYYY") }}
				</v-card-title>
				<v-card-text>
					<v-alert
						class="mt-4"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								A base utilizada para importação deve ser
								parecida com base exportada. As colunas
								<strong>bandeira, id_cluster, id_espaco_tematico, id_espaco, tema</strong> são
								obrigatórios (<strong
									>e os nomes devem ser identicos aos da base
									exportada</strong
								>), as demais colunas podem ser excluidas.
							</li>
							<li>
								O <strong>mês</strong> considerado na importação
								da <strong>ponta temática</strong> é o mês selecionado
								no
								<strong>filtro da lista do formulário</strong
								>.
							</li>
						</ul>
					</v-alert>
					<v-row no-gutters class="mt-4">
						<v-col cols="3" sm="4" md="3" lg="3" xl="3">
							<v-text-field
								label="Início Mês"
								v-model="mesImportacaoInicial"
								:rules="regra.mesImportacao"
								:min="mesProximo"
								required
								outlined
								dense
								type="month"
							></v-text-field>
						</v-col>
						<v-col cols="3" sm="4" md="3" lg="3" xl="3" class="px-2">
							<v-text-field
								label="Final Mês"
								v-model="mesImportacaoFinal"
								:rules="regra.mesImportacao"
								:min="mesImportacaoInicial"
								required
								outlined
								dense
								type="month"
							></v-text-field>
						</v-col>
					
						<v-col cols="6" class="px-2">
							<v-form ref="form" v-model="isCsv">
								<v-file-input
									label="Arquivo CSV"
									outlined
									dense
									v-model="arquivoCSV"
									:rules="regra.csv"
									accept=".csv"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-btn
								color="primary"
								:disabled="!isCsv"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv
										:data="erros"
										:labels="errosLabels"
										name="erros.csv"
										delimiter=";"
									>
										<v-btn color="primary">
											<v-icon left
												>mdi-microsoft-excel</v-icon
											>
											Exportar Erros
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<v-data-table
								:headers="headerErros"
								:items="erros"
								:items-per-page="5"
								:options.sync="paginationErros"
								class="elevation-0 data-table-2"
							></v-data-table>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</v-container>
</template>

<script>
import ExportacoesBtn from './components/ExportacoesBtn.vue';
import moment from 'moment'
export default {
	data() {
		return {
			headers: [
				{ text: "MÊS/ANO", value: "mes_nome", align: "center" },
				{
					text: "BANDEIRA",
					value: "bandeira",
					align: "center",
				},
				{ text: "CLUSTER", value: "cluster", align: "center" },
				{ text: "ID ESPAÇO PONTA TEMÁTICA", value: "id_espaco_tematico", align: "center" },
				{
					text: "NOME ESPAÇO PONTA TEMÁTICA",
					value: "espaco_tematico",
					align: "center",
				},
				{ text: "ID ESPAÇO PONTA DE GÔNDOLA", value: "id_espaco", align: "center" },
				{ text: "NOME ESPAÇO PONTA DE GÔNDOLA", value: "espaco", align: "center" },
				{
					text: "TEMA",
					value: "tema",
					align: "center",
				},
				{ text: "", value: "botao", align: "center", sortable: false },
			],
			dados: [],
			bandeiras: ['RAIA','DROGASIL'],
			clusters: [],
			pontasGondolas: [],
			pontasTematicas: [],
			mes: null,
			mesImportacaoInicial: null,
			mesImportacaoFinal: null,
			pontatematica: {
				id_espaco: null,
				id_espaco_tematico: null,
				bandeira: null,
			},
			clonePontatematica: {},
			pagination: {},
			paginationErros: {},
			total: null,
			carregando: false,
			busca: null,
			dialogo: false,
			dialogoImportar: false,
			validaForm: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			arquivoCSV: null,
			lista: {
				pontatematica: [],
				
			},
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				mes: [
					(v) => !!v || "O campo mês é obrigatório",
					(v) =>
						(v && v > this.mesInicial) ||
						"O período inicial deve posterior ao mês atual",
				],
				mesImportacao: [
					(v) => !!v || "O campo mês é obrigatório",
					(v) =>
						(v && v > this.mesInicial) ||
						"O período inicial deve posterior ao mês atual",
				],
			},
			isCsv: false,
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
		};
	},
	watch: {
		pagination: {
			handler() {
				this.filtrar();
			},
			deep: true,
		},
		busca(v) {
			setTimeout(() => {
				if (v == this.busca) {
					this.filtrar();
				}
			}, 1500);
		},
	},
	components: {
		ExportacoesBtn
	},
	async created() {
		this.mes = this.mesProximo;



		var responseCluster = await this.$http().post("/cluster/lista", {
			data: {},
		});

		this.clusters = responseCluster.data;

		var response = await this.$http().post("/pontatematica/listacompletacomanexos", {
			data: {},
		});

		this.lista.pontatematica = response.data.pontatematica;
		this.pontasGondolas = response.data.pontasGondolas;
		this.pontasTematicas = response.data.pontasTematicas;


	},
	computed: {
		mesInicial() {
			return this.$moment().format("YYYY-MM");
		},
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
	},
	methods: {
		async filtrar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			data.pagination = this.pagination;
			data.busca = this.busca;

			var response = await this.$http().post("/pontatematica/lista", {
				data: data,
			});

			this.dados = response.data;
			this.total = response.data.length ? response.data[0].total : 0;

			if (this.total == 0 && this.pagination.page > 1) {
				this.pagination.page = 1;
				this.filtrar();
			}

			this.carregando = false;
		},
		async exportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var data = { mes: this.mes + "-01" };

			var response = await this.$http().post("/pontatematica/exportar", {
				data: data,
			});

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		exportAction(obj) {
			switch (obj.fnc) {
				case "excluidas":
					this.excluidas(
						obj.params.tipo,
						obj.params.isTransfer,
						obj.params.isOrganico
					);
					break;
				default:
					this[obj.fnc]();
					break;
			}
		},
		async exportar_espacos() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var response = await this.$http().post("/pontatematica/exportarTematicosGondolas");

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async exportar_cluster() {
			if (!this.$refs.formFiltro.validate()) return;

			this.carregando = true;

			var response = await this.$http().post("/cluster/exportar");

			window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
		async abrirEditar(item) {

			var response = await this.$http().post("/pontatematica/possoalterar", {
				data: {            
					bandeira: item.bandeira,
					mes: item.mes + '-01',
					id_espaco: item.id_espaco,
					id_espaco_tematico: item.id_espaco_tematico,
					id_cluster: item.id_cluster
				}
			});

			if (response.data[0].count === "0") {
				this.clonePontatematica = this.$helpers.cloneObj(item)
				this.pontatematica = { ...item, mes: item.mes.substring(0,7) };
				this.dialogo = true;
			} else {
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = 'Atenção a acordos feitos que impedem essa alteração.';
			}

		},
		async editar() {
			this.carregando = true;

			var response = await this.$http().post("/pontatematica/possoalterar", {
				data: {            
					bandeira: this.pontatematica.bandeira,
					mes: moment(this.pontatematica.mes).format('YYYY-MM-DD'),
					id_espaco: this.pontatematica.id_espaco,
					id_espaco_tematico: this.pontatematica.id_espaco_tematico,
					id_cluster: this.pontatematica.id_cluster
				}
			});

			if (response.data[0].count === "0") {

				var data = this.pontatematica
				data.mes = moment(this.pontatematica.mes).format('YYYY-MM-DD')

				var responseEdicao = await this.$http().post("/pontatematica/editar", {
					data: data,
				});

				this.carregando = false;

				if (responseEdicao.data.sucesso) {
					this.filtrar();

					this.dialogo = false;
				} else {
					this.pontatematica = this.clonePontatematica
					this.alerta = true;
					this.alertaColor = "error";
					this.alertaTimeout = -1;
					this.alertaTexto = responseEdicao.data.msg;
				}

			} else {
				this.pontatematica = this.clonePontatematica
				this.alerta = true;
				this.alertaColor = "error";
				this.alertaTimeout = -1;
				this.alertaTexto = 'Atenção a acordos feitos que impedem essa alteração.';
			}	

		},
		abrirImportar() {
			if (!this.$refs.formFiltro.validate()) return;

			this.arquivoCSV = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;

			var data = { mesInicial: this.mesImportacaoInicial + "-01", mesFinal: this.mesImportacaoFinal + "-01" };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/pontatematica/importar",
				formData
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.filtrar();
				this.dialogoImportar = false;
			} else {
				this.filtrar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},
	},
};
</script>
