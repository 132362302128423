<template>
  <v-container fluid>
    <v-card class="px-5 py-3 br-15 w-100" elevation="0">
      <v-row class="align-center">
        <v-col class="flex-grow-1">
          <v-toolbar-title class="page-title"
            >Material Campanhas</v-toolbar-title
          >
        </v-col>
        <v-btn class="new-button mr-3" color="primary" @click="novo()">
          <v-icon left>mdi-plus-thick</v-icon> Novo material
        </v-btn>
      </v-row>
    </v-card>
    <v-card class="px-5 py-3 mt-5 br-15 w-100" elevation="0">
      <v-row>
        <v-col class="flex-grow-1">
          <v-data-table
            class="elevation-0 tabela"
            :headers="headers"
            :items="materiais"
            :options.sync="pagination"
            :items-per-page="20"
            :footer-props="tableConfig"
            :header-props="{ sortIcon: 'mdi-chevron-down' }"
            multi-sort
          >
            <template v-slot:[`item.data_criacao`]="{ item }">
              {{ $moment(item.data_criacao).format("DD/MM/YYYY") }}
            </template>
            <template v-slot:[`item.data_inicial`]="{ item }">
              {{ $moment(item.data_inicial).format("DD/MM/YYYY") }}
            </template>
            <template v-slot:[`item.data_final`]="{ item }">
              {{ $moment(item.data_final).format("DD/MM/YYYY") }}
            </template>
            <template v-slot:[`item.arquivo`]="{ item }">
              <a
                v-if="item.arquivo"
                :href="$assinarUrl(item.arquivo)"
                target="_blank"
                class="mx-2 "
              >
                <v-icon>mdi-file-document-outline</v-icon>
              </a>
              <v-btn
                fab
                dark
                @click="editar(item)"
                class="mx-2 elevation-4"
                x-small
                color="light-blue "
              >
                <v-icon dark dense>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                fab
                dark
                @click="excluir(item)"
                class="mx-2 elevation-4"
                x-small
                color="error"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template></v-data-table
          >
        </v-col>
      </v-row>
    </v-card>
    <v-dialog content-class="br-15" v-model="dialog" width="600">
      <v-card>
        <v-toolbar
          elevation="0"
          dark
          class="primary-degrade white--text fix-flex"
        >
          <v-toolbar-title>
            {{ edicao ? "Editar " : "Adicionar novo " }} material
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialog = false"
          >
            <v-icon size="18px" color="primary">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-form ref="form">
            <v-row>
              <v-col cols="6" class="px-2 pb-0">
                <DataInput
                  v-model="novoMaterial.dataInicial"
                  type="date"
                  label="Data de Início"
                  :min="new Date().toISOString().substring(0, 10)"
                  :rules="regra.dataInicial"
                  outlined
                  dense
                ></DataInput>
              </v-col>
              <v-col cols="6" class="px-2 pb-0">
                <DataInput
                  v-model="novoMaterial.dataFinal"
                  type="date"
                  label="Data de final"
                  outlined
                  :min="novoMaterial.dataInicial"
                  :rules="regra.dataFinal"
                  dense
                ></DataInput>
              </v-col>
              <v-col v-if="!edicao" cols="12" class="pt-0">
                <ArquivoInput
                  label="Arraste seu arquivo PDF"
                  v-model="novoMaterial.arquivo"
                  :rules="regra.pdf"
                  accept=".pdf"
                >
                </ArquivoInput>
                <!-- <v-file-input
                label="Arquivo PDF"
                outlined
                class="input-cinza"
                dense
                v-model="novoMaterial"
                :rules="regra.pdf"
                accept=".pdf"
              ></v-file-input> -->
              </v-col>
              <v-col class="d-flex">
                <v-btn
                  v-if="!edicao"
                  @click="salvar"
                  color="primary"
                  class="new-button px-6 ml-auto"
                >
                  <v-icon class="mr-2 ml-auto">mdi-check-circle</v-icon>
                  Salvar material
                </v-btn>
                <v-btn
                  v-if="edicao"
                  @click="salvarEdicao"
                  color="primary"
                  class="new-button px-6 ml-auto"
                >
                  <v-icon class="mr-2 ml-auto">mdi-check-circle</v-icon>
                  Editar material
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="br-15" v-model="dialogoExclusao" width="600">
      <v-card>
        <v-toolbar elevation="0" dark class="error white--text fix-flex">
          <v-toolbar-title>
            Excluir material
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            width="24px"
            height="24px"
            elevation="0"
            light
            @click="dialogoExclusao = false"
          >
            <v-icon size="18px" color="error">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          Você deseja realmente excluir o material?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            class="new-button ml-auto mb-2 mr-2 px-3"
            @click="confirmarExclusao()"
          >
            Excluir material
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="carregando" :z-index="300">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      materiais: [],
      carregando: false,
      edicao: false,
      idExclusao: null,
      dialogoExclusao: false,
      regra: {
        dataInicial: [(v) => !!v || "O campo período inicial é obrigatório"],
        dataFinal: [(v) => !!v || "O campo período final é obrigatório"],
        pdf: [
          (v) => (v) =>
            !v ||
            [".pdf", ".PDF"].includes(v.name.substr(-4)) ||
            "O arquivo deve ser do tipo PDF.",
        ],
      },
      novoMaterial: {
        dataInicial: this.$moment().format("YYYY-MM-DD"),
        dataFinal: this.$moment().format("YYYY-MM-DD"),
      },
      tableConfig: {
        itemsPerPageOptions: [10, 15, 25, 50, 100],
        itemsPerPageText: "Linhas por páginas",
        ofText: "de",
        pageText: "{0}-{1} de {2}",
      },
      headers: [
        {
          text: "Criado em:",
          value: "data_criacao",
          align: "center",
          class: "th-separador",
        },
        {
          text: "Data inicial",
          value: "data_inicial",
          class: "th-separador",
          align: "center",
        },
        {
          text: "Data final",
          value: "data_final",
          class: "th-separador",
          align: "center",
        },
        {
          text: "Ações",
          value: "arquivo",
          align: "center",
          class: "th-separador",
        },
      ],
    };
  },
  created() {
    this.filtrar();
  },
  methods: {
    novo() {
      this.dialog = true;
      this.edicao = false;
      this.novoMaterial = {
        dataInicial: this.$moment().format("YYYY-MM-DD"),
        dataFinal: this.$moment().format("YYYY-MM-DD"),
      };
    },
    editar(item) {
      this.dialog = true;
      this.edicao = true;
      this.novoMaterial = {
        dataInicial: this.$moment(item.data_inicial).format("YYYY-MM-DD"),
        dataFinal: this.$moment(item.data_final).format("YYYY-MM-DD"),
        arquivo: item.arquivo,
        id_material: item.id_material,
      };
    },
    async filtrar() {
      const response = await this.$http().post(
        "/app/campanhas/listarMateriais"
      );
      this.materiais = response.data;
    },

    excluir(material) {
      this.dialogoExclusao = true;
      this.idExclusao = material.id_material;
    },

    async confirmarExclusao() {
      this.carregando = true;

      const response = await this.$http().post(
        `/app/campanhas/excluirMaterial/${this.idExclusao}`
      );
      if (response.data.success === true) {
        this.$dialog.notify.success("Material excluído com sucesso", {
          position: "top-right",
          timeout: 5000,
        });
        this.dialogoExclusao = false;
        this.carregando = false;
        this.idExclusao = null;
        this.filtrar();
      } else {
        this.$dialog.notify.error("Erro ao excluir material", {
          position: "top-right",
          timeout: 5000,
        });
        this.carregando = false;
      }
    },

    async salvarEdicao() {
      if (!this.$refs.form.validate()) return;
      const data = {
        dataInicial: this.novoMaterial.dataInicial,
        dataFinal: this.novoMaterial.dataFinal,
      };

      const response = await this.$http().post(
        `/app/campanhas/editarMaterial/${this.novoMaterial.id_material}`,
        data
      );

      if (response.data.success === true) {
        this.$dialog.notify.success("Material editado com sucesso", {
          position: "top-right",
          timeout: 5000,
        });
        this.dialog = false;
        this.carregando = false;
        this.filtrar();
        this.novoMaterial.arquivo = null;
      } else {
        this.$dialog.notify.error("Erro ao editar material", {
          position: "top-right",
          timeout: 5000,
        });
        this.carregando = false;
      }
    },

    async salvar() {
      if (!this.$refs.form.validate()) return;
      if (!this.novoMaterial.arquivo)
        return this.$dialog.notify.error("Selecione um arquivo", {
          position: "top-right",
          timeout: 5000,
        });
      this.carregando = true;
      const formData = new FormData();
      const data = {
        dataInicial: this.novoMaterial.dataInicial,
        dataFinal: this.novoMaterial.dataFinal,
      };
      formData.append("data", JSON.stringify(data));
      formData.append("files", this.novoMaterial.arquivo);

      console.log(this.novoMaterial);
      const response = await this.$http().post(
        "/app/campanhas/salvarMaterial",
        formData
      );

      if (response.data.success === true) {
        this.$dialog.notify.success("Material adicionado com sucesso", {
          position: "top-right",
          timeout: 5000,
        });
        this.dialog = false;
        this.carregando = false;
        // this.iniciaFiltro();
        this.filtrar();
        this.novoMaterial.arquivo = null;
      } else {
        this.$dialog.notify.error("Erro ao salvar material", {
          position: "top-right",
          timeout: 5000,
        });
        this.carregando = false;
      }
    },
  },
};
</script>
