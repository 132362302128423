<template>
    <v-container fluid>
        <filtro
            ref="filtro"
            @filtrar="filtrar"
            @limpar="limparFiltros"
            v-model="filtro"
        ></filtro>
        <v-row>
            <v-col
                v-for="auditoria in dados"
                :key="`${auditoria.nome}-${auditoria.id_acordo}`"
                class="d-flex child-flex"
                :cols="colunasValue"
            >
                <FotoAuditoria :auditoria="auditoria"></FotoAuditoria>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="8">
                <v-container class="max-width">
                    <v-pagination
                        v-model="filtro.pagination.page"
                        :length="total"
                        :total-visible="10"
                    ></v-pagination>
                </v-container>
            </v-col>
        </v-row>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import Filtro from "./Filtro.vue";
import FotoAuditoria from "./_components/foto.vue";

export default {
    components: {
        Filtro,
        FotoAuditoria,
    },
    data() {
        return {
            total: 0,
            carregando: false,
            filtro: {
                mesInicial: this.$moment().format("YYYY-MM"),
                mesFinal: this.$moment().format("YYYY-MM"),
                pagination: {
                    page: 1,
                    itemsPerPage: 12,
                    sortBy: [],
                },
            },

            dados: [],
        };
    },
    watch: {
        "filtro.colunas": function () {
            localStorage.setItem("auditoria-colunas", this.filtro.colunas);
        },
        "filtro.pagination": {
            handler() {
                this.$vuetify.goTo(0);
                this.filtrar();
            },
            deep: true,
        },
    },
    computed: {
        colunasValue: function () {
            return this.filtro.colunas;
        },
    },
    mounted() {
        this.iniciaFiltro();
        this.filtrar();
    },
    methods: {
        async filtrar() {
            // this.$refs.filtro.filtrar();
            this.carregando = true;
            const filtro = JSON.parse(JSON.stringify(this.filtro));

            const data = {
                pagination: filtro.pagination,
                mesInicial: filtro.mesInicial,
                mesFinal: filtro.mesFinal,
                id_filial: filtro.id_filial,
                bandeira: filtro.bandeira,
                id_fornecedor: filtro.id_fornecedor,
                id_espaco: filtro.id_espaco,
                espaco_macro: filtro.espaco_macro,
                id_cluster: filtro.id_cluster,
                regiao: filtro.regiao,
                perfil: filtro.perfil,
                tamanho: filtro.tamanho,
                id_acordo: filtro.id_acordo ? [filtro.id_acordo] : [],
                acordo_rd: filtro.acordo_rd ? [filtro.acordo_rd] : [],
                id_contrato: filtro.id_contrato ? [filtro.id_contrato] : [],
                contrato: filtro.contrato ? [filtro.contrato] : [],
            };

            const response = await this.$http().post(
                "/gerencial/auditoriaFotos",
                {
                    data: data,
                }
            );
            this.dados = response.data.lista;
            if (this.dados.length > 0) {
                this.total = Math.ceil(
                    (this.dados[0].total || 0) / filtro.pagination.itemsPerPage
                );
            } else {
                this.total = 1;
            }

            this.carregando = false;
        },
        limparFiltros() {
            this.iniciaFiltro();
            this.filtrar();
        },
        iniciaFiltro() {
            this.filtro = {
                colunas: localStorage.getItem("auditoria-colunas") || 3,
                bandeira: [],
                mesInicial: this.$moment().format("YYYY-MM"),
                mesFinal: this.$moment().format("YYYY-MM"),
                id_espaco: [],
                espaco_macro: [],
                id_fornecedor: [],
                id_cluster: [],
                id_acordo: null,
                id_contrato: null,
                acordo_rd: null,
                contrato: null,
                id_filial: [],
                regiao: [],
                perfil: [],
                pagination: {
                    page: 1,
                    itemsPerPage: 12,
                    sortBy: 'Recentes',
                },
            };
        },
    },
};
</script>
<style >
.col-4 {
    transition: all 0.5s ease-in-out;
}

.col-3 {
    transition: all 0.5s ease-in-out;
}
.expand-buttons .v-input--radio-group__input {
    font-size: 0.8rem;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
.expand-buttons .v-label {
    font-size: 0.8rem;
    color: #fff;
}
.expand-buttons .v-input--selection-controls__input {
    transform: scale(70%);
    margin: 0;
}
.expand-buttons .v-radio {
    margin-right: 5px !important;
}
.v-expansion-panel-content__wrap {
    padding: 0;
}
</style>