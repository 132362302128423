var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formFiltro"},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('MesInput',{attrs:{"label":"Período Inicial","rules":_vm.regra.mesInicial,"required":"","outlined":"","dense":"","type":"month"},model:{value:(_vm.filtro.mesInicial),callback:function ($$v) {_vm.$set(_vm.filtro, "mesInicial", $$v)},expression:"filtro.mesInicial"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('MesInput',{attrs:{"label":"Período Final","rules":_vm.regra.mesInicial,"required":"","outlined":"","dense":"","type":"month"},model:{value:(_vm.filtro.mesFinal),callback:function ($$v) {_vm.$set(_vm.filtro, "mesFinal", $$v)},expression:"filtro.mesFinal"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{staticClass:"input-cinza select",attrs:{"label":"Usuário","items":_vm.lista.usuario,"menu-props":_vm.menuProps,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(
                                _vm.filtro.id_usuario.length === 1 &&
                                index === 0
                            )?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
                                _vm.filtro.id_usuario.length > 1 && index === 0
                            )?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.filtro.id_usuario.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.filtro.id_usuario),callback:function ($$v) {_vm.$set(_vm.filtro, "id_usuario", $$v)},expression:"filtro.id_usuario"}})],1),_c('v-col',{staticClass:"px-2 ml-auto pb-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","align":"end"}},[_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":300}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }