<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2"
                    >Acessos e Logs</v-toolbar-title
                >
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
                <!-- <v-btn class="ml-2 my-1" color="primary" @click="exportar()">
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    Consolidado por Mês
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportar(true)"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    Consolidado por Dia
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="exportarDetalhado()"
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar Detalhado
                </v-btn> -->
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on">
                            <v-icon left>mdi-microsoft-excel</v-icon>
                            Exportar
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="exportar()">
                            <v-list-item-title>
                                Exportar Consolidado por Mês
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportar(true)">
                            <v-list-item-title>
                                Exportar Consolidado por Dia
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportarDetalhado()">
                            <v-list-item-title>
                                Exportar Detalhado
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportarConsolidadoFornecedor()">
                            <v-list-item-title>
                                Exportar Consolidado Fornecedor
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="exportarDetalhadoFornecedor()">
                            <v-list-item-title>
                                Exportar Detalhado Fornecedor
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-card class="mt-2 pt-4 px-2" elevation="1">
            <filtro ref="filtro" @filtrar="filtrar"></filtro>
        </v-card>
        <v-card class="my-4 pt-2 px-2" elevation="1">
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
                @click:row="detalhado"
            >
            </v-data-table>
        </v-card>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-dialog v-model="dialogDetalhado" width="95%">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Detalhado
                </v-card-title>
                <v-data-table
                    :headers="headersDetalhado"
                    :items="dadosDetalhado"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100],
                    }"
                    :options.sync="paginationDetalhado"
                    multi-sort
                    class="elevation-0 my-4"
                >
                </v-data-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialogDetalhado = false">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import Filtro from "./Filtro.vue";

export default {
    components: {
        Filtro,
    },
    data() {
        return {
            dados: [],
            headers: [
                { text: "Cargo", value: "cargo", align: "center" },
                { text: "Usuário", value: "usuario", align: "center" },
                { text: "Mês", value: "mes", align: "center" },
                { text: "Acessos", value: "acessos", align: "center" },
                { text: "Criações", value: "criacao", align: "center" },
                { text: "Edições", value: "edicao", align: "center" },
                { text: "Aprovações", value: "aprovado", align: "center" },
                { text: "Reprovações", value: "reprovado", align: "center" },
                { text: "Liberações", value: "liberado", align: "center" },
                { text: "Renovações", value: "renovado", align: "center" },
                { text: "Cancelamentos", value: "cancelado", align: "center" },
                {
                    text: "Edições de Planograma",
                    value: "planograma",
                    align: "center",
                },
            ],
            headersDetalhado: [
                { text: "Cargo", value: "cargo", align: "center" },
                { text: "Usuário", value: "usuario", align: "center" },
                { text: "Dia", value: "mes", align: "center" },
                { text: "Acessos", value: "acessos", align: "center" },
                { text: "Criações", value: "criacao", align: "center" },
                { text: "Edições", value: "edicao", align: "center" },
                { text: "Aprovações", value: "aprovado", align: "center" },
                { text: "Reprovações", value: "reprovado", align: "center" },
                { text: "Liberações", value: "liberado", align: "center" },
                { text: "Renovações", value: "renovado", align: "center" },
                { text: "Cancelamentos", value: "cancelado", align: "center" },
                {
                    text: "Edições de Planograma",
                    value: "planograma",
                    align: "center",
                },
            ],
            pagination: {},
            dadosDetalhado: [],
            paginationDetalhado: {},
            carregando: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            dialogDetalhado: false,
        };
    },
    computed: {},
    methods: {
        async filtrar() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = { ...this.$refs.filtro.filtro };

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_usuario = filtro.id_usuario;

            this.pagination.page = 1;

            var response = await this.$http().post("/gerencial/acessos", {
                data: data,
            });

            this.dados = response.data;

            this.carregando = false;
        },
        async exportar(fnDetalhado = false) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_usuario = filtro.id_usuario;
            data.fnDetalhado = fnDetalhado;

            var response = await this.$http().post(
                "/gerencial/exportarAcessos",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async exportarConsolidadoFornecedor(fnDetalhado = false) {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_usuario = filtro.id_usuario;
            data.fnDetalhado = fnDetalhado;

            var response = await this.$http().post(
                "/gerencial/exportarAcessosFornecedor",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async exportarDetalhado() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_usuario = filtro.id_usuario;

            var response = await this.$http().post(
                "/gerencial/exportarAcessosDetalhado",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async exportarDetalhadoFornecedor() {
            if (!this.$refs.filtro.validate()) return;

            this.carregando = true;

            var filtro = this.$refs.filtro.filtro;

            var data = {};

            data.mesInicial = filtro.mesInicial;
            data.mesFinal = filtro.mesFinal;
            data.id_usuario = filtro.id_usuario;

            var response = await this.$http().post(
                "/gerencial/exportarAcessosDetalhadoFornecedor",
                { data: data }
            );

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async detalhado(row) {
            this.carregando = true;

            var data = {};

            data.mesInicial =
                row.mes.split("/")[1] + "-" + row.mes.split("/")[0];
            data.mesFinal = row.mes.split("/")[1] + "-" + row.mes.split("/")[0];
            data.id_usuario = [row.id_usuario];
            data.fnDetalhado = true;

            this.paginationDetalhado.page = 1;

            var response = await this.$http().post("/gerencial/acessos", {
                data: data,
            });

            this.dadosDetalhado = response.data;

            this.dialogDetalhado = true;
            this.carregando = false;
        },
    },
};
</script>


<style>
</style>