<template>
	<div >
		<v-btn
			v-if="usuarioTipo == 'fornecedor'"
			:disabled="!id_fornecedor"
			class="new-button w-100"
			color="#6eca97"
			dark
			@click="CarregarDadosFiltro('aprovarGeral')"
		>
			<v-icon left>mdi-marker-check</v-icon> Aprovação
		</v-btn>

		<v-btn
			v-if="usuarioTipo == 'rd'"
			:disabled="!id_fornecedor"
			class="new-button w-100"
			color="#6eca97"
			dark
			@click="CarregarDadosFiltro('aprovarGeralRD')"
		>
			<v-icon left>mdi-marker-check</v-icon> Aprovar Simulação de Acordo (RD)
		</v-btn>

		<dialogo
			ref="dialogo"
			@aprovarSimulacaoGeral="aprovarSimulacaoGeral"
			@aprovarSimulacaoGeralRD="aprovarSimulacaoGeralRD"
		></dialogo>

		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>

		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			:top="top"
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>

		<v-snackbar
			v-model="alerta2"
			:color="alertaColor2"
			:timeout="alertaTimeout2"
			:top="top2"
			style="z-index: 400"
		>
			<v-alert :type="alertaColor2">
				{{ alertaTexto2 }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta2 = false">
				Fechar
			</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
import Dialogo from "./Dialogo.vue";

export default {
	props: ["id_fornecedor", "ano", "usuarioTipo"],
	components: {
		Dialogo,
	},
	data() {
		return {
			top: false,
			top2: false,
			dados: [],
			mes: null,
			carregando: false,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
			alerta2: false,
			alertaTexto2: "",
			alertaColor2: "error",
			alertaTimeout2: 3000,
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
			confirm: {
				dialogo: false,
				titulo: null,
				texto: null,
				funcao: null,
				color: null,
				botao: null,
			},
			obj: {},
			obs: {
				dialogo: false,
				valor: null,
			},
			editar: {
				dialogo: false,
				valor: null,
				titulo: null,
				tipo: "",
			},
			item: {},
			status: [],
			item_Reprovados_Aprovados: {},
			selected: [],
		};
	},
	methods: {
		async CarregarDadosFiltro(tipo_aprovacao) {
			this.item_Reprovados_Aprovados.id_fornecedor = this.id_fornecedor;
			this.item_Reprovados_Aprovados.ano = this.ano;

			this.carregando = true;

			var data = {
				id_fornecedor: this.id_fornecedor,
				ano: this.ano,
				usuarioTipo: this.usuarioTipo,
			};
			var lista = (
				await this.$http().post(
					"/simulacao/aprovacao/bloquearAcessos",
					{ data: data }
				)
			).data;

			if (!lista.validaAprovacao.success) {
				this.alerta = true;
				this.alertaColor = "warning";
				this.alertaTimeout = -1;
				this.alertaTexto = lista.validaAprovacao.msg;
			}
			 else if (
				lista.validaAprovacao.success  // &&
				// (lista.campanha.length > 0 ||
				// 	lista.encommerce.length > 0 ||
				// 	lista.pdv.length > 0 ||
				// 	lista.tabloide.length > 0)
			)
			//  {
			// 	this.alerta = true;
			// 	this.alertaColor = "error";
			// 	this.alertaTimeout = -1;
			// 	this.alertaTexto =
			// 		this.usuarioTipo == "rd"
			// 			? "Não foi possivel realizar a aprovação, pois ja existe simulações aprovadas pela RD para este fornecedor e ano"
			// 			: "Não foi possivel realizar a aprovação, pois ja existe simulações aprovadas.";
			// } 
			// else 
			{
				this.$refs.dialogo[`${tipo_aprovacao}`](
					this.item_Reprovados_Aprovados
				); // chamar o modal para seguir o fluxo de aprovação
			}
			this.carregando = false;
		},

		async aprovarSimulacaoGeral(data) {
			this.carregando = true;

			var response = await this.$http().post(
				"/simulacao/aprovacao/aprovar",
				{ data: data }
			);

			if (response.data.sucesso) {
				this.alerta = true;
				this.top = true;
				this.alertaColor = "success";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			} else {
				this.top2 = true;
				this.top = false;

				if (response.data.msg2) {
					this.alerta2 = true;
					this.alertaColor2 = "success";
					this.alertaTimeout2 = -1;
					this.alertaTexto2 = response.data.msg2;
				}

				this.alerta = true;
				this.alertaColor = "orange";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}

			this.carregando = false;
		},

		async aprovarSimulacaoGeralRD(data) {
			this.carregando = true;

			var response = await this.$http().post(
				"/simulacao/aprovacao/aprovar",
				{ data: data }
			);
			if (response.data.sucesso) {
				this.alerta = true;
				this.top = true;
				this.alertaColor = "success";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			} else {
				this.top2 = true;
				this.top = false;

				if (response.data.msg2) {
					this.alerta2 = true;
					this.alertaColor2 = "success";
					this.alertaTimeout2 = -1;
					this.alertaTexto2 = response.data.msg2;
				}

				this.alerta = true;
				this.alertaColor = "orange";
				this.alertaTimeout = -1;
				this.alertaTexto = response.data.msg;
			}

			this.carregando = false;
		},
	},
	mounted() {},
};
</script>
