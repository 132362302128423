<template>
	<v-menu offset-y close-on-click max-height="320">
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" dark v-bind="attrs" v-on="on">
				<v-icon left>mdi-microsoft-excel</v-icon>
				Exportações
				<v-icon right>mdi-menu-down</v-icon>
			</v-btn>
		</template>

		<v-list>
			<v-list-item
				v-for="(item, index) in items"
				:key="index"
				link
				@click="action(item)"
			>
				<v-list-item-title>{{ item.title }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			dialogoExcluidas: false,
			items: [
				{ title: "Histórico", fnc: "historico" },
				{ title: "Exportar inventário", fnc: "exportar" },
				{ title: "Exportar inventário detalhado", fnc: "exportarDetalhado" },
				{ title: "Exportar importações", fnc: "exportarImportacoes" },
				{
					title: "Filiais excluidas Pendentes de Aprovação",
					fnc: "excluidas",
					params: {
						tipo: "pendente",
						isTransfer: false,
					},
				},
				{
					title: "Filiais transferidas Pendentes de Aprovação",
					fnc: "excluidas",
					params: {
						tipo: "pendente",
						isTransfer: true,
					},
				},
				{
					title: "Filiais excluídas por mês selecionado",
					fnc: "excluidas",
					params: {
						tipo: "mes",
						isTransfer: false,
					},
				},
				{
					title: "Filiais transferidas por mês selecionado",
					fnc: "excluidas",
					params: {
						tipo: "mes",
						isTransfer: true,
					},
				},
			],
		};
	},
	computed: {},
	methods: {
		action(obj) {
			this.$emit("export-action", obj);
		},
	},
};
</script>

<style></style>
