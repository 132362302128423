<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-toolbar-title class="ml-2 mt-2">Macro Fornecedores</v-toolbar-title>
            </v-col>   
            <v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">   
                <v-btn
                    class="ml-2 my-1"
                    color="primary"
                    @click="novo()"
                >
                    <v-icon left>mdi-plus-thick</v-icon> Criar
                </v-btn>
                <v-btn
                    class="ml-2 my-1"
                    color="primary"                     
                    @click="exportar()"   
                >
                    <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
                </v-btn>           
            </v-col>               
        </v-row>
        <v-card class="mt-2 py-4 px-2" elevation="1">  
            <v-row no-gutters class="mb-2">
                <v-col cols="12" sm="12" md="6" lg="8" xl="8">
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" align="end">                     
                    <v-text-field
                        v-model="busca"
                        append-icon="mdi-magnify"
                        label="Pesquisar"
                        dense 
                        outlined
                        hide-details
                    ></v-text-field>     
                </v-col>            
            </v-row>          
            <v-data-table
                :headers="headers"
                :items="dados"
                :items-per-page="5"
                :search="busca"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100]
                }"
                :options.sync="pagination"
                multi-sort
                class="elevation-0"
            >   
                <template v-slot:[`item.botao`]="{ item }"> 
                    <v-icon
                        small
                        class="mr-2"
                        color="primary"   
                        @click="editar(item)"
                        title="Editar"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="dialogo"
            width="600"            
        >  
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    Macro Fornecedor
                </v-card-title>
                <v-card-text class="mt-4"> 
                    <v-form v-model="validaForm"> 
                        <v-row no-gutters class="mt-4">                                
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    disabled
                                    label="Id"
                                    v-model="macrofornecedor.id_macrofornecedor"
                                ></v-text-field> 
                            </v-col>                                          
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
                                <v-text-field
                                    outlined
                                    dense 
                                    label="Nome"
                                    v-model="macrofornecedor.nome"
                                    :rules="regra.nome"
                                    required
                                ></v-text-field> 
                            </v-col>  
                        </v-row>  
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        :disabled="!validaForm"
                        @click="salvar()"
                    >
                        <v-icon left>mdi-content-save</v-icon> Salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>        
            <v-btn
                dark
                text
                class="float-right"
                @click="alerta=false"
            >
            Fechar
            </v-btn>
        </v-snackbar>  

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            headers: [
                { text: 'ID', value: 'id_macrofornecedor', align: 'center'},
                { text: 'Nome', value: 'nome', align: 'center'},
                { text: '', value: 'botao', align: 'center', sortable: false },
            ],
            dados: [], 
            macrofornecedor: {
                id_macrofornecedor: null,
                nome: null,
            },
            pagination: {}, 
            carregando: false, 
            busca: null, 
            dialogo: false,
            validaForm: false,
            alerta: false,
            alertaTexto: '',
            alertaColor: 'error',
            alertaTimeout: 3000,
            regra: { 
                nome: [v => !!v || 'O campo nome é obrigatório.'],
            },  
        }
    },
    watch: {
    },
    created() { 
        this.filtrar()      
    },
    methods: {
        async filtrar() {

            this.carregando = true

            this.pagination.page = 1

            var data = {}

            var response = await this.$http().post('/macrofornecedor/lista', {data: data})   
            
            this.dados = response.data 

            this.carregando = false
        },
        async exportar() {
           
            this.carregando = true
            
            var data = {}

            var response = await this.$http().post('/macrofornecedor/exportar', {data: data})   
          
            window.location = this.$http('baseURL')+response.data.url
            
            this.carregando = false
        }, 
        novo() {
           
            this.macrofornecedor = {
                id_macrofornecedor: null,
                nome: null,
            }
            
            this.dialogo = true          
        }, 
        editar(item) {
           
            this.macrofornecedor = {...item}
            
            this.dialogo = true          
        }, 
        async salvar() {
            
            this.carregando = true

            var data = this.macrofornecedor

            var response = await this.$http().post('/macrofornecedor/salvar', {data: data})   
            
            if(response.data.sucesso){

                this.filtrar()

                this.dialogo = false  
            }
            else{

                this.carregando = false
                
                this.alerta = true
                this.alertaColor = 'error'
                this.alertaTimeout = -1
                this.alertaTexto = response.data.msg
            }  
        }, 
    },
}
</script>