<template>
	<v-container fluid class="mt-0 pt-0 overflow-hidden">
		<v-card class="px-5 py-3 resumo-filtro" elevation="0">
			<Filtros
				@filtrar="filtrarResumo"
			/>
		</v-card>

        <v-row>
            <v-col lg="4" xl="3">
                <v-row>
                    <v-col cols="12">
                        <InformacoesGerais
                            :ano="ano"
                            :fornecedor="fornecedor"
                        />
                    </v-col>
                    <v-col>
                        <AprovacaoInformativo
                            :ano="ano"
                            :fornecedor="fornecedor"
                        />
                    </v-col>
                    <v-col cols="12">
                        <OrientacoesDeExecucao
                            :ano="ano"
                            :fornecedor="fornecedor"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" xl="9">
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <ResumoInvestimentos
                            :ano="ano"
                            :fornecedor="fornecedor"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <GraficoDistInvestimentos
                            :ano="ano"
                            :fornecedor="fornecedor"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <GraficoPerfilLoja
                            :ano="ano"
                            :fornecedor="fornecedor"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="mt-n3">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <GraficoEvolucaoMensal :ano="ano" :fornecedor="fornecedor" />
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <TabelaTrimestre :ano="ano" :fornecedor="fornecedor" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <TabelaValorAtivacaoMensal
                    :ano="ano"
                    :fornecedor="fornecedor"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ResumoInvestimentos from "./_components/ResumoInvestimentos.vue";
import GraficoDistInvestimentos from "./_components/GraficoDistInvestimentos.vue";
import GraficoPerfilLoja from "./_components/GraficoPerfilLoja.vue";
import GraficoEvolucaoMensal from "./_components/GraficoEvolucaoMensal.vue";
import TabelaTrimestre from "./_components/TabelaTrimestre.vue";
import TabelaValorAtivacaoMensal from "./_components/TabelaValorAtivacaoMensal.vue";
import InformacoesGerais from "./_components/InformacoesGerais.vue";
import OrientacoesDeExecucao from "./_components/OrientacoesDeExecucao.vue";
import Filtros from "./_components/Filtros.vue";
import AprovacaoInformativo from "./_components/AprovacaoInformativo.vue";
export default {
	components: {
		ResumoInvestimentos,
		GraficoDistInvestimentos,
		GraficoPerfilLoja,
		GraficoEvolucaoMensal,
		TabelaTrimestre,
		TabelaValorAtivacaoMensal,
		OrientacoesDeExecucao,
		InformacoesGerais,
		Filtros,
        AprovacaoInformativo
	},
	data() {
		return {
			ano: parseInt(this.$moment().format("YYYY")),
			fornecedor: {},
			dataUsuarioTipo: null,
		};
	},
	watch: {},
	async created() {
		this.$emit("teste");
	},
	computed: {},
	methods: {
		filtrarResumo(filtros) {
			this.ano = null;
			this.fornecedor = null;
			setTimeout(() => {
				this.ano = filtros.ano;
				this.fornecedor ={
					value:  filtros.id_fornecedor
				};
			}, 200);
		},
	},
};
</script>
<style>
.resumo-filtro {
    border-radius: 0 15px 15px 15px !important;
}
</style>
