<template>
    <div class="d-flex">
        <v-autocomplete
            v-bind:label="label"
            v-bind:items="items"
            v-bind:rules="rules"
            v-model="selecionados"
            chips
            multiple
            class="input-cinza"
            outlined
            dense
            @focus="filialFocus = true"
            @blur="filialFocus = false"
            :search-input.sync="search"
            v-bind:disable="disabled"
            clearable
        >
            <template v-slot:selection="{ item, index }">
                {{ item.id_java
                }}{{ selecionados.length - 1 == index ? '' : ',  '
                }}{{
                    selecionados.length - 1 == index && filialFocus
                        ? ',  '
                        : ' '
                }}
            </template>
        </v-autocomplete>
        <v-btn
            @click="abrirDialogo"
            color="primary"
            class="new-button px-6 ml-4"
            v-bind:disable="disabled"
        >
            <v-icon class="mr-2">mdi-plus</v-icon>
            Filiais
        </v-btn>
        <v-dialog
            content-class="br-15"
            scrollable
            v-model="dialogo"
            width="600"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>Importação de filiais </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogo = false"
                    >
                        <v-icon size="20px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">
                    Selecione o arquivo para importação. apenas a coluna
                    "id_java" será importada.
                    <v-file-input
                        accept=".csv"
                        label="Arquivo"
                        dense
                        v-model="arquivoImportado"
                        outlined
                        class="mt-5 input-cinza"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button mr-3 px-4 mb-4"
                        @click="processaCSV"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Realizar importação
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'idJavaInput',
    props: [
        'value',
        'rules',
        'placeholder',
        'label',
        'required',
        'disabled',
        'change',
        'prepend-icon',
        'items',
        'importacao',
    ],
    data() {
        return {
            filialFocus: false,
            selecionados: [],
            search: '',
            dialogo: false,
            arquivoImportado: null,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.selecionados = newValue
            },
        },
        selecionados() {
            this.$emit('input', this.selecionados)
            this.search = ''
        },
        search(newSearch) {
            if (!this.selecionados.some((item) => item.name === newSearch)) {
                this.selectItemsFromList(newSearch)
            }
        },
    },
    methods: {
        abrirDialogo() {
            this.dialogo = true
            this.arquivoImportado = null
        },
        processaCSV() {
            if (!this.arquivoImportado) {
                this.$dialog.notify.warning(
                    'Selecione um arquivo para importar'
                )
                return
            }
            const reader = new FileReader()
            reader.onload = (e) => {
                const csv = e.target.result
                const lines = csv.split('\n')
                const data = lines
                    .map((line) => line.split(';')[0])
                    .filter((line) => line)
                    .join(',')
                this.selectItemsFromList(data)
            }
            reader.readAsText(this.arquivoImportado)
            this.dialogo = false
        },
        selectItemsFromList(data) {
            if (!data || !data.includes(',')) return
            const id_java = data
                .split(',')
                .map((id) => Number(id.trim()))
                .filter((id) => !isNaN(id) && id > 0)

            const id_filial = this.items
                .filter((item) => id_java.includes(item.id_java))
                .map((item) => item.value)
            if (id_filial.length == 0) {
                this.$dialog.notify.warning(
                    `Filial ${id_java} não foi encontrada!`
                )
                this.search = ''
                return
            }

            const final = id_filial.filter(
                (item) => !this.selecionados.includes(item)
            )
            if (final.length == 0) {
                console.log('duplicada filial')
                this.$dialog.notify.warning(
                    `Filial ${id_java} já está selecionada.`
                )
            }

            this.selecionados.push(...final)
            this.search = ''
        },
    },
}
</script>
