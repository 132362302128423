<template>
  <v-form ref="formFiltro">
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <v-text-field
          v-model="filtro.busca"
          append-icon="mdi-magnify"
          label="Pesquisar"
          dense
          outlined
          class="input-cinza"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <MesInput
          label="Período Inicial"
          v-model="filtro.mesInicial"
          :rules="regra.mesInicial"
          :min="'2022-07'"
          required
        ></MesInput>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <MesInput
          label="Período Final"
          v-model="filtro.mesFinal"
          :rules="regra.mesFinal"
          :min="'2022-07'"
          required
        ></MesInput>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <v-autocomplete
          label="Filial"
          v-model="filtro.id_filial"
          :items="lista.filial"
          :menu-props="menuProps"
          multiple
          outlined
          class="input-cinza"
          dense
        >
          <template v-slot:selection="{ item, index }">
            <div
              v-if="filtro.id_filial.length === 1 && index === 0"
              style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
            >
              {{ item.text }}
            </div>
            <span
              v-if="filtro.id_filial.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.id_filial.length }} selecionados</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <v-autocomplete
          label="Região"
          v-model="filtro.regiao"
          :items="lista.regiao"
          :menu-props="menuProps"
          multiple
          outlined
          class="input-cinza"
          dense
        >
          <template v-slot:selection="{ item, index }">
            <div
              v-if="filtro.regiao.length === 1 && index === 0"
              style="
                width: 70% !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
                overflow: hidden;
              "
            >
              {{ item.text }}
            </div>
            <span
              v-if="filtro.regiao.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.regiao.length }} selecionados</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <v-select
          label="Cluster"
          :items="lista.cluster"
          v-model="filtro.id_cluster"
          multiple
          outlined
          class="input-cinza"
          :menu-props="menuProps"
          dense
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="filtro.id_cluster.length === 1 && index === 0">{{
              item.text
            }}</span>
            <span
              v-if="filtro.id_cluster.length > 1 && index === 0"
              class="grey--text caption mr-1"
              >{{ filtro.id_cluster.length }} selecionados</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <v-autocomplete
          label="Status"
          v-model="filtro.status"
          :items="lista.status"
          multiple
          outlined
          class="input-cinza"
          :menu-props="menuProps"
          dense
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="py-0">
        <v-autocomplete
          label="Bandeira"
          v-model="filtro.bandeira"
          :items="['DROGASIL', 'RAIA']"
          multiple
          outlined
          class="input-cinza"
          :menu-props="menuProps"
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        class="ml-auto py-0"
        align="end"
      >
        <v-btn class="ml-4 new-button"	color="yellow" dark @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar
        </v-btn>
        <v-btn class="ml-4 new-button" color="primary" @click="filtrar()">
          <v-icon left>mdi-filter</v-icon> Filtrar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
export default {
  inject: ["listaCampos"],

  data() {
    return {
      menuProps: {
        bottom: true,
        offsetY: true,
        contentClass: "select-outline",
        transition: "slide-y-transition",
      },
      lista: {
        campo: [
          { text: "ID JAVA", value: "id_java" },
          { text: "Cluster", value: "cluster" },
          { text: "Região", value: "regiao" },
        ],
      },
      filtro: {
        id_filial: [],
        busca: "",
      },
      regra: {
        mesInicial: [
          (v) => !!v || "Selecione um período inicial para o filtro",
          (v) =>
            (!!v && v >= "2021-06") ||
            "O período inicial deve ser igual ou posterior a junho de 2021",
        ],
        mesFinal: [
          (v) => !!v || "Selecione um período final para o filtro",
          (v) =>
            (!!v && v >= "2021-06") ||
            "O período final deve ser igual ou posterior a junho de 2021",
          (v) =>
            (!!v && v >= this.filtro.mesInicial) ||
            "O período final deve ser igual ou posterior ao período inicial",
        ],
      },
    };
  },
  methods: {
    iniciaFiltro() {
      this.filtro = {
        mesInicial: this.$moment().format("YYYY-MM"),
        mesFinal: this.$moment().format("YYYY-MM"),
        id_cluster: [],
        id_filial: [],
        regiao: [],
        status: [],
        busca: "",
      };
    },
    limpar() {
      this.iniciaFiltro();

      this.filtrar();
    },
    filtrar() {
      this.$emit("filtrar");
    },
  },
  watch: {
    "filtro.busca": function(v) {
      setTimeout(() => {
        if (v == this.filtro.busca) {
          this.$emit("filtrar");
        }
      }, 1500);
    },
  },
  async created() {
    this.iniciaFiltro();
    var lista = await this.listaCampos;
    this.lista.filial = lista.filial;
    this.lista.regiao = lista.regiao;
    this.lista.cluster = lista.cluster;
    this.lista.status = [
      { text: "Não lido", value: "0" },
      { text: "Lido", value: "1" },
      { text: "App atualizado", value: "2" },
    ];
    this.iniciaFiltro();
  },
};
</script>
<style scoped></style>
