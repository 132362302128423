<template>
  <v-card class="my-0 br-15 pa-5 pt-3 overflow-hidden" elevation="0">
    <div class="dist-investimento">
      <div class="graficoa">
        <InfoBox>
          <span class="titulo">Distribuição dos investimentos: </span> Gráfico
          com os percentuais e valores de invenstimentos, nas visões: PDV,
          Campanha e Ecommerce.
        </InfoBox>
        <h2
          class="text-center"
          v-if="empty || error"
          style="font-weight: normal"
        >
          Distribuição de <br />
          investimentos ({{ ano }})
        </h2>
        <highcharts :options="grafico" ref="chartDisInv" v-if="mostrarInfo" />
      </div>
      <Overlay :overlay="overlay" :error="error" :empty="empty"></Overlay>

      <v-row v-if="mostrarInfo" class="legenda-row">
        <v-col cols="12" sm="4" md="4" class="pb-0">
          <div class="legenda">
            <div class="legenda-titulo pdv">PDV</div>
            <div class="legenda-valor">
              <formataReal :valor="pegarValor('PDV')" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="pb-0">
          <div class="legenda">
            <div class="legenda-titulo campanha">Campanha</div>
            <div class="legenda-valor">
              <formataReal :valor="pegarValor('Campanha')" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="pb-0">
          <div class="legenda">
            <div class="legenda-titulo ecommerce">Ecommerce</div>
            <div class="legenda-valor">
              <formataReal :valor="pegarValor('E-commerce')" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Overlay from "./Overlay.vue";
import InfoBox from "./InfoBox.vue";

export default {
  components: {
    Overlay,
    InfoBox,
  },
  props: {
    ano: Number,
    fornecedor: Object,
  },
  data() {
    return {
      overlay: true,
      error: false,
      empty: false,
      cores: {
        PDV: "#6eca97",
        Campanha: "#2f988b",
        Ecommerce: "#fbc17a",
      },
      grafico: {
        chart: {
          height: 300,
          style: {
            fontFamily: "TT Norms Pro",
          },

          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          //     events: {
          //   load: adjustLegend,
          //   redraw: adjustLegend
          // }
        },
        title: {
          text: `Distribuição de <br> investimentos ${
            this.ano && `(${this.ano})`
          }`,
        },
      tooltip: {
          borderRadius: 10,
          padding: 6,
          headerFormat: '<div style="padding: 0 10px; margin-bottom:4px; font-size:14px; color: {point.color}; text-align: center">{point.point.name} ({point.y:.0f}%)</div>',
          useHTML: true,
          pointFormat:
            '<div style="text-align: center; padding: 0 10px;  font-size:14px; "> R$ {point.valor:.2f} </div>',
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        data: {
          decimalPoint: ",",
        },

        plotOptions: {
          pie: {
            borderWidth: "0px",
            allowPointSelect: false,
            cursor: "pointer",
            dataLabels: {
              color: "white",
              style: {
                textOutline: false,
              },
              enabled: true,
              format: "{point.percentage:.0f}%",
              distance: -50,
              filter: {
                property: "percentage",
                operator: ">",
                value: 4,
              },
            },
            colors: ["#fbc17a", "#6eca97", "#2f988b"],
            showInLegend: true,
          },
        },
        exporting: {
          chartOptions: {
            legend: {
              enabled: true,
            },
          },
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "print",
                "downloadPNG",
                "downloadJPEG",
                "downloadPDF",
                "downloadCSV",
                "downloadXLS",
              ],
            },
          },
        },
        legend: {
          enabled: false,
        },

        series: [
          {
            name: "Brands",
            colorByPoint: true,
            data: [],
          },
        ],
      },
    };
  },
  methods: {
    async getDados() {
      this.overlay = true;
      this.error = false;
      this.empty = false;

      const response = await this.$http().post(
        "/simulacao/resumo/distribuicaoInvestimento",
        {
          data: {
            id_fornecedor: this.fornecedor.value,
            ano_atual: this.ano,
          },
        }
      );

      if ((response || { status: 500 }).status != 200) {
        this.overlay = false;
        this.error = true;
        return;
      }

      const _tt = response.data.reduce(function (total, num) {
        return {
          valor: parseFloat(total.valor) + parseFloat(num.valor),
        };
      });

      if (_tt.valor == 0) {
        this.empty = true;
        return (this.overlay = false);
      }

      const _toData = response.data.map((e) => {
        return {
          name: e.name,
          valor: parseFloat(e.valor),
          color: this.cores[e.name],
          y:
            _tt.valor > 0
              ? this.$helpers.roundDecimal(
                  (parseFloat(e.valor) / _tt.valor) * 100,
                  2
                )
              : 0,
          sliced: false,
          selected: false,
        };
      });
      this.grafico.series[0].data = _toData;
      this.overlay = false;
    },

    pegarValor(ferramenta) {
      const dados = this.grafico.series[0].data.find(
        (e) => e.name == ferramenta
      );
      return dados ? dados.valor : 0;
    },
  },
  computed: {
    mostrarInfo() {
      return !this.error && !this.empty && !this.overlay;
    },
  },
  created() {},
  watch: {
    $props: {
      handler() {
        if (this.ano != null) this.getDados();
      },
      deep: true,
      immediate: false,
    },
  },
};
</script>
<style>
.grafico {
  height: 100%;
}
.dist-investimento {
  max-height: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
}
.legenda-row {
  margin-top: auto;
  flex-shrink: 1;
}

.pdv {
  background-color: #6eca97;
}
.campanha {
  background-color: #2f988b;
}
.ecommerce {
  background-color: #fbc17a;
}

.legenda {
  width: 100%;
  min-width: 100%;
  display: block;
  font-size: 0.8rem;
}
.legenda .legenda-titulo {
  text-align: center;
  color: #fff;
  font-size: 1rem;
  padding: 6px;
  border-radius: 8px 8px 0 0;
  font-size: 0.8rem;
  font-weight: 500;
}
.legenda .legenda-valor {
  background-color: #f2f2f2;
  text-align: center;
  padding: 6px;
  border-radius: 0 0 8px 8px;
  color: #888;
  font-weight: 500;
}
.highcharts-menu {
  border-radius: 10px;
  border: none !important;
  box-shadow: 0 0 5px #ccc !important;
  padding: 10px !important;
}
.highcharts-menu-item:hover {
  color: var(--v-primary-base) !important;
  background-color: #f2f2f2 !important;
  border-radius: 5px;
}
.highcharts-contextmenu {
}
.highcharts-menu-item {
  padding: 15px;
}

.highcharts-contextbutton {
  fill: blue;
}
</style>
